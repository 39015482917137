import React from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CreatableSelect from "react-select/lib/Creatable";
import { useFormAnswerInfos } from "../../context";

export default (props) => {
  const [managers, setManagers] = React.useState([]);
  const { company_id } = useFormAnswerInfos();

  const prefix = {
    id: "supervisor_info_attributes_name",
    name: "supervisor_info_attributes[name]",
  };

  const { t } = useTranslation();

  const getManagers = () => {
    axios
      .get(`/selects/${company_id}/supervisors.json`)
      .then((res) => setManagers(res.data))
      .catch((error) => console.log(error.response));
  };

  const handleChange = (manager) => {
    props.setValue(prefix.name, manager?.value);
    props.handleSupervisorChange(manager);
  }

  React.useEffect(() => {
    getManagers();
  }, [company_id]);

  return (
    <CreatableSelect
      onChange={handleChange}
      required
      options={managers}
      placeholder={t(
        "update_form_answer.questions.company_manager.name.placeholder"
      )}
      isSearchable
      isClearable
      noOptionsMessage={() => <span>Sem opções disponíveis</span>}
      formatCreateLabel={(value) => <span>Selecionar "{`${value}`}"</span>}
    />
  );
};
