import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useCompany() {
  const { id } = useParams();
  const { data, isLoading } = useFetch(`/v1/profile/companies/${id}`);

  return {
    company: data?.data?.attributes,
    contributions: data?.included?.filter(element => element.type === "contribution"),
    isLoading,
  };
}
