import React from "react";
import { useJob } from "./context";
import Main from "./Main";
import MetaTags from "./MetaTags";
import { Loader } from "components/ui";
import EditMode from "components/users/EditMode";
import "./style.scss";

export default () => {
  const { isLoading } = useJob();

  if(isLoading){
    return <Loader />
  }

  return (
    <>
      <div className="job-show">
        <EditMode>
          <Main />
        </EditMode>
      </div>
    </>
  );
};
