import React from "react";
import "./style.scss";
import Welcome from "../components/body/Welcome";
import CourseArea from "../components/body/CourseArea";
import { Content } from "components/ui/academy/header/styles";
import { connect } from "react-redux";
import Home from './Home';

function Academy(props) {
  const { user } = props;
  return (
    <Content>
      <Home user={user} />
    </Content>
  )
}

function mapStateToProps(state){
  const user = state.session?.attributes;
  return { user }
}

export default connect(mapStateToProps)(Academy); 