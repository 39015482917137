import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import {default as Name} from "components/users/fields/essential/Name";
import Email from "components/users/fields/essential/Email";
import Password from "components/users/fields/settings/Password";
import PasswordConfirmation from "components/users/fields/settings/PasswordConfirmation";
import { USERS } from "navigation/ROUTES";

function Form() {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  let navigate = useNavigate();

  const successfulRegistration = () => {
    navigate(USERS.ESSENTIAL_FIELDS);
    swal(
      "Olá!",
      "Seu cadastro foi criado com sucesso! Seja bem-vindo(a) ao WallJobs!",
      "success"
    );
  };

  const unsuccessfulRegistration = () => {
    swal(
      "Ops!",
      "Não foi possível concluir seu cadastro. Por favor, corrija os campos solicitados e tente novamente!",
      "error"
    );
  };

  const getData = () => {
    api
      .post(``)
      .then(() => successfulRegistration())
      .catch(() => unsuccessfulRegistration());
  };

  return (
    <form onSubmit={handleSubmit(getData)}>
      <h3>Crie seu cadastro</h3>
      <Name
        register={register}
        errors={errors}
        required
        prefix={{
          id: "user_user_info_attributes_full_name",
          name: "user[user_info_attributes][full_name]",
        }}
      />
      <Email
        register={register}
        errors={errors}
        required
        prefix={{ id: "user_email", name: "user[email]" }}
      />
      <Password register={register} errors={errors} required />
      <PasswordConfirmation
        register={register}
        errors={errors}
        required
      />
      <button className="button" type="submit">
        Cadastre-se agora
      </button>
      <p>
        Ao clicar em Cadastre-se agora, você aceita o{" "}
        <a href="https://www.walljobs.com.br/terms.pdf">
          Contrato do Usuário, a Política de Privacidade e a Política de Cookies
          do WallJobs
        </a>
        .
      </p>
    </form>
  );
}

export default Form;
