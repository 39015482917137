import styled from "styled-components";
import { Paper } from "@mui/material";

export const Test = styled(Paper)`
  min-height: 210px;
  padding: 20px;
  border-radius: 5px;
  position: relative;

  p.unavailable {
    color: #536e6e;
    margin-bottom: 0;
  }

  h3.result {
    margin-bottom: 0;
  }

  a.button {
    background: #7338c4;
    margin-bottom: 0;
  }
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 5px;
`;

export const Status = styled.div`
  justify-self: end;
  margin: 0;
  height: fit-content;
`;

export const Title = styled.p`
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
`;

export const Small = styled.small`
  display: block;
  text-align: right;
  font-size: 12px;
  margin-bottom: 2px;

  i {
    font-size: 10px;
    position: relative;
    top: 2px;
  }
`;

export const LinkOrResult = styled.div`
  position: absolute;
  bottom: 20px;

  h2 {
    margin-bottom: 0;
  }
`;

export const Link = styled.div`
  position: absolute;
  bottom: 20px;
`;

export const Button = styled.button`
  background: #7338c4;
  margin-bottom: 0;
`;
