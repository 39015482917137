import React, { useState, useEffect } from "react";
import _ from "lodash";
import api from "services/api";
import { MenuItem, TextField } from "@mui/material";
import { useFilters } from "utils/hooks";
import { Filter } from "components/ui";
import { FilterContext } from "components/filters/FilterController";

function Filters() {
  const { infos, filterData } = React.useContext(FilterContext);

  const params = { name: "", jobs: "", sector: "", location: "" };

  const {
    filters,
    handleChange,
    createFilterLabel,
    renderFilters,
    handleSelect,
  } = useFilters(params, filterData);

  return (
    <Filter
      length={infos.length}
      totalCount={infos.totalCount}
      renderFilters={renderFilters}
    >
      <Name
        filters={filters}
        handleChange={handleChange}
        createFilterLabel={createFilterLabel}
      />
      <Jobs filters={filters} handleSelect={handleSelect} />
      <Sector filters={filters} handleSelect={handleSelect} />
      <Location
        filters={filters}
        handleChange={handleChange}
        createFilterLabel={createFilterLabel}
      />
    </Filter>
  );
}

function Name(props) {
  const { filters, handleChange, createFilterLabel } = props;

  return (
    <TextField
      filter
      variant="standard"
      id="name"
      name="name"
      label="Nome"
      placeholder="Ex: WallJobs, 99, Goldman Sachs..."
      value={filters.name}
      onChange={handleChange}
      onBlur={createFilterLabel}
    />
  );
}

function Jobs(props) {
  const { filters, handleSelect } = props;
  const options = [
    { value: "true", label: "Com vagas" },
    { value: "false", label: "Sem vagas" },
  ];

  function _renderOptions() {
    const list = options || [];
    return list.map((item) => (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    ));
  }

  return (
    <TextField
      select
      filter
      variant="standard"
      id="jobs"
      name="jobs"
      label="Vagas"
      placeholder="Selecione..."
      value={filters.jobs}
      onChange={(event) => handleSelect(event, options)}
    >
      {_renderOptions()}
    </TextField>
  );
}

function Sector(props) {
  const [sectors, setSectors] = useState([]);
  const { filters, handleSelect } = props;

  useEffect(() => {
    if (_.isEmpty(sectors)) getSectors();
  });

  function _renderOptions() {
    const list = sectors || [];
    return list.map((sector) => (
      <MenuItem key={sector.value} value={sector.value}>
        {sector.label}
      </MenuItem>
    ));
  }

  function getSectors() {
    api
      .get("/selects/sectors.json")
      .then((resp) => {
        let sectors = resp.data;
        setSectors(sectors);
      })
      .catch(() => setSectors([]));
  }

  return (
    <TextField
      select
      filter
      variant="standard"
      id="sector"
      name="sector"
      label="Setor"
      placeholder="Ex: Startup, Serviços..."
      value={filters.sector}
      onChange={(event) => handleSelect(event, sectors)}
    >
      {_renderOptions()}
    </TextField>
  );
}

function Location(props) {
  const { filters, handleChange, createFilterLabel } = props;
  return (
    <TextField
      filter
      variant="standard"
      id="location"
      name="location"
      label="Localidade"
      placeholder="Ex: Avenida Paulista, RJ..."
      value={filters.location}
      onChange={handleChange}
      onBlur={createFilterLabel}
    />
  );
}

export default Filters;
