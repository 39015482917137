import React from "react";
import ChangePassword from "./actions/ChangePassword";
import TwoFactor from "./actions/TwoFactor";
import SoftDelete from "./actions/SoftDelete";

const tabs = [<ChangePassword />, <TwoFactor />, <SoftDelete />];

function Content(props) {
  const { selectedIndex } = props;
  return (
    <div className="content" role="tabpanel">
      {tabs[selectedIndex]}
    </div>
  );
}

export default Content;
