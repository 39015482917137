import React from "react";
import { Link } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";
import { ATS } from "navigation/ROUTES";
import { Paper } from "@mui/material";

const Questionnaire = ({ candidature_id, questionnaire }) => (
    <Paper elevation={10} className="questionnaire">
      <div className="header">
        <small>Questionário</small>
        <div
          className="status"
          dangerouslySetInnerHTML={{
            __html: questionnaire.status,
          }}
        ></div>
      </div>
      <p className="name">
        <Link
          to={{
            pathname: pathWithParams(ATS.QUESTIONNAIRE.FORM_ANSWER.EDIT, {id: questionnaire.id}),
            state: { candidature_id },
          }}
        >
          {questionnaire.title}
        </Link>
      </p>
      <small className="answer_at">
        <i className="material-icons">access_time</i> {questionnaire.answer_at}
      </small>
      <div className="link-or-result">
        {questionnaire.finished ? (
          <h2 className="result">{questionnaire.result}</h2>
        ) : (
          <Link
            to={{
              pathname: pathWithParams(ATS.QUESTIONNAIRE.FORM_ANSWER.EDIT, {id: questionnaire.id}),
              state: { candidature_id },
            }}
            className="button"
          >
            Responder
          </Link>
        )}
      </div>
    </Paper>
);

export default Questionnaire;
