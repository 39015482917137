import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";
import { pathWithParams } from "utils/pathWithParams";
import {
  Name,
  Email,
  CPF,
  CellPhone,
  DateOfBirth,
  Ethnicity,
  Gender,
  MaritalStatus,
} from "components/users/fields/essential";

import {
  University,
  Course,
  CurrentSemester,
  FormationDate,
  Matriculation,
  Period,
} from "components/users/fields/academic";

import AddressSection from "pages/profile/users/Wizard/forms/sections/AddressSection";
import DisabilitySection from "pages/profile/users/Wizard/forms/sections/DisabilitySection";

export function useConvention() {
  const { uuid } = useParams();
  const path = pathWithParams("/v1/conventions/conventions/attributes", { uuid })
  const { data, isLoading } = useFetch(path);

  return {
    convention: data?.data?.attributes,
    isLoading,
  };
}

export const userInfoAttributes = {
  full_name: <Name />,
  email: <Email />,
  cpf: <CPF />,
  university_id: <University />,
  course_id: <Course />,
  cellphone: <CellPhone />,
  date_of_birth: <DateOfBirth />,
  ethnicity_id: <Ethnicity />,
  gender_id: <Gender />,
  marital_status: <MaritalStatus />,
  period: <Period />,
  current_semester: <CurrentSemester />,
  formation_date: <FormationDate />,
  matriculation: <Matriculation />,
  address: <AddressSection />,
  disabilities: <DisabilitySection />,
}