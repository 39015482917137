import React from "react";
import { useUserInfo } from "../../context";
import { EditIcon } from "components/users/EditMode";
import { useTranslation } from "react-i18next";

function University() {
  const { userInfo } = useUserInfo();
  const {
    university,
    course,
    course_situation,
    period,
    formation_date,
    current_semester,
    course_start_date,
    not_in_university,
  } = userInfo;
  
  const { t } = useTranslation();

  return (
    <>
      <EditIcon type="academic" />
      {!not_in_university ? (
      <section className="university">
        <h3>{university}</h3>
        <h4>
          {course} {course_situation}
        </h4>
        <p>
          <b>{t("user_info.attributes.period")}:</b> {period}
        </p>
        <p>
          <b>{t("user_info.attributes.course_start_date")}:</b> {course_start_date}
        </p>
        <p>
          <b>{t("user_info.attributes.formation_date")}:</b> {formation_date}
        </p>
        <p>
          <b>{t("user_info.attributes.current_semester")}:</b> {current_semester}
        </p>
      </section>
      ) : (
      <p>
        <b>Não possui ensino superior.</b>
      </p>
      )}
    </>
  );
}

export default University;
