import React from "react";
import { Paper } from "@mui/material";

const Test = ({ test }) => (
  <Paper elevation={10} className="test">
    <div className="header">
      <small>Teste</small>
      <div
        className="status"
        dangerouslySetInnerHTML={{ __html: test.status }}
      ></div>
    </div>
    <p className="name">{test.name}</p>
    <small className="answer_at">
      <i className="material-icons">access_time</i> {test.answer_at}
    </small>
    <small className="duration">Duração: {test.duration}</small>
    <div
      className="link-or-result"
      dangerouslySetInnerHTML={{ __html: test.link_or_result }}
    ></div>
  </Paper>
);

export default Test;
