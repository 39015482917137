import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useFormAnswer } from "./context";
import api from "services/api";
import Answer from "./Answer";
import { pathWithParams } from 'utils/pathWithParams'
import { USERS } from "navigation/ROUTES";

function Form() {
  let location = useLocation();
  let navigate = useNavigate();
  const candidature_id = location?.state?.candidature_id
  
  const { form } = useFormAnswer();
  const { finished, answers, observation } = form;
  const { register, control, setError, errors, handleSubmit, getValues } = useForm();

  const renderAnswers = () => {
    return answers?.map((answer, index) => (
      <Answer
        key={answer.id}
        answer={answer}
        index={index}
        control={control}
        register={register}
        errors={errors}
        getValues={getValues}
        disabled={finished}
      />
    ));
  };

  const renderButton = () => {
    return !finished && (
      <button className="button button-primary" type="submit">
        Enviar
      </button>
    )
  }

  const submitAnswers = (data) => {
    api
      .put(`/v1/ats/questionnaire/form_answers/${form.id}`, { data })
      .then(() => successfulRequisition())
      .catch((error) => unsuccessfulRequisition(error.response.data.errors));
  };

  function successfulRequisition(){
    const request = candidature_id ? pathWithParams(USERS.CANDIDATURES.SHOW, {id: candidature_id}) : USERS.HOME;
    navigate(request);
    swal(
      "Formulário respondido!",
      "Suas respostas foram salvas com sucesso! Boa sorte neste processo seletivo!",
      "success"
    );
  }

  function unsuccessfulRequisition(errors){
    for(let field in errors){
      errors[field].forEach((message) => {
        setError(field, {type: "manual", message})
      })
    }

    swal(
      "Ops!",
      "Não foi possível salvar suas respostas! Por favor, tente novamente.",
      "error"
    );
  }

  return (
    <form onSubmit={handleSubmit(submitAnswers)}>
      <h3>Responda às perguntas para continuar com o processo:</h3>
      {renderAnswers()}
      <p dangerouslySetInnerHTML={{__html: observation}}></p>
      {renderButton()}
    </form>
  );
}

export default Form;
