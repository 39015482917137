import React from "react";
import Section from "./Section";
import Education from "components/users/fields/academic/Education";

function EducationSection(props) {
  return (
    <Section title="Outras formações">
      <Education {...props} />
    </Section>
  );
}

export default EducationSection;
