import React from "react";
import { useJob } from "../context";
import Question from "./Question";
import styled from "styled-components"

function Faq(){
  const { job, company } = useJob();
  const list = job.faq || [];

  const Title = styled.h1`
    text-align: center;
    margin: 1rem 0 1rem 0;
  `

  const Section = styled.section`
    margin-top: 1.25rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  `

  const SectionTitle = styled.h2`
    margin: 1rem auto 1rem auto;
  `

  return (
    <div>
      <Title>Dúvidas Frequentes</Title>
      {list.map((section) => (
        <>
          <Section>
          <SectionTitle key={section.section_id}>{section.section_name}</SectionTitle>
            {section.questions.map((question_arr) => (
              <>
                {question_arr.map((question) => (
                  <Question question={question.question} answer={question.answer} />
                ))}
              </>
            ))}
          </Section>
        </>
      ))}
    </div>
  )
}

export default Faq;