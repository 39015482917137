import React, { useState, useEffect } from "react";
import _ from "lodash";
import Axios from "axios";
import { serialize } from "object-to-formdata";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import Fields from "./Fields";
import { useTranslation } from "react-i18next";
import { useFormAnswer } from "./context";

function Form() {
  const [data, setData] = useState({});
  const { pathname } = useLocation();
  const {
    register,
    unregister,
    control,
    handleSubmit,
    setError,
    resetField,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { company } = useFormAnswer();

  const { t } = useTranslation();

  const getData = (collectedData) => {
    disableButton();
    setData(collectedData);
  };

  const successfulRequisition = () => {
    swal(
      t(`admission.form_answer.${company.type}.successfulRequisition.title`),
      t(`admission.form_answer.${company.type}.successfulRequisition.text`),
      "success"
    ).then(() => window.location.href = '/usuarios/home');
  };

  const unsuccessfulRequisition = (errors) => {
    $(".button:submit").removeAttr("disabled")
    swal(
      t(`admission.form_answer.${company.type}.unsuccessfulRequisition.title`),
      t(`admission.form_answer.${company.type}.unsuccessfulRequisition.text`),
      "error"
    );

    for (let field in errors) {
      errors[field].forEach((message) => {
        setError(field, { type: "manual", message });
      });
    }
  };

  const submit = () => {
    const params = serialize({ 
      user_info: _.omit(data, ['internship_type', 'intern_response', 'has_created_rescission', 'has_signed_rescission', 'internship_end_date']),
      contract: {internship_type: data.internship_type},
      invite: {
        intern_response: data.intern_response,
        has_created_rescission: data.has_created_rescission, 
        has_signed_rescission: data.has_signed_rescission, 
        internship_end_date: data.internship_end_date
      }
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Axios.post(pathname, params, config)
      .then(() => successfulRequisition())
      .catch((error) => unsuccessfulRequisition(error.response.data.errors));
  };

  const disableButton = () => {
    $(".button:submit").attr("disabled", true);
  }

  useEffect(() => {
    if (!_.isEmpty(data)) submit();
  }, [data]);

  return (
    <form onSubmit={handleSubmit(getData)}>
      <Fields
        register={register}
        unregister={unregister}
        control={control}
        errors={errors}
        resetField={resetField}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
      />
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <button className="button" type="submit">
            Enviar
          </button>
        </div>
      </div>
      <div className="col-md-12 text-center">
        <p>Em caso de dúvidas ou para entender mais sobre os seus proxímos passos <a href="https://walljobs.octadesk.com/kb/category/estagiario" target="_blank">clique aqui!</a></p>
      </div>
    </form>
  );
}

export default Form;
