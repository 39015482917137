import React, { useState } from "react";
import { Collapse } from "@mui/material";
import { useCandidature } from "../context";
import { ExpandMore } from "components/ui";

function Description() {
  const { job } = useCandidature();
  const [expanded, setExpanded] = useState(false);

  return (
    <section className="description">
      <h3>
        Descrição da Vaga 
        <ExpandMore expanded={expanded} setExpanded={setExpanded} />
      </h3>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div dangerouslySetInnerHTML={{ __html: job.description }}></div>
      </Collapse>
    </section>
  );
}

export default Description;
