import React from "react";
import Embed from "./Embed";
import { Breadcrumb, ContentBox, MainBox } from "components/ui";
import InternMentoring from "./InternMentoring";
import Fieldset from "./components/Fieldset";

function Main(){
  return(
    <MainBox>
      <Breadcrumb>
        <a>Mentores de Carreira</a>
      </Breadcrumb>
      <ContentBox>
        <InternMentoring />
        <Fieldset />
        <Embed />
      </ContentBox>
    </MainBox>
  )
}

export default Main;