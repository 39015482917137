import React from "react";
import _ from "lodash";
import { ContentBox } from "components/ui";
import { useCompany } from "./context";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Contributions() {
  const { contributions } = useCompany();

  const _renderContributions = () => {
    return contributions.map((contribution) => (
      <Contribution key={contribution.id} attributes={contribution.attributes} />
    ));
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  if(_.isEmpty(contributions)){
    return;
  }

  return(
    <ContentBox>
      <div className="section">
        <h3>Depoimentos ({contributions.length})</h3>
        <div className="contributions">
          <Slider {...settings}>{_renderContributions()}</Slider>
        </div>
      </div>
    </ContentBox>
  );
}

export default Contributions;

function Contribution({ attributes }) {
  return (
    <div className="contribution">
      <img src={attributes.avatar} alt={`Foto de ${attributes.name}`} />
      <h4>{attributes.name}</h4>
      <small>{attributes.title}</small>
      <p dangerouslySetInnerHTML={{ __html: attributes.testimony }}></p>
    </div>
  );
}
