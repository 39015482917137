import React from "react";
import { useEnvelope } from "../context";

export default () => {
  const { envelope } = useEnvelope();

  return(
    <header className="content-box-header">
      <div className="content-box-header-infos">
        <img className="logo" src={envelope.company_avatar} alt={`Logo ${envelope.company_name}`} />
        <div>
          <h3>{envelope.company_name}</h3>
          <h1>{envelope.type_name}</h1>
          <h4>{envelope.company_sector_name}</h4>
          <p>
            <b>Documento criado em {envelope.created_at}</b>
          </p>
        </div>
      </div>
    </header>
  )
}