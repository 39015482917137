import styled from "styled-components";
import MuiAccordion from '@mui/material/Accordion';
import MuiTypography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles'
import { styled as styledMui } from '@mui/system';

export const DrawerOpen = styled.div`
  width: 380px;
  padding: 32px;
  background-color: #333b4d;
  display: flex;
  flex-direction: column !important;
`

export const ButtonToggle = styled.div`
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.closed ? "center" : "space-between"} !important;

  .back {
    color: #FAFAFA;
    font-size: 14px;
    cursor: pointer;
  }
`

export const DrawerClosed = styled.div`
  width: 80px;
  min-height: 100vh;
  background-color: #333b4d;
  display: flex;
`

export const Accordion = styledMui(MuiAccordion)(({ theme }) => ({
  borderRadius: "7px",
  border: "1px solid #333b4d !important",
  boxShadow: "none",
  margin: "1rem 0 0  !important",
  color: "white",
  backgroundColor: "#1f232e",
  '::before': {
    display: "none",
  },
  WebkitTransition: "all 0.3s ease-in",
  MozTransition: "all 0.3s ease-in",
}));

export const Typography = styledMui(MuiTypography)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  fontWeight: "bold",
}))

export const useStyles = makeStyles(() => ({
  expanded: {
    margin: "300px !important",
  },
  content: {
    margin: "0 !important",
    justifyContent: "space-between",
  },
}));

export const LectureContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 20px;
  justify-content: space-between;
  border-radius: 4px;
`

export const LectureTitle = styled.p`
  padding-left: 1rem;
  font-weight: 600;
`

export const LectureInfos = styled.div`
  margin: 1rem 4px;
  display: flex;
  flex-direction: column;
`

export const TimeInfos = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  align-items: center;
`

export const LeftItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
export const RightItems = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const LectureDuration = styled.p`
  padding-left: 1rem;
`
