import React from 'react';
import Disabilities from './Disabilities';

function Icons(){
  return(
    <div className="icons">
      <Disabilities />
    </div>
  )
}

export default Icons;