import React from "react";
import { TextField } from "@mui/material";
import { useFilters } from "utils/hooks";
import { Filter } from "components/ui";
import { FilterContext } from "components/filters/FilterController";

function Filters() {
  const { infos, filterData } = React.useContext(FilterContext);

  const params = { title: "", company: "" };

  const { filters, handleChange, createFilterLabel, renderFilters } =
    useFilters(params, filterData);

  return (
    <Filter
      length={infos.length}
      totalCount={infos.totalCount}
      renderFilters={renderFilters}
    >
      <TextField
        filter
        variant="standard"
        id="title"
        name="title"
        label="Evento"
        placeholder="Digite o nome do evento..."
        value={filters.title}
        onChange={handleChange}
        onBlur={createFilterLabel}
      />
      <TextField
        filter
        variant="standard"
        id="company"
        name="company"
        label="Empresa"
        placeholder="Ex: WallJobs, 99, Goldman Sachs..."
        value={filters.company}
        onChange={handleChange}
        onBlur={createFilterLabel}
      />
    </Filter>
  );
}

export default Filters;
