import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

 export const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  align-self: flex-start;

  @media (min-width: 767px) {
    font-size: 24px;
    padding: 1.75rem 0 0 0;
  }
`;

export const OptionsContainer = styled.div`
  width: 100%;
  min-height: 450px;
  margin: 20px auto;
  padding: 1.5rem;
  background: rgba(217, 217, 217, 0.40);
  box-shadow: 4px 4px 18px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column; 
`;

export const Button = styled.button`
  align-self: center;
  margin-top: 25px;
  background-color: #009bf6;
  color: #ececee;
  display: flex;
  width: 300px;
  max-width: 90vw;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 8px;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  z-index: 10;
  display: block;
  margin-top: auto;
  margin-left: auto;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  cursor: pointer;

  input {
    align-self: flex-start;
    margin-top: 8px;
  }

  ${(props) =>
    props.isLast &&
    css`
      @media (min-width: 767px) {
        padding-bottom: 100px;
      }
    `}
`;

export const OptionText = styled.label`
  font-size: 16px;
  cursor: pointer;
`;

export const Video = styled.div`
  width: 800px;
  max-width: 100%;
  align-self: flex-start;
  margin: .5rem 0;
  z-index: 2;
`;

export const FlexOption = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FinishContainer = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  margin: 50px 0;
  width: 100%;
  align-items: center;
`;

export const FinalizeButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #009bf6;
  color: #ececee;
  width: 300px;
  max-width: 90vw;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`;