import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  
  @media (min-width: 732px) {
    padding-top: 0;
  }

  @media (min-width: 1234px){
    display: ${props => props.display || "grid"};
    grid-template-columns: auto auto;
    padding: 10px 200px;
    gap: 10px;
    row-gap: 13px;
  }

`