import React from "react";
import { UncontrolledInput as Input } from "components/form";
import { useTranslation } from "react-i18next";

export default (props) => {
  const { t } = useTranslation();

  const prefix = {
    name: `observation`,
    id: `observation`,
  }

  return(
    <Input
      {...props}
      type="text"
      multiline
      placeholder={t("update_form_answer.questions.observation.placeholder")}
      rows={3}
      name={prefix.name}
      id={prefix.id}
      label={props.label || t("update_form_answer.questions.observation.label")}
    />
  )
}