import styled from "styled-components"

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 60rem;
  margin: 30px auto 0 auto;
`

export const Action = styled.a`
  text-align: center;
  padding: 13px;
  color: white;
  min-width: 197px;
  border-radius: 12px;
  cursor: pointer;
  background-color: #C13950;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 0 0 0.9em rgba(0, 0, 0, 0.4);
  
  :hover {
    background-color: #711324;
    color: white;
    transition: .4s;
  }
  @media(max-width: 764px){
    margin: 15px;
  }
`

export const CertificateContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 980px;
  max-height: 720px;
  height: 720px;
  background-image: linear-gradient(to bottom right, #C13950, #711324);
  margin: 2rem auto;
  @media (max-width: 1024px){
    width: 720px;
    height: 460px;
  }
  @media (max-width: 720px){
    width: 260px;
    height: 211px;
  }
`

export const CertificateTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100px;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 720px){
    width: 40px;
  }
`

export const CertificateTitle = styled.p `
  font-size: 90px;
  text-transform: uppercase;
  transform: rotate(270deg);
  background: linear-gradient(to right top, #C13950, #711324);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  @media (max-width: 1024px){
    font-size: 70px;
  }
  @media (max-width: 720px){
    font-size: 34px;
  }
`

export const CertificateMainContent = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CertificateHeader = styled.div `
  display: flex;
  flex-direction: row;
  padding: 2rem;
  max-height: 200px;
  width: 100%;
  word-break: break-all;
  justify-content: space-evenly;
  @media (max-width: 720px){
    height: 80px;
    padding: 12px;
  }
`

export const CertificateHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
`

export const WJLogo = styled.img`
  width: 200px;
  height: 180px;
  @media (max-width: 720px){
    width: 30px;
    height: 23px;
    align-self: center;
  }
`

export const WJTitle = styled.h3`
  color: white;
  font-size: 1.25rem !important;
  @media (max-width: 720px){
    font-size: 0.555rem !important;
  }
`

export const CourseTitle = styled.h2`
  color: white;
  font-size: 2rem;
  @media (max-width: 720px){
    font-size: 0.755rem !important;
  }
`

export const CourseDuration = styled.h3`
  color: white;
  font-weight: 600;
  font-size: 1rem;
  @media (max-width: 720px){
    font-size: 0.555rem !important;
  }
`

export const CertificateContent = styled.div `
  width: 100%;
  display: flex;
  color: white;
  font-size: 28px;
`
export const TextCertification = styled.div `
  padding: 100px;
  @media (max-width: 1024px){
    font-size: 16px;
    padding: 40px;
  }
  @media (max-width: 720px){
    font-size: 10px;
    padding: 6px;
  }
`
