import React from "react";
import { connect } from "react-redux";
import { Loader } from "components/ui";
import { useCourse, useNextItem, useUserInfo } from "./context";
import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';

import Section from "./Section";
import { USERS, ACADEMY } from "navigation/ROUTES";
import api from "services/api";
import { pathWithParams } from "utils/pathWithParams";
import DOMPurify from "dompurify";
import {
  SectionContainer,
  CourseImage,
  CourseImageDiv,
  CourseContainer,
  CourseContainerDescription,
  CourseArea,
  CourseTitle,
  CourseDescription,
  CourseContainerDuration,
  CourseLabel,
  CourseWorkload,
  CourseInfoWrapper,
  CourseActionsDuration,
  CourseContainerActions,
  CourseActionsDescription,
  CourseActionsTitle,
  CourseActionsOthers,
  AccordionCourse,
  AccordionSummary,
  ButtonBox,
  useStyles,
  InfoBox,
  SubmitButton,
} from "./styles";

function Show(props) {

  let navigate = useNavigate();

  const redirectNoLoggedUser = () => {
    swal(
      "Ops!",
      "Você precisa estar logado para ver essa página",
      "warning"
    ).then(() => {
      navigate(USERS.LOGIN)
    })
  }

  const { user, isLogged } = props;

  if(user === undefined ) {
    return redirectNoLoggedUser();
  };

  const { course, courseIsLoading } = useCourse();
  const { userInfo, isLoading } = useUserInfo(user.slug);
  const { nextItem, nextItemIsLoading } = useNextItem();
  const classes = useStyles();

  if (isLoading || courseIsLoading || nextItemIsLoading) {
    return <Loader />;
  }

  function getMatriculation() {
    const matriculation = userInfo.academy_matriculations.find(userInfo => userInfo.academy_course_id === course.id);
    return matriculation;
  }

  const matriculation = getMatriculation();

  if (course.target === "internship" && !userInfo.intern || course.target === "candidate" && userInfo.intern) {
    swal({
      title: "Ops!",
      text: `Esse curso é apenas para ${course.target === "internship" ? "estagiários" : "candidatos"}.`,
      type: "warning",
      timer: 3000
    }).then(() => {
      navigate(ACADEMY.COURSE.INDEX);
    })
  }

  const successfulRequisition = () => {
    swal(
      "Sucesso!",
      `Sua inscrição no curso foi concluída. Agora você pode assistir às aulas e ter acesso a todo conteúdo do curso.`,
      "success"
    ).then(() => {
      window.location.reload(false);
    })
  };

  const unsuccessfulRequisition = (message) => {
    swal("Ops!", message, "warning");
  };

  const applyInCourse = () => {
    const data = { course_id: course.slug, user_info_id: user.slug };
    api
      .post(`/v1/academy/matriculations`, data)
      .then(() => successfulRequisition())
      .catch((error) => unsuccessfulRequisition());
  };

  const redirectOrApply = () => {
    if (isLogged) {
      applyInCourse();
    } else {
      navigate(USERS.LOGIN);
      swal(
        "Ops!",
        "Você precisa estar logado para se matricular neste curso!",
        "warning"
      );
    }
  };

  function redirectToCourse(matriculation) {
    navigate(pathWithParams(ACADEMY.COURSE.WATCH, { id: course.slug, item_id: nextItem, matriculation_id: matriculation.id }));
  }

  function RenderButtonApply() {

    if (matriculation != undefined) {
      return (
        <SubmitButton onClick={() => redirectToCourse(matriculation)}>
          Assista o curso
        </SubmitButton>
      )
    } else {
      return (
        <SubmitButton onClick={() => redirectOrApply()}>
          Matricule-se
        </SubmitButton>
      )
    }
  }

  function RenderButtonCertificate() {
    if (matriculation != undefined && matriculation.finished_at != undefined) {
      return (
        <SubmitButton onClick={() => navigate(pathWithParams(ACADEMY.COURSE.FINISHED, { id: course.slug, matriculation_id: matriculation.id }))}>
          Certificado
        </SubmitButton>
      )
    }
  }

  const description = DOMPurify.sanitize(course.description)

  return (
    <>
      <SectionContainer>
        <CourseContainer>
          <CourseImageDiv>
            <CourseImage alt="Foto do Curso" src={course.image} />
          </CourseImageDiv>
          <CourseContainerDescription>
            <CourseArea>Curso de {course.area}</CourseArea>
            <CourseTitle>{course.title}</CourseTitle>
            <CourseDescription dangerouslySetInnerHTML={{ __html: description }}/>
          </CourseContainerDescription>
          <CourseContainerDuration>
            <CourseContainerActions>
              <CourseInfoWrapper>
                <i className="material-icons">access_time</i>
                <CourseLabel>Carga Horária:</CourseLabel>
                <CourseWorkload><p>{course.duration}</p></CourseWorkload>
              </CourseInfoWrapper>
              <ButtonBox style={{ alignSelf: "flex-end" }}>
                {RenderButtonCertificate()}
                {RenderButtonApply()}
              </ButtonBox>
            </CourseContainerActions>
          </CourseContainerDuration>
        </CourseContainer>
      </SectionContainer>
      <InfoBox>
        <AccordionCourse defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{
              fontSize: '30px',
              color: 'rgba(0, 0, 0, 0.35)',
            }} />}
            aria-controls="panel1a-content"
            id="panel1a-header" classes={{ content: classes.content }}
          >
            <CourseActionsTitle>{course.title}</CourseActionsTitle>
          </AccordionSummary>
          <AccordionDetails>
            <CourseActionsDescription dangerouslySetInnerHTML={{ __html: description, }} />
            <CourseActionsDuration>Carga Horária: {course.formatted_duration}.</CourseActionsDuration>
            <CourseActionsOthers>
              <p>Outras Ações: </p>
              <ButtonBox>
                {RenderButtonCertificate()}
                {RenderButtonApply()}
              </ButtonBox>
            </CourseActionsOthers>
          </AccordionDetails>
        </AccordionCourse>
        {course.content.map((section) => (
          <>
            <Section section={section} course_id={course.slug} matriculation={() => getMatriculation()} />
          </>
        ))}
      </InfoBox>
    </>
  )
}

function mapStateToProps(state) {
  const user = state.session?.attributes;
  const isLogged = state.session?.isLogged;

  return { user, isLogged };
}

export default connect(mapStateToProps)(Show);