import React from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledInput as Input } from "components/form";

export default (props) => {
  const prefix = {
    id: "supervisor_info_attributes_email",
    name: "supervisor_info_attributes[email]",
  };

  const { t } = useTranslation();

  React.useEffect(() => {
    props.setValue(prefix.name, props.defaultValue);
  }, [props.defaultValue]);

  return (
    <Input
      {...props}
      placeholder={t(
        "update_form_answer.questions.company_manager.email.placeholder"
      )}
      type="text"
      name={prefix.name}
      id={prefix.id}
      label={t("update_form_answer.questions.company_manager.email.label")}
      InputLabelProps={props.defaultValue ? { shrink: true } : {}}
    />
  );
};
