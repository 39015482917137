import { useFetch } from "utils/hooks";

export function useCertificates() {
  const { data, isLoading } = useFetch(`/v1/profile/users/certificates`);

  return {
    certificates: data,
    isLoading,
  };
}
