import React from "react";
import { useUserInfo } from "./context";

function Header() {
  const { userInfo } = useUserInfo();
  const { first_name, age, city_and_state, working, updated_profile_at } = userInfo;

  const formatDate = date => {
    return new Date(date).toLocaleDateString();
  }

  return (
    <>
      <small className="updated-profile-at">
        <i className="material-icons">update</i>perfil atualizado em{" "}
        {formatDate(updated_profile_at)}
      </small>
      <header>
        <div>
          <h1>
            {first_name}, {age}
            <small>
              <i className="material-icons">place</i> {city_and_state}
            </small>
          </h1>
          <p>{working}</p>
        </div>
      </header>
    </>
  );
}

export default Header;
