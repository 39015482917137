import React from 'react';
import { connect } from "react-redux";
import Tabs from './tabs/Tabs';
import TabContent from './tabs/TabContent';
import Header from './Header';
import { Switch as EditModeSwitch} from "components/users/EditMode";

function mapStateToProps(state) {
  const { isLogged } = state.session;
  return { isLogged };
}

export default connect(mapStateToProps)(({ isLogged }) => (
  <div className="content">
    {isLogged && <EditModeSwitch />}
    <Header />
    <Tabs />
    <TabContent />
  </div>
));