import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, ContentBox, MainBox } from "components/ui";
import FormAnswer from "./FormAnswer";
import FormAnswerContext from "./context";
import { INTERNSHIP } from "navigation/ROUTES";

function Main() {
  const { company_name, time_or_answer_date } =
    useContext(FormAnswerContext).formAnswer;

  return (
    <MainBox>
      <Breadcrumb>
        <Link to={INTERNSHIP.COMPANY_EVALUATION.INDEX}>
          Avaliações de Estágio/Empresa
        </Link>
        <a>Avaliar {company_name}</a>
      </Breadcrumb>
      <ContentBox>
        <TimeOrAnswerAt message={time_or_answer_date} />
        <FormAnswer />
      </ContentBox>
    </MainBox>
  );
}

function TimeOrAnswerAt(props) {
  return (
    <p className="answer_at">
      <i className="material-icons">schedule</i>
      {props.message}
    </p>
  );
}

export default Main;
