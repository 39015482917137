import styled from "styled-components";

export const WindowElement = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const MainContent = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
`

export const HeaderComplement = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, .1);
  padding: 20px;
`

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4rem;  
`

export const LectureTitle = styled.h2`
  font-size: 32px;
  margin: 0 !important;
`

export const HeaderRight = styled.div`
  background-color: #333b4d;
  display: flex;
  min-height: 30px;
  align-items: center;
  margin-right: 4rem;
  justify-content: space-evenly;
  color: white;
  width: 12rem;
  border-radius: 7px;
  cursor: pointer;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 100px);
`

export const LectureVideo = styled.div`
  width: 80%;
  margin: 2.05rem auto 0 auto;
`

export const AttachmentContainer = styled.section`
  width: auto;
  margin: 0 1.8rem 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media(min-width: 720px){
    flex-direction: row;
    justify-content: center;
    column-gap: 100px;
    flex-wrap: wrap;
  }

`

export const AttachmentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 200px;
  max-width: 200px;
  margin: 20px 0;
  box-shadow: 1px 0px 19px -7px rgba(0,0,0,0.57);
  -webkit-box-shadow: 1px 0px 19px -7px rgba(0,0,0,0.57);
  -moz-box-shadow: 1px 0px 19px -7px rgba(0,0,0,0.57);
  white-space: nowrap;
  overflow: overlay;
  max-height: 93px;
  min-height: 93px;

  p {
    font-size: 16px;
    margin: 0 !important;
  }

  div {
    padding: 15px 20px;
    white-space: nowrap;
    overflow: overlay;
  }

  @media(min-width: 720px){
    max-width: 300px;
    min-width: 300px;
  }
`

export const ConfirmDiv = styled.div`
  margin: 0 auto;
  width: 300px;
  height: 100px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  padding-bottom: 20px;
`

export const ConfirmButton = styled.button`
  width: 100px;
  height: auto;
  color: white;
  /* margin-top: 1rem; */
  background-color: #333b4d;
  border: none;
  border-radius: 8px;
  padding: 3px;
`

export const FinishedCourseContainer = styled.div`
  margin: 1rem 4rem;
`

export const FinishedCourseText = styled.p`
  font-size: 32px;
  text-align: center;
`
export const FinishedCourseActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 60rem;
  margin: 30px auto 0 auto;
`

export const FinishedCourseAction = styled.a`
  text-align: center;
  padding: 13px;
  color: white;
  min-width: 197px;
  border-radius: 12px;
  cursor: pointer;
  background-color: #C13950;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 0 0 0.9em rgba(0, 0, 0, 0.4);
  
  :hover {
    background-color: #711324;
    color: white;
    transition: .4s;
  }

  @media(max-width: 764px){
    margin: 15px;
  }

`

export const CertificateUrl = styled.p`
  text-align: center;
  padding-top: 8px;
`

export const CertificateContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 980px;
  max-height: 720px;
  height: 720px;
  background-image: linear-gradient(to bottom right, #C13950, #711324);
  margin: 2rem auto;

  @media (max-width: 1024px){
    width: 720px;
    height: 460px;
  }

  @media (max-width: 720px){
    width: 260px;
    height: 211px;
  }

`

export const CertificateTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100px;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 720px){
    width: 40px;
  }
`

export const CertificateTitle = styled.p `
  font-size: 90px;
  text-transform: uppercase;
  transform: rotate(270deg);
  background: linear-gradient(to right top, #C13950, #711324);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  @media (max-width: 1024px){
    font-size: 70px;
  }

  @media (max-width: 720px){
    font-size: 34px;
  }
`

export const CertificateMainContent = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CertificateHeader = styled.div `
  display: flex;
  flex-direction: row;
  padding: 2rem;
  max-height: 200px;
  width: 100%;
  word-break: break-all;
  justify-content: space-evenly;

  @media (max-width: 720px){
    height: 80px;
    padding: 12px;
  }
`

export const CertificateHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
`

export const WJLogo = styled.img`
  width: 200px;
  height: 180px;
  @media (max-width: 720px){
    width: 30px;
    height: 23px;
    align-self: center;
  }

`

export const WJTitle = styled.h3`
  color: white;
  font-size: 1.25rem !important;

  @media (max-width: 720px){
    font-size: 0.555rem !important;
  }
`

export const CourseTitle = styled.h2`
  color: white;
  font-size: 2rem;

  @media (max-width: 720px){
    font-size: 0.755rem !important;
  }
`

export const CourseDuration = styled.h3`
  color: white;
  font-weight: 600;
  font-size: 1rem;

  @media (max-width: 720px){
    font-size: 0.555rem !important;
  }
`

export const CertificateContent = styled.div `
  width: 100%;
  display: flex;
  color: white;
  font-size: 28px;
`
export const TextCertification = styled.div `
  padding: 100px;

  @media (max-width: 1024px){
    font-size: 16px;
    padding: 40px;
  }

  @media (max-width: 720px){
    font-size: 10px;
    padding: 6px;
  }
`