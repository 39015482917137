import React from "react";
import "./style.scss";
import Form from "./Form";
import { Footer, Loader } from "components/ui";
import Header from "./Header";
import { useConvention } from "./context";

function Participate() {
  const { isLoading } = useConvention();

  if(isLoading){
    return <Loader />;
  }

  return (
    <div className="conventions-participate">
      <Header />
      <Form />
      <Footer />
    </div>
  )
}

export default Participate;
