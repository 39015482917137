import styled, { keyframes } from "styled-components";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiTypography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles'
import { styled as styledMui } from '@mui/system';

export const SectionContainer = styled.section`
  width: 100%;
  min-height: fit-content;
  flex-direction: row;
  margin: 0 auto;
  background: linear-gradient(270.39deg, rgba(49, 33, 133, 0.98) 23.86%, rgba(55, 40, 130, 0.95) 56.44%, rgba(58, 44, 128, 0.93) 79.2%, rgba(60, 47, 126, 0.92) 99.22%);
`;

export const CourseImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 256px;
  height: 256px;
  border-radius: 6px;
  overflow: hidden;

  @media (max-width: 769px) {
    margin: 0 auto;
  }
`;

export const CourseImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;

  @media (min-width: 770px) {
    margin: 0;
  }
`;

export const CourseArea = styled.h4`
  color: #fafafa;
  font-weight: 500;
  font-size: 18px;
`;

export const CourseDescription = styled.p`
  color: white;
  max-width: 85%;
`;

export const CourseContainer = styled.div`
  padding: 2.5rem;

  @media (min-width: 770px) {
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 90%;
    max-width: 90rem;
    margin: 0 auto;
    padding: 2% 0;
  }
`;

export const CourseContainerDescription = styled.div`
  margin: 0 2rem;
  @media (max-width: 770px) {
    display: none;
  }
`;

export const CourseContainerDuration = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 770px) {
    display: none;
  }
`;

export const CourseLabel = styled.p`
  color: white;
  font-size: 17px;
  margin: 0 5px 0 5px;
`;

export const CourseInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .material-icons {
    color: #fafafa;
    font-size: 26px;
    font-weight: 100;
  }
`;

export const CourseWorkload = styled.p`
  color: white;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
`;

export const CourseContainerActions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CourseActionsDuration = styled.p`
  border-top: 1px solid #dde0e3;
  font-size: 13px;
  font-weight: 400;
  padding: 0.5rem 0;
`;

export const CourseActionsDescription = styled.p`
  border-top: 1px solid #dde0e3;
  font-size: 14px;
  font-weight: 400;
  padding: 0.5rem 0;
  margin: 0 14px;
`;

export const CourseActionsTitle = styled.h2`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
`;

export const CourseActionsOthers = styled.div`
  border-top: 1px solid #dde0e3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;

  p {
    color: rgba(51, 59, 77, 1);
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
  }
`;

export const InfoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: -1rem;
  padding: 0.75rem 0 1.25rem 0;
  position: relative;
  z-index: 5;

  @media (min-width: 770px) {
    width: 90%;
    max-width: 90rem;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    &::before {
      content: 'Conteúdo do Curso';
      color: #3f4952;
      font-size: 20px;
      font-weight: 600;
      padding: 0 0 10px 32px;
      border-bottom: 1px solid #dde0e3;
    }
  }
`;

export const ButtonBox = styled.div `
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`;

export const SubmitButton = styled.button `
  align-self: center;
  width: 98px;
  padding: 5px;
  word-break: no-break;
  background-color: #C13950;
  border: none;
  color: white;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17);
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;

  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  &:hover {
    background-color: #ea2f51;
    box-shadow: none;
    text-shadow: none;
  }

  @media (min-width: 770px) {
    width: 200px;
    height: 40px;
    padding: 5px;
    font-weight: 600;
    font-size: 16px;
  }
`;

export const CourseTitle = styled.h1`
  align-self: start;
  color: #fafafa;
`;

export const slideDown = keyframes`
  0% {
    transform: translateY(-20%);
  }
  50%{
    transform: translateY(8%);
  }
  100% {
    transform: translateY(0%);
  }

  from {
    height: 0;
  }
  to {
    height: 100%;
  }
`;

export const Accordion = styledMui(MuiAccordion)(({ theme }) => ({
  borderBottom: "1px solid #dde0e3 !important",
  boxShadow: "none",
  '::before': {
    display: "none",
  },
  WebkitTransition: "all 0.3s ease-in",
  MozTransition: "all 0.3s ease-in",
}));

export const AccordionCourse = styledMui(MuiAccordion)(({ theme }) => ({
  width: "95%",
  margin: "-44px auto 0",
  borderRadius: "4px",
  border: "1px solid #fafafa !important",
  backgroundColor: "#fcfcfc",
  zIndex: "10",
  boxShadow:
    "rgba(0, 0, 0, 0.05) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;",
  "::before": {
    display: "none",
  },
  WebkitTransition: "all 0.3s ease-in",
  MozTransition: "all 0.3s ease-in",
  "&:hover": {
    backgroundColor: "#fcfcfc",
  },
  "&.Mui-expanded": {
    margin: "-44px auto 0 !important",
  },

  "@media (min-width: 770px)": {
    display: "none",
  },
}));

export const Typography = styledMui(MuiTypography)(({ theme }) => ({
  display: "inline",
  paddingLeft: "1rem",
  lineHeight: "19px",
  color: "#3f4952",
  fontWeight: "400",
  fontSize: "17px",

  // "@media (min-width: 1208px)": {
  //   fontSize: "16px",
  // },
}));

export const AccordionSummary = styledMui(MuiAccordionSummary)(({theme}) => ({
  padding: "3px",
  margin: "5px 22px",
}));

export const SectionTypography = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  color: #3f4952;
  font-weight: 400;
  font-size: 13px;

  @media (min-width: 1208px) {
    font-size: 15px;
  }
`;

export const useStyles = makeStyles(() => ({
  expanded: {},
  content: {
    margin: "0 !important",
    justifyContent: "space-between",
  },
}));

export const LectureContainer = styled.div`
  margin-left: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  animation: ${slideDown} .7s;
  max-height: auto;

  @media (max-width: 1208px){
    hr {
      width: 95%;
    }
  }

  @media (max-width: 456px){
    hr {
      width: 80%;
    }
  }
`;

export const LectureTitle = styled.p`
  color: #3f4952;
  padding-left: 1rem;
  font-weight: 500;
  font-size: 15px;
  
  a {
    text-decoration: none;

  }
`;

export const LectureInfos = styled.div`
  margin: 1rem 4px;
  display: flex;
  flex-direction: column;
`;

export const TimeInfos = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  align-items: center;
`;

export const LeftItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const RightItems = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
`;

export const LectureDuration = styled.p`
  font-size: 14px;
  color: #6c737a;
  padding-left: 0.5rem;
`;