import React from "react";
import Content from "./Content"
import Header from "components/ui/Header";
import Footer from 'components/ui/Footer'

function Main(){
  
  return (
    <div>
      <Header />
      <Content />
      <Footer />
    </div>
  )
}

export default Main;