import React from "react";
import styled from "styled-components";

const Button = styled.button`
  width: 64px;
  height: 40px;
  background-color: #34a6a6;
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 20px;

  i {
    line-height: 40px;
    font-size: 24px;
  }

  &:disabled {
    background: rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
`;

export default ({ Steps }) => (
  <div className="buttons">
    <Button
      type="button"
      className="step-control"
      onClick={Steps.previous}
      disabled={!Steps.hasPrevious()}
    >
      <i className="material-icons">keyboard_arrow_left</i>
    </Button>
    <Button type="submit" className="step-control">
      <i className="material-icons next">
        {Steps.hasNext() ? "keyboard_arrow_right" : "done"}
      </i>
    </Button>
  </div>
);
