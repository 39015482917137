import React from "react";
import { useCandidature } from "../context";

function Logo() {
  const { job } = useCandidature();

  return (
    <img className="logo" src={job.avatar} alt={`Foto de ${job.author_name}`} />
  );
}

export default Logo;
