import React from "react";
import { Link } from "react-router-dom";
import { BackButton } from "components/ui";
import { useAttendee } from "../context";
import { pathWithParams } from "utils/pathWithParams";
import { ATTENDEES } from "navigation/ROUTES";

function BackDescription() {
  const { attendee } = useAttendee();
  return (
    <BackButton>
      <Link to={pathWithParams(ATTENDEES.ABOUT, {id: attendee.id})}>
        <i className="material-icons">keyboard_backspace</i>voltar para
        descrição
      </Link>
    </BackButton>
  );
}

export default BackDescription;
