import React from "react";
import { Route, Routes } from "react-router-dom";
import PATHS from "./ROUTES";

import { default as UserRegistration } from "pages/profile/registration/User";
import { default as UserLogin } from "pages/profile/login/User";
import { default as UserHome } from "pages/profile/users/Home";
import { default as UserShow } from "pages/profile/users/Show";
import { default as UserSettings } from "pages/profile/users/Settings";
import { default as UserEssentialFields } from "pages/profile/users/EssentialFields";
import { default as UserWizard } from "pages/profile/users/Wizard";

import { default as Academy } from "pages/profile/academy/Index/Index";
import { default as Courses } from "pages/profile/academy/Courses/Index";
import { default as AcademyShow } from "pages/profile/academy/Show/Main";
import { default as MyCourses } from "pages/profile/academy/MyCourses/Index";
import { default as WatchCourse } from "pages/profile/academy/Show/watch/WatchCourse";
import { default as FinishedCourse } from "pages/profile/academy/Show/watch/FinishedCourse";
import { default as Certificate } from "pages/profile/academy/Certificate/Index";
import { default as StudyTrackShow } from "pages/profile/academy/StudyTrack/Show/Show";
import { default as StudyTrackIndex } from "pages/profile/academy/StudyTrack/Index/Index";

import { default as TestIndex } from "pages/tests/index";
import { default as TestStart } from "pages/tests/start/Start";
import { default as TestFinished } from "pages/tests/finished/Finished";
import { default as TestTaking } from "pages/tests/taking/Taking";

import { default as CandidatureIndex } from "pages/ats/candidatures/Index";
import { default as CandidatureShow } from "pages/ats/candidatures/Show";
import { default as CandidatureFastCreate } from "pages/ats/candidatures/FastCreate";

import { default as CompanyIndex } from "pages/profile/companies/Index";
import { default as CompanyShow } from "pages/profile/companies/Show";
import { default as CompanyNew } from "components/companies/New";
import { default as JobIndex } from "pages/ats/jobs/Index";
import { default as JobShow } from "pages/ats/jobs/Show";

import { default as InternshipProgram } from "pages/internship_program/Show";

import { default as ConventionsParticipate } from "pages/conventions/Participate";

import { default as AdmissionFormAnswer } from "pages/internship/admission/FormAnswer";
import { default as AdmissionLogin } from "pages/internship/admission/Login";

import { default as UpdateFormAnswer } from "pages/internship/update/FormAnswer";
import { default as UpdateLogin } from "pages/internship/update/Login";

import { default as Benefits } from "pages/profile/benefits/Index";
import { default as CareerMentors } from "pages/profile/career_mentors/Index";

import { default as PersonalDocuments } from "pages/profile/personal_documents/Index";

import { default as QuestionnaireEdit } from "pages/ats/questionnaire/form_answers/Edit";

import { default as CompanyEvaluationIndex } from "pages/internship/company_evaluation/form_answer/Index";
import { default as CompanyEvaluationShow } from "pages/internship/company_evaluation/form_answer/Show";

import { default as EnvelopeOwned } from "pages/internship/envelopes/Owned";
import { default as EnvelopeShow } from "pages/internship/envelopes/Show";

import { default as AttendeeIndex } from "pages/attendees/Index";
import { default as AttendeeShow } from "pages/attendees/Show";

import { default as Certificates } from "pages/profile/users/certificates/Index";

import UserWrapper from "components/ui/wrappers/UserWrapper";
import VerifyAccessToken from "./VerifyAccessToken";
import AcademyWrapper from "components/ui/wrappers/AcademyWrapper";
import TakingTestWrapper from "components/ui/wrappers/TakingTestWrapper";

import { default as IesCompanyNew } from "pages/ies/board/company/New";

import NotFound from "pages/errors/NotFound";

import Test from "pages/Test";

export default () => (
  <Routes>
    <Route
      path={PATHS.CONVENTIONS.PARTICIPATE}
      element={<ConventionsParticipate />}
    />

    <Route 
      path={PATHS.INTERNSHIP_PROGRAMS.SHOW}
      element={<InternshipProgram />}
    />

    <Route
      path={PATHS.INTERNSHIP.ADMISSION.FORM_ANSWER}
      element={<AdmissionFormAnswer />}
    />

    <Route
      path={PATHS.INTERNSHIP.ADMISSION.LOGIN}
      element={<AdmissionLogin />}
    />

    <Route
      path={PATHS.INTERNSHIP.STELLANTIS.ADMISSION.FORM_ANSWER}
      element={<AdmissionFormAnswer />}
    />

    <Route
      path={PATHS.INTERNSHIP.STELLANTIS.ADMISSION.LOGIN}
      element={<AdmissionLogin />}
    />

    <Route
      path={PATHS.INTERNSHIP.UPDATE.FORM_ANSWER}
      element={<UpdateFormAnswer />}
    />

    <Route path={PATHS.INTERNSHIP.UPDATE.LOGIN} element={<UpdateLogin />} />

    <Route path={PATHS.JOB_BOARD.COMPANIES.NEW} element={<IesCompanyNew />} />

    <Route path={PATHS.ACADEMY.INDEX}>
      <Route index element={<AcademyWrapper element={<Academy />} />} />
      <Route
        path={PATHS.ACADEMY.COURSE.SHOW}
        element={<AcademyWrapper element={<AcademyShow />} />}
      />
      <Route
        path={PATHS.ACADEMY.COURSE.OWNED}
        element={<AcademyWrapper element={<MyCourses />} />}
      />
      <Route
        path={PATHS.ACADEMY.COURSE.INDEX}
        element={<AcademyWrapper element={<Courses />} />}
      />
      <Route path={PATHS.ACADEMY.COURSE.WATCH} element={<WatchCourse />} />
      <Route
        path={PATHS.ACADEMY.COURSE.FINISHED}
        element={<FinishedCourse />}
      />
      <Route
        path={PATHS.ACADEMY.COURSE.CERTIFICATE}
        element={<Certificate />}
      />
      <Route
        path={PATHS.ACADEMY.STUDY_TRACK.SHOW}
        element={<AcademyWrapper element={<StudyTrackShow />} />}
      />
      <Route
        path={PATHS.ACADEMY.STUDY_TRACK.INDEX}
        element={<AcademyWrapper element={<StudyTrackIndex />} />}
      />
    </Route>

    <Route path={PATHS.TESTS.INDEX}>
      <Route
        index
        element={
          <VerifyAccessToken>
            <UserWrapper element={<TestIndex />} />
          </VerifyAccessToken>
        }
      />
      <Route
        path={PATHS.TESTS.START}
        element={
          <VerifyAccessToken>
            <UserWrapper element={<TestStart />} />
          </VerifyAccessToken>
        }
      />
      <Route
        path={PATHS.TESTS.FINISHED}
        element={
          <VerifyAccessToken>
            <UserWrapper element={<TestFinished />} />
          </VerifyAccessToken>
        }
      />
      <Route
        path={PATHS.TESTS.TAKING}
        element={<TakingTestWrapper element={<TestTaking />} />}
      />
    </Route>

    <Route path={PATHS.ATS.JOBS.INDEX}>
      <Route
        index
        element={
          <VerifyAccessToken requireAuth={false}>
            <UserWrapper element={<JobIndex />} />
          </VerifyAccessToken>
        }
      />
      <Route
        path={PATHS.ATS.JOBS.SHOW}
        element={
          <VerifyAccessToken requireAuth={false}>
            <UserWrapper element={<JobShow />} />
          </VerifyAccessToken>
        }
      />
      <Route
        path={PATHS.ATS.JOBS.CANDIDATURES.FAST_CREATE}
        element={<CandidatureFastCreate />}
      />
    </Route>

    <Route path={PATHS.COMPANIES.INDEX}>
      <Route
        index
        element={
          <VerifyAccessToken requireAuth={false}>
            <UserWrapper element={<CompanyIndex />} />
          </VerifyAccessToken>
        }
      />
      <Route
        path={PATHS.COMPANIES.SHOW}
        element={
          <VerifyAccessToken requireAuth={false}>
            <UserWrapper element={<CompanyShow />} />
          </VerifyAccessToken>
        }
      />
    </Route>

    <Route 
      path={PATHS.COMPANIES.NEW}
      element={<CompanyNew />}
    />
    

    <Route path={PATHS.USERS.INDEX}>
      <Route path={PATHS.USERS.LOGIN} element={<UserLogin />} />
      <Route path={PATHS.USERS.REGISTRATION} element={<UserRegistration />} />
      <Route
        path={PATHS.USERS.HOME}
        element={
          <VerifyAccessToken>
            <UserWrapper element={<UserHome />} />
          </VerifyAccessToken>
        }
      />
      <Route
        path={PATHS.USERS.ESSENTIAL_FIELDS}
        element={
          <VerifyAccessToken>
            <UserWrapper element={<UserEssentialFields />} />
          </VerifyAccessToken>
        }
      />
      <Route
        path={PATHS.USERS.SHOW}
        element={
          <VerifyAccessToken>
            <UserWrapper element={<UserShow />} />
          </VerifyAccessToken>
        }
      />
      <Route path={PATHS.USERS.CANDIDATURES.INDEX}>
        <Route
          index
          element={
            <VerifyAccessToken>
              <UserWrapper element={<CandidatureIndex />} />
            </VerifyAccessToken>
          }
        />
        <Route
          path={PATHS.USERS.CANDIDATURES.SHOW}
          element={
            <VerifyAccessToken>
              <UserWrapper element={<CandidatureShow />} />
            </VerifyAccessToken>
          }
        />
      </Route>
      <Route
        path={PATHS.USERS.WIZARD}
        element={
          <VerifyAccessToken>
            <UserWrapper element={<UserWizard />} />
          </VerifyAccessToken>
        }
      />
      <Route
        path={PATHS.USERS.SETTINGS}
        element={
          <VerifyAccessToken>
            <UserWrapper element={<UserSettings />} />
          </VerifyAccessToken>
        }
      />
      <Route
        path={PATHS.USERS.CERTIFICATES.INDEX}
        element={
          <VerifyAccessToken>
            <UserWrapper element={<Certificates />} />
          </VerifyAccessToken>
        }
      />
    </Route>

    <Route
      path={PATHS.BENEFITS.INDEX}
      element={
        <VerifyAccessToken>
          <UserWrapper element={<Benefits />} />
        </VerifyAccessToken>
      }
    />

    <Route
      path={PATHS.CAREER_MENTORS.INDEX}
      element={
        <VerifyAccessToken>
          <UserWrapper element={<CareerMentors />} />
        </VerifyAccessToken>
      }
    />

    <Route
      path={PATHS.INTERNSHIP.ENVELOPES.OWNED}
      element={
        <VerifyAccessToken>
          <UserWrapper element={<EnvelopeOwned />} />
        </VerifyAccessToken>
      }
    />

    <Route
      path={PATHS.INTERNSHIP.ENVELOPES.SHOW}
      element={
        <VerifyAccessToken>
          <UserWrapper element={<EnvelopeShow />} />
        </VerifyAccessToken>
      }
    />

    <Route path={PATHS.ATTENDEES.INDEX}>
      <Route
        index
        element={
          <VerifyAccessToken>
            <UserWrapper element={<AttendeeIndex />} />
          </VerifyAccessToken>
        }
      />
      <Route
        path={PATHS.ATTENDEES.SHOW}
        element={
          <VerifyAccessToken>
            <UserWrapper element={<AttendeeShow />} />
          </VerifyAccessToken>
        }
      />
    </Route>

    <Route
      path={PATHS.ATS.QUESTIONNAIRE.FORM_ANSWER.EDIT}
      element={
        <VerifyAccessToken>
          <UserWrapper element={<QuestionnaireEdit />} />
        </VerifyAccessToken>
      }
    />

    <Route path={PATHS.INTERNSHIP.COMPANY_EVALUATION.INDEX}>
      <Route
        index
        element={
          <VerifyAccessToken>
            <UserWrapper element={<CompanyEvaluationIndex />} />
          </VerifyAccessToken>
        }
      />

      <Route
        path={PATHS.INTERNSHIP.COMPANY_EVALUATION.SHOW}
        element={
          <VerifyAccessToken>
            <UserWrapper element={<CompanyEvaluationShow />} />
          </VerifyAccessToken>
        }
      />

      <Route
        path={PATHS.INTERNSHIP.COMPANY_EVALUATION.EDIT}
        element={
          <VerifyAccessToken>
            <UserWrapper element={<CompanyEvaluationShow />} />
          </VerifyAccessToken>
        }
      />
    </Route>

    <Route
      path={PATHS.PERSONAL_DOCUMENTS.INDEX}
      element={
        <VerifyAccessToken>
          <UserWrapper element={<PersonalDocuments />} />
        </VerifyAccessToken>
      }
    />
    <Route path="/testando-design" element={<Test />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
