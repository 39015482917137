import React from "react";
import Section from "./Section";
import Skype from "components/users/fields/personal/Skype";
import Linkedin from "components/users/fields/personal/Linkedin";
import Facebook from "components/users/fields/personal/Facebook";
import Instagram from "components/users/fields/personal/Instagram";

function SocialMediaSection(props) {
  return (
    <Section title="Redes sociais">
      <div className="row">
        <div className="col-md-3">
          <Skype {...props} />
        </div>
        <div className="col-md-3">
          <Linkedin {...props} />
        </div>
        <div className="col-md-3">
          <Facebook {...props} />
        </div>
        <div className="col-md-3">
          <Instagram {...props} />
        </div>
      </div>
    </Section>
  );
}

export default SocialMediaSection;
