import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getCollection } from "utils/getCollection";
import { Breadcrumb, ContentBox, MainBox } from "components/ui";
import Content from "./Content";
import Header from "./Header";

function Main() {
  const [documents, setDocuments] = useState([]);

  const getDocuments = () => {
    getCollection("/v1/profile/users/personal_documents", setDocuments);
  }

  useEffect(() => {
    if (_.isEmpty(documents)) getDocuments();
  }, [documents]);

  return (
    <MainBox>
      <Breadcrumb>
        <a>Documentos Pessoais</a>
      </Breadcrumb>
      <ContentBox>
        <Header getDocuments={getDocuments} />
        <Content documents={documents} getDocuments={getDocuments} />
      </ContentBox>
    </MainBox>
  );
}

export default Main;
