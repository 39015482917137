import React, {useState} from "react";
import {OptionContainer, WrongAnswer} from "./styles.js";
import api from "services/api";
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

function Option(props){
  const [result, setResult] = useState([]);
  const [value, setValue] = useState(null);
  const [color, setColor] = useState("");

  React.useEffect(() => {
    if(value){
      let backgroundColor = result.data.attributes.result ? "#79eb8f" : "#f17b97";
      setColor(backgroundColor);
    }
  }, [value, result]);

  if(result.length < 1){
    if(props.selectedOption){
      api.get(`/v1/academy/options/${props.id}`)
        .then(res => {
          setValue(true);
          setResult(res.data)
        })
        .catch((error) => console.log(error.response))
    }
  }

  function renderResult(){
    if(result.data.attributes.result === false){
      return (<><ClearIcon /> <WrongAnswer> {props.text} </WrongAnswer></>)
    }else {
      return (<><CheckIcon /> {props.text}</>)
    }
  }
  
  return(
    <OptionContainer onClick={() => props.selectOption()} style={{backgroundColor: color}} >
      {result.length < 1 ? (
        <><RadioButtonUncheckedSharpIcon /> {props.text}</>
      ) : renderResult()} 
    </OptionContainer>
  )
}

export default Option;