import React from "react";
import { WithoutCourses, DivWithoutCourses } from "../styles";
import CardItem from "../../StudyTrack/Index/components/CardItem";
import { ACADEMY } from "navigation/ROUTES";

function StudyTrack({studyTracks, hasStudyTracks}){

  return(
    <>
      {hasStudyTracks ? (
        <DivWithoutCourses>
          <WithoutCourses>Você ainda não salvou nenhuma Trilha. <a href={ACADEMY.STUDY_TRACK.INDEX} style={{color: "#0645AD", textDecoration: "none"}}>Clique aqui</a> para acessar a lista de Trilhas.</WithoutCourses> 
        </DivWithoutCourses>
      ) : 
        studyTracks.map((item) => (
          <CardItem item={item} />
        ))
      }
    </>
  )
}

export default StudyTrack;