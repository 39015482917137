import React, { useContext } from "react";
import _ from "lodash";
import UserInfoContext, { useUserInfo } from "../../context";
import skill from "assets/images/skill.png";
import { useTranslation } from "react-i18next";

function Skills() {
  const { userInfo } = useUserInfo();
  const { skills } = userInfo;
  const { t } = useTranslation();

  const _renderSkills = () => {
    return skills.map((name, index) => <Skill key={index} name={name} />);
  };

  if(_.isEmpty(skills)){
    return;
  }

  return (
    <section style={{ marginTop: "25px" }}>
      <h3>{t("user_info.attributes.skills")}</h3>
      <div className="skills">{_renderSkills()}</div>
    </section>
  )
}

function Skill({ name }) {
  return (
    <div className="skill">
      <img src={skill} alt="Habilidades" />
      <h3>{name}</h3>
    </div>
  );
}

export default Skills;
