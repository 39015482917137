import React from "react";
import api from "services/api";
import { useCandidature } from "../context";

function AutoEnrollInvite() {
  const { candidature, mutate } = useCandidature();

  const successMessage = (data) => {
    const { message } = data;
    swal("Tudo certo!", message, "success");
    mutate();
  };

  const errorMessage = (message) => {
    swal("Ops!", message, "error");
  };

  const answeredInvite = (answer) => {
    api
      .post(`/v1/ats/candidatures/${candidature.id}/invite`, { answer })
      .then((resp) => successMessage(resp.data))
      .catch((error) => errorMessage(error.response.data.message));
  };

  return (
    <div className="auto-enroll-invite">
      <p>Você foi pré-selecionado para esse processo seletivo.</p>

      <p>
        <b>Aceita participar?</b>
      </p>

      <div className="buttons">
        <button
          className="button button-primary"
          onClick={() => answeredInvite("accepted")}
        >
          Aceitar
        </button>
        <button
          className="button button-danger"
          onClick={() => answeredInvite("declined")}
        >
          Recusar
        </button>
      </div>
    </div>
  );
}

export default AutoEnrollInvite;
