import styled from "styled-components"

export const AcademyHeader = styled.div`
  width: 100%;
  min-height: 197px;
  padding: 55px 30px;
  background: linear-gradient(270.39deg, rgba(49, 33, 133, 0.98) 23.86%, rgba(55, 40, 130, 0.95) 56.44%, rgba(58, 44, 128, 0.93) 79.2%, rgba(60, 47, 126, 0.92) 99.22%);

  @media(min-width: 720px){
    padding: 55px 100px;
  }
`

export const HeaderTitle = styled.h1`
  color: white;
  font-size: 3rem;
`