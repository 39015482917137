import React, { useState } from "react";
import { StatusCodes } from "http-status-codes";

import api from "services/api";
import { getContentSrc, convertIndexToLetter } from "../utils";
import { Container, Title, Video, OptionsContainer, Button, OptionContainer, OptionText, FlexOption } from './styles';
import CardMedia from "@mui/material/CardMedia";
import QuestionImage from './QuestionImage';
import OptionImage from './OptionImage';
import TimeExpired from "./TimeExpired";

function Question(props) {
  const { question, currentQuestion, answer, isLastQuestion, nextQuestion, time, sendTest } = props;
  const [ selectedOptions, setSelectedOptions ] = useState([]);
  const timeExpired = time < 2;

  if(timeExpired){
    return <TimeExpired sendTest={sendTest} />
  }

  const handleSingleOptionSelect = (id) => {
    if (!selectedOptions.includes(id)){
      setSelectedOptions([id])
    }
  }
  
  const handleMultipleOptionSelect = (id) => {
    if (selectedOptions.includes(id)) {
      setSelectedOptions(selectedOptions.filter(option => option !== id));
    } else {
      setSelectedOptions([...selectedOptions, id]);
    }
  };
  
  const handleOptionSelect = (id) => {
    if (question.question_type === "single_correct_answer") {
      handleSingleOptionSelect(id);
    } else {
      handleMultipleOptionSelect(id);
    }
  };

  function sendAnswer() {
    if(_.isEmpty(selectedOptions)) {
      swal("Ops!", "Você deve selecionar ao menos uma alternativa", "error");
      return;
    }

    const data = { 
      answer_id: answer,
      question_id: question.id,
      option_ids: selectedOptions,
    };

    api.post(`/v1/test/answers/answer`, data)
    .then(({data, status}) => {
      if (status == StatusCodes.OK) {
        swal("Sucesso!", data.message, "success").then(() => nextQuestion());
      } else {
        swal("Ops!", data.message, "warning").then(() => nextQuestion());
      }
    })
    .catch((error) => {
      const { message } = error.response.data;
      swal("Ops!", message, "error");
    });
  };

  function currentQuestionAndDescription(){
    return `${currentQuestion}. ${question.description}`;
  }

  return (
    <div className="question">
      <Container>
        <Title>
          <div dangerouslySetInnerHTML={{ __html: currentQuestionAndDescription() }} />
        </Title>
        { question.video_url && 
          <Video>
            <CardMedia
              component="iframe"
              controls
              width="100%"
              height="400px"
              src={getContentSrc(question.video_url)}
              allowFullScreen
            />
          </Video>}
        { question.image && <QuestionImage src={question.image} alt={question.description}></QuestionImage> }
      </Container>
      <OptionsContainer>
        <>
          { question.options && question.options.map((option, index, array) => (
            <OptionContainer value="option" key={index}  isLast={index === array.length - 1} >
              <input
                type={question.question_type === "single_correct_answer" ? "radio" : "checkbox"}
                name="option"
                id={index}
                onClick={() => handleOptionSelect(option.id) }
              />
              { option.content && option.image &&
              <FlexOption>
                <OptionText type="text" name="option" htmlFor={index}>
                {convertIndexToLetter(index)}. {option.content}
                </OptionText>
                <OptionImage type="text" name="option" htmlfor={index} src={option.image} alt={`Imagem da opção ${convertIndexToLetter(index)}`} number={convertIndexToLetter(index)} />
              </FlexOption> }
              { option.content && !option.image &&
                <OptionText type="text" name="option" htmlFor={index}>
                  {convertIndexToLetter(index)}. {option.content}
                </OptionText>}
              { option.image && !option.content &&
                <OptionImage type="text" name="option" htmlfor={index} src={option.image}  alt={`Imagem da opção ${convertIndexToLetter(index)}`} number={convertIndexToLetter(index)} />}
            </OptionContainer>
          ))}
        </>
        <Button onClick={sendAnswer}>{isLastQuestion ? "Finalizar Teste" : "Enviar Resposta"}</Button>
      </OptionsContainer>
    </div>
  );
}

export default Question;