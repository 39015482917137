import React from "react";
import styled from "styled-components";
import VerifiedIcon from "@mui/icons-material/Verified";

const Text = styled.p`
  font-weight: 600;
  width: 100%;
`;

const Icon = styled(VerifiedIcon)`
  margin-right: 2px;
  color: #ccad00;
`;

export default () => {
  return (
    <Text className="ies_exclusivity">
      <Icon fontSize='inherit' />
      Vaga exclusiva para a sua Instituição de Ensino
    </Text>
  );
};
