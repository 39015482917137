import React from 'react'
import { useCandidature } from '../context';
import Questionnaire from './Questionnaire';

function Questionnaires() {
  const { candidature } = useCandidature();
  const { id: candidature_id, questionnaire_answers } = candidature;

  return questionnaire_answers.map((answer) => (
    <Questionnaire key={answer.id} candidature_id={candidature_id} questionnaire={answer.attributes} />
  ))
}

export default Questionnaires;
