import React, { useContext, createContext } from "react";
import { useFetch } from "utils/hooks";
import { API } from "navigation/ROUTES";

const FlipperContext = createContext();

export default ({children}) => {
  // this way of fetching features is NOT updated in real time

  // const [features, setFeatures] = useState({});
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(async() => {
  //   const response = await fetch('/features.json');
  //   const data = await response.json();
  //   setFeatures(data);
  //   setIsLoading(false);
  // }, []);

  //this way of fetching features is updated in real time
  const { data: features, isLoading, mutate } = useFetch(API.FEATURES, { focusThrottleInterval: 10000 });

  return (
    <FlipperContext.Provider value={{ features, mutate }} loading={isLoading}>
      {children}
    </FlipperContext.Provider>
  );
}

export const useFlipper = () => {
  const context = useContext(FlipperContext);

  if (!context) {
    throw new Error('Context must be used within a Provider');
  }

  const isEnabled = (feature, defaultValue = false) => {
    return context.features[feature] ?? defaultValue;
  }

  return { isEnabled, refreshFlipper: context.mutate };
}