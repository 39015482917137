import React from 'react';
import { useUserInfo } from '../../context';

function StudySchedule() {
  const { userInfo } = useUserInfo();
  const { study_schedule } = userInfo;

  const renderStudySchedule = () => {
    if(typeof study_schedule == "string") return study_schedule;

    let days = study_schedule.filter(day => day.available)

    return days.map(day => (
      <p key={day.id}><b>{day.name}</b>{day.hours_attributes.map(hour => (`, das ${hour.from} até ${hour.to}`))}</p>
    ))
  }

  return (
    <>
      <p><b>Horário das aulas:</b></p>
      {renderStudySchedule()}
    </>
  );
}

export default StudySchedule;
