import React, { useState, useEffect } from "react";
import _ from "lodash";
import api from "services/api";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import OTPRequired from "components/users/fields/settings/OTPRequired";
import { USERS } from "navigation/ROUTES";
import { connect } from "react-redux";

export default connect(mapStateToProps)((props) => {
  const [data, setData] = useState({});

  const { OtpRequiredForLogin, OtpForced } = props;

  let navigate = useNavigate();

  const {
    control,
    handleSubmit,
  } = useForm({
    mode: "onBlur"
  });

  const getData = (collectedData) => {
    setData({ ...data, ...collectedData });
  };

  const submit = () => {
    api
      .post(`/v1/profile/users`, { user: data })
      .then(() => successfulRequisition())
      .catch((error) => unsuccessfulRequisition(error.response.data.errors));
  };

  function successfulRequisition() {
    navigate(USERS.HOME);
    swal("Tudo certo!", "Sua solicitação foi concluída com sucesso!", "success");
  }

  function unsuccessfulRequisition() {
    swal(
      "Ops!",
      "Não foi possível concluir sua solicitação. Por favor, tente novamente!",
      "error"
    );
  }

  useEffect(() => {
    if (!_.isEmpty(data)) submit();
  }, [data]);

  return(
    <form className="two-factor" onSubmit={handleSubmit(getData)}>
      <h3>Autenticação</h3>
      <p>A autenticação de dois fatores é uma camada extra de segurança para sua conta. Com esse recurso ativo, ao efetuar o login, enviaremos um código em seu email para confirmar sua identidade.</p>

      {OtpForced && <p><b>Atenção: a empresa onde você estagia marcou o duplo fator de autenticação como obrigatório para gestores e estagiários.</b></p>}

      <OTPRequired
        control={control}
        disabled={OtpForced}
        defaultChecked={OtpRequiredForLogin}
        required
      />
      <button className="button button-primary" type="submit" disabled={OtpForced}>
        Atualizar
      </button>
    </form>
  )
});

function mapStateToProps(state){
  const { OtpRequiredForLogin, OtpForced } = state.session
  return { OtpRequiredForLogin, OtpForced }
}

