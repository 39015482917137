import React from "react";
import { useUserInfo } from "../context";
import Icons from "./Icons";
import { EditIcon } from "components/users/EditMode";
import { useTranslation } from "react-i18next";

function Infos() {
  const { userInfo } = useUserInfo();
  const { gender, ethnicity, marital_status, sexual_orientation, date_of_birth, expected_salary } = userInfo;

  const { t } = useTranslation();

  return (
    <section>
      <h4>
        INFORMAÇÕES <EditIcon type="infos" />
      </h4>
      <hr />
      <p>
        <b>{t("user_info.attributes.gender")}:</b> {gender}
      </p>
      <p>
        <b>{t("user_info.attributes.ethnicity")}:</b> {ethnicity}
      </p>
      <p>
        <b>{t("user_info.attributes.marital_status")}:</b> {marital_status}
      </p>
      <p>
        <b>{t("user_info.attributes.sexual_orientation")}:</b> {sexual_orientation}
      </p>
      <p>
        <b>{t("user_info.attributes.date_of_birth")}:</b> {date_of_birth}
      </p>
      <p>
        <b>{t("user_info.attributes.expected_salary")}:</b> {expected_salary}
      </p>
      <Icons />
    </section>
  );
}

export default Infos;
