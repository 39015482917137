import React from "react";
import logo from "assets/images/logo.png";
import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();
  return (
    <header>
      <h2>{t("update_form_answer.header.title")}</h2>
      <img src={logo} alt="WallJobs" />
    </header>
  );
}

export default Header;
