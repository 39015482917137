import React from "react";
import _ from "lodash";
import { useUserInfo } from "../../context";
import {
  NotInformed,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "components/ui";
import { EditIcon } from "components/users/EditMode";
import { timelineItemClasses } from "@mui/lab";

function Experiences() {
  const { getIncluded } = useUserInfo();
  const experiences = getIncluded("experience");
  const hasExperiences = !_.isEmpty(experiences);

  const _renderExperiences = () => {
    const list = experiences || [];
    return list.map((item, index) => (
      <TimelineItem key={item.id}>
        <TimelineSeparator>
          <TimelineDot />
          {index + 1 != list.length && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Experience key={item.id} attributes={item.attributes} />
        </TimelineContent>
      </TimelineItem>
    ));
  };

  return (
    <div
      className="tab-pane fade"
      id="experiences"
      role="tabpanel"
      aria-labelledby="experiences-tab"
    >
      <EditIcon type="experiences" />
      {hasExperiences ? (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {_renderExperiences()}
        </Timeline>
      ) : (
        <NotInformed />
      )}
    </div>
  );
}

function Experience({ attributes }) {
  return (
    <div className="experience">
      <h3>
        {attributes.job} <small>({attributes.level})</small>
      </h3>
      <h3>{attributes.company}</h3>
      <h4>{attributes.total_time}</h4>
      <p dangerouslySetInnerHTML={{ __html: attributes.description }}></p>
    </div>
  );
}

export default Experiences;
