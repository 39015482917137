import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";
import { useCompany } from "./context";
import api from "services/api";
import { USERS } from "navigation/ROUTES";
import { ContentBoxHeaderButtons } from "components/ui";

function Header(props) {
  const { company } = useCompany();

  const { isLogged } = props;

  let navigate = useNavigate();

  const successfulRequisition = () => {
    swal(
      "Sucesso!",
      `Agora seu perfil faz parte do banco de candidatos da ${company.name}! Te desejamos sorte!`,
      "success"
    );
  };

  const unsuccessfulRequisition = (message) => {
    swal("Ops!", message, "warning");
  };

  const applyCompanyCandidate = () => {
    const data = { company_id: company.id };
    api
      .post(`/v1/profile/companies/candidate`, data)
      .then(() => successfulRequisition())
      .catch((error) => unsuccessfulRequisition(error.response.data?.message));
  };

  const redirectOrCandidate = () => {
    if (isLogged) {
      applyCompanyCandidate();
    } else {
      navigate(USERS.LOGIN);
      swal(
        "Ops!",
        "Você precisa estar logado para fazer parte do banco de candidatos dessa empresa!",
        "warning"
      );
    }
  };

  return (
    <header className="content-box-header">
      <div className="content-box-header-infos">
        <img className="logo" src={company.avatar} alt={`Logo de ${company.name}`} />
        <div>
          <h1>{company.name}</h1>
          <h3>{company.sector_and_location}</h3>
          <p>
            <b>{company.employees_number}</b>
          </p>
          <div className="website">
            <span dangerouslySetInnerHTML={{ __html: company.website }}></span>
            <i className="material-icons">arrow_right_alt</i>
          </div>
        </div>
      </div>
      <ContentBoxHeaderButtons>
        <div className="company">
          <img src={company.avatar} alt={`Logo de ${company.name}`} />
          <small>Gostou da empresa?</small>
        </div>
        <button className="button" onClick={() => redirectOrCandidate()}>
          Deixar currículo
        </button>
      </ContentBoxHeaderButtons>
      <Menu />
    </header>
  );
}

function mapStateToProps(state) {
  const { isLogged } = state.session;
  return { isLogged };
}

export default connect(mapStateToProps)(Header);
