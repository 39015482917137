import React from "react";
import styled from "styled-components";

const Infos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  .see_study_track {
    width: 100%;
    text-align: right;
    cursor: pointer;
    transition: 0.8s;
    text-decoration: uppercase;

    &: hover {
      color: #000000;
    }

    &: active {
      color: #000000;
    }
  }
`;

export default (props) => <Infos>{props.children}</Infos>;
