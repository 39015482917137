import React from "react";
import Card from "./card/Card";
import CardImage from "./card/CardImage";
import CardDivTitle from "./card/CardDivTitle";
import CardInfos from "./card/CardInfos";
import CardTitle from "./card/CardTitle";
import ProgressBar from "./card/ProgressBar";
import CardInfosSecondary from "./card/CardInfosSecondary";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArticleIcon from "@mui/icons-material/Article";
import { useNavigate } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";
import { ACADEMY } from "navigation/ROUTES";

function CardItem({ item }) {

  let navigate = useNavigate();

  return (
    <Card>
      <CardImage
        image={item.attributes.image}
        onClick={() =>
          navigate(
            pathWithParams(ACADEMY.STUDY_TRACK.SHOW, { id: item.attributes.slug })
          )
        }
      />
      <CardInfos>
        <CardDivTitle>
          <p className="area">{item.attributes.area}</p>
          <CardTitle
            onClick={() =>
              navigate(
                pathWithParams(ACADEMY.STUDY_TRACK.SHOW, {
                  id: item.attributes.slug,
                })
              )
            }
          >
            {item.attributes.title}
          </CardTitle>
        </CardDivTitle>
        <ProgressBar percentage={item.attributes.progress || 0} />
        <CardInfosSecondary>
          <AccessTimeIcon style={{ width: "0.6em", height: "0.6em" }} />
          <p>{item.attributes.study_track_duration}</p>
          <ArticleIcon style={{ width: "0.6em", height: "0.6em" }} />
          <p>{item.attributes.course_quantity} cursos</p>
        </CardInfosSecondary>
        <p
          className="see_study_track"
          onClick={() =>
            navigate(
              pathWithParams(ACADEMY.STUDY_TRACK.SHOW, {
                id: item.attributes.slug,
              })
            )
          }
        >
          Ver trilha
        </p>
      </CardInfos>
    </Card>
  );
}

export default React.memo(CardItem);
