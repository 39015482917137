import styled from "styled-components";

export const Container = styled.div`
    background-color: #fafafa;
`;

export const HomepageContainer = styled.div`
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1920px;
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 60px;
  justify-content: space-between;
  flex-direction: row;
  margin: 30px 60px 80px;

  @media (max-width: 1472px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 460px) {
    padding: 0 30px;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1472px) {
    margin: 0 auto;
  }
`;

export const MainTitle = styled.h1`
  word-wrap: break-word;
  font-weight: bold;
  line-height: 1;
  max-width: 600px;
`

export const BlackTitle = styled.span`
  color: black;
  font-size: 90px;

  @media (max-width: 894px) {
    font-size: 70px;
  }

  @media (max-width: 594px) {
    font-size: 54px;
  }
`

export const BlueTitle = styled.span`
  color: #331b89;
  font-size: 90px;

  @media (max-width: 894px) {
    font-size: 70px;
  }

  @media (max-width: 594px) {
    font-size: 54px;
  }
`

export const Text = styled.p`
  background-color: #fafafa;
  margin-top: 20px;
  color: black;
  line-height: 1;
  font-size: 29.5px;
  word-wrap: break-word;
  max-width: 780px;

  @media (max-width: 894px) {
    font-size: 24px;
  }

  @media (max-width: 594px) {
    font-size: 18px;
  }
`

export const MyCourses = styled.button`
  background-color: #331b89;
  border-radius: 10px;
  margin-top: 25px;
  padding: 5px 18px;
  color: white;
  font-size: 29.5px;

  @media (max-width: 894px) {
    font-size: 24px;
  }

  @media (max-width: 594px) {
    font-size: 18px;
  }
`

export const Image = styled.img`
  width: 530px;

  @media (max-width: 1472px) {
    margin-top: 40px;
    align-self: center;
  }

  @media (max-width: 592px) {
    width: 90vw;
  }
`;

export const MiddleBelt = styled.div`
  background-color: #2b384b;
  min-height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MiddleBeltContainer = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 30px;

  @media (max-width: 622px) {
    padding: 0 24px;
  };

  @media (max-width: 392px) {
    padding: 0 20px;
  };
`;

export const MiddleBeltTitle = styled.p`
  color: white;
  font-size: 60px;
  padding-bottom: 10px;
  padding-top: 30px;

  @media (max-width: 622px) {
    font-size: 40px;
  };

  @media (max-width: 392px) {
    font-size: 34px;
  };
`

export const MiddleBeltMessage = styled.p`
  color: white;
  font-size: 28px;
  word-wrap: break-word;
  max-width: 1250px;
  padding-bottom: 36px;

  @media (max-width: 1240px) {
    font-size: 24px;
  };

  @media (max-width: 622px) {
    font-size: 18px;
  };
`

export const FindCourses = styled.button`
  color: #331b89;
  font-size: 20px;
  background-color: inherit;
  border: none;

  @media (max-width: 594px) {
    font-size: 16px;
  }
`

export const CourseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;