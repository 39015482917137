import React from "react";
import { WithoutCourses, DivWithoutCourses } from "../styles";
import Course from "../../components/courses/Course";

function Finished({finished, hasFinished}){

  return(
    <>
      {hasFinished ? (
        <DivWithoutCourses>
          <WithoutCourses>Você ainda não finalizou nenhum curso.</WithoutCourses> 
        </DivWithoutCourses>
      ) : 
        finished.map((item) => (
          <Course item={item} />
        ))
      }
    </>
  )
}

export default Finished;