import React from "react";
import { RadioButtons } from "components/form";

function OptionType(props) {
  const { answer, index } = props;
  const { options } = answer?.attributes;

  const prefix = {
    id: `answers_${index}_ats_questionnaire_option_id`,
    name: `answers[${index}][ats_questionnaire_option_id]`,
  };

  return (
    <>
      <RadioButtons
        {...props}
        id={prefix.id}
        name={prefix.name}
        options={options}
      />
      <input type="hidden" name={`answers[${index}][type]`} value="ATS::Questionnaire::AnswerMultipleChoice" />
    </>
  );
}

export default OptionType;
