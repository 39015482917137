import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import Answer from "./Answer";
import Comment from "./Comment";
import FormAnswerContext from "./context";
import { INTERNSHIP, USERS } from "navigation/ROUTES";

function FormAnswer() {
  const { formAnswer } = useContext(FormAnswerContext);
  const { register, control, errors, handleSubmit, setValue, getValues } =
    useForm();
  let navigate = useNavigate();

  const renderAnswers = () => {
    const { answers, finished } = formAnswer;

    return answers?.map((answer, index) => (
      <Answer
        key={answer.id}
        answer={answer}
        index={index}
        control={control}
        register={register}
        errors={errors}
        getValues={getValues}
        disabled={finished}
      />
    ));
  };

  const successfulRequisition = () => {
    navigate(USERS.HOME);
    swal(
      "Tudo certo!",
      "Sua solicitação foi concluída com sucesso! Se você escolheu apenas salvar, poderá terminar o preenchimento da avaliação mais tarde!",
      "success"
    );
  };

  const unsuccessfulRequisition = () => {
    navigate(INTERNSHIP.COMPANY_EVALUATION.INDEX);
    swal(
      "Ops!",
      "Não foi possível concluir sua avaliação. Por favor, tente novamente mais tarde",
      "error"
    );
  };

  const submitAnswers = (data) => {
    const request = `/v1/internship/company_evaluation/form_answers/${formAnswer.uuid}`;
    const { id } = formAnswer;
    api
      .put(request, { data, id })
      .then(() => successfulRequisition())
      .catch(() => unsuccessfulRequisition());
  };

  const renderComment = () => {
    return (
      <Comment
        defaultValue={formAnswer.comment}
        control={control}
        register={register}
        errors={errors}
        getValues={getValues}
        disabled={formAnswer.finished}
      />
    );
  };

  const renderButton = () => {
    return formAnswer.finished ? (
      ""
    ) : (
      <div className="buttons">
        <button className="button button-secondary" type="submit" onClick={() => setValue("only_save", true)}>
          Apenas salvar
        </button>
        <button className="button button-primary" type="submit">
          Enviar
        </button>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(submitAnswers)}>
      <h3>
        Responda às perguntas para avaliar seu estágio e/ou a empresa onde
        estagia:
      </h3>
      {renderAnswers()}
      {renderComment()}
      {renderButton()}
    </form>
  );
}


export default FormAnswer;
