import { CardBox, CardBoxFooter, CardBoxImage, CardBoxInfo, CardBoxLabel } from "components/ui";
import { COMPANIES } from "navigation/ROUTES";
import React from "react";
import { Link } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";

function Company({ item }) {
  const company = item.attributes;

  return (
    <CardBox>
      <div className="card-box-body">
        <CardBoxImage image={company.avatar} />

        <CardBoxInfo title={
          <Link to={pathWithParams(COMPANIES.SHOW, {id: company.slug})}>
            {company.name}
          </Link>
        } subtitle={company.sector_name}>
          <CardBoxLabel>
            <small>{company.job_count} vagas disponíveis</small>
            <small>
              <i className="material-icons">access_time</i> desde{" "}
              {company.created_at} no WallJobs
            </small>
          </CardBoxLabel>

          <CardBoxFooter>
            <i className="material-icons">link</i>{" "}
            <span dangerouslySetInnerHTML={{ __html: company.website }}></span>
          </CardBoxFooter>
          
        </CardBoxInfo>
      </div>
    </CardBox>
  );
}

export default React.memo(Company);