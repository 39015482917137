import React, { useState } from "react";
import Course from "components/users/fields/academic/Course";
import University from "components/users/fields/academic/University";
import CurrentSemester from "components/users/fields/academic/CurrentSemester";
import FormationDate from "components/users/fields/academic/FormationDate";
import NotInUniversity from "components/users/fields/academic/NotInUniversity";
import AuthorizeWhatsapp from "components/users/fields/essential/AuthorizeWhatsapp";
import AutoEnroll from "components/users/fields/essential/AutoEnroll";
import CellPhone from "components/users/fields/essential/CellPhone";
import CPF from "components/users/fields/essential/CPF";
import DateOfBirth from "components/users/fields/essential/DateOfBirth";
import Email from "components/users/fields/essential/Email";
import Ethnicity from "components/users/fields/essential/Ethnicity";
import Gender from "components/users/fields/essential/Gender";
import Sex from "components/users/fields/essential/Sex";
import Name from "components/users/fields/essential/Name";
import SocialName from "components/users/fields/personal/SocialName";
import Working from "components/users/fields/essential/Working";
import Nationality from "components/users/fields/essential/Nationality";
import CurriculumVitae from "components/users/fields/essential/CurriculumVitae";
import MaritalStatus from "components/users/fields/essential/MaritalStatus";
import RG from "components/users/fields/personal/RG";
import DisabilitySection from "./sections/DisabilitySection";
import JobInterests from "components/users/fields/essential/JobInterests";
import InstitutionalEmail from "components/users/fields/essential/InstitutionalEmail";
import store from "@root/redux/store";


function Essential(props) {
  const notInUniversity = props.getValues?.("not_in_university")?.toString() || false;
  const [showAcademic, setShowAcademic] = useState(notInUniversity !== "true");
  const [genderId, setGenderId] = useState(null);

  const user = store.getState().session?.attributes;

  const GENDER_MALE_ID = 1;
  const GENDER_FEMALE_ID = 2;

  const handleShowAcademic = (value) => {
    setShowAcademic(value !== "true");
  };

  const handleGender = (value) => {
    setGenderId(value);
  }

  function renderSocialName(){
    if(genderId === GENDER_MALE_ID || genderId === GENDER_FEMALE_ID || genderId === null){
      return ;
    }

    return (
      <div className="col-md-4">
        <SocialName {...props} />
      </div>
    )
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <h3>Dados Essenciais</h3>
      </div>

      <div className="col-md-4">
        <Name {...props} required />
      </div>
      {
        renderSocialName()
      }
      <div className="col-md-4">
        <CPF {...props} required />
      </div>
      <div className="col-md-4">
        <RG {...props} required />
      </div>
      <div className="col-md-4">
        <DateOfBirth {...props} required />
      </div>
      <div className="col-md-4">
        <Ethnicity {...props} required />
      </div>
      <div className="col-md-4">
        <Gender {...props} handleChange={handleGender} required />
      </div>
      <div className="col-md-4">
        <Sex {...props} />
      </div>
      <div className="col-md-4">
        <Email {...props} required />
      </div>
      <div className="col-md-4">
        <CellPhone {...props} required />
      </div>
      <div className="col-md-4">
        <MaritalStatus {...props} required />
      </div>
      { user?.intern && 
        <div className="col-md-4">
          <InstitutionalEmail {...props}/>
        </div>
      }
      <div className="col-md-4">
        <Nationality {...props} />
      </div>
      <div className="col-md-4">
        <JobInterests {...props} />
      </div>
      <div className="col-md-12">
        <NotInUniversity
          {...props}
          handleChange={handleShowAcademic}
        />
      </div>
      {showAcademic ? <UniversityFields {...props} /> : null}
      
      <div className="col-md-4">
        <Working {...props} />
      </div>
      <div className="col-md-4">
        <AutoEnroll {...props} />
      </div>
      <div className="col-md-4">
        <AuthorizeWhatsapp {...props} />
      </div>
      <DisabilitySection {...props} />

      <div className="col-md-4" style={{ marginTop: "40px" }}>
        <CurriculumVitae {...props} />
      </div>
    </div>
  );
}

export default Essential;

function UniversityFields(props) {
  return (
    <>
      <div className="col-md-6">
        <University {...props} required />
      </div>
      <div className="col-md-6">
        <Course {...props} required />
      </div>
      <div className="col-md-6">
        <CurrentSemester {...props} required />
      </div>
      <div className="col-md-6">
        <FormationDate {...props} required />
      </div>
    </>
  );
}
