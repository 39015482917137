import React from "react";
import { connect } from "react-redux";
import Contact from "./sidebar/Contact";
import Infos from "./sidebar/Infos";
import Languages from "./sidebar/Languages";
import Avatar from "./sidebar/Avatar";
import Header from "./Header";
import EmptyFields from "./sidebar/EmptyFields";
import { Switch as EditModeSwitch} from "components/users/EditMode";

function mapStateToProps(state) {
  const { isLogged } = state.session;
  return { isLogged };
}

export default connect(mapStateToProps)(({ isLogged }) => {
  return (
    <div className="sidebar">
      {isLogged && <EditModeSwitch />}
      <Avatar />
      <Header />
      <EmptyFields />
      <Infos />
      <Contact />
      <Languages />
    </div>
  );
});
