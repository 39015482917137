import React, { useEffect } from "react";
import { UncontrolledInput as Input } from "components/form";
import { useTranslation } from "react-i18next";

export default (props) => {
  const { question } = props;

  const { t } = useTranslation();

  const prefix = {
    name: `question_answers_attributes[${props.index}][answer]`,
    id: `question_answers_attributes_${props.index}_answer`,
  }

  useEffect(() => {
    let question_field_name = `question_answers_attributes[${props.index}][internship_update_question_id]`;
    props.setValue?.(question_field_name, question.id);
  }, [])

  return (
    <div className="col-md-12">
      <Input
        {...props}
        type="text"
        multiline
        placeholder={t("update_form_answer.questions.question_answer.placeholder")}
        rows={3}
        name={prefix.name}
        id={prefix.id}
        label={question.title}
      />
    </div>
  )
}