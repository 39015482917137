import React, { useEffect, useState } from "react";
import { formPost } from "services/api";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { USERS } from "navigation/ROUTES";
import Fields from "./Fields";

export default () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  let navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur", shouldUnregister: true });

  const getData = (collectedData) => {
    setData(collectedData);
  };

  useEffect(() => {
    const submit = () => {
      const user_info = data;

      formPost("/v1/ats/candidatures/fast_create", { user_info, job_id: id })
        .then((resp) => {
          const { message } = resp.data;
          swal("Tudo certo!", message, "success");
          navigate(USERS.LOGIN);
        })
        .catch((error) => {
          const { message } = error.response.data;
          swal("Ops!", message, "error");
        });
    };

    if (!_.isEmpty(data)) submit();
  }, [data]);

  return (
    <form onSubmit={handleSubmit(getData)}>
      <Fields
        register={register}
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
      />
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <button className="button button-primary" type="submit">
            Candidatar-se
          </button>
        </div>
      </div>
    </form>
  );
};
