import React from "react";
import { AcademyHeader, HeaderTitle } from "./styles";
import Breadcrumb from "../breadcrumb/Breadcrumb";

function Header({title, breadcrumb}){

  return (
    <AcademyHeader>
      <HeaderTitle>{title}</HeaderTitle>
      <Breadcrumb text={breadcrumb} />
    </AcademyHeader>
  )
}

export default Header;