import React from "react";
import Main from "./Main";
import "./style.scss";

function Owned() {
  return (
    <div className="envelope-owned">
      <Main />
    </div>
  );
}

export default Owned;
