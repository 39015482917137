import React from "react";
import { useParams } from "react-router-dom";
import { ActionsContainer, Action } from "./styles";
import Certificate from "./Certificate";

function Index(){

  const { id } = useParams();


  return (
    <>
      <ActionsContainer>
        <Action href={`/academy/cursos/certificado-em-pdf/${id}`} target="_blank" >
          Baixar o certificado em PDF
        </Action>
      </ActionsContainer>
      <Certificate />
    </>
  )
}

export default Index;
