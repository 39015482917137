import React from "react";
import Jobs from './Jobs';
import About from './About';

function TabContent() {
  return (
    <div className="tab-content section">
      <About />
      <Jobs />
    </div>
  );
}

export default TabContent;
