import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useProgram() {
  const { id } = useParams();
  const { data } = useFetch(`/v1/internship_programs/${id}`);
  return {
    internship_program: data?.collection?.data?.attributes,
    jobs: data?.jobs?.data,
  };
}