import React from "react";
import { Helmet } from "react-helmet";

export default ({company}) => (
  <Helmet>
    <title>{company.name}</title>
    <meta name="description" content={company.description} />
    <meta property="og:title" content={`Veja as vagas da empresa ${company.name}<br />Se candidate clicando aqui`} />
    <meta property="og:description" content={company.description} />
    <meta property="og:site_name" content="Walljobs" />
    <meta property="og:image" content={company.avatar} />
  </Helmet>
);
