import React from "react";
import _ from "lodash";
import { LinearProgress } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useUserInfo } from "../context";
import { EditIcon } from "components/users/EditMode";

const BorderLinearProgress = withStyles({
  root: {
    height: 8,
    marginTop: 15,
  },
  colorPrimary: {
    background: "#DBDBDB",
  },
  bar: {
    backgroundColor: "#6DE0C6",
    marginBottom: 0,
  },
})(LinearProgress);

const normalise = (value) => ((value + 1) * 100) / 5;

function Languages() {
  const { getIncluded } = useUserInfo();
  const languages = getIncluded("language");

  const _renderLanguages = () => {
    const list = languages || [];
    return list.map(({ id, attributes }) => (
      <Language key={id} attributes={attributes} />
    ));
  };

  if(_.isEmpty(languages)) {
    return;
  }

  return (
    <section>
      <h4>
        IDIOMAS <EditIcon type="languages" />
      </h4>
      <hr />
      {_renderLanguages()}
    </section>
  );
}

function Language({ attributes }) {
  return (
    <div className="language">
      <p>
        <b>{attributes.name}</b> <small>({attributes.level_name})</small>
      </p>
      <BorderLinearProgress
        variant="determinate"
        value={normalise(attributes.level)}
      />
    </div>
  );
}

export default Languages;
