import React from "react";
import Main from "./Main";
import HeaderCourses from "../components/header/Header";
import { connect } from "react-redux";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { USERS } from "navigation/ROUTES";

function Index(props){
  const {user, isLogged} = props;

  let navigate = useNavigate();

  if(!isLogged){
    swal(
      "Ops!",
      "Você precisa estar logado para visualizar essa página!",
      "warning"
    ).then(() => navigate(USERS.LOGIN));
  }

  return(
    isLogged && 
    <>
      <HeaderCourses title={"Meus Cursos"} breadcrumb={"Meus Cursos"} />
      <Main />
    </>
  )
}

function mapStateToProps(state) {
  const user = state.session?.attributes;
  const isLogged = state.session?.isLogged;

  return {user, isLogged};
}


export default connect(mapStateToProps)(Index);