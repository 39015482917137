import React from "react";
import styled from "styled-components";

import login from "assets/images/login.png";

const ErrorPage = styled.div`
  width: 100vw;
  height: 100vh;
  background: url(${login}) no-repeat left bottom -80px;
  background-color: rgba(35, 40, 53, 1);
`

const Text = styled.div`
  width: 600px;
  position: absolute;
  right: 130px;
  top: 100px;

  h1,
  h2,
  p {
    font-family: "Source Sans Pro", sans-serif;
    color: rgba(155, 155, 155, 1);
    text-align: right;
  }

  h1 {
    font-size: 220px;
    font-weight: 600;
    text-shadow: 10px 10px 0px rgba(255, 255, 255, 0.15);
  }

  h2 {
    font-weight: normal;
    font-size: 50px;
  }

  p {
    font-size: 18px;
  }

  @media screen and (max-width: 560px) {
    & {
      width: 310px;
      top: 75px;
      right: 0;
      position: relative;
      display: block;
      margin: 0 auto;

      h1 {
        font-size: 200px;
      }

      h2 {
        font-size: 46px;
      }
    }
  }
`

const BackButton = styled.a`
  position: absolute;
  display: flex;
  align-items: center;
  top: 30px;
  left: 30px;
  color: rgba(200, 200, 200, 1);
  font-size: 20px;
  text-decoration: none;
  transition: .1s linear all;

  &:hover{
    color: rgba(200, 200, 200, 1);
    left: 25px;
  }
`

export default () => {
  return (
    <ErrorPage>
      <Text>
        <h1>404</h1>
        <h2>A página não foi<br /><b>encontrada!</b></h2>
        <p>Talvez você tenha errado o endereço<br /> ou nós mudamos a página de lugar.</p>
      </Text>

      <BackButton href="/">
        <i className="material-icons">keyboard_arrow_left</i>
        Voltar para Home
      </BackButton>
    </ErrorPage>
  )
}