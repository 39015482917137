import React from "react";
import FilterController from "components/filters/FilterController";
import { Breadcrumb, MainBox } from "components/ui";
import Envelopes from "./Envelopes";
import Filters from "./Filters";
import Actions from "./components/Actions";

function Main() {
  return (
    <MainBox>
      <Breadcrumb Actions={Actions}>
        <a>Meus Contratos</a>
      </Breadcrumb>
      <FilterController>
        <Filters />
        <Envelopes />
      </FilterController>
    </MainBox>
  );
}

export default Main;
