import React from 'react'
import Main from './Main'
import './style.scss';

function Settings() {
  return (
    <div className="user-settings">
      <Main />
    </div>
  )
}

export default Settings
