import React from 'react'
import Matriculation from 'components/users/fields/academic/Matriculation';
import RGState from 'components/users/fields/personal/RGState';
import StudyScheduleSection from './sections/StudyScheduleSection';

function Internship(props) {
  return (
    <div className="row">
      <div className="col-md-12">
        <h3>Dados para Estágio</h3>
        <p>Quando você encontrar um estágio pela nossa plataforma, os dados a seguir serão utilizados na elaboração do contrato...</p>
      </div>
      <div className="col-md-6">
        <Matriculation {...props} />
      </div>
      <div className="col-md-6">
        <RGState {...props} />
      </div>
      <StudyScheduleSection {...props} />
    </div>
  )
}



export default Internship;
