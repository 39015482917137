import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { CPF, Email, DateOfBirth, CellPhone } from "components/users/fields/essential";
import { INTERNSHIP } from "navigation/ROUTES";
import { pathWithParams } from 'utils/pathWithParams';
import { useFormAnswer } from "../FormAnswer/context";

function Form({loginKey}) {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const { uuid } = useParams();
  const { company } = useFormAnswer();

  const [data, setData] = useState({});
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const getData = (collectedData) => {
    $(".button:submit").attr("disabled", true);
    setData({ ...data, ...collectedData });
  };

  const successfulLogin = () => {
    swal("Login bem-sucedido", "Pedimos que você preencha esse formulário com atenção e cuidado!", "success");

    let path = company.type === "default" ? INTERNSHIP.ADMISSION.FORM_ANSWER : INTERNSHIP.STELLANTIS.ADMISSION.FORM_ANSWER;

    navigate(pathWithParams(path, { uuid }));
  }

  const unsuccessfulLogin = (message) => {
    $(".button:submit").removeAttr("disabled")
    swal("Ops!", message, "error");
    setError("email", { type: "manual" })
    setError(loginKey, { type: "manual" })
  }

  const submit = () => {
    axios
      .post(pathname, { user_info: data })
      .then(() => successfulLogin())
      .catch((error) => unsuccessfulLogin(error.response.data?.message));
  };

  let fields = {
    cpf: <CPF register={register} errors={errors} required />,
    cellphone: <CellPhone register={register} errors={errors} required />,
    date_of_birth: <DateOfBirth register={register} errors={errors} required />,
  }

  useEffect(() => {
    if (!_.isEmpty(data.email) && !_.isEmpty(data[loginKey])) submit();
  }, [data]);

  return (
    <form onSubmit={handleSubmit(getData)}>
      <h3>Faça seu login</h3>
      {fields[loginKey]}
      <Email register={register} errors={errors} label="Email" required />
      <button className="button button-primary" type="submit">Entrar</button>
    </form>
  );
}

export default Form;
