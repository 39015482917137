import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useFormAnswer() {
  const { uuid } = useParams();
  const { data, isLoading } = useFetch(`/estagios/admissao/formulario/${uuid}/infos`);

  return { 
    company: data?.company,
    term: data?.term,
    category_documents: data?.category_documents,
    loginKey: data?.login_key,
    intern_response: data?.intern_response,
    has_created_rescission: data?.has_created_rescission,
    has_signed_rescission: data?.has_signed_rescission,
    internship_end_date: data?.internship_end_date,
    isLoading,
  };
}
