import React from "react";
import OptionType from "./types/OptionType";
import TextType from "./types/TextType";
import styled from 'styled-components';

const Paragraph = styled.p`
  color: ${({theme}) => theme.secondary.main};
  font-size: 1rem;
  font-weight: 600;
`

function Answer(props) {
  const { answer } = props;
  const { type, title, description, text, option_id } = answer?.attributes;

  const defaultValue = text || option_id;

  const renderAnswer = () => {
    const component = type ? <OptionType /> : <TextType />;
    const componentProps = { defaultValue };
    return React.cloneElement(component, { ...props, ...componentProps }, null);
  };

  return (
    <div className="answer">
      <IdField {...props} />
      <Paragraph>{title}</Paragraph>
      {renderAnswer()}
      <p dangerouslySetInnerHTML={{__html: description}}></p>
    </div>
  );
}

function IdField(props) {
  const { answer, index } = props;
  const { id } = answer;

  const prefix = {
    id: `answers_${index}_id`,
    name: `answers[${index}][id]`,
  };

  const inputRef = props.register?.(prefix.name)

  return (
    <input
      type="hidden"
      value={id}
      name={prefix.name}
      id={prefix.id}
      ref={inputRef?.ref}
    />
  );
}

export default Answer;
