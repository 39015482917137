import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useConfiguration() {
  const { display_name } = useParams();
  const { data, isLoading } = useFetch(`/v1/ies/boards/configurations/${display_name}`);

  return { 
    configuration: {logo: data?.logo, university_name: data?.university_name},
    display_name: display_name, 
    isLoading 
  };
}