import React, { useState } from "react";
import { ButtonToggle, DrawerOpen, DrawerClosed } from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MenuIcon from '@mui/icons-material/Menu';
import Section from "./Section";
import { pathWithParams } from "utils/pathWithParams";
import { useNavigate } from "react-router-dom";
import { ACADEMY } from "navigation/ROUTES";


function Drawer(props){

  const [open, setOpen] = useState(true);
  
  function renderButtonToggle(){
    let navigate = useNavigate();
    
    if(open){
      return(
        <ButtonToggle>
          <div className="back" onClick={() => navigate(pathWithParams(ACADEMY.COURSE.SHOW, {id: props.course.id}))}>Voltar para a página do curso</div>
          <ArrowBackIosIcon style={{cursor: "pointer", color: "white"}} onClick={() => setOpen(!open)} />
        </ButtonToggle>
      )
    }else{
      return(
        <ButtonToggle marginRight={"0"} closed={true}>
          <MenuIcon style={{cursor: "pointer", color: "white"}} onClick={() => setOpen(!open)} />
        </ButtonToggle>
      )
    }
  }

  return(
    <>
      {open ? (
        <DrawerOpen>
          {renderButtonToggle()}
          <div>
            {props.course.content.map((section) => (
              <Section section={section} section_id={props.item.academy_section_id} />
            ))}
          </div>
        </DrawerOpen>
      ) : (
        <DrawerClosed>
          {renderButtonToggle()}
        </DrawerClosed>
      )}
      
    </>
  )
}

export default Drawer;