import React from "react";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default (props) => {
  const { items, value, handleChange } = props;

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} variant="scrollable" allowScrollButtonsMobile>
        {items.map((tab, index) => {
          return <Tab key={tab.type} label={tab.label} {...a11yProps(index)} />
        })}
      </Tabs>
    </Box>
  )
}