import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  Typography,
} from "@mui/material";
import { ExpandMore } from "components/ui";
import DeleteAction from "./delete/DeleteAction";

function Document(props) {
  const { document } = props;
  const [expanded, setExpanded] = useState(false);

  const _renderLinks = () => {
    const links = document.document_files || [];
    return links.map((link, index) => (
      <a className="document-link" target="_blank" key={index} href={link.url}>
        <i className="material-icons">vertical_align_bottom</i> {link.filename}
      </a>
    ));
  };

  return (
    <Card sx={{ height: "fit-content" }}>
      <CardContent>
        <div>
          <p>{document.category}</p>
          <h4>{document.document_type}</h4>
        </div>
        <CardActions>
          <ExpandMore expanded={expanded} setExpanded={setExpanded} />
          <DeleteAction id={document.id} {...props} />
        </CardActions>
      </CardContent>
      <Collapse
        sx={{ maxWidth: 300 }}
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>
          <Typography variant="body1" color="textSecondary">
            {_renderLinks()}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default Document;
