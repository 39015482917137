import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useFormAnswer() {
  const { id } = useParams();
  const { data, isLoading, mutate } = useFetch(`/v1/ats/questionnaire/form_answers/${id}`);

  return {
    form: data?.data?.attributes,
    isLoading,
    mutate
  };
}
