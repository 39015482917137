import React from "react";
import { Loader } from "components/ui";
import { useCourse, useMatriculation} from "./context.js";
import Certificate from "./Certificate.jsx";
import {FinishedCourseContainer, FinishedCourseText, FinishedCourseActionsContainer, FinishedCourseAction, CertificateUrl} from "./styles.js";

function FinishedCourse() {
  const {course, courseIsLoading} = useCourse();
  const {matriculationData, userInfo, matriculationIsLoading} = useMatriculation();

  if(courseIsLoading || matriculationIsLoading){
    return(
      <Loader />
    )
  }

  const certificateUrl = matriculationData.certificate_url.replace("api.", "www.")

  return (
    <>
      <FinishedCourseContainer>
        <FinishedCourseText>
          Parabéns! Você concluiu o curso com sucesso. Seu comprometimento e dedicação foram exemplares. 
          Continue trilhando seu caminho com confiança e colha os frutos do seu esforço!
        </FinishedCourseText>
        <FinishedCourseActionsContainer>
          <FinishedCourseAction href={`/academy/cursos/certificado-em-pdf/${matriculationData.id}`} target="_blank">
            Baixar certificado em PDF
          </FinishedCourseAction>
          <FinishedCourseAction href={`/academy`}>
            Menu principal
          </FinishedCourseAction>
          <FinishedCourseAction href={`/academy/cursos/meus`}>
            Meus cursos
          </FinishedCourseAction>
        </FinishedCourseActionsContainer>
        <CertificateUrl>
          Link de acesso público do certificado: <a href={certificateUrl} target="_blank">{certificateUrl}</a>
        </CertificateUrl>
      </FinishedCourseContainer>
      
      <Certificate course={course} userInfo={userInfo} />
    </>
  )
}

export default FinishedCourse;