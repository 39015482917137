import React from "react";
import { connect } from "react-redux";
import { useJob } from "../context";
import JobLabel from "components/ats/JobLabel";
import BackBreadcrumb from "../BackBreadcrumb";
import IesExclusivity from "./IesExclusivity";
import candidatureProcess from "../candidatureProcess";
import { ContentBoxHeaderButtons } from "components/ui";

function mapStateToProps(state) {
  const { isLogged } = state.session;
  const updatedProfileAt = state.session?.attributes?.updated_profile_at;

  return { isLogged, updatedProfileAt };
}

export default connect(mapStateToProps)((props) => {
  const { job, university } = useJob();

  const { isLogged, updatedProfileAt } = props;

  const CandidatureProcess = candidatureProcess({ job, updatedProfileAt });

  const redirectOrCandidate = () => {
    if (!isLogged) {
      CandidatureProcess.unauthenticated();
    } else {
      CandidatureProcess.applyCandidature();
    }
  };

  const candidatureButton = (job) => {
    return (
      <button
        className={`button button-${job.state}`}
        onClick={redirectOrCandidate}
        disabled={job.state == "closed"}
      >
        {candidatureText(job)}
      </button>
    );
  };

  const candidatureText = (job) => {
    switch (job.state) {
      case "closed":
        return "Inscrições Encerradas";
      case "started":
        return "Quero me candidatar";
    }
  };

  return (
    <>
      <header className="content-box-header">
        <div className="content-box-header-infos">
          <div className="images">
            <img
              className="logo"
              src={university.avatar}
              alt={`Logo de ${university.name}`}
            />
          </div>
          <div>
            <h3>{job.author_name}</h3>
            <h1>{job.title}</h1>
            {job.ies_exclusive && <IesExclusivity />}
            <p>{job.workplace_type}</p>
            <p className="job-salary">
              <i className="material-icons">attach_money</i> {job.salary}
            </p>
            <p className="job-address">
              <i className="material-icons">place</i> {job.address}
            </p>
          </div>
        </div>

        <div className="content-box-header-label">
          <JobLabel job={job} />
        </div>

        <ContentBoxHeaderButtons>
          {candidatureButton(job)}
        </ContentBoxHeaderButtons>
      </header>
      <BackBreadcrumb />
    </>
  );
});
