import React from "react";
import Courses from "./Courses";
import FilterController from "components/filters/FilterController";
import Filters from "./Filters";
import { FilterDiv } from "./styles";

function Main(){
  return(
    <FilterController>
      <FilterDiv>
        <Filters />
        <Courses />
      </FilterDiv>
    </FilterController>
  )
}

export default Main;