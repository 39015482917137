import React from "react";
import { useJob } from "./context";

function Sidebar() {
  const { job } = useJob();

  return (
    <div className="sidebar">
      <p>{job.address_city_state}</p>
      <p>{job.views}</p>
      <p>{job.days_to_close}</p>
    </div>
  );
}

export default Sidebar;
