import React from "react";
import { Breadcrumb, MainBox } from "components/ui";
import Main from "./Main";

function Index() {
  return (
    <MainBox>
      <Breadcrumb>
        <a>Vagas</a>
      </Breadcrumb>
      <Main />
    </MainBox>
  );
}

export default Index;
