import React from "react";
import styled from "styled-components";

export const InfosSecondary = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 20px 20px 0;
  column-gap: 2px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  
  p {
    margin: 0 12px 0 0;
    font-size: 13px;
  }
`

export default (props) => (
  <InfosSecondary>
    {props.children}
  </InfosSecondary>
)