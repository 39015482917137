import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useAttendee() {
  const { id } = useParams();
  const { data, isLoading, mutate } = useFetch(`/v1/attendee/attendees/${id}`);

  return {
    attendee: data?.data?.attributes,
    meeting: data?.included[0]?.attributes,
    isLoading,
    mutate
  };
}
