import React from "react";
import { Loader, Logo } from "components/ui";
import Form from "./Form";
import "./style.scss";
import { useFormAnswer } from "../FormAnswer/context";

const Login = () => {
  const {loginKey, isLoading } = useFormAnswer();

  if(isLoading){
    return <Loader />
  }

  return (
    <div className="internship-admission-login">
      <Logo />
      <Form loginKey={loginKey} />
    </div>
  )
}

export default Login;