import React from "react";
import { useTranslation } from "react-i18next";
import { useUserInfo, useInvite } from "../../context";
import StudySchedule from "./StudySchedule";
import { Loader } from "components/ui";
import { EditIcon } from "components/users/EditMode";

function Internship() {
  const { userInfo } = useUserInfo();
  const { matriculation, rg_state } = userInfo;

  const { t } = useTranslation();
  const { invite, isLoading } = useInvite();

  if(isLoading){
    return(
      <Loader />
    )
  }
  
  function renderInvite(){
    if(invite){
      return (
          <div>
            <b>{t("internship.update_form_answer.update_form")}: </b> <br /> {t("internship.update_form_answer.pendent")}. <a href={invite.replace("api.", "www.")} target="_blank">{t("internship.update_form_answer.click_here")}</a> {t("internship.update_form_answer.to_answer")}.
          </div>
        )
    }  
  }
    
  return (
    <div
      className="tab-pane fade"
      id="internship"
      role="tabpanel"
      aria-labelledby="internship-tab"
    >
      <EditIcon type="internship" />

      <h3>{t("internship.title")}</h3>
      <p>
        <b>{t("user_info.attributes.matriculation")}:</b> {matriculation}
      </p>
      <p>
        <b>{t("user_info.attributes.rg_state")}:</b> {rg_state}
      </p>
      <br />
      <StudySchedule />
      <br />
      <br />
      <p>
        {renderInvite()}
      </p>
    </div>
  );
}

export default Internship;
