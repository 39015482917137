import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useAttendee } from "./context";
import Confirm from "./sections/Confirm";
import Decline from "./sections/Decline";
import Description from "./sections/Description";
import Reschedule from "./sections/Reschedule";
import { pathWithParams } from "utils/pathWithParams";
import { ATTENDEES } from "navigation/ROUTES";

function Content() {
  const { attendee, mutate } = useAttendee();
  const { id: attendee_id } = attendee;

  let navigate = useNavigate();

  const successfulRequisition = (message) => {
    mutate();
    navigate(pathWithParams(ATTENDEES.ABOUT, {id: attendee_id}))
    swal("Tudo certo!", message, "success");
  };

  return (
    <div className="main-content">
      <Routes>
        <Route path="/">
          <Route path="sobre" element={<Description />} />
          <Route
            path="confirmar"
            element={<Confirm successfulRequisition={successfulRequisition} />}
          />
          <Route
            path="recusar"
            element={<Decline successfulRequisition={successfulRequisition} />}
          />
          <Route
            path="reagendar"
            element={
              <Reschedule successfulRequisition={successfulRequisition} />
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default Content;
