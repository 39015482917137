import React from "react";
import logo from "assets/images/logo.png";
import { useCandidature } from "../context";

export default () => {
  const { job } = useCandidature();

  return (
    <>
      <header>
        <h2>{job.title}</h2>
        <img src={logo} className="logo" alt="Logo Walljobs" />
      </header>
      <p>
        Esse formulário serve para você fazer uma pré-candidatura à vaga <b>{job.title}</b>. Após preencher os dados abaixo, caso você ainda não possua um login em nossa plataforma, enviaremos um email para que você complete seu cadastro e os requisitos do processo seletivo.
      </p>
    </>
  );
};
