import styled from "styled-components";

export const FilterDiv = styled.div`
  width: 100%;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1234px){
    padding: 10px 200px;
  }
`