import React, { Suspense, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import networkService from "services/api/network-service";

import { ButtonToTop, Loader } from "components/ui";
import RouterConfig from 'navigation/RouterConfig';

const MetaTags = () => (
  <Helmet>
    <title>WallJobs - Vagas de estágio e trainee</title>
    <meta charset="UTF-8" />
    <meta
      content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      name="viewport"
    />
    <meta name="description" content="Trabalhe onde você quer, não onde deu!" />
    <meta
      name="keywords"
      content="vagas, vaga, empregos, emprego, oportunidade, trabalho, jobs, carreira, contratação, candidatura, profissão, recrutamento, estágio, networking, trainee, empregabilidade, candidato"
    />
    <meta http-equiv="Content-Language" content="pt-br, en" />
    <meta name="ROBOTS" content="NOODP" />

    <meta property="og:title" content="Walljobs - Vagas de estágio e trainee" />  
    <meta property="og:description" content="Trabalhe onde você quer, não onde deu!" />
    <meta property="og:url" content="https://www.walljobs.com.br/" />
    <meta property="og:image" content="/assets/images/wj_300x300.png" />
    <meta property="og:type" content="website" />
  </Helmet>
);

export default () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();

  networkService.setupInterceptors(navigate);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <Suspense fallback={<Loader />}>
      <MetaTags />

      <RouterConfig />

      <ButtonToTop />
    </Suspense>
  );
};
