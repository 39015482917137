import React from "react";
import api from "services/api";
import BackDescription from "./BackDescription";
import ConfirmForm from "./ConfirmForm";
import { useAttendee } from "../context";

function Confirm(props) {
  const { attendee } = useAttendee();
  const { id: attendee_id } = attendee;

  const handleSubmit = ({ time }) => {
    api
      .post(`/v1/attendee/attendees/confirm`, {
        time,
        attendee_id,
      })
      .then(() =>
        props.successfulRequisition(
          "Sua presença nesse evento foi confirmada com sucesso!"
        )
      )
      .catch((error) => {
        swal(
          "Algo deu errado",
          `${error.response.data.error[0]}`,
          "error"
        )
      });
  };

  return (
    <div className="confirm-form">
      <BackDescription />
      <h2>Confirmar</h2>
      <p>Escolha um horário para confirmar sua presença no evento.</p>
      <ConfirmForm handleSubmit={handleSubmit} />
    </div>
  );
}

export default Confirm;
