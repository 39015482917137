import React from "react";
import Display from 'components/filters/Display';
import Job from "pages/ats/jobs/shared/Job";
import { useCompany } from '../context';

function List() {
  const { company } = useCompany();

  const request = `/v1/ats/jobs?company=${company.id}`;
  return <Display request={request} component={<Job />}/>
}

export default List;
