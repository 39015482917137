import React from "react";
import { Link } from "react-router-dom";
import { useAttendee } from "../context";
import { pathWithParams } from "utils/pathWithParams";
import { ATTENDEES } from "navigation/ROUTES";

function Description() {
  const { attendee, meeting } = useAttendee();

  return (
    <div className="description">
      <h2>Descrição</h2>
      <section
        dangerouslySetInnerHTML={{ __html: meeting.description }}
      ></section>
      <Links attendee={attendee} meeting={meeting} />
    </div>
  );
}

function Links({ attendee, meeting }) {
  if (attendee.declined || !meeting.available){
    return;
  }

  return (
    <div className="buttons">
      {attendee.waiting_confirmation ? (
        <Link
          className="button"
          to={pathWithParams(ATTENDEES.CONFIRM, { id: attendee.id })}
        >
          Confirmar
        </Link>
      ) : (
        <Link
          className="button"
          to={pathWithParams(ATTENDEES.RESCHEDULE, { id: attendee.id })}
        >
          Reagendar
        </Link>
      )}

      <Link
        className="button button-secondary"
        to={pathWithParams(ATTENDEES.DECLINE, { id: attendee.id })}
      >
        Recusar
      </Link>
    </div>
  );
}

export default Description;
