import React from "react";
import { useFormAnswer } from "./context";
import { MainBox, ContentBox, Breadcrumb, Loader } from "components/ui";
import Form from "./Form";

function Main() {
  const { form, isLoading } = useFormAnswer();
  
  const renderAnswerAt = () => {
    return form.finished && <AnswerAt answer_at={form.answer_at} />;
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <MainBox>
      <Breadcrumb>
        <a>Questionários</a>
        <a>{form.title}</a>
      </Breadcrumb>
      <ContentBox>
        {renderAnswerAt()}
        <Form />
      </ContentBox>
    </MainBox>
  );
}

function AnswerAt(props) {
  return (
    <p className="answer_at">
      <i className="material-icons">check</i> Respondido em {props.answer_at}
    </p>
  );
}

export default Main;
