import React, { useState } from "react";
import HasCreatedRescission from "./HasCreatedRescission";
import InternshipEndDate from "./InternshipEndDate"
import { RadioButtons } from "components/form";

function InternResponse(props){
  const [value, setValue] = useState(null);    

  const prefix = props.prefix || {
    name: "intern_response",
    id: "intern_response",
  };

  const handleChange = (value) => {
    setValue(value);
  };  

  const options = [
    { value: "1", label: "Sim" },
    { value: "2", label: "Não" },
  ];
  
  const renderHasCreatedRescission = () => {
    if (value != "1") return;

    return( 
      <>
        <HasCreatedRescission {...props} /> 
        <InternshipEndDate {...props} />
      </>
    )    
  };  

  return (
    <div className="form-group">
      <RadioButtons
        {...props}
        id={prefix.id}
        name={prefix.name}
        options={options}
        required={true}
        label={props.label || "Você está, atualmente, estagiando em alguma empresa?"}
        handleChange={handleChange}
      />
      { renderHasCreatedRescission() }    
    </div>
  );
}

export default InternResponse;
