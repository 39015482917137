import React from "react";
import Main from "./Main";

function WatchCourse(){

  return (
    <Main />
  )
}

export default WatchCourse;