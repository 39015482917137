import React, { useState } from "react";
import InternshipLaw from "./InternshipLaw";
import { RadioButtons } from "components/form";

function HasSignedRescission(props){
  const [value, setValue] = useState(null);  

  const prefix = props.prefix || {
    name: "has_signed_rescission",
    id: "has_signed_rescission",
  };

  const handleChange = (value) => {
    setValue(value);
  };

  const options = [
    { value: "1", label: "Sim" },
    { value: "2", label: "Não" },
  ];

  const renderInternshipLaw = () => {
    if(value != "2") return;
    return < InternshipLaw />
  }

  return (
    <div className="form-group">
      <RadioButtons
        {...props}
        id={prefix.id}
        name={prefix.name}
        options={options}
        required={true}
        label={props.label || "A rescisão já foi totalmente assinada? (Ou seja, assinado pela empresa, por você e pela Instituição de Ensino)"}
        handleChange={handleChange}
      />
      { renderInternshipLaw() }    
    </div>
  );  
}

export default HasSignedRescission;