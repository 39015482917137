import React from 'react';
import Main from './Main';
import "./style.scss";


function Index(){
  return(
    <div className="career-mentor-index">
      <Main/>
    </div>
  )
}

export default Index;