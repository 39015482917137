import React from "react";
import Name from "components/users/fields/essential/Name";
import Email from "components/users/fields/essential/Email";
import CellPhone from "components/users/fields/essential/CellPhone";
import CPF from "components/users/fields/essential/CPF";
import DateOfBirth from "components/users/fields/essential/DateOfBirth";
import University from "components/users/fields/academic/University";
import Course from "components/users/fields/academic/Course";
import CurrentSemester from "components/users/fields/academic/CurrentSemester";
import FormationDate from "components/users/fields/academic/FormationDate";
import Summary from "components/users/fields/experience/Summary";
import Terms from "./Terms";

export default (props) => {
  return (
    <>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6">
          <Name {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <CPF {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-4 col-md-4">
          <Email {...props} required />
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4">
          <CellPhone {...props} required />
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4">
          <DateOfBirth {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <University {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <Course {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <CurrentSemester {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <FormationDate {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <Summary {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <Terms {...props} required />
        </div>
      </div>
    </>
  );
};
