import React from "react";
import './style.scss';
import { Breadcrumb, ContentBox, MainBox } from "components/ui";
import List from "./List";

export default () => {
  return (
    <div className="user-certificates">
      <MainBox>
        <Breadcrumb>
          <a>Certificados de Horas Complementares</a>
        </Breadcrumb>
        <List />
      </MainBox>
    </div>
  )
}