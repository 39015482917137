import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, MainBox, Loader } from "components/ui";
import MainSection from "./MainSection";
import Gallery from "./Gallery";
import { useCompany } from "./context";
import Contributions from "./Contributions";
import { COMPANIES } from "navigation/ROUTES";
import MetaTags from "./MetaTags";
import "./style.scss";

function Main() {
  const { company, isLoading } = useCompany();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <MetaTags company={company} />
      <MainBox>
        <Breadcrumb>
          <Link to={COMPANIES.INDEX}>Empresas</Link>
          <a>{company.name}</a>
        </Breadcrumb>
        <MainSection />
        <Gallery />
        <Contributions />
      </MainBox>
    </>
  );
}

export default Main;
