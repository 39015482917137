import React from "react";
import logo from "assets/images/logo.png";
import { useConvention } from "./context";

function Header() {
  const { convention } = useConvention();
  return (
    <>
      <div className="header">
        <h2>{convention.name}</h2>
        <img src={logo} className="logo" alt="Walljobs Logo" />
      </div>
      <p dangerouslySetInnerHTML={{ __html: convention.summary }}></p>
    </>
  );
}

export default Header;
