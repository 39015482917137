import React from "react";
import _ from "lodash";
import { NotInformed } from "components/ui";
import { useUserInfo } from "../../context";
import medal from "assets/images/medal.png";
import Skills from "./Skills";
import { EditIcon } from "components/users/EditMode";
import { useTranslation } from "react-i18next";

function Certifications() {
  const { getIncluded } = useUserInfo();
  const certifications = getIncluded("certification");
  const hasCertifications = !_.isEmpty(certifications);
  
  const { t } = useTranslation();

  const _renderCertifications = () => {
    const list = certifications || [];
    return list.map(({ id, attributes }) => (
      <Certification key={id} attributes={attributes} />
    ));
  };

  return (
    <div
      className="tab-pane fade"
      id="certifications"
      role="tabpanel"
      aria-labelledby="certifications-tab"
    >
      <EditIcon type="certifications" />
      <h3>{t("user_info.attributes.certifications")}</h3>
      {hasCertifications ? _renderCertifications() : <NotInformed />}
      <Skills />
    </div>
  );
}

function Certification({ attributes }) {
  return (
    <div className="certification">
      <img src={medal} alt="Medalha" />
      <h3>{attributes.name}</h3>
    </div>
  );
}

export default Certifications;
