import React from "react";
import {Accordion, Typography, useStyles} from "./styles.js";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Lecture from "./Lecture.jsx";
import { Loader } from "components/ui";
import { useMatriculation } from "../context";
import { useTotalWatched } from "../context";

function Section(props){
  const classes = useStyles();
  const {total, isLoading} = useTotalWatched(props.section.id)

  if(isLoading){
    return(
      <Loader />
    )
  }

  function renderLectures(section_items) {
    return section_items.map((section_item) => (
      <Lecture section_item={section_item} />
    ))
  }
  
  return (
    <>
      <Accordion defaultExpanded={props.section.id === props.section_id } >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: "white"}} />}
          aria-controls="panel1a-content"
          id="panel1a-header" classes={{content: classes.content}}
        >
          <Typography>{props.section.title}</Typography>
          <p style={{fontWeight: "600"}}>{total}/{props.section.section_items.length}</p>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {renderLectures(props.section.section_items)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default Section;