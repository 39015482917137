import React from "react";
import  { useCandidature } from "../context";
import IesExclusivity from "pages/ats/jobs/Show/IesJob/IesExclusivity";

function Header() {
  const { job } = useCandidature();

  return (
    <header>
      <small>{job.author_name}</small>
      <h1>{job.title}</h1>
      {job.ies_exclusive && <IesExclusivity />}
      <small className="address"><i className="material-icons">place</i> {job.address}</small>
    </header>
  );
}

export default Header;
