import React from "react";
import { Link } from "react-router-dom";
import {
  CardBox,
  CardBoxImage,
  CardBoxInfo,
} from "components/ui";
import { INTERNSHIP } from "navigation/ROUTES";
import { pathWithParams } from "utils/pathWithParams";
import { useTranslation } from "react-i18next";

export default React.memo(({ item }) => {
  const envelope = item.attributes;
  const { t } = useTranslation();

  return (
    <CardBox>
      <div className="card-box-body">
        <CardBoxImage image={envelope.company_avatar} />

        <CardBoxInfo>
          <div className="infos">
            <small>{envelope.company_sector_name}</small>
            <small>
              <b>{envelope.company_name}</b>
            </small>
            <h3>{envelope.type_name}</h3>
            <span>({t(`contract_type.${envelope.contract_type}`)})</span>
          </div>

          <Link to={pathWithParams(INTERNSHIP.ENVELOPES.SHOW, {id: item.id})} className="button icon-button">
            <i className="material-icons-outlined">navigate_next</i>
            <p>Ver mais</p>
          </Link>
        </CardBoxInfo>
      </div>
    </CardBox>
  );
});