import React from "react";
import { Test, Header, Status, Title, Small, Link, Button } from "./styles";

export default ({ test, testSubscribe }) => (
  <Test elevation={10}>
    <Header>
      <small>Teste de habilidade</small>
      <Status>
        <span className="label" style={{ backgroundColor: "#34a853" }}>
          Disponível
        </span>
      </Status>
    </Header>
    <Title>{test.title}</Title>
    <Small className="duration">Duração: {test.formatted_duration}</Small>
    <Link>
      <Button className="button" onClick={() => testSubscribe(test)}>
        Responder
      </Button>
    </Link>
  </Test>
);
