import React from "react";
import { Accordion, Typography, useStyles, SectionTypography, AccordionSummary } from "./styles";
import Lecture from "./Lecture";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTotalWatched, useWatchedAll } from "./context";
import { Loader } from "components/ui";

function Section(props) {

  const { total, isLoading } = useTotalWatched(props.section.id)
  const { watched_all, watchedIsLoading } = useWatchedAll(props.section.id)
  const classes = useStyles();
  const matriculation = props.matriculation();

  if (isLoading || watchedIsLoading) {
    return <Loader />
  }

  function renderLectures(section_items) {
    return section_items.map((section_item) => (
      <Lecture key={section_item.id} item={section_item} applied={!!matriculation} matriculation_id={matriculation?.id} course_id={props.course_id} />
    ))
  }

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header" classes={{ content: classes.content }}
        >
          <Typography>{props.section.title}</Typography>
          <SectionTypography>
            <p>{props.section.total_duration}</p>
            {watched_all ?
              <CheckCircleIcon style={{ color: "green", width: "13px" }} />
              : <p>{total || 0}/{props.section.section_items.length}</p>}
          </SectionTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {renderLectures(props.section.section_items)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )

}

export default Section;