import React from "react";
import styled from "styled-components";

const Infos = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  p {
    width: auto;
    font-size: 14px;
  }
`;

const BarWrapper = styled.div`
  width: 100%;
  background-color: #ccc;
  border-radius: 10px;
`;

const BarProgress = styled.div`
  width: ${({ percentage }) => percentage}%;
  height: 5px;
  background-color: #007bff;
  transition: width 0.3s ease-in-out;
  border-radius: 10px;

  background: ${({ percentage }) => {
    if (percentage < 25) {
      return "#EB3723";
    }

    if (percentage >= 25 && percentage < 100) {
      return "#0C6DBC";
    }

    return "#0CBC87";
  }};
`;

export default (props) => (
  <>
    <Infos>
      <p>Progresso:</p>

      <p>{props.percentage}%</p>
    </Infos>
    <BarWrapper>
      <BarProgress percentage={props.percentage} />
    </BarWrapper>
  </>
);
