import React from "react";
import { useUserInfo } from "../context";
import { SocialMedia } from "components/ui";
import { EditIcon } from "components/users/EditMode";
import { useTranslation } from "react-i18next";

function Contact() {
  const { userInfo } = useUserInfo();
  const {
    email,
    cellphone,
    phone,
    message_phone,
    authorize_whatsapp,
    facebook,
    linkedin,
    instagram,
    skype,
  } = userInfo;

  const { t } = useTranslation();

  return (
    <section>
      <h4>
        CONTATO <EditIcon type="contact" />
      </h4>
      <hr />
      <p>
        <b>{t("user_info.attributes.email")}:</b> {email}
      </p>
      <p>
        <b>{t("user_info.attributes.cellphone")}:</b> {cellphone}
      </p>
      <p>
        <b>{t("user_info.attributes.phone")}:</b> {phone}
      </p>
      <p>
        <b>{t("user_info.attributes.message_phone")}:</b> {message_phone}
      </p>
      <p>
        <b>{t("user_info.attributes.authorize_whatsapp")}: </b> {authorize_whatsapp}
      </p>
      <p>
        <b>{t("user_info.attributes.skype")}:</b> {skype}
      </p>
      <SocialMedia
        list={{
          facebook,
          linkedin,
          instagram,
        }}
      ></SocialMedia>
    </section>
  );
}

export default Contact;
