import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import FirstAlert from "./components/FirstAlert";

const Title = styled.h1`
  color: ${({ theme }) => theme.info[700]};
  font-weight: bolder;
`;

function Content(props) {
  const { user } = props;

  return (
    <div className="content">
      <FirstAlert />

      {user?.talent_hub_ies_student ? (
        <StudentMessage user={user} />
      ) : user?.intern ? (
        <InternMessage user={user} />
      ) : (
        <CandidateMessage user={user} />
      )}
    </div>
  );
}

const InternMessage = ({ user }) => (
  <>
    <h2>Olá, {user?.first_name}!</h2>
    <Title>Bem-vindo(a) a {user?.internship_company_name}</Title>
    <h2>Seu perfil está {user?.profile_completeness_percentage} preenchido!</h2>
    <h3>Nós queremos te ajudar a ter um estágio incrível!</h3>
  </>
);

const CandidateMessage = ({ user }) => (
  <>
    <Title>Olá, {user?.first_name}!</Title>
    <Title>
      Seu perfil está {user?.profile_completeness_percentage} preenchido!
    </Title>
    <h3>
      Nós queremos te ajudar a conquistar uma oportunidade de trabalho incrível!
    </h3>
  </>
);

const StudentMessage = ({ user }) => (
  <>
    <h2>Olá, {user?.first_name}!</h2>
    <Title>
      Boas-vindas ao portal {user?.ies_board_name} da plataforma do WallJobs
    </Title>
    <h2>
      Seu perfil está {user?.profile_completeness_percentage} preenchido!
    </h2>
    <h3>Nós queremos te ajudar a ter um estágio incrível!</h3>
  </>
);

function mapStateToProps(state) {
  const user = state.session?.attributes;
  return { user };
}

export default connect(mapStateToProps)(Content);
