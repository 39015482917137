import React from "react";
import Section from "./Section";
import Language from "components/users/fields/academic/Language";

function LanguagesSection(props) {
  return (
    <Section title="Idiomas">
      <Language {...props} />
    </Section>
  );
}

export default LanguagesSection;
