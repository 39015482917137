import React from 'react'
import ExpectedSalary from 'components/users/fields/experience/ExpectedSalary';
import Interests from 'components/users/fields/experience/Interests';
import Portfolios from 'components/users/fields/experience/Portfolios';
import Summary from 'components/users/fields/experience/Summary'
import ExperiencesSection from './sections/ExperiencesSection';

function Experience(props) {
  return (
    <div className="row">
      <div className="col-md-12">
        <h3>Dados Profissionais</h3>
      </div>
      <div className="col-md-12">
        <Summary {...props} />
      </div>
      <div className="col-md-12">
        <Portfolios {...props} />
      </div>
      <div className="col-md-6">
        <Interests {...props} />
      </div>
      <div className="col-md-6">
        <ExpectedSalary {...props} />
      </div>
      <ExperiencesSection {...props} />
    </div>
  )
}



export default Experience
