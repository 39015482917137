import React from "react";
import AcademicEntities from "./AcademicEntities";
import Educations from "./Educations";
import University from "./University";
import { EditIcon } from "components/users/EditMode";

function Academic() {
  return (
    <div
      className="tab-pane fade"
      id="academic"
      role="tabpanel"
      aria-labelledby="academic-tab"
    >
      <University />
      <Educations />
      <AcademicEntities />
    </div>
  );
}

export default Academic;
