import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";
import { ExpandMore } from "components/ui";
import { useUserInfo } from "../context";
import { EditIcon } from "components/users/EditMode";

function EmptyFields() {
  const { userInfo } = useUserInfo();
  const { empty_fields } = userInfo;

  const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation();

  const renderEmptyFields = () => {
    return empty_fields.map((field) => (
      <li key={field}>{t(`user_info.attributes.${field}`)}</li>
    ));
  };

  if (_.isEmpty(empty_fields)) {
    return;
  }

  return (
    <section className="empty-fields">
      <h4>
        ITENS PARA PREENCHER
        <ExpandMore expanded={expanded} setExpanded={setExpanded} />
      </h4>
      <hr />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <EditIcon type="empty_fields" attributes={empty_fields} />

        <span>Faltam esses itens para completar seu perfil:</span>
        <ul>{renderEmptyFields()}</ul>
      </Collapse>
    </section>
  );
}

export default EmptyFields;
