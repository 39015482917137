import React, { useState } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PasswordIcon from '@mui/icons-material/Password';
import AppBlockingIcon from "@mui/icons-material/AppBlocking";

function Menu(props) {
  const { selectedIndex } = props;

  return (
    <div className="menu">
      <List component="nav">
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={() => props.setSelectedIndex(0)}
        >
          <ListItemIcon>
            <PasswordIcon />
          </ListItemIcon>
          <ListItemText primary="Alterar senha" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={() => props.setSelectedIndex(1)}
        >
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary="Autenticação" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 2}
          onClick={() => props.setSelectedIndex(2)}
        >
          <ListItemIcon>
            <AppBlockingIcon />
          </ListItemIcon>
          <ListItemText primary="Desativar conta" />
        </ListItemButton>
      </List>
    </div>
  );
}

export default Menu;
