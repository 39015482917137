import React from "react";
import styled from "styled-components";

const CourseCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 250px;
  border-radius: 8px;
  background-color: #FFFFFF;
  margin: 35px 0 0 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 15px 25px,rgba(0, 0, 0, 0.05) 0px 5px 10px;

  @media (min-width: 770px) {
    flex-direction: row;
    min-height: 100px;
  }

  @media (min-width: 1234px) {
    flex-direction: row;
    max-width: 677px;
    min-width: 400px;
    min-height: 200px;
  }
`;

export default (props) => <CourseCard>{props.children}</CourseCard>;
