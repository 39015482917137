import React, { useState } from "react";
import InternshipLaw from "./InternshipLaw";
import HasSignedRescission from "./HasSignedRescission";
import { RadioButtons } from "components/form";

function HasCreatedRescission(props){

  const [value, setValue] = useState(null);

  const prefix = props.prefix || {
    name: "has_created_rescission",
    id: "has_created_rescission",
  };

  const handleChange = (value) => {
    setValue(value);
  };

  const options = [
    { value: "1", label: "Sim" },
    { value: "2", label: "Não" },
  ];  

  const renderHasSignedRescission = () => {
    if (value === "1"){
      return(        
        <HasSignedRescission {...props} /> 
      )
    }else if(value === "2"){
      return(        
        <InternshipLaw />
      )
    }   
  };

  return (
    <div className="form-group">
      <RadioButtons
        {...props}
        id={prefix.id}
        name={prefix.name}
        options={options}
        required={true}
        label={props.label || "Você já possui rescisão elaborada?"}       
        handleChange={handleChange}
      />  
      {renderHasSignedRescission()} 
    </div>
  );  
}

export default HasCreatedRescission;