import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { styled as styledMui } from '@mui/system';
import { Skeleton } from '@mui/material';

const Container = styled.div`
  max-width: 320px;
  height: 210px;
  object-fit: cover;
  z-index: 2;

`;

export const ImageContainer = styled.label`
  z-index: 2;

  img {
    object-fit: contain;
    max-width: 220px;
    max-height: 220px;
    cursor: pointer;
  }
`;

export const SkeletonMui = styledMui(Skeleton)(({theme}) => ({
  zIndex: 2,
  objectFit: 'contain',
  width: '220px',
  height: '220px',
  cursor: 'pointer',

}));

function OptionImage(props) {

  const [loading,setLoading ] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = props.src;

    const handleLoad = () => {
      setLoading(false);
    };

    img.addEventListener('load', handleLoad);

    return () => {
      img.removeEventListener('load', handleLoad);
    };
  }, [props.src]);
  
  return (
    <Container>
      {!loading ?
        <ImageContainer type={props.type} name={props.name} htmlFor={props.htmlfor}><img src={props.src} alt={props.description} /></ImageContainer> :
        <SkeletonMui variant="rectangular" animation="wave" /> }
    </Container>
  )
};

export default OptionImage;