import styled from "styled-components";

// Welcome styles
export const ContentInfoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #FAFAFA;
  color: #FFFFFF;     
  font-size: 20px;
  padding: 90px;

  .wrapper{
    position: relative;

  }

  .circle{
      width: 110px;
      height: 110px;
      background-color: rgba(80, 227, 194, 0.37);
      border-radius: 50%;
      position: absolute;
      bottom: -50px;
      right: 90px;
      z-index: 1;
    }

  .dotted_circle{
      width: 130px;
      height: 130px;
      border-radius: 50%;
      border: 1px dotted rgba(165, 165, 165, 0.87);
      border-width: 2px; 
      position: absolute;
      bottom: -60px;
      right: 80px;
      z-index: 3;
  }


  .dotted_circle2{
      width: 85px;
      height: 85px;
      border-radius: 50%;
      border: 1px dotted rgba(165, 165, 165, 0.87);
      border-width: 2px; 
      position: absolute;
      top: 20px;
      left: -55px;
      z-index: 1;
  }

  .dotted_circle3{
      width: 65px;
      height: 65px;
      border-radius: 50%;
      border: 1px dotted rgba(165, 165, 165, 0.87);
      border-width: 2px; 
      position: absolute;
      top: 30px;
      left: -45px;
      z-index: 3;
  }

  .rectangle{
      width: 149px;
      height: 76px;
      background: rgba(225, 24, 18, 0.15);
      border-radius: 33px 0px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      position: absolute;
      top: 70px;
      left: -80px;
      z-index: 1;
    }

  .welcome{ 
    background-color: #FFFFFF;
    position: relative;
    padding: 80px 40px;
    box-shadow: 0px 3px 6px rgba(49, 33, 133, 0.2);
    border: 1px solid #312185;
    border-radius: 9px;
    z-index: 3;

    .title{
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: fit-content;
      background-color: #312185;
      padding: 16px 24px;
      border-radius: 7px;
      top: -50px;
      margin: 0 auto;
      right: 0;
      left: 0;

      h1{
        text-align: center;
      }
    }

    p{
      text-align: center;
      color: #474747 !important;
    }

  }
`;

export const CardCourseArea = styled.div`
  .course_area{
    display: grid;
    grid-template-columns: repeat(2, minmax(12rem, 12rem));
    padding: 110px;  
    background-color: #FAFAFA;
    height: auto;
    gap: 50px;
    margin: 0 auto;
    justify-content: end;
    position: relative;
    min-height: 100vh;
  }

  .dotted_circle01{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px dotted rgba(38, 18, 139, 0.89);    
    border-width: 2px; 
    position: absolute;
    top: 150px;
    right: 40px;
    z-index: 3;
  }

  .rectangle01{
    width: 900px;
    height: 500px;
    background: rgba(80, 227, 194, 0.08);
    border-radius: 150px 0px 0px 0px;
    position: absolute;
    top: 200px;
    left: 449px;
    z-index: 1;
    }

    .rectangle02{
      position: absolute;
      width: 215px;
      height: 125px;
      background: rgba(179, 165, 253, 0.21);
      border-radius: 33px 0px 66px 33px;
      top: 280px;
      left: 100px;
      z-index: 1;
    }
  
  .areas_card{
    background: #FAFAFA;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
    width: 200px;
    height: 190px;
    border-radius: 28px;
    padding: 30px 30px;
    text-align: center;
    font-size: 20px;
    z-index: 3;
    //left: 400px;
    transition: 0.3s;

    &:hover{
      box-shadow: 0px 10px 30px rgba(152, 152, 152, 0.27);

    }
  }

  .text{
    position: absolute;
    top: 320px;
    left: 160px;
    z-index: 3;
  }

  .image{
    position: absolute;
    width: 500px;
    height: 200px;
    top: 500px;
    left: 200px;
    z-index: 3;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
`;

export const Title = styled.div`
color: black;
`;