import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import styled from "styled-components";
import { connect } from "react-redux";

const Title = styled.h1`
  text-align: center;
  margin-bottom: 15px;
  color: ${({theme}) => theme.mode === "light" ? '#7338c4' : '#8D69E0'};
`

const Paragraph = styled.p`
  font-size: 1.25rem;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  color: ${({theme}) => theme.mode === "light" ? '#7338c4' : '#8D69E0'};
  transition: .2s linear all;
`

const DefaultMessage = () => {
  return (
    <>
      <Title>Olá, boas vindas ao WallJobs!</Title>
      <Paragraph>
        O cadastro completo é muito importante para que os recrutadores possam
        conhecer melhor o seu perfil. Por isso, não o deixe desatualizado!
      </Paragraph>
      <Paragraph>
        Temos uma comunidade no WhatsApp, em que divulgamos programas de
        estágios imperdíveis. Confira:
      </Paragraph>
      <Paragraph>
        <Link
          href="https://chat.whatsapp.com/DeFKictNPdwJSYsFTpUERP"
          target="_blank"
        >
          <i className="fab fa-whatsapp"></i> WhatsApp
        </Link>
      </Paragraph>
      <Paragraph>
        Você também pode acompanhar em nosso Instagram e TikTok:
      </Paragraph>
      <Paragraph>
        <Link href="https://www.instagram.com/wall.jobs" target="_blank">
          <i className="fab fa-instagram"></i> Instagram
        </Link>
      </Paragraph>
      <Paragraph>
        <Link href="https://www.tiktok.com/@walljobs" target="_blank">
          <i className="material-icons">tiktok</i> TikTok
        </Link>
      </Paragraph>
    </>
  );
};

const FirstAlert = (props) => {
  const [open, setOpen] = useState(props.firstSignIn);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <DefaultMessage />
          <DialogActions sx={{ padding: "20px 24px", gap: "10px" }}>
            <button
              className="button button-primary modal-button"
              type="button"
              onClick={() => setOpen(false)}
            >
              Fechar
            </button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

function mapStateToProps(state) {
  const firstSignIn = state.session?.firstSignIn;

  return { firstSignIn };
}

export default connect(mapStateToProps)(FirstAlert);
