import React from "react";
import { Logo } from "components/ui";
import Form from "./Form"
import "./style.scss";

const Login = () => {
  return (
    <div className="internship-update-login">
      <Logo />
      <Form />
    </div>
  )
}

export default Login;