import React from "react";
import { useCompany } from "../context";
import { SocialMedia } from "components/ui";

function Sidebar() {
  const { company } = useCompany();
  
  return (
    <section>
      <h3>Informações da Empresa</h3>
      <p>
        <b>Setor</b>
        <br />
        {company.sector_name}
      </p>
      <p>
        <b>Site</b>
        <br />
        <span dangerouslySetInnerHTML={{ __html: company.website }}></span>
      </p>
      <p>
        <b>Sede</b>
        <br />
        {company.headquarter}
      </p>
      <p>
        <b>Ano de fundação</b>
        <br />
        {company.founded_at}
      </p>
      <SocialMedia
        list={{
          facebook: company.facebook,
          twitter: company.twitter,
          linkedin: company.linkedin,
          instagram: company.instagram,
        }}
      ></SocialMedia>
    </section>
  );
}

export default Sidebar;
