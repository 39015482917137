import React, { useState } from "react";
import Box from "../components/box/Box";
import { connect } from "react-redux";
import { useMyCourses } from "./context";
import { Loader } from "components/ui";
import { FilterContainer, FilterItem } from "./styles";
import Unfinished from "./components/Unfinished"
import Finished from "./components/Finished";
import StudyTrack from "./components/StudyTrack";

function Main(props){
  const {user, isLogged} = props;
  const {finished, unfinished, studyTracks, isLoading} = useMyCourses(user.slug);
  const [content, setContent] = useState("unfinished");

  if(isLoading){
    return <Loader />
  }

  let hasUnfinished = unfinished.length < 1;
  let hasFinished = finished.length < 1;
  let hasStudyTracks = studyTracks.length < 1;

  function renderContent() {
    switch(content){
      case "unfinished":
        return <Unfinished unfinished={unfinished} hasUnfinished={hasUnfinished} />
      case "finished":
        return <Finished finished={finished} hasFinished={hasFinished} />
      case "study_tracks":
        return <StudyTrack studyTracks={studyTracks} hasStudyTracks={hasStudyTracks} />
    }
  }

  function hasSomeCourse() {
    if(content === "finished"){
      if(hasFinished){
        return "flex"
      }else {
        return "grid"
      }
    }else if(content === "unfinished"){
      if(hasUnfinished){
        return "flex"
      }else {
        return "grid"
      }
    }else if(content === "study_tracks"){
      if(hasStudyTracks){
        return "flex"
      }else {
        return "grid"
      }
    }
  }

  return(
    <>
      <FilterContainer>
        <FilterItem onClick={() => setContent("unfinished")} selected={content === "unfinished"}>
          Cursos em andamento
        </FilterItem>
        <FilterItem onClick={() => setContent("finished")} selected={content === "finished"}>
          Cursos finalizados
        </FilterItem>
        <FilterItem onClick={() => setContent("study_tracks")} selected={content === "study_tracks"}>
          Trilhas
        </FilterItem>
      </FilterContainer>
      <Box display={hasSomeCourse()}>
        {renderContent()}
      </Box>
    </>
  )
}

function mapStateToProps(state) {
  const user = state.session?.attributes;
  const isLogged = state.session?.isLogged;

  return {user, isLogged};
}

export default connect(mapStateToProps)(Main);