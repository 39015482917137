import React, { useState } from "react";
import api from "services/api";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

function Benefit({ attributes }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const useBenefit = () => {
    handleOpen();
    api.post(`/v1/profile/users/benefits/${attributes.id}/use_benefit`);
  };

  return (
    <Card elevation={10} sx={{ height: "fit-content" }}>
      <div className="card-image">
        <CardMedia component="img" image={attributes.avatar} alt="Parceiro" />
      </div>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {attributes.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {attributes.discount}
        </Typography>
      </CardContent>
      <CardActions>
        <button
          className="button button-info"
          type="button"
          onClick={useBenefit}
        >
          Usar Benefício{" "}
        </button>
      </CardActions>
      <Dialog open={open} onClose={handleOpen}>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "15px" }}>
            <h1>Como usar o benefício</h1>
            <p>
              <b>Código do desconto:</b> {attributes.discount_code}
            </p>
            <b>Link para o benefício:</b>{" "}
            <a href={attributes.link} target="_blank">
              {attributes.link}
            </a>
            <p>
              <b>Lugares válidos:</b> {attributes.valid_places}
            </p>
            <p>
              <b>Informações:</b>{" "}
              <p
                dangerouslySetInnerHTML={{
                  __html: attributes.partnership_info,
                }}
              ></p>
            </p>
            <p>
              <b>Validade: </b>
              {attributes.start_date} - {attributes.end_date}
            </p>
          </DialogContentText>
          <DialogActions sx={{ padding: "20px 24px" }}>
            <button
              className="button button-secondary modal-button"
              type="button"
              onClick={handleOpen}
            >
              Voltar
            </button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Card>
  );
}

export default Benefit;
