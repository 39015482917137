import React from 'react'
import Form from './Form'
import './style.scss';

function User() {
  return (
    <div className="user-registration">
      <Form />
    </div>
  )
}

export default User
