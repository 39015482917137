import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import api from "services/api";
import PATHS from "navigation/ROUTES";
import { pathWithParams } from 'utils/pathWithParams';
import Test from "./components/AvailableTest";
import { useAvailable } from "./context";
import { Loader } from "components/ui";

const Paragraph = styled.p`
  color: #898d94;
  margin-top: 20px;
  grid-column: 1 / -1;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`

export default (props) => {
  const { tests, isLoading } = useAvailable();
  let navigate = useNavigate();

  if (isLoading) {
    return <Loader />;
  }

  if (_.isEmpty(tests)) {
    return <Paragraph>Não encontramos nenhum teste que você já não esteja vinculado. Veja a aba <span onClick={() => props.changeTab(null, 1)}>MEUS TESTES</span>...</Paragraph>
  }

  const testSubscribe = (test) => {
    api
      .post(`/v1/ats/test/tests/${test.id}/subscribe`)
      .then(({data}) => {
        navigate(pathWithParams(PATHS.TESTS.START, {id: data.exam_id, user_info_test_id: data.user_info_test_id}))
      })
      .catch((error) => swal("Ops!", error.response.data.message, "error"));
  };

  const renderTests = () => {
    return tests.map((test) => (
      <Test test={test.attributes} testSubscribe={testSubscribe} />
    ));
  };

  return <>{renderTests()}</>;
};
