import React, { useState } from "react";
import _ from "lodash";
import styled from 'styled-components';
import { Popover } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useUserInfo } from "../context";

const Section = styled.section`
  padding: 15px;
  max-width: 300px;

  @media screen and (max-width: 767px){
    &{
      max-width: 100vw;
    }
  }
`

const CustomPopover = withStyles({
  paper: {
    marginTop: "10px",
  }
})(Popover);

function Disabilities() {
  const { getIncluded } = useUserInfo();
  const disabilities = getIncluded("disability");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const _renderDisabilities = () => {
    const list = disabilities || [];
    return list.map(({ id, attributes }) => (
      <div key={id}>
        <p>
          <b>Tipo de deficiência: </b> {attributes.type}
        </p>
        <p>
          <b>Descrição: </b> {attributes.description}
        </p>
        <p>
          <b>
            <a href={attributes.cid}>CID</a>
          </b>
        </p>
      </div>
    ));
  };

  if(_.isEmpty(disabilities)) {
    return;
  }

  return (
    <>
      <span className="material-icons" onClick={handleClick}>accessible</span>
      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Section>
          {_renderDisabilities()}
        </Section>
      </CustomPopover>
    </>
  );
}

export default Disabilities;
