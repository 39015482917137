import React from "react";
import { Breadcrumb, ContentBox, MainBox } from "components/ui";
import Sidebar from "./Sidebar";
import Content from "./Content";

function Main() {
  return (
    <MainBox>
      <Breadcrumb>
        <a>Meu Perfil</a>
      </Breadcrumb>
      <ContentBox>
        <Sidebar />
        <Content />
      </ContentBox>
    </MainBox>
  );
}

export default Main;
