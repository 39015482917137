import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import styled from "styled-components";
import Available from "./Available";
import MyTests from "./MyTests";

const TabPanel = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;

  @media (max-width: 767px) {
    padding: 0 15px 20px 15px; 
  }
`

const CustomTabPanel = (props) => {
  const { children, tab, index, ...other } = props;

  return (
    <TabPanel
      role="tabpanel"
      hidden={tab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {tab === index && children}
    </TabPanel>
  );
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

export default () => {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleChange} aria-label="Testes">
          <Tab label="Disponíveis" {...a11yProps(0)} />
          <Tab label="Meus testes" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel tab={tab} index={0}>
        <Available changeTab={handleChange} />
      </CustomTabPanel>
      <CustomTabPanel tab={tab} index={1}>
        <MyTests />
      </CustomTabPanel>
    </>
  );
};
