import React from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  ContentBox,
  MainBox,
  HeaderAlert,
  Loader,
} from "components/ui";
import Content from "./Content";
import { useAttendee } from "./context";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { ATTENDEES } from "navigation/ROUTES";

function Main() {
  const { meeting, isLoading } = useAttendee();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <MainBox>
      <Breadcrumb>
        <Link to={ATTENDEES.INDEX}>Agenda</Link>
        <a>{meeting.title}</a>
      </Breadcrumb>
      {!meeting.available && (
        <HeaderAlert message="Esse convite já expirou..." />
      )}
      <ContentBox>
        <Header />
        <Sidebar />
        <Content />
      </ContentBox>
    </MainBox>
  );
}

export default Main;
