import React from "react";
import Name from "components/users/fields/essential/Name";
import Email from "components/users/fields/essential/Email";
import CellPhone from "components/users/fields/essential/CellPhone";
import Gender from "components/users/fields/essential/Gender";
import Sex from "components/users/fields/essential/Sex";
import Ethnicity from "components/users/fields/essential/Ethnicity";
import DisabilitySection from "pages/profile/users/Wizard/forms/sections/DisabilitySection";
import DateOfBirth from "components/users/fields/essential/DateOfBirth";
import CPF from "components/users/fields/essential/CPF";
import University from "components/users/fields/academic/University";
import Course from "components/users/fields/academic/Course";
import CurrentSemester from "components/users/fields/academic/CurrentSemester";
import InternshipType from "components/folders/InternshipType"
import FormationDate from "components/users/fields/academic/FormationDate";
import RG from "components/users/fields/personal/RG";
import RGState from "components/users/fields/personal/RGState";
import Address from "components/users/fields/personal/address/Address";
import Matriculation from "components/users/fields/academic/Matriculation";
import Period from "components/users/fields/academic/Period";
import StudySchedule from "components/users/fields/academic/StudySchedule";
import DocumentFiles from "pages/profile/personal_documents/Index/new/fields/DocumentFiles";
import InstitutionalEmail from "components/users/fields/essential/InstitutionalEmail";
import HiddenField from "components/form/HiddenField";
import InternResponse from "./components/InternResponse";
import { Checkbox } from "components/form";
import { useFormAnswer } from "./context";

function Fields(props) {  
    
  const { category_documents, company, term } = useFormAnswer();  
  
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h3>Dados Básicos</h3>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12">
          <Name {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <Email {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <CellPhone {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <DateOfBirth {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <CPF {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <RG {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <RGState {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <Gender {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <Sex {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <Ethnicity {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12" style={{ padding: 0 }}>
          <DisabilitySection {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3>Endereço</h3>
        </div>
        <Address {...props} required />
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3>Dados Acadêmicos</h3>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <University {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <Course {...props} required />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <CurrentSemester {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <FormationDate {...props} required />
        </div>
        {company.type === "default" && (<div className="col-xs-12 col-sm-6 col-md-6">
          <InternshipType {...props} required />
        </div>)}
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <Matriculation {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <Period {...props} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <InstitutionalEmail {...props} required />
        </div>
      </div> 
      <div className="row">    
        <div className="col-xs-12 col-sm-6 col-md-6">
          <InternResponse {...props} required />
        </div>                    
      </div>                

      {
        category_documents.map((ctd, index) => (
          <div className="row">
            <PersonalDocuments {...props} category={ctd} index={index} />
          </div>
        ))
      }
      <div className="row" style={{marginRight: "-15px", marginLeft: "-15px"}}>
        <div className="col-md-12">
          <h3>Horário de Estudo</h3>
          <StudySchedule {...props} />
        </div>
      </div>
      <div className="row">
        <Terms {...props} term={term} />
      </div>
    </>
  );
}

function PersonalDocuments(props) {
  let category = props.category;
  let index = props.index;
  const prefix = `personal_documents_attributes`;  

  const handleCategory = (value) => {
    props.setValue?.(`${prefix}[${index}][category_document_id]`, value)
  }

  const handleDocumentType = (value) => {
    props.setValue?.(`${prefix}[${index}][document_type]`, value)
  }
  
  return (
    <>
      <br />
      <div className="col-md-12">
        <h3>{category.name} <abbr title="Obrigatório">*</abbr></h3>
        <p>Faça upload do seu {category.name}</p>
      </div>
      <div className="col-md-4">
        <DocumentFiles
        key={category.id}
          {...props}
          prefix={{
            id: `${prefix}_${index}_document_files`,
            name: `${prefix}[${index}][document_files]`,
          }}
        />

        <HiddenField {...props}
          name={`${prefix}[${index}][category_document_id]`} 
          value={category.id}
          handleChange={handleCategory} 
          />

        <HiddenField {...props}
          name={`${prefix}[${index}][document_type]`} 
          value={category.name} 
          handleChange={handleDocumentType}           
          />
      </div>
    </>
  );
}

const Terms = (props) => (
  <>
    <div className="col-md-12">
      <h3>Termo de Aceite</h3>
    </div>
    <div className="col-md-12">
      <Checkbox {...props} id="terms" name="terms" label={ <p dangerouslySetInnerHTML={{ __html: props.term }}></p>
        } required />
    </div>
  </>
);

export default Fields;
