import React from "react";
import _ from "lodash";
import { ContentBox, PhotoGallery } from "components/ui";
import { useCompany } from "./context";
import Videos from "./Videos";

function Gallery() {
  const { company } = useCompany();
  const { photos, videos } = company;

  if (_.isEmpty(photos) && _.isEmpty(videos)) {
    return;
  }

  return (
    <ContentBox>
      <div className="section">
        <h3>Galeria</h3>
        <PhotoGallery photos={photos} />
        <Videos videos={videos} />
      </div>
    </ContentBox>
  );
}

export default Gallery;
