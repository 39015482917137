import React from 'react'
import styled from 'styled-components';
import ActionMenu from 'components/ui/ActionMenu';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

function Actions() {
  return (
    <Wrapper>
      <ActionMenu>
        <a href="/folders/horas-estagiadas" target="_blank">
          <i className="material-icons">insert_drive_file</i> Comprovante de horas estagiadas
        </a>
      </ActionMenu>
    </Wrapper>
  );
}

export default Actions