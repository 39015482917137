import React from "react";
import _ from "lodash";
import WhyWork from "./WhyWork";
import Benefits from  "./Benefits";
import { useCompany } from "../context";

function AditionalContent() {
  const { company } = useCompany();

  if (!company.why_work || _.isEmpty(company.company_benefits)){
    return;
  }

  return (
    <>
      <a
        className="collapse-link"
        data-toggle="collapse"
        href="#moreContent"
        role="button"
        aria-controls="moreContent"
        aria-expanded="false"
      >
        ver
      </a>
      <div className="collapse" id="moreContent">
        <WhyWork why_work={company.why_work} /><br /><br />
        <Benefits benefits={company.company_benefits} />
      </div>
    </>
  )
}

export default AditionalContent;
