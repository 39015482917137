import React from "react";
import {FinalizeButton, FinishContainer} from "./styles";

export default (props) => (
  <FinishContainer>
    <h3>O tempo para responder o teste acabou.</h3>
    <FinalizeButton onClick={props.sendTest}>
      Finalizar Teste
    </FinalizeButton>
  </FinishContainer>
);