import React from "react";
import _ from "lodash";
import Benefit from "components/ats/Benefit";
import benefits from "components/ats/benefits";

function Benefits(props) {
  const _renderBenefits = () => {
    const list = props.benefits || [];
    return list.map((item) => (
      <Benefit key={item.icon} path={benefits[item.icon]} name={item.name} />
    ));
  };

  if(_.isEmpty(props.benefits)){
    return;
  }

  return (
    <>
      <h3>Benefícios</h3>
      <div className="benefits">{_renderBenefits()}</div>
    </>
  )
}

export default Benefits;
