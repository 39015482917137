import React from "react";
import { useAttendee } from "./context";

function Sidebar() {
  const { meeting } = useAttendee();

  const renderDays = () => {
    const list = meeting.days_and_times || [];

    return list.map(({ day, times }, index) => (
      <Day day={day} times={times} key={index} />
    ));
  };

  return (
    <div className="sidebar">
      <p>
        <b>Horários do evento</b>
      </p>
      {renderDays()}
    </div>
  );
}

function Day({ day, times }) {
  const renderTimes = () => {
    const list = times || [];

    return list.map(({ time, full }, index) => (
      <Time key={index} time={time} full={full} />
    ));
  };

  return (
    <div className="day">
      <p>
        <b>{day}:</b>
      </p>
      <div className="times">{renderTimes()}</div>
    </div>
  );
}

function Time({ time, full }) {
  return <p className={`full-${full}`}>{time}</p>;
}

export default Sidebar;
