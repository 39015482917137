import React from "react";
import FilterController from "components/filters/FilterController";
import { Breadcrumb, MainBox } from "components/ui";
import Attendees from "./Attendees";
import Filters from "./Filters";

function Main() {
  return (
    <MainBox>
      <Breadcrumb>
        <a>Agenda</a>
      </Breadcrumb>
      <FilterController>
        <Filters />
        <Attendees />
      </FilterController>
    </MainBox>
  );
}

export default Main;
