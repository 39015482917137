import React from "react";
import { Breadcrumb, ContentBox, MainBox } from "components/ui";
import List from "./List";

function Main() {
  return (
    <MainBox>
      <Breadcrumb>
        <a>Clube de Benefícios</a>
      </Breadcrumb>
      <ContentBox>
        <List />
      </ContentBox>
    </MainBox>
  );
}

export default Main;
