import React from "react";
import MessagePhone from "components/users/fields/personal/MessagePhone";
import Phone from "components/users/fields/personal/Phone";
import SexualOrientation from "components/users/fields/personal/SexualOrientation";
import Avatar from "components/users/fields/personal/Avatar";
import MotherName from "components/users/fields/personal/MotherName";
import AddressSection from "./sections/AddressSection";
import AboutSection from "./sections/AboutSection";
import SocialMediaSection from "./sections/SocialMediaSection";

function Personal(props) {
  return (
    <div className="row">
      <div className="col-md-12">
        <h3>Dados Pessoais</h3>
      </div>
      <div className="col-md-3 row">
        <div className="col-md-12">
          <Avatar {...props} />
        </div>
      </div>
      <div className="col-md-9 row">
        <div className="col-md-12">
          <MotherName {...props} />
        </div>
        <div className="col-md-12">
          <SexualOrientation {...props} />
        </div>
        <div className="col-md-12">
          <Phone {...props} />
        </div>
        <div className="col-md-12">
          <MessagePhone {...props} />
        </div>
      </div>
      <AddressSection {...props} required />
      <SocialMediaSection {...props} />
      <AboutSection {...props} />
    </div>
  );
}

export default Personal;