import React from "react";
import { Link } from "react-router-dom";
import JobLabel from "components/ats/JobLabel";
import {
  CardBox,
  CardBoxFooter,
  CardBoxImage,
  CardBoxInfo,
  HeaderAlert,
} from "components/ui";
import IesExclusivitySeal from "components/ats/IesExclusivitySeal";
import AtsInfos from "../shared/AtsInfos";
import { pathWithParams } from "utils/pathWithParams";
import { USERS } from "navigation/ROUTES";

function Candidature({ item, included }) {
  const candidature = item.attributes;
  const job = included?.job?.attributes;

  if (!candidature || !job) {
    return;
  }

  return (
    <CardBox disabled={!job.available}>
      <IesExclusivitySeal job={job} />

      <div className="card-box-body">
        <CardBoxImage image={job.avatar} />

        <CardBoxInfo title={
          <Link to={pathWithParams(USERS.CANDIDATURES.SHOW, { id: candidature.id })}>
            {job.title}
          </Link>
        } subtitle={job.author_name}>
          <JobLabel job={job} />
          <CardBoxFooter>
            <i className="material-icons">place</i> {job.address}
          </CardBoxFooter>
        </CardBoxInfo>

        <div className="candidature-infos">
          <AtsInfos job={job} candidature={candidature} />
          <Link to={pathWithParams(USERS.CANDIDATURES.SHOW, { id: candidature.id })} className="button">
            ver detalhes
          </Link>
        </div>

        <HeaderAlert
          className="footer-message"
          message={candidature.pendencies_message}
        />
      </div>
    </CardBox>
  );
}

export default React.memo(Candidature);
