import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const InternMentoring = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div>
        <h1>Mentoria Coletiva</h1>
        <p style={{fontSize: "18px"}}>Quer dicas de carreira para se desenvolver e se destacar no seu estágio?</p>
        <p style={{fontSize: "18px"}}>Acesse a mentoria coletiva, todas as terças-feiras, às 14h</p>
      </div>
      <a className="button button-primary" href="#" onClick={handleOpen}>
        Mentoria de Carreira Walljobs
      </a>
      <Dialog open={open} onClose={handleOpen}>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "15px" }}>
            <h1>Mentoria de Carreira Walljobs</h1>
            <p>
              Todas as <b>terças-feiras</b>, às 14:00 horas, a especialista de carreiras
              do WallJobs te atenderá de forma coletiva, dando dicas de
              carreira para o melhor desenvolvimento no seu estágio!
            </p>
          </DialogContentText>
          <DialogActions sx={{ padding: "20px 24px", gap: "10px" }}>
            <button
              className="button button-secondary modal-button"
              type="button"
              onClick={handleOpen}
            >
              Voltar
            </button>
            <a
              className="button button-primary modal-button"
              href="https://meet.google.com/mvd-wnrr-zgs?hs=224"
              target="_blank"
              onClick={handleOpen}
            >
              Acessar
            </a>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InternMentoring;
