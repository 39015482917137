import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import Show from "./Show";
import { useNavigate } from "react-router-dom";
import { USERS } from "navigation/ROUTES";

function Main(props){
  const {user, isLogged} = props;
  let navigate = useNavigate();
  
  if(!isLogged){
    swal(
      "Ops!",
      "Você precisa estar logado para visualizar esse curso!",
      "warning"
    ).then(() => navigate(USERS.LOGIN));
    
  }

  return isLogged && <Show />
}

function mapStateToProps(state) {
  const user = state.session?.attributes;
  const isLogged = state.session?.isLogged;

  return { user, isLogged };
}

export default connect(mapStateToProps)(Main);