import React from "react";
import { useTranslation } from "react-i18next";

import { useFormAnswer } from "./context";
import logo from "assets/images/logo.png";

function Header() {
  const { company } = useFormAnswer();

  const { t } = useTranslation();

  return (
    <header>
      <h2>{t(`admission.form_answer.${company.type}.title`)}</h2>
      <img src={logo} alt="WallJobs" />
    </header>
  );
}

export default Header;
