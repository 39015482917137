import styled from "styled-components";

export const QuizContainer = styled.div`
  width: 550px;
  height: auto;
  background-color: #F0F0F0;
  margin: 30px auto 0 auto;
  display: flex;
  flex-direction: column;
`

export const QuestionTitle = styled.h2`
  padding-top: 30px;
  font-size: 26px;
  font-weight: 600;
  color: #20232D;
`

export const OptionContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: rgb(0 0 0 /.1);
  margin: 20px 30px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 15px 15px 20px;
  column-gap: 20px;
  border-radius: 4px;

  :hover {
    background-color: #333b4d;
    cursor: pointer;
    transition: 0.8s;
    color: #FAFAFA;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
`

export const WrongAnswer = styled.p`
 
`