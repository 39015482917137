import React from "react";
import Abilities from "components/users/fields/academic/Abilities";
import Skills from "components/users/fields/academic/Skills";
import Section from "./Section";

function AbilitiesSection(props) {
  return (
    <Section title="Habilidades e Certificados">
      <div className="row">
        <div className="col-md-6">
          <Skills {...props} />
        </div>
        <div className="col-md-6">
          <Abilities {...props} />
        </div>
      </div>
    </Section>
  );
}

export default AbilitiesSection;
