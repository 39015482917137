import React from "react";
import { UncontrolledInput as Input } from "components/form";

function TextType(props) {
  const { index } = props;

  const prefix = {
    id: `answers_${index}_text`,
    name: `answers[${index}][text]`,
  };

  return (
    <Input
      {...props}
      placeholder="Digite sua resposta..."
      type="text"
      name={prefix.name}
      id={prefix.id}
      multiline
      rows={6}
    />
  );
}

export default TextType;
