import { useContext, useEffect, useState } from "react";
import api from "services/api";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";
import { USERS, ATS } from "navigation/ROUTES";
import { EditModeContext } from "components/users/EditMode/context";
import { usePrevPropValue } from "utils/hooks";

function candidatureProcess(props){
  const { job, updatedProfileAt } = props;
  const prevUpdatedProfileAt = usePrevPropValue(updatedProfileAt);
  const [incompleteProfile, setIncompleteProfile] = useState(false);
  const [reviewingProfile, setReviewingProfile] = useState(false);

  let navigate = useNavigate();
  const EditMode = useContext(EditModeContext);

  useEffect(() => {
    if(prevUpdatedProfileAt !== updatedProfileAt){
      if (incompleteProfile) {
        setIncompleteProfile(false);
        if (job.imported || job.external) return _applyCandidature();

        _internalCandidature();
      } else if (reviewingProfile) {
        setReviewingProfile(false);
        _internalCandidature();
      }
    }
  }, [updatedProfileAt]);

  useEffect(() => {
    if(incompleteProfile) _callEditMode(job.candidature_required_fields)
  }, [incompleteProfile])

  const _callEditMode = (attributes, type = "candidatureRequired") => {
    EditMode.handleEditModal({ type, attributes, required: true });
  }

  const _candidaturePost = async () => {
    const data = { job_id: job.id };
    return await api.post("/v1/ats/candidatures", data);
  };

  const unauthenticated = () => {
    swal(
      "Ops!",
      "Você precisa estar logado para se candidatar a essa vaga!",
      "warning"
    ).then(() => navigate(USERS.LOGIN));
  };

  const applyCandidature = () => {
    if (job.imported){
      _importedJob();
    } else if (job.external) {
      _externalJob();
    } else {
      _internalJob();
    }
  }

  const _handleCandidatureError = ({ message, type }) => {
    if (type === "incomplete") {
      setIncompleteProfile(false);

      swal("Ops!", message, "warning", {
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Continuar",
            value: true,
            closeModal: true,
          },
        },
      }).then((value) => {
        if (value) setIncompleteProfile(true);
      });
    } else {
      swal("Ops!", message, "warning");
    }
  };

  const _internalJob = () => {
    const url = pathWithParams("/v1/ats/candidatures/validate_candidate", { job_id: job.id });

    api.get(url)
      .then((resp) => {
        swal("Quase lá!", resp.data.message, "info")
        .then(() => setReviewingProfile(true, _callEditMode(resp.data.attributes, "candidatureReview")));
      })
      .catch((error) => _handleCandidatureError(error.response.data))
  };

  const _internalCandidature = () => {
    _candidaturePost()
      .then((resp) => {
        let { candidature_id, questionnaire_answer_id } = resp?.data;
        if (questionnaire_answer_id){
          swal(
            "Quase lá!",
            "Para confirmar sua candidatura é necessário responder o questionário! Você será redicionado para ele agora.",
            "warning"
          ).then(() => {
            navigate(
              pathWithParams(ATS.QUESTIONNAIRE.FORM_ANSWER.EDIT, { id: questionnaire_answer_id })
            );  
          });
        } else {
          navigate(
            pathWithParams(USERS.CANDIDATURES.SHOW, { id: candidature_id })
          );
          swal(
            "Sucesso!",
            "Sua candidatura a essa vaga foi feita com sucesso! \n\n Te desejamos sorte!",
            "success"
          );
  
        }
      })
      .catch((error) => _handleCandidatureError(error.response.data));
  };

  const _externalJob = () => {
    swal(
      "Atenção",
      "Essa vaga tem todo o seu processo seletivo em um site externo. \n\n Para continuar com sua inscrição, clique em 'Continuar'. \n\n Desejamos boa sorte!",
      "warning",
      {
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Continuar",
            value: true,
            closeModal: true,
          },
        },
      }
    ).then((value) => {
      if (value) _candidatureToExternalJob();
    });
  };

  const _importedJob = () => {
    swal(
      "Atenção",
      "O processo seletivo dessa vaga não é feito pelo WallJobs. \n\nPara ser redirecionado para o site da vaga, clique em 'Continuar'.",
      "warning",
      {
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Continuar",
            value: true,
            closeModal: true,
          },
        },
      }
    ).then((value) => {
      if (value){
        _candidatureToImportedJob()
        window.open(job.contact, "_blank");
      } 
    });
  };

  const _candidatureToImportedJob = () => {
    _candidaturePost()
      .then((resp) => {
        let { candidature_id } = resp?.data;
        navigate(
          pathWithParams(USERS.CANDIDATURES.SHOW, { id: candidature_id })
        );
      })
      .catch((error) => _handleCandidatureError(error.response.data));
  };


  const _candidatureToExternalJob = () => {
    _candidaturePost()
      .then((resp) => {
        let { candidature_id } = resp?.data;
        let contact = job.contact;
        let message =
          "Salvamos sua candidatura em nosso sistema para que você tenha fácil acesso às infomações da vaga.\n\n Lembramos que o processo dessa vaga NÃO é feito através de nossa plataforma, sendo de sua responsabilidade o contato com os responsáveis.";

        if (contact.match(/@/g)) {
          message += `\n\nO email para contato com o responsável é ${contact}.`;
          contact = `mailto:${contact}`;
        } else if (!contact.match(/(http|https):\/\//g)) {
          contact = `https://${contact}`;
        }

        message += "\n\nDeseja ser redirecionado para entrar em contato?";

        navigate(
          pathWithParams(USERS.CANDIDATURES.SHOW, { id: candidature_id })
        );

        swal("Sucesso!", message, "success", {
          closeOnClickOutside: false,
          buttons: {
            cancel: {
              text: "Agora não",
              value: false,
              visible: true,
              closeModal: true,
            },
            confirm: {
              text: "Sim",
              value: true,
              closeModal: true,
            },
          },
        }).then((value) => {
          if (value) window.open(contact, "_blank");
        });
      })
      .catch((error) => _handleCandidatureError(error.response.data));
  };

  return {
    unauthenticated,
    applyCandidature
  }
}

export default candidatureProcess;