import React from "react";
import { useUserInfo } from "../../context";
import { useTranslation } from 'react-i18next';


function CurriculumVitae() {
  const { userInfo } = useUserInfo();
  const {curriculum_vitae} = userInfo;

  const { t } = useTranslation();

  if(_.isEmpty(curriculum_vitae)) {
    return;
  }

  const _renderCurriculum = () => {
    return(
      <div>
        <a href={curriculum_vitae} target="_blank">{t("user_info.attributes.curriculum_vitae")}</a>
      </div>
    )
  }
 
  return(
    <section>
      <hr />
      <p><b>{t("user_info.attributes.curriculum_vitae")}:</b></p>
      <ul>
        {_renderCurriculum()}
      </ul>
    </section>
  )
}
export default CurriculumVitae;
