import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useJob() {
  const { id } = useParams();
  const { data, isLoading } = useFetch(`/v1/ats/jobs/${id}`);

  return {
    job: data?.data?.attributes,
    company: data?.included.find(element => element?.type === "company")?.attributes || [],
    university: data?.included.find(element => element?.type === "university")?.attributes || [],
    isLoading,
  };
}
