import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import { useFilters } from "utils/hooks";
import { Filter } from "components/ui";
import { FilterContext } from "components/filters/FilterController";
import api from "services/api";

function Filters() {
  const { infos, filterData } = React.useContext(FilterContext);

  const [types, setTypes] = useState([]);

  const params = { company: "", type: "" };

  const {
    filters,
    handleChange,
    createFilterLabel,
    renderFilters,
    handleSelect,
  } = useFilters(params, filterData);

  useEffect(() => {
    if (types.length === 0) {
      getTypes();
    }
  });

  function getTypes() {
    api
      .get("/selects/document_types.json")
      .then((resp) => setTypes(resp.data))
      .catch(() => setTypes([]));
  }

  function _renderOptions() {
    const list = types || [];
    return list.map((type) => (
      <MenuItem key={type.value} value={type.value}>
        {type.label}
      </MenuItem>
    ));
  }

  return (
    <Filter
      length={infos.length}
      totalCount={infos.totalCount}
      renderFilters={renderFilters}
    >
      <TextField
        filter
        variant="standard"
        id="company"
        name="company"
        label="Empresa"
        placeholder="Ex: WallJobs, 99, Goldman Sachs..."
        value={filters.company}
        onChange={handleChange}
        onBlur={createFilterLabel}
      />
      <TextField
        select
        filter
        variant="standard"
        id="type"
        name="type"
        label="Documento"
        placeholder="Selecione..."
        value={filters.type}
        onChange={(event) => handleSelect(event, types)}
      >
        {_renderOptions()}
      </TextField>
    </Filter>
  );
}

export default Filters;
