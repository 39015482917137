import React, { useState, useEffect } from "react";
import _ from "lodash";
import { SelectInput } from "components/form";
import api from "services/api";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "@root/redux/features/sessions/actions";
import { useStaticCollection } from "utils/hooks";

function SoftDelete() {
  const [data, setData] = useState({});
  const collection = useStaticCollection("/selects/delete_reason.json");
  const { control, handleSubmit, errors } = useForm();
  let navigate = useNavigate();

  const getData = (collectedData) => {
    setData({ ...data, ...collectedData });
  };

  const submit = () => {
    api
      .post(`/v1/profile/users`, data)
      .then(() => successfulRequisition())
      .catch(() => unsuccessfulRequisition());
  };

  function successfulRequisition() {
    let message = {
      title: "Sentiremos sua falta!",
      text: "Sua conta foi desativada com sucesso",
      icon: "info",
    };
    handleLogout(navigate, message);
  }

  function unsuccessfulRequisition() {
    swal(
      "Ops!",
      "Não foi possível concluir sua solicitação. Por favor, tente novamente!",
      "error"
    );
  }

  useEffect(() => {
    if (!_.isEmpty(data)) submit();
  }, [data]);

  return (
    <form className="soft-delete" onSubmit={handleSubmit(getData)}>
      <h3>Desativar conta</h3>
      <p>
        <strong>Atenção: você está prestes a desativar sua conta.</strong>
      </p>
      <p>
        Isso significa que você não conseguirá utilizar seu cadastro no
        WallJobs.
        <br />
        Isso <strong>não</strong> significa a exclusão permanente de seus dados.
        <br />
        Caso você queira excluir de vez sua conta e seus dados, entre em contato
        conosco pelo nosso chat.
      </p>
      <p>
        Desativando sua conta, você poderá ativá-la quando quiser, mas terá que
        solicitar isso também pelo chat ou por{" "}
        <a href="mailto:suporte@walljobs.com.br">email</a>, informando seu email
        de login.
      </p>
      <p>
        Caso você queira desativar sua conta, pedimos que escolha uma das razões
        listadas abaixo e depois clique em 'Confirmar'.
      </p>
      <SelectInput
        control={control}
        collection={collection}
        required
        errors={errors}
        id="user_delete_reason"
        name="user[delete_reason]"
        placeholder="Selecione o motivo..."
        label="Motivo da desativação de conta"
      />
      <button type="submit" className="button button-primary">
        Confirmar
      </button>
    </form>
  );
}

export default SoftDelete;
