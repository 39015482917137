import React, { useEffect, useState } from "react";

function Section(props) {
  const [expanded, setExpanded] = useState(false);
  const [icon, setIcon] = useState("add");
  
  useEffect(() => {
    let icon = expanded ? "remove" : "add";
    setIcon(icon);
  }, [expanded])

  return (
    <section className={props.name}>
      <a
        className="collapse-title"
        data-toggle="collapse"
        href={`#${props.name}Collapse`}
        role="button"
        aria-controls={`${props.name}Collapse`}
        aria-expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      >
        <h2>{props.title}</h2>
        <i className="material-icons">{icon}</i>
      </a>
      <div className="collapse" id={`${props.name}Collapse`}>
        <div className="section-content">{props.children}</div>
      </div>
    </section>
  );
}

export default Section;
