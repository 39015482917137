import React from "react";
import { ContentInfoBox } from "./styles";

function InfoBox() {
  return (
    <ContentInfoBox>
      <div className="wrapper">
        <div className="welcome">
          <span className="title">
            <h1>
              Seja Bem-Vindo ao <strong> WallJobs Academy </strong>
            </h1>
          </span>

          <p> O <strong> WallJobs Academy </strong> é onde você vai estudar, aprender
              e se desenvolver com <strong> <br /> cursos gratuitos</strong>, conteúdos de apoio e
              benefícios exclusivos, além de garantir <br /> 
              suas <strong> horas complementares da faculdade.</strong>
          </p>
        </div>
        <div className="circle"></div>
        <div className="dotted_circle"></div>
        <div className="dotted_circle2"></div>
        <div className="dotted_circle3"></div>
        <div className="rectangle"></div>                
      </div>
    </ContentInfoBox>
  )
}

export default InfoBox;