import React from "react";
import axios from "axios";
import api from "services/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleUnauth, handleInternalError } from "services/api/handlers";
import { Loader } from "components/ui";
import { login } from "@root/redux/features/sessions/actions";

export default ({ children, requireAuth = true }) => {
  const [authorized, setAuthorized] = React.useState(!requireAuth);
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const tokenInfo = axios.create({ withCredentials: true });
  const data = {};

  tokenInfo
    .get(`/oauth/token/info`, data, api.defaults.headers.common)
    .then((resp) => {
      dispatch(login(resp.data)).then(() => setAuthorized(true));
    })
    .catch((error) => {
      const { status } = error?.response;

      if (status === 401 && requireAuth) handleUnauth(navigate);

      if (status === 500) handleInternalError();
    });
  
  if(!authorized){
    return <Loader />
  }

  return children;
}