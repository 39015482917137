import React from "react";
import Name from "components/users/fields/essential/Name";
import CPF from "components/users/fields/essential/CPF";
import Ethnicity from "components/users/fields/essential/Ethnicity";
import Gender from "components/users/fields/essential/Gender";
import SexualOrientation from "components/users/fields/personal/SexualOrientation";
import CellPhone from "components/users/fields/essential/CellPhone";
import University from "components/users/fields/academic/University";
import Course from "components/users/fields/academic/Course";
import CurrentSemester from "components/users/fields/academic/CurrentSemester";
import FormationDate from "components/users/fields/academic/FormationDate";
import NotInUniversity from "components/users/fields/academic/NotInUniversity";
import Phone from "components/users/fields/personal/Phone";
import State from "components/users/fields/personal/address/State";
import City from "components/users/fields/personal/address/City";
import Language from "components/users/fields/academic/Language";
import Disability from "components/users/fields/personal/Disability";
import Disabilities from "components/users/fields/personal/Disabilities";
import Email from "components/users/fields/essential/Email";

export const fields = [
  { type: "essential", component: <Name />, autoFocus: true, required: true },
  { type: "essential", component: <CellPhone />, autoFocus: true, required: true },
  { type: "essential", component: <Phone />, autoFocus: true, required: false },
  { type: "essential", component: <Email />, autoFocus: true, required: true },
  { type: "essential", component: <CPF />, autoFocus: true, required: true },
  { type: "essential", component: <State />, autoFocus: true, required: true },
  { type: "essential", component: <City />, autoFocus: true, required: true },
  { type: "essential", component: <Language />, autoFocus: false, required: false },
  { type: "essential", component: <NotInUniversity />, autoFocus: true, required: true },
  { type: "academic", component: <University />, autoFocus: true, required: true },
  { type: "academic", component: <Course />, autoFocus: true, required: true },
  { type: "academic", component: <CurrentSemester />, autoFocus: true, required: true },
  { type: "academic", component: <FormationDate />, autoFocus: true, required: true },
  { type: "essential", component: <Ethnicity />, autoFocus: true, required: true },
  { type: "essential", component: <Disability />, autoFocus: true, required: true },
  { type: "disability", component: <Disabilities />, autoFocus: false, required: true },
  { type: "essential", component: <Gender />, autoFocus: true, required: true },
  { type: "essential", component: <SexualOrientation />, autoFocus: true, required: true },
];
