import React, { useEffect } from "react";
import Main from "./Main";
import "./style.scss";
import { useFlipper } from "services/api/flipper";

function Home() {
  const { refreshFlipper } = useFlipper();

  useEffect(() => {
    refreshFlipper();
  }, []);

  return (
    <div className="user-home">
      <Main />
    </div>
  );
}

export default Home;
