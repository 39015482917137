import React, { useState } from "react";
import {QuestionTitle, QuizContainer} from "./styles.js";
import Option from "./Option.jsx";

function Quiz({quiz}){
  const [value, setValue] = useState(false);

  function onSelectOption() {
    setValue(true);
  }

  function renderOptions(){
    return(
      quiz.options.map((option) => (
        <Option text={option.text} id={option.id} selectOption={() => onSelectOption()} selectedOption={value} />
      ))
    )
  }

  return (
    <>
      <QuizContainer>
        <QuestionTitle>
          {quiz.question}
        </QuestionTitle>
        
        {renderOptions()}

      </QuizContainer>
    </>
  )
}

export default Quiz;