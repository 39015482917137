import React from "react";
import CourseSituation from "components/users/fields/academic/CourseSituation";
import Period from "components/users/fields/academic/Period";
import CourseStartDate from "components/users/fields/academic/CourseStartDate";
import AcademicEntities from "components/users/fields/academic/AcademicEntities";
import SoftwaresSection from "./sections/SoftwaresSection";
import LanguagesSection from "./sections/LanguagesSection";
import EducationSection from "./sections/EducationSection";
import AbilitiesSection from "./sections/AbilitiesSection";

function Academic(props) {
  return (
    <div className="row academic">
      <div className="col-md-12">
        <h3>Dados Acadêmicos</h3>
      </div>
      <div className="col-md-6">
        <CourseSituation {...props} />
      </div>
      <div className="col-md-6">
        <Period {...props} />
      </div>
      <div className="col-md-6">
        <CourseStartDate {...props} />
      </div>
      <div className="col-md-6">
        <AcademicEntities {...props} />
      </div>
      <AbilitiesSection {...props} />
      <SoftwaresSection {...props} />
      <LanguagesSection {...props} />
      <EducationSection {...props} />
    </div>
  );
}

export default Academic;
