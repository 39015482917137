import React, { useState, useEffect } from "react";
import _ from "lodash";
import api from "services/api";
import "./style.scss";
import { MainBox } from "components/ui";
import Form from "./Form";
import Begin from "./Begin";
import { useFlipper } from "services/api/flipper";

function EssentialFields() {
  const { refreshFlipper } = useFlipper();

  const [defaultValues, setDefaultValues] = useState({});
  const [component, setComponent] = useState(
    <Begin changeComponent={changeComponent} />
  );

  function changeComponent() {
    getDefaultValues();
  }

  function getDefaultValues() {
    const step = "essential_fields";
    api
      .get(`/v1/profile/user_infos/attributes`, { params: { step } })
      .then((resp) => setDefaultValues(resp.data?.data?.attributes))
      .catch(() => setDefaultValues({}));
  }

  useEffect(() => {
    if (!_.isEmpty(defaultValues)) setComponent(<Form defaultValues={defaultValues} />);
  }, [defaultValues]);

  useEffect(() => {
    refreshFlipper();
  }, []);

  return (
    <div className="user-essential-fields">
      <MainBox>{component}</MainBox>
    </div>
  );
}

export default EssentialFields;
