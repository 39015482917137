import React from 'react'
import { useCandidature } from '../context';
import Test from './Test';

function Tests() {
  const { candidature } = useCandidature();
  const { test_results } = candidature;

  return test_results.map((test) => (
    <Test key={test.id} test={test.attributes} />
  ))
}

export default Tests;
