import React, { useState, useEffect } from "react";
import { useAttendee } from "./context";

function Header() {
  const { meeting, attendee } = useAttendee();
  const [confirmation, setConfirmation] = useState(null);

  useEffect(() => {
    setConfirmation(attendee.last_confirmation?.attributes)
  }, [attendee])

  return (
    <header className="content-box-header">
      <div className="content-box-header-infos">
        <img className="logo" src={meeting.company_avatar} alt={`Logo ${meeting.company_name}`} />
        <div>
          <small>{meeting.company_name}</small>
          <h1>{meeting.title}</h1>
          <small className="address">
            <i className="material-icons">place</i> {meeting.address}
          </small>
        </div>
      </div>
      <MeetingConfirmation confirmation={confirmation} />
    </header>
  );
}

function MeetingConfirmation({confirmation}) {
  return confirmation ? (
    <div className="meeting-confirmation">
      {confirmation.confirmed ? <Confirmed time={confirmation.time} /> : <Declined />}
    </div>
  ) : null;
}

function Confirmed({time}){
  return(
    <p className="confirmed">
      <i className="fa fa-check"></i>
      <b>Horário:</b> {time}
    </p>
  )
}

function Declined(){
  return(
    <p className="declined">
      <i className="fa fa-times"></i>
      <b>Convite recusado</b>
    </p>
  )
}

export default Header;
