import React from "react";
import styled from "styled-components";

const ImageWrap = styled.div`
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0px 0px;

  cursor: pointer;
  overflow: hidden;

  :hover {
    img {
      transform: scale(1.1);
    }
  }

  @media (min-width: 720px) {
    /* height: auto; */
  }

  @media (min-width: 770px) {
    width: 350px;
    height: auto;
    border-radius: 8px 0px 0px 8px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s;
`;

export default (props) => (
  <ImageWrap>
    <Image src={props.image} alt="Imagem do curso" onClick={props.onClick} />
  </ImageWrap>
);
