import React from "react";
import {LectureTitle, LectureContainer, LectureDuration, LeftItems, LectureInfos, TimeInfos, RightItems} from "./styles";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import QuizIcon from '@mui/icons-material/Quiz';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { ACADEMY } from "navigation/ROUTES";
import { Link, useParams } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";
import { useProgressItem } from "../context";
import { Loader } from "components/ui";


function Lecture(props){
  const {id, item_id, matriculation_id} = useParams();
  const section_item = props.section_item;

  const { progressItem, isLoading } = useProgressItem(matriculation_id, section_item.id)

  if(isLoading){
    return <Loader />; 
  }
  
  return(
    <>
      <LectureContainer style={{backgroundColor: section_item.id == item_id ? "rgba(61, 71, 92, 0.4)" : "#1f232e"}}>
        <LeftItems>
          {!section_item.isQuiz ? (<PlayCircleIcon style={{fontSize: "32px", color: section_item.id == item_id ? "#B70404" : ""}} />) : (<QuizIcon style={{fontSize: "32px", color: section_item.id == item_id ? "#B70404" : ""}} />)}
          <LectureInfos>
            <LectureTitle > <Link to={pathWithParams(ACADEMY.COURSE.WATCH, {id: id, item_id: section_item.id, matriculation_id: matriculation_id})} >{section_item.title}</Link></LectureTitle>
            <TimeInfos>
              <QueryBuilderIcon style={{fontSize: "18px"}} />
              <LectureDuration>{section_item.duration} min</LectureDuration>
            </TimeInfos>
          </LectureInfos>
        </LeftItems>

        <RightItems>
          {progressItem.watched ? <CheckCircleIcon style={{color: "green"}} /> : <RadioButtonUncheckedIcon />}
        </RightItems>
      </LectureContainer>
      <hr />
    </>
  )
}

export default Lecture;