import styled from "styled-components";
import MuiWarningAmberIcon from '@mui/icons-material/WarningAmber';
import { styled as styledMui } from '@mui/system';

export const Main = styled.section`
  width: 100%;
  height: 100%;
`;

export const Title = styled.h1`
  color: #404555;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  max-width: 860px;
  margin: 40px auto;
  margin-bottom: 15px;

  @media (min-width: 767px) {
    font-size: 38px;
  }
`;

export const Description = styled.p`
  margin: 0 auto;
  max-width: 506px;
  padding: 0 2rem;
  color: #404555;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;

  @media (min-width: 767px) {
    max-width: 860px;
  }
`;

export const TutorialContainer = styled.div`
  width: 100%;
  min-height: 450px;
  margin: 2rem auto;
  padding: 2rem 1.5rem;
  background: rgba(217, 217, 217, 0.40);
  box-shadow: 4px 4px 18px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 

  @media (min-width: 767px) {
    width: 94%;
    max-width: 1340px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 20% repeat(2, 1fr);
  }
`;

export const Warning = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 1.5rem; 

  @media (min-width: 767px) {
    grid-column: 1/-1;
    grid-row: 1/2;
  }
`;

export const WarningTitle = styled.h2`
  font-size: 32px;
  margin: 0;
  vertical-align: middle;
  font-weight: 700;

  @media (min-width: 767px) {
    font-size: 40px;
  }
`;

export const WarningAmberIcon = styledMui(MuiWarningAmberIcon)(({theme}) => ({
  fontSize: "35px",

  '@media (min-width: 767px)': {
    fontSize: "43px",
  }
}));

export const Tutorial = styled.div`
  display: flex;
  max-width: 400px;
  flex-direction: column;
  margin-bottom: 2rem;

  @media (min-width: 767px) {
    max-width: 660px;
    margin: 0 1rem 2rem;
  }
`;

export const TutorialTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;

  @media (min-width: 767px) {
    font-size: 32px;
  }
`;

export const TutorialDescription = styled.p`
  font-size: 12px;
  line-height: 120%;

  @media (min-width: 767px) {
    font-size: 16px;
  }
`;

export const Button = styled.button`
  margin: 25px auto;
  margin-bottom: 50px;
  background-color: #009bf6;
  color: #ececee;
  display: flex;
  width: 300px;
  max-width: 90vw;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 8px;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;

  @media (min-width: 767px) {
    width: 400px;
    height: 64px;
    flex-shrink: 0;
    margin: 45px auto;
    margin-bottom: 140px;
  }
`;

export const FinishedTestContainer = styled.div`
  display: flex;
  height: calc(100vh - 200px);
  flex-direction: column;
  font-size: 16px;
  text-align: center;
  padding: 60px 15px;
  align-items: center;
  
  @media(min-width: 720px){
    width: 60vw;
    margin: 0 auto;
    font-size: 24px;
  }
`