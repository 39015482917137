import React from 'react';
import Section from './Section';
import Software from "components/users/fields/academic/Software";

function SoftwaresSection(props){
  return(
    <Section title="Softwares">
      <Software {...props} />
    </Section>
  )
}

export default SoftwaresSection