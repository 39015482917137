import React from "react";
import HeaderStudyTrack from "../../components/header/Header";
import Box from "../../components/box/Box";
import { useStudyTracks } from "./context";
import { Loader } from "components/ui";
import CardItem from "./components/CardItem";



function Index(){
  
  const {studyTracks, isLoading} = useStudyTracks();

  if(isLoading){
    return <Loader />
  }

  function renderStudyTracks(){
    return studyTracks.map((st) => (
      <CardItem item={st} />
    ))
  }

  return (
    <>
      <HeaderStudyTrack title={"Trilhas de Estudos"} breadcrumb={"Trilhas de Estudos"} />
      <Box>
        {renderStudyTracks()}
      </Box>
    </>
  )
}

export default Index;