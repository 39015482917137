import React from "react";
import Display from "components/filters/Display";
import Job from "../shared/Job";

function Jobs() {
  const request = "/v1/ats/jobs";

  return <Display request={request} component={<Job />} />;
}

export default Jobs;
