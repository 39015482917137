import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, ContentBox, Loader, MainBox, HeaderAlert } from "components/ui";
import Sidebar from "./sections/Sidebar";
import Header from "./sections/Header";
import Logo from "./sections/Logo";
import MainContent from "./sections/MainContent";
import { useCandidature } from "./context";
import { USERS } from "navigation/ROUTES";

function Main() {
  const { candidature, job, isLoading } = useCandidature();

  if(isLoading){
    return <Loader />
  }

  return (
    <MainBox>
      <Breadcrumb>
        <Link to={USERS.CANDIDATURES.INDEX}>Candidaturas</Link>
        <a>{job.title}</a>
      </Breadcrumb>
      <HeaderAlert
        className="candidature-message"
        message={candidature.pendencies_message}
      />
      <ContentBox>
        <Logo />
        <Header />
        <Sidebar />
        <MainContent />
      </ContentBox>
    </MainBox>
  );
}

export default Main;
