import React from "react";
import { Link } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";
import { INTERNSHIP } from "navigation/ROUTES";
import {
  CardBox,
  CardBoxImage,
  CardBoxInfo,
  CardBoxLabel,
} from "components/ui";

function FormAnswer({ item }) {
  const form_answer = item.attributes;

  return (
    <CardBox>
      <Link
        to={pathWithParams(INTERNSHIP.COMPANY_EVALUATION.SHOW, {
          uuid: form_answer.uuid,
        })}
        className="card-box-body"
      >
        <CardBoxImage image={form_answer.company_avatar} />

        <CardBoxInfo title={form_answer.company_name} subtitle="Avalie">
          <CardBoxLabel>
            <small>
              <i className="material-icons">bookmark</i> {form_answer.status}
            </small>
            <small>
              <i className="material-icons">schedule</i>{" "}
              {form_answer.time_or_answer_date}
            </small>
          </CardBoxLabel>
        </CardBoxInfo>
      </Link>
    </CardBox>
  );
}

export default React.memo(FormAnswer);
