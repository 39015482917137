import React from "react"
import {CertificateContainer, CertificateHeader, CertificateHeaderRight, CourseTitle, WJTitle, WJLogo, CourseDuration,
  CertificateTitle,
  CertificateTitleDiv,
  CertificateContent,
  CertificateMainContent,
  TextCertification
} from "./styles.js";
import logo from "assets/images/logo-w.png";

function Certificate({course, userInfo}) {
  
  return (
    <CertificateContainer>
      <CertificateTitleDiv>
        <CertificateTitle>Certificado</CertificateTitle>
      </CertificateTitleDiv>
      <CertificateMainContent>
        <CertificateHeader>
          <WJLogo src={logo} alt="Logo" />
          <CertificateHeaderRight>
            <WJTitle>Walljobs</WJTitle>
            <CourseTitle>{course.title}</CourseTitle>
            <CourseDuration>{course.formatted_duration}</CourseDuration>
          </CertificateHeaderRight>
        </CertificateHeader>
        <CertificateContent>
          <TextCertification>
            Certificamos que o aluno {userInfo.name} concluiu o curso {course.title} com a carga horária de {course.formatted_duration}.
          </TextCertification>
        </CertificateContent>
      </CertificateMainContent>
    </CertificateContainer>
  )
}

export default Certificate;