import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useCourse() {
  const { id } = useParams();
  const { data, isLoading } = useFetch(`/v1/academy/courses/${id}`);

  return {
    course: data?.data?.attributes,
    courseIsLoading: isLoading,
  };
}

export function useItem() {
  const { item_id } = useParams();
  const { data, isLoading } = useFetch(`/v1/academy/section_items/${item_id}`);

  return {
    item: data?.data?.attributes,
    itemIsLoading: isLoading,
  }
}

export function useMatriculation() {
  const {matriculation_id} = useParams();
  const { data, isLoading } = useFetch(`/v1/academy/matriculations/${matriculation_id}`);

  return {
    matriculationData: data?.data?.attributes,
    userInfo: data?.included[0].attributes,
    matriculationIsLoading: isLoading,
  }
}

export function useWatchCourse() {
  const { id, item_id } = useParams();
  const { data, isLoading } = useFetch(`/v1/academy/courses/assistir/${id}/${item_id}`);

  return {
    watch: data?.collection,
    watchCourseIsLoading: isLoading,
  }
}

export function useProgressItem(matriculation_id = null, item_id = null) {

  if(!matriculation_id || !item_id){
    return {
      progressItem: {
        watched: false
      }
    }
  }

  const {data, isLoading } = useFetch(`/v1/academy/progress_items/progress_item/${matriculation_id}/${item_id}`)

  return {
    progressItem: data?.data?.attributes,
    isLoading
  }
}

export function useTotalWatched(section_id){
  const {data, isLoading } = useFetch(`/v1/academy/progress_items/total_watched/${section_id}`)

  return {
    total: data?.total,
    isLoading
  }

}

export function useNextItem(){
  const params = useParams();
  const course_id = params.id;
  const {data, isLoading } = useFetch(`/v1/academy/progress_items/next_item/${course_id}`)

  return {
    nextItem: data?.section_item_id,
    nextItemIsLoading: isLoading
  }
}