import React from "react";
import { Test, Header, Status, Title, Small, LinkOrResult } from "./styles";

export default ({ test }) => (
  <Test elevation={10}>
    <Header>
      <small>Teste de habilidade</small>
      <Status dangerouslySetInnerHTML={{ __html: test.status }}></Status>
    </Header>
    <Title>{test.name}</Title>
    <Small>
      <i className="material-icons">access_time</i> {test.answer_at}
    </Small>
    <Small className="duration">Duração: {test.duration}</Small>
    <LinkOrResult
      dangerouslySetInnerHTML={{ __html: test.link_or_result }}
    ></LinkOrResult>
  </Test>
);
