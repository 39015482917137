import React, { useEffect } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { FinishedTestContainer } from "./styles";
import { Loader } from "components/ui";
import { usePayload } from "./context";
import { pathWithParams } from "utils/pathWithParams";
import api from "services/api";
import PATHS from "navigation/ROUTES";
import Content from "./Content";

function Start({ isLogged }) {
  const { test, payload, answered, isLoading } = usePayload();
  const { id, user_info_test_id } = useParams();
  let navigate = useNavigate();

  if (!isLogged) {
    swal(
      "Ops!",
      "Você precisa estar logado para realizar o teste!",
      "warning"
    ).then(() => navigate(PATHS.USERS.LOGIN));
  }

  if (isLoading) {
    return <Loader />;
  }

  if (payload.has_active_session) {
    swal(
      "Ops!",
      "Você já está fazendo o teste em outra aba.",
      "warning"
    ).then(() => navigate(PATHS.USERS.HOME));
  }

  const startTest = () => {
    api
      .post(`/v1/test/answers/set_started_at`, { answer_id: payload.answer_id })
      .then(() =>
        navigate(pathWithParams(PATHS.TESTS.TAKING, { id, user_info_test_id }))
      )
      .catch(({ response }) => swal("Ops!", response.data.message, "error"));
  };

  if (answered) {
    return (
      <FinishedTestContainer>
        <h2>Você já respondeu o teste {test.title}.</h2>
        <p style={{ marginTop: "20px" }}>
          Caso esse teste revele sua nota, você pode conferir a mesma nas
          candidaturas que utilizam o teste ou na página de{" "}
          <Link to={PATHS.TESTS.INDEX}>Testes de habilidades</Link>.
        </p>
      </FinishedTestContainer>
    );
  }

  return <Content test={test} startTest={startTest} />;
}

function mapStateToProps(state) {
  const isLogged = state.session?.isLogged;

  return { isLogged };
}

export default connect(mapStateToProps)(Start);
