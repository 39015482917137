import React from "react";
import { UncontrolledInput as Input } from "components/form";

function Comment(props) {
  const prefix = {
    id: "comment",
    name: "comment",
  };

  return (
    <Input
      {...props}
      placeholder="Se você quiser fazer um comentário adicional, digite aqui..."
      type="text"
      name={prefix.name}
      id={prefix.id}
      multiline
      rows={6}
      label="Comentário Adicional"
    />
  );
}

export default Comment;
