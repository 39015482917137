import React from "react";
import { useTranslation } from "react-i18next";
import { useUserInfo } from "../../context";

function About() {
  const { userInfo } = useUserInfo();
  const { job_interests, interesting_fact, motivations, hobbies } = userInfo;
  
  const { t } = useTranslation();
  
  return (
    <section>
      <hr />
      <p>
        <b>{t("user_info.attributes.job_interests")}:</b> {job_interests}
      </p>
      <p>
        <b>{t("user_info.attributes.interesting_fact")}:</b> {interesting_fact}
      </p>
      <p>
        <b>{t("user_info.attributes.motivations")}:</b> {motivations}
      </p>
      <p>
        <b>{t("user_info.attributes.hobbies")}:</b> {hobbies}
      </p>
    </section>
  );
}

export default About;
