import React from "react";
import { Paper } from "@mui/material";
import styled from "styled-components";

const HeaderNav = styled(Paper)`
  grid-area: nav;
  width: 90%;
  height: 55px;
  margin: 0 auto 50px auto;

  ul {
    height: 100%;
    align-items: center;
    padding: 0;
    border: 1px solid
      ${({theme}) => (theme.mode === "light" ? "#f0f0f0" : "#333b4d")};
    border-radius: 4px;

    li {
      height: 100%;
      flex: 1;
      background-color: ${({theme}) =>
        theme.mode === "light" ? "#f0f0f0" : "#333b4d"};
      border-collapse: collapse;
      box-sizing: border-box;
    }

    li:first-child {
      a {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    li:last-child {
      a {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    li a {
      font-size: 20px;
      font-weight: bold;
      line-height: 55px;
      padding: 0;
      text-align: center;
      text-decoration: none;
      background: none;
      display: block;
      height: 100%;
      opacity: 0.65;
    }

    li a.active {
      opacity: 1;
      background-color: ${({theme}) =>
        theme.mode === "light" ? "white" : "#3B4459"};
      ${({theme}) =>
        theme.mode === "dark" &&
        `{background-image:linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))}`}
    }
  }

  @media screen and (max-width: 767px) {
  }
`;

function Menu() {
  return (
    <HeaderNav className="content-box-header-nav" elevation={0}>
      <ul className="nav">
        <li>
          <a className="active" data-toggle="tab" href="#about">
            Visão Geral
          </a>
        </li>
        <li>
          <a data-toggle="tab" href="#jobs">
            Vagas
          </a>
        </li>
      </ul>
    </HeaderNav>
  );
}

export default Menu;
