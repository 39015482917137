import React from "react";
import { Helmet } from "react-helmet";

export default ({job}) => (
  <Helmet>
    <title>{job.page_title}</title>
    <meta name="description" content={job.meta_description} />
    <meta property="og:url" content={job.url} />
    <meta property="og:title" content={`${job.author_name} - ${job.title}`} />
    <meta property="og:description" content={job.summary} />
    <meta property="og:site_name" content="Walljobs" />
    <meta property="og:image" content={job.avatar} />
  </Helmet>
);
