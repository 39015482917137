import React from "react";
import styled from "styled-components";
import FilterController from "components/filters/FilterController";
import Filters from "pages/ats/jobs/Index/Filters";
import List from "./List";
import { useCompany } from "../context";

const Paragraph = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-align: center !important;
  padding: 0 10vw 0 10vw;
`;

function Jobs() {
  const { company } = useCompany();
  return (
    <div
      className="tab-pane fade"
      id="jobs"
      role="tabpanel"
      aria-labelledby="jobs-tab"
    >
      {company.job_count > 0 ? (
        <FilterController>
          <Filters />
          <List />
        </FilterController>
      ) : (
        <Paragraph>
          Essa empresa não tem nenhuma vaga disponível no momento... &#128542;<br /><br />
          Que tal se inscrever no banco de candidatos? Basta clicar no botão <b>Deixar currículo</b>! &#128521;
        </Paragraph>
      )}
    </div>
  );
}

export default Jobs;
