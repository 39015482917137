import React from "react";
import { Link } from "react-router-dom";
import { ContentBox, Breadcrumb, MainBox } from "components/ui";
import Header from "./Header";
import { default as IesJobHeader } from "./IesJob/Header";
import MainSection from "./MainSection";
import Sidebar from "./Sidebar";
import { useJob } from "./context";
import { ATS } from "navigation/ROUTES";
import MetaTags from "./MetaTags";

function Content() {
  const { job } = useJob();

  return (
    <>
      <MetaTags job={job} />
      <MainBox>
        <Breadcrumb>
          <Link to={ATS.JOBS.INDEX}>Vagas</Link>
          <a>{job.title}</a>
        </Breadcrumb>
        <ContentBox>
          {job.created_by_ies ? <IesJobHeader /> : <Header />}
          <Sidebar />
          <MainSection />
        </ContentBox>
      </MainBox>
    </>
  );
}

export default Content;
