import React from "react";
import { CircularProgressWithLabel } from "components/ui";

export default ({ min, max }) => {
  const calculate = () => Math.round((min * 100) / (max + 1));

  const value = calculate();

  return (
    <CircularProgressWithLabel
      size={70}
      value={value}
      text={`${value}%`}
    />
  )
}
