import React, { useState } from "react";
import styled, {keyframes} from "styled-components";

function Question({question, answer}){

  const [isActive, setIsActive] = useState(false);

  const QuestionItem = styled.div`
    border: 1px solid ${({theme}) => theme.mode === "light" ? "gray" : "#333b4d"};
    background-color: ${({theme}) => theme.mode === "light" ? "#F2F2F2" : "#3B4459"};
    display: inline;
    width: 100%;
    color: ${({theme}) => theme.mode === "light" ? "#2F58CD" : "#cfdaec"};
    border-left: none;
    border-right: none;
    border-top: none;
    margin-top: 0.825rem;
    padding: 0.825rem 1rem;
  `

  const QuestionTitle = styled.h4 `
    cursor: pointer;
    display: inline;
    padding-left: 23px;
    border: 2px solid #850000;
    border-right: none;
    border-top: none;
    border-bottom: none;
  `
  const slideDown = keyframes`
    0% {
      transform: translateY(-20%);
    }
    50%{
      transform: translateY(8%);
    }
    100% {
      transform: translateY(0%);
    }

    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  `

  const Answer = styled.div`
    color: ${({theme}) => theme.mode === "light" ? "black" : "white"};
    word-break: break-all;
    margin-top: 1rem;
    border: 2px solid #8D69E0;
    border-right: none;
    border-top: none;
    border-bottom: none;
    padding-left: 23px;
    animation: ${slideDown} .7s;
  `

  return (
    <QuestionItem>
      <div onClick={() => setIsActive(!isActive)}>
        <div>
          <QuestionTitle>{question}</QuestionTitle>
          <div style={{float: "right", cursor: "pointer"}}>
            {isActive ? (<em class="fa fa-angle-up"></em>) : (<em class="fa fa-angle-down"></em>)}
          </div>
        </div>
      </div>
      {isActive && <Answer>{answer}</Answer>}
    </QuestionItem>
  )

}

export default Question;