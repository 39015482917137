import React from "react";
import styled from "styled-components";
import { useUserInfo } from "../../context";
import About from "./About";
import Portfolios from "./Portfolios";
import { EditIcon } from "components/users/EditMode";
import { useTranslation } from "react-i18next";
import CurriculumVitae from "./CurriculumVitae";

const SummaryContent = styled.div`
  margin-top: 30px;
  display: inline-block;
  height: fit-content;

  p.quotes {
    font-size: 120px;
    margin: 0;
    padding: 0;
    line-height: 0px;
    margin-top: 38px;
    margin-right: 12px;
    opacity: 0.3;
    width: fit-content;
    float: left;
  }
`;

function Summary() {
  const { userInfo } = useUserInfo();
  const { name, summary } = userInfo;

  const { t } = useTranslation();

  return (
    <div
      className="tab-pane fade show active"
      id="summary"
      role="tabpanel"
      aria-labelledby="summary-tab"
    >
      <EditIcon type="summary" />
      <p>
        <b>{t("user_info.attributes.full_name")}:</b> {name}
      </p>
      {summary && (
        <SummaryContent>
          <p className="quotes">&#8220;</p>
          <span dangerouslySetInnerHTML={{ __html: summary }}></span>
        </SummaryContent>
      )}
      <Portfolios />
      <CurriculumVitae />
      <About />
    </div>
  );
}

export default Summary;
