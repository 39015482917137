import React from 'react';
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import { useUserInfo } from '../../context';

function Portfolios(){
  const { userInfo } = useUserInfo();
  const { portfolios } = userInfo;

  const { t } = useTranslation();

  const _renderPortfolios = () => {
    const list = portfolios || []
    return list.map((link, index) => (
      <li key={index}>
        <a href={link} target="_blank">Portfólio #{index + 1}</a>
      </li>
    ))
  }

  if(_.isEmpty(portfolios)){
    return;
  }

  return (
    <section>
      <hr />
      <p><b>{t("user_info.attributes.portfolios")}:</b></p>
      <ul>
        {_renderPortfolios()}
      </ul>
    </section>
  );
}

export default Portfolios;