import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import styled from "styled-components";

const Link = styled.a`
  color: #c13950;
`

function TabPanel(props) {
  const { content, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
        (_.isEmpty(content) ? (
          <Typography align="center" sx={{ marginTop: '50px' }}>
            Não foram encontrados certificados de horas complementares nessa
            aba...
          </Typography>
        ) : (
          <Box sx={{ p: 3 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>Tipo</TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>Nome</TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="center">Nº de horas</TableCell>
                    <TableCell sx={{ fontWeight: 600 }} align="center">Comprovante</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {content.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.category}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell align="center">{row.hours}</TableCell>
                      <TableCell align="center">
                        <Link href={row.download} target="_blank"> <i className="material-icons">download</i></Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
    </div>
  );
}

export default TabPanel;
