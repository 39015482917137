import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TextField, MenuItem } from "@mui/material";
import { useFilters } from "utils/hooks";
import { Filter } from "components/ui";
import { FilterContext } from "components/filters/FilterController";
import IesJobsFilter from "components/ats/IesJobsFilter";
import api from "services/api";

function Filters(props) {
  const { infos, filterData } = React.useContext(FilterContext);
  const { user } = props;

  const {
    filters,
    handleChange,
    createFilterLabel,
    renderFilters,
    handleSelect,
  } = useFilters(
    { company_name: "", job: "", location: "", job_type: "" },
    filterData
  );

  const [types, setTypes] = useState([]);

  useEffect(() => {
    if (types.length === 0) {
      get_job_types();
    }
  });

  function get_job_types() {
    api
      .get("/selects/job_types.json")
      .then((resp) => setTypes(resp.data))
      .catch(() => setTypes([]));
  }

  function _renderOptions() {
    const list = types || [];
    return list.map((type) => (
      <MenuItem key={type.value} value={type.value}>
        {type.label}
      </MenuItem>
    ));
  }

  return (
    <>
      {user?.talent_hub_ies_student && (
        <IesJobsFilter
          enabled={!!filters.ies_jobs_only}
          handleChange={handleChange}
          createFilterLabel={createFilterLabel}
        />
      )}
      
      <Filter
        length={infos.length}
        totalCount={infos.totalCount}
        renderFilters={renderFilters}
      >
        <TextField
          filter
          variant="standard"
          id="company_name"
          name="company_name"
          label="Empresa"
          placeholder="Ex: WallJobs..."
          value={filters.company_name}
          onChange={handleChange}
          onBlur={createFilterLabel}
        />
        <TextField
          filter
          variant="standard"
          id="job"
          name="job"
          label="Informações da Vaga"
          placeholder="Ex: Marketing, RH..."
          value={filters.job}
          onChange={handleChange}
          onBlur={createFilterLabel}
        />
        <TextField
          filter
          variant="standard"
          id="location"
          name="location"
          label="Localidade"
          placeholder="Ex: Avenida Paulista, RJ..."
          value={filters.location}
          onChange={handleChange}
          onBlur={createFilterLabel}
        />
        <TextField
          select
          filter
          variant="standard"
          id="job_type"
          name="job_type"
          label="Tipo de vaga"
          placeholder="Ex: Programa de estágio, Trainee, etc..."
          value={filters.job_type}
          onChange={(event) => handleSelect(event, types)}
        >
          {_renderOptions()}
        </TextField>
      </Filter>
    </>
  );
}

function mapStateToProps(state) {
  const user = state.session?.attributes;

  return { user };
}

export default connect(mapStateToProps)(Filters);
