import React from "react";
import styled from "styled-components";
import Test from "./components/TestResult";
import { useTestResults } from "./context";
import { Loader } from "components/ui";

const Paragraph = styled.p`
  color: #898d94;
  margin-top: 20px;
  grid-column: 1 / -1;
`

export default () => {
  const { tests, isLoading } = useTestResults();

  if (isLoading) {
    return <Loader />;
  }

  if (_.isEmpty(tests)) {
    return <Paragraph>Você não tem nenhum teste de habilidade respondido ou pendente...</Paragraph>
  }

  const renderTests = () => {
    return tests.map((test) => (
      <Test test={test.attributes} />
    ));
  };

  return <>{renderTests()}</>;
};
