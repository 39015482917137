import React, { useEffect } from "react"
import {UncontrolledInput as Input} from 'components/form';
import VMasker from "vanilla-masker";
import FormLabel from "@mui/material/FormLabel";

function InternshipEndDate(props) {
  const prefix = props.prefix || {
    name: "internship_end_date",
    id: "internship_end_date"
  }

  const validation = {
    pattern: /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/  
      
  };

  const messages = {
    pattern: "Não segue o padrão DD/MM/AAAA ou não é uma data válida",
  };

  useEffect(() => {
    VMasker(document.querySelector(".internship_end_date")).maskPattern("99/99/9999");
  });
  
  return(
    <div className="form-group">      
        <FormLabel id={prefix.id}>Você estagiará nessa empresa até quando? (Caso não tenha uma data estipulada, informe uma previsão)</FormLabel>
        <Input
          {...props}
          className="internship_end_date"
          placeholder="DD/MM/AAAA"
          type="text"
          id={prefix.id}
          name={prefix.name}          
          label={props.label || "Data"}
          validation={validation}
          messages={messages}
        />      
    </div>
  )
}
export default InternshipEndDate;