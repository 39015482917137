import React, {useState} from 'react'
import { useCourses } from "../context";
import { Loader } from "components/ui";
import Card from './Card';
import { Carousel as CarouselContainer, ChevronLeftIcon, ChevronRightIcon } from "./styles";

const FIRST = 0;
const CENTER = 1;
const LAST = 2;

function Carousel() {
  const [center, setCenter] = useState(CENTER);
  const [first, setFirst] = useState(FIRST);
  const [last, setLast] = useState(LAST);

  const { courses, isLoading } = useCourses();

  if(isLoading) {
    <Loader/>
  }

  const handleMoveLeft = () => {
    setCenter((center + 1) % courses.length);
    setFirst((first + 1) % courses.length);
    setLast((last + 1) % courses.length);
  };
  
  const handleMoveRight = () => {
    setCenter((center - 1 + courses.length) % courses.length);
    setFirst((first - 1 + courses.length) % courses.length);
    setLast((last - 1 + courses.length) % courses.length);
  };

  return (
    <CarouselContainer>
      {courses && courses.map((course, index) => 
      <React.Fragment key={index}>
          {index === center && (
            <ChevronLeftIcon onClick={handleMoveLeft} />
          )}
          <Card 
            course={course} 
            index={index} 
            center={center} 
            first={first} 
            last={last} 
          />
          {index === center && (
            <ChevronRightIcon onClick={handleMoveRight} />
          )}
        </React.Fragment>
      )}
    </CarouselContainer>
  )
}

export default Carousel;

