import React from "react";
import Form from "./Form";
import Header from "./Header";
import { useFormAnswer } from "./context";
import { Loader } from "components/ui";
import "./style.scss";

function FormAnswer() {
  const {isLoading} = useFormAnswer();

  if(isLoading){
    return <Loader />;
  }

  return (
    <div className="internship-admission-form">
      <Header />
      <Form />
    </div>
  );
}

export default FormAnswer;
