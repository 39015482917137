import React, { useMemo } from "react";
import { Buffer } from "buffer";
import styled from "styled-components";

const WatermarkContainer = styled.div`
  padding: 35px;
  min-height: 100vh;
  background-image: url('data:image/svg+xml;base64,${props => props.backgroundImage}');

  @media (max-width: 767px) {
    padding: 15px;
  }
`

function Watermark({ content, children }) {

  const imageBg = useMemo(() =>{
    let width = Math.round(content.length * 8.3);

    return Buffer.from(`<svg id="diagtext" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%"><style>text{fill:rgba(128,128,128,.2);font-family:Avenir,Arial,Helvetica,sans-serif}</style><defs><pattern id="twitterhandle" patternUnits="userSpaceOnUse" width="${width}" height="20"><text y="50" font-size="15" id="name">${content}</text></pattern><pattern id="combo" xlink:href="#twitterhandle" patternTransform="rotate(-20)"><use xlink:href="#name"/><use xlink:href="#occupation"/></pattern><text y="12" x="10" font-size="15" id="occupation">${content}</text></defs><rect width="100%" height="100%" fill="url(#combo)"/></svg>`).toString("base64");
  }, [content])

  return (
    <WatermarkContainer backgroundImage={imageBg}>
      {children}
    </WatermarkContainer>
  );
}

export default Watermark;
