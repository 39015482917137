import React from "react";
import _ from "lodash";
import styled from "styled-components";
import Document from "./Document";

function Content(props) {
  const { documents } = props;

  const _renderList = () => {
    return documents.map((document, index) => (
      <Document key={index} document={document} getDocuments={props.getDocuments} />
    ));
  };

  return !_.isEmpty(documents) ? (
    <div className="display">{_renderList()}</div>
  ) : (
    <EmptyList />
  );
}

const Paragraph = styled.p`
  & {
    margin-top: 20px;
    text-align: center;
  }
`;

function EmptyList() {
  return (
    <Paragraph>
      <b>Você ainda não adicionou nenhum documento! </b>
      &#128556;
    </Paragraph>
  );
}

export default Content;
