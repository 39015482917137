export const getContentSrc = (content) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  const iframeElement = doc.querySelector("iframe");
  const src = iframeElement.getAttribute("src");
  return src;
}

export const convertIndexToLetter = (i) => String.fromCharCode(97 + i).toUpperCase();

export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};