import React, { useState } from 'react'
import AddDocument from './new/AddDocument'

function Header(props) {
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  }

  return (
    <header>
      <h2>Documentos Pessoais</h2>
      <p>De tempos em tempos, tanto o WallJobs como a sua empresa podem pedir alguns documentos. Faça o upload dos documentos nessa área!</p>
      <div className='buttons'>
        <a className="button button-info" href="/folders/horas-estagiadas" target="_blank">
          Comprovante de horas estagiadas
        </a>
        <button className="button button-primary" onClick={handleClickOpen}>Adicionar documento</button>
        <AddDocument open={openModal} setOpen={setOpenModal} {...props} />
      </div>
    </header>
  )
}

export default Header
