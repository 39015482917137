import React from "react";
import Essential from "./forms/Essential";
import Personal from "./forms/Personal";
import Academic from "./forms/Academic";
import Experience from "./forms/Experience";
import Internship from "./forms/Internship";
import store from "@root/redux/store";

function formList(){
  let forms = [
    {component: <Essential />, stepLabel: "Essencial"},
    {component: <Personal />, stepLabel: "Pessoal"},
    {component: <Academic />, stepLabel: "Acadêmico"},
    {component: <Experience />, stepLabel: "Experiências"},
  ]

  let user = store.getState().session?.attributes;

  if(user?.intern || user?.studying) forms.push({component: <Internship />, stepLabel: "Estágio"})

  return forms;
}

export default formList;