import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { styled as styledMui } from '@mui/system';
import { Skeleton } from '@mui/material';

const Container = styled.div`
  width: 100%;
  min-height: 320px;
  align-self: flex-start;
  object-fit: cover;
  margin: 0 6px;
  z-index: 2;

  @media (min-width: 767) {
    width: 440px;
    margin: 0 6px;
  }
`;

const ImageContainer = styled.img`
  width: 95%;
  max-width: 440px;
  margin: .5rem 0;
  align-self: flex-start;
  z-index: 2;

  @media (min-width: 767px) {
    width: 100%;
    padding-left: 1.5rem;
  }
`;

export const SkeletonMui = styledMui(Skeleton)(({theme}) => ({
  width: "95%",
  height: "320px",
  maxWidth: "440px",
  margin: ".5rem 0",
  alignSelf: "flex-start",
  zIndex: 2,

  '@media (min-width: 767px)': {
    width: "100%",
    paddingLeft: "1.5rem",
  }
}));


function QuestionImage(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = props.src;

    const handleLoad = () => {
      setLoading(false);
    };

    img.addEventListener('load', handleLoad);

    return () => {
      img.removeEventListener('load', handleLoad);
    };
  }, [props.src]);

  return (
    <Container>
      {!loading ? (
        <ImageContainer
          src={props.src}
          alt={props.alt}
        />
      ) : (
        <SkeletonMui variant="rectangular" animation="wave" />
      )}
    </Container>
  );
}

export default QuestionImage;