import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useStudyTracks() {
  const { data, isLoading } = useFetch(`/v1/academy/study_track/study_tracks`);
  
  return {
    studyTracks: data?.collection.data,
    isLoading: isLoading,
  };
}