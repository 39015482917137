import React from "react";
import { useUserInfo } from "../context";

function Avatar() {
  const { userInfo } = useUserInfo();
  const { avatar, name } = userInfo;

  return <img className="avatar" src={avatar} alt={`Foto de ${name}`} />;
}

export default Avatar;
