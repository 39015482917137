import React, { useState, useEffect } from "react";
import _ from "lodash";
import api from "services/api";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ResetPassword from "components/users/fields/settings/ResetPassword";
import { USERS } from "navigation/ROUTES";

function ChangePassword() {
  const [data, setData] = useState({});

  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur"
  });

  const getData = (collectedData) => {
    setData({ ...data, ...collectedData });
  };

  const submit = () => {
    api
      .post(`/v1/profile/users`, { user: data })
      .then(() => successfulRequisition())
      .catch((error) => unsuccessfulRequisition(error.response.data.errors));
  };

  function successfulRequisition() {
    navigate(USERS.HOME);
    swal("Tudo certo!", "Senha alterada com sucesso!", "success");
  }

  function unsuccessfulRequisition(errors) {
    for (let field in errors) {
      errors[field].forEach((message) => {
        setError(field, { type: "manual", message });
      });
    }

    swal(
      "Ops!",
      "Não foi possível alterar sua senha. Por favor, tente novamente!",
      "error"
    );
  }

  useEffect(() => {
    if (!_.isEmpty(data)) submit();
  }, [data]);

  return (
    <form onSubmit={handleSubmit(getData)}>
      <h3>Alterar senha</h3>
      <ResetPassword
        register={register}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
        required
      />
      <button className="button button-primary" type="submit">
        Atualizar
      </button>
    </form>
  );
}

export default ChangePassword;
