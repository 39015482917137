import React from 'react';
import Tests from './Tests';
import Questionnaires from './Questionnaires';
import Description from './Description';
import AutoEnrollInvite from './AutoEnrollInvite';
import { useCandidature } from '../context';

function MainContent(){
  const { candidature } = useCandidature();

  return(
    <div className="main-content">
      <Description />
      {
        candidature.auto_enroll_waiting ? <AutoEnrollInvite />
      : <>
          <Tests />
          <Questionnaires />
        </>
      }
    </div>
  )
}

export default MainContent;