import React from "react";
import Display from "components/filters/Display";
import FilterController from "components/filters/FilterController";
import { Breadcrumb, MainBox } from "components/ui";
import Filters from "./Filters";
import FormAnswer from "./FormAnswer";

function Main() {
  const request = "/v1/internship/company_evaluation/form_answers";

  return (
    <MainBox>
      <Breadcrumb>
        <a>Avaliações de Estágio/Empresa</a>
      </Breadcrumb>
      <FilterController>
        <Filters />
        <Display request={request} component={<FormAnswer />} />
      </FilterController>
    </MainBox>
  );
}

export default Main;
