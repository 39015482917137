import React from "react";
import "./style.scss";

import { Link } from "react-router-dom";
import { Breadcrumb, ContentBox, Loader, MainBox } from "components/ui";
import { INTERNSHIP } from "navigation/ROUTES";
import { useEnvelope } from "./context";
import Header from "./components/Header";
import Content from "./components/Content";

export default () => {
  const { envelope, isLoading } = useEnvelope();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="envelope-show">
      <MainBox>
        <Breadcrumb>
          <Link to={INTERNSHIP.ENVELOPES.OWNED}>Meus Contratos</Link>
          <a>{envelope.company_name}</a>
        </Breadcrumb>
        <ContentBox>
          <Header />
          <Content />
        </ContentBox>
      </MainBox>
    </div>
  );
};
