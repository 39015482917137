import React from "react";
import Fieldset from "../components/Fieldset"

function ProviderLogin() {
  let csrfToken  = document.querySelector('[name=csrf-token]').content

  return (
    <div className="omniauth-login">
      <Fieldset/>
      <Facebook csrfToken={csrfToken} />
      <Linkedin csrfToken={csrfToken} />
    </div>
  );
}

function Facebook(props) {
  return (
    <form method="post" action="/usuarios/auth/facebook">
      <input type="hidden" name="authenticity_token" value={props.csrfToken}  />
      <button className="btn-facebook" type="submit">
        <i className="fab fa-facebook"></i> Continuar com o Facebook
      </button>
    </form>
  );
}

function Linkedin(props) {
  return (
    <form method="post" action="/usuarios/auth/linkedin">
      <input type="hidden" name="authenticity_token" value={props.csrfToken} />
      <button className="btn-linkedin" type="submit">
        <i className="fab fa-linkedin"></i> Continuar com o LinkedIn
      </button>
    </form>
  );
}

export default ProviderLogin;
