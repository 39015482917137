import React, { useState, useEffect } from "react";
import _ from "lodash";
import Axios from "axios";
import Fields from "./Fields";
import { serialize } from "object-to-formdata";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormAnswerInfos } from "./context";

function Form() {
  const [data, setData] = useState({});
  const { pathname } = useLocation();
  const {
    register,
    unregister,
    control,
    handleSubmit,
    setError,
    resetField,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { user_info } =  useFormAnswerInfos();

  const { t } = useTranslation();

  const getData = (collectedData) => {
    disableButton();
    setData(collectedData);
  };

  const successfulRequisition = () => {
    swal(
      t("update_form_answer.form.successfulRequisition.title"),
      t("update_form_answer.form.successfulRequisition.text"),
      t("update_form_answer.form.successfulRequisition.icon")
    ).then(() => window.location.href = '/usuarios/home');
  };

  const unsuccessfulRequisition = (errors) => {
    $(".button:submit").removeAttr("disabled")
    swal(
      t("update_form_answer.form.unsuccessfulRequisition.title"),
      t("update_form_answer.form.unsuccessfulRequisition.text"),
      t("update_form_answer.form.unsuccessfulRequisition.icon")
    );

    for (let field in errors) {
      errors[field].forEach((message) => {
        setError(field, { type: "manual", message });
      });
    }
  };

  const submit = () => {
    const attributes = serialize({
      user_info_id: user_info.user_info_id,
      update_form_answer: data
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Axios.post(pathname, attributes, config)
      .then(() => successfulRequisition())
      .catch((error) => unsuccessfulRequisition(error.response.data.errors));
  };

  const disableButton = () => {
    $(".button:submit").attr("disabled", true);
  }

  useEffect(() => {
    if (!_.isEmpty(data)) submit();
  }, [data]);


  return (
    <form onSubmit={handleSubmit(getData)}>
      <Fields
        register={register}
        unregister={unregister}
        control={control}
        errors={errors}
        resetField={resetField}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
      />
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <button className="button" type="submit">
            {t("update_form_answer.form.submit")}
          </button>
        </div>
      </div>
    </form>
  );
}

export default Form;
