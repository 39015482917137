import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Email from "components/users/fields/essential/Email";
import Password from "components/users/fields/settings/Password";
import { login, handleLogin } from "@root/redux/features/sessions/actions";

function Form() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState({
    grant_type: "password",
    user_type: "user",
    email: "",
    password: "",
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const getData = (collectedData) => {
    setData({ ...data, ...collectedData });
  };

  const unsuccessfulLogin = () => {
    swal("Ops!", "Essa tentativa de login falhou! Verifique o email e a senha digitados e tente novamente.", "error");
    setError("email", { type: "manual" })
    setError("password", { type: "manual" })
  }

  const submit = () => {
    const api = axios.create({ withCredentials: true });
    api
      .post(`/oauth/token`, data)
      .then((resp) => {
        dispatch(login(resp.data)).then(() => {
          handleLogin(resp.data, navigate)
        })
      })
      .catch(() => unsuccessfulLogin());
  };

  useEffect(() => {
    if (!_.isEmpty(data.email) && !_.isEmpty(data.password)) submit();
  }, [data]);

  return (
    <form onSubmit={handleSubmit(getData)}>
      <h3>Faça seu login</h3>
      <Email register={register} errors={errors} label="Email" required />
      <Password register={register} errors={errors} required />
      <ForgotPassword/>
      <button className="button" type="submit">Entrar</button>
    </form>
  );
}

function ForgotPassword(){
  return(
    <>
      <a className="password-link" href="/usuarios/senha/criar">
        Esqueci minha senha
      </a>
    </>
  )
}

export default Form;
