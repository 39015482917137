import React, { useState } from "react";
import { UncontrolledInput as Input } from "components/form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";

function Activity(props) {
  const [value, setValue] = useState();

  const { t } = useTranslation();

  const prefix = props.prefix || {
    name: "activities",
    id: "activities",
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  function renderActivityField() {
    if (value === "1") {
      return (
        <Input
          {...props}
          placeholder={t("update_form_answer.questions.activity.placeholder")}
          type="text"
          multiline
          rows={4}
          name={prefix.name}
          id={prefix.id}
          label={
            props.label || t("update_form_answer.questions.activity.label")
          }
        />
      );
    }
  }

  return (
    <div className="form-group">
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label" color="secondary">
          {t("update_form_answer.questions.activity.question")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            slotProps={{ typography: { variant: "body2" } }}
            value="1"
            control={<Radio color="secondary" />}
            label={t("update_form_answer.questions.activity.radio.yes")}
          />
          <FormControlLabel
            slotProps={{ typography: { variant: "body2" } }}
            value="0"
            control={<Radio color="secondary" />}
            label={t("update_form_answer.questions.activity.radio.no")}
          />
        </RadioGroup>
      </FormControl>
      {renderActivityField()}
    </div>
  );
}

export default Activity;
