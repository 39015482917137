import React from 'react'
import Main from './Main';

function Index() {
  return (
    <div className="company-evaluation-index">
      <Main />
    </div>
  )
}

export default Index
