import React, { useEffect, useState } from "react";
import Course from "components/users/fields/academic/Course";
import FormationDate from "components/users/fields/academic/FormationDate";
import InternshipTypeField from "./components/InternshipTypeField"
import StudySchedule from "components/users/fields/academic/StudySchedule";
import DocumentFiles from "pages/profile/personal_documents/Index/new/fields/DocumentFiles";
import UniversityField from "./components/UniversityField"
import CurrentSemester from "components/users/fields/academic/CurrentSemester";
import SubArea from "./components/SubArea";
import Schedule from "./components/Schedule";
import Activity from "./components/Activity";
import CompanyManager from "./components/CompanyManager";
import HiddenField from "components/form/HiddenField";
import QuestionAnswer from "./components/QuestionAnswer";
import Observation from "./components/Observation";
import { useTranslation } from "react-i18next";
import { useFormAnswerInfos } from "./context";

function Fields(props) {
  const { required_attributes, user_info, category_documents, questions } =  useFormAnswerInfos();

  const [semester, setSemester] = useState(user_info.current_semester);

  const formation_date = user_info.formation_date;
  const course_id = user_info.course_id;

  const { t } = useTranslation();

  const handleSemester = (value) => {
    setSemester(value);
  }

  useEffect(() => {
    props.setValue("current_semester", semester);
  }, [semester])

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h3>{t("update_form_answer.title")}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6">
          <CurrentSemester {...props} defaultValue={semester} handleChange={handleSemester} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <FormationDate {...props} defaultValue={formation_date} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <Course {...props} defaultValue={course_id} required />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6">
          <UniversityField {...props} required />
        </div>
      </div>
      <div className="row">
        {required_attributes?.internship_type === "true" && (
          <div className="col-xs-12 col-sm-6 col-md-6">
            <InternshipTypeField {...props} required />
          </div>
        )}

        {required_attributes?.sub_area === "true" && (
          <div className="col-xs-12 col-sm-6 col-md-6">
            <SubArea {...props} required />
          </div>
        )}

        {required_attributes?.activities === "true" && (
          <div className="col-xs-12 col-sm-6 col-md-6">
            <Activity {...props} required />
          </div>
        )}

        {required_attributes?.schedule_from === "true" && (
          <div className="col-xs-12 col-sm-6 col-md-6">
            <Schedule {...props} required />
          </div>
        )}

        {required_attributes?.company_manager === "true" && (
          <div className="col-xs-12 col-sm-6 col-md-6">
            <CompanyManager {...props} required />
          </div>
        )}
      </div>

      {category_documents.map((ctd, index) => (
          <div className="row">
            <PersonalDocuments {...props} category={ctd} index={index} />
          </div>
        ))
      }

      <div className="row">
        <div className="col-md-12" style={{ padding: 0 }}>
          <StudySchedule {...props} required />
        </div>
      </div>

      {questions.map((question, index) => (
        <div className="row">
          <QuestionAnswer {...props} question={question} index={index} required />
        </div>
      ))}

      <div className="row">
        <div className="col-md-12">
          <Observation {...props} />
        </div>
      </div>
    </>
  );
}

function PersonalDocuments(props) {
  let category = props.category;
  let index = props.index;
  const prefix = `personal_documents_attributes`; 
  const { t } = useTranslation();
  const handleCategory = (value) => {
    props.setValue?.(`${prefix}[${index}][category_document_id]`, value)
  }

  const handleDocumentType = (value) => {
    props.setValue?.(`${prefix}[${index}][document_type]`, value)
  }
  
  return (
    <>
      <br />
      <div className="col-md-12">       
        <h3>{category.name} <abbr title="Obrigatório">*</abbr></h3>
        <p>{t("update_form_answer.questions.categories.label")} {category.name} </p>
      </div>
      <div className="col-md-4">
        <DocumentFiles
        key={category.id}
          {...props}
          prefix={{
            id: `${prefix}_${index}_document_files`,
            name: `${prefix}[${index}][document_files]`,
          }}
        />

        <HiddenField {...props}
          name={`${prefix}[${index}][category_document_id]`} 
          value={category.id}
          handleChange={handleCategory} 
          />

        <HiddenField {...props}
          name={`${prefix}[${index}][document_type]`} 
          value={category.name} 
          handleChange={handleDocumentType}           
          />
      </div>
    </>
  );
}

export default Fields;