import styled from "styled-components";

export const FilterContainer = styled.div`
  min-height: 75px;
  margin: 13px 30px 13px 30px;
  border: 0.5px solid #dde0e3;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  flex-wrap: wrap;
  padding: 8px;

  @media(min-width: 769px) {
    justify-content: flex-start;
    padding: 13px;
  }

  @media(min-width: 1232px) {
    margin: 13px 200px 0 200px;
  }
`

export const FilterItem = styled.a`
  color: ${({selected}) => selected ? "#393367" : "#6a658c"} !important;
  border: none;
  font-size: 17px !important;
  margin: 4px 16px 0 16px;
  justify-content: center;
  text-align: left;
  cursor: pointer;

  :hover {
    color: #393367 !important;
  }

  ::after{
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: linear-gradient(270.39deg, rgba(49, 33, 133, 0.98) 23.86%, rgba(55, 40, 130, 0.95) 56.44%, rgba(58, 44, 128, 0.93) 79.2%, rgba(60, 47, 126, 0.92) 99.22%);
    border-radius: 10px;
  }

  :hover::after {
    width: 40%;
    transition: width .7s;
  }
`

export const DivWithoutCourses = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media(min-width: 1280px){
    justify-content: center;
  }
`
export const WithoutCourses = styled.h1`
  font-size: 20px;
  color: #C13950;
  padding: 24px 25px;
`