import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useMyCourses(id) {
  const { data, isLoading } = useFetch(`/v1/academy/courses/meus/${id}`);
 
  return {
    finished: data?.finished.data,
    unfinished: data?.unfinished.data,
    studyTracks: data?.study_tracks.data,
    isLoading: isLoading,
  };
}