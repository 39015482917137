import React from "react";
import { File } from "components/form";

function DocumentFiles(props) {
  const prefix = props.prefix || {
    id: "document_files",
    name: "document_files",
  };

  return (
    <div className="form-group">
      <File
        {...props}
        multiple
        required
        id={prefix.id}
        name={prefix.name}
        label="Formatos permitidos: PDF / PNG / JPG / JPEG"
        buttonLabel="Adicionar arquivos"
        accept='application/pdf, image/*'
      />
    </div>
  );
}

export default DocumentFiles;
