import React, { useContext } from "react";
import { Filter } from "components/ui";
import { FilterContext } from "components/filters/FilterController";
import { useFilters } from "utils/hooks";
import { MenuItem, TextField } from "@mui/material";

function Filters() {
  const { infos, filterData } = useContext(FilterContext);

  const params = { company: "", status: "" };

  const {
    filters,
    handleChange,
    createFilterLabel,
    renderFilters,
    handleSelect,
  } = useFilters(params, filterData);

  return (
    <Filter
      length={infos.length}
      totalCount={infos.totalCount}
      renderFilters={renderFilters}
    >
      <Company
        value={filters.company}
        onChange={handleChange}
        onBlur={createFilterLabel}
      />
      <Status value={filters.status} onChange={handleSelect} />
    </Filter>
  );
}

function Company(props) {
  return (
    <TextField
      filter
      variant="standard"
      id="company"
      name="company"
      label="Empresa"
      placeholder="Digite o nome da empresa..."
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
    />
  );
}

function Status(props) {
  const statuses = [
    { value: 0, label: "Não iniciado" },
    { value: 1, label: "Em andamento" },
    { value: 2, label: "Concluído" },
  ];

  const _renderOptions = () => {
    const list = statuses || [];
    return list.map((status) => (
      <MenuItem key={status.value} value={status.value}>
        {status.label}
      </MenuItem>
    ));
  };

  return (
    <TextField
      select
      filter
      variant="standard"
      id="status"
      name="status"
      label="Status"
      placeholder="Selecione..."
      value={props.value}
      onChange={(event) => props.onChange(event, statuses)}
    >
      {_renderOptions()}
    </TextField>
  );
}

export default Filters;
