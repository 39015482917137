import React from "react";
import { SelectInput } from "components/form";
import { useStaticCollection } from "utils/hooks";
import { getCollection } from "utils/getCollection";
import { pathWithParams } from 'utils/pathWithParams';
import _ from "lodash";

function Category(props) {
  const [categories, setCategories] = React.useState([]);
 React.useEffect(() => {
  if (_.isEmpty(categories)){
   getCollection( 
    pathWithParams("/v1/profile/users/category_documents.json", {uuid: props.uuid}), 
    setCategories);
    }
  },[categories]);
  
 const prefix = props.prefix || {
    id: "category",
    name: "category",
  };
  return (
    <div className="form-group">
      <SelectInput
        {...props}
        required
        placeholder="Selecione..."
        name={prefix.name}
        id={prefix.id}
        collection={categories}
        label="Categoria"
      />
    </div>
  );
}

export default Category;
