import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { UncontrolledInput as Input } from "components/form";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";

function Schedule(props) {
  const [value, setValue] = useState();

  const { t } = useTranslation();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  function renderScheduleFields() {
    if (value === "1") {
      return (
        <div className="form-group">
          <Input
            {...props}
            placeholder={t(
              "update_form_answer.questions.schedule.schedule_from"
            )}
            type="text"
            name="schedule_from"
            id="schedule_from"
            label={t("update_form_answer.questions.schedule.from")}
            required
          />
          <Input
            {...props}
            placeholder={t("update_form_answer.questions.schedule.schedule_to")}
            type="text"
            name="schedule_to"
            id="schedule_to"
            label={t("update_form_answer.questions.schedule.to")}
            required
          />
        </div>
      );
    }
  }

  return (
    <div className="form-group">
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label" color="secondary">
          {t("update_form_answer.questions.schedule.question")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            slotProps={{ typography: { variant: "body2" } }}
            value="1"
            control={<Radio color="secondary" />}
            label={t("update_form_answer.questions.schedule.radio.yes")}
          />
          <FormControlLabel
            slotProps={{ typography: { variant: "body2" } }}
            value="0"
            control={<Radio color="secondary" />}
            label={t("update_form_answer.questions.schedule.radio.no")}
          />
        </RadioGroup>
      </FormControl>
      {renderScheduleFields()}
    </div>
  );
}

export default Schedule;
