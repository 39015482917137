import React from 'react';
import Display from 'components/filters/Display';
import FilterController from 'components/filters/FilterController';
import { Breadcrumb, MainBox } from 'components/ui';
import Filters from './Filters';
import Candidature from './Candidature';

function Main(){

  const request = "/v1/ats/candidatures";

  return(
    <MainBox>
      <Breadcrumb>
        <a>Minhas candidaturas</a>
      </Breadcrumb>
      <FilterController>
        <Filters />
        <Display request={request} component={<Candidature />} />
      </FilterController>
    </MainBox>
  )
}

export default Main;