import React from "react";
import { ContentBox } from "components/ui";
import Header from "./Header";
import TabContent from "./tabs/TabContent";

function MainSection() {
  return (
    <ContentBox>
      <Header />
      <TabContent />
    </ContentBox>
  );
}

export default MainSection;
