import styled from "styled-components";
import MuiChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiSignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import MuiAccessTimeIcon from "@mui/icons-material/AccessTime";
import MuiArticleIcon from "@mui/icons-material/Article";
import { styled as styledMui } from '@mui/system';

export const Carousel = styled.section`
  width: 100%; 
  max-width: 1632px;
  margin: 0 auto;
  height: 700px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 8% 572px 8% auto;

  @media (max-width: 1632px) {
    overflow-x: hidden;
  };

  @media (max-width: 1080px) {
    grid-template-columns: auto 8% 512px 8% auto;
  };

  @media (max-width: 820px) {
    grid-template-columns: auto 8% 424px 8% auto;
  };

  @media (max-width: 600px) {
    grid-template-columns: auto 8% 354px 8% auto;
    height: 560px;
  };

  @media (max-width: 494px) {
    grid-template-columns: auto 7% 292px 7% auto;
    height: 480px;
  };

  @media (max-width: 406px) {
    grid-template-columns: auto 6% 256px 6% auto;
    height: 360px;
  };
`;

export const Container = styled.div`
  width: ${(props) => props.isCenter ? '31em' : '25em'}; 
  height: ${(props) => props.isCenter ? '31em' : '25em'}; 
  box-shadow: 0px 0px 31px 2px #0a0a0a14;
  border-radius: 10px;
  background-color: #ffffff;
  justify-self: center;
  grid-row: 1/-1;
  transition: all 0.1s ease;
  cursor: pointer;
  grid-column: ${(props) => (props.isCenter ? '3/4' : (props.isFirst ? '1/2' : (props.isLast ? '5/-1' : 'initial')))};

  @media (max-width: 1632px) {
    margin-left: ${(props) => props.isFirst && '-240px'};
    margin-right: ${(props) => props.isLast && '-240px'};
  };

  @media (max-width: 1080px) {
    width: ${(props) => props.isCenter && '31em'};
    height: ${(props) => props.isCenter && '31em'}; 
    margin-left: ${(props) => props.isFirst && '-360px'};
    margin-right: ${(props) => props.isLast && '-360px'}; 
  };

  @media (max-width: 820px) {
    width: ${(props) => props.isCenter ? '24em' : '15em'};
    height: ${(props) => props.isCenter ? '24em' : '15em'}; 
    margin-left: ${(props) => props.isFirst && '-200px'};
    margin-right: ${(props) => props.isLast && '-200px'};
  };

  @media (max-width: 600px) {
    width: ${(props) => props.isCenter ? '20em' : '12em'};
    height: ${(props) => props.isCenter ? '298px' : '202px'}; 
    margin-left: ${(props) => props.isFirst && '-200px'};
    margin-right: ${(props) => props.isLast && '-200px'};
  };

  @media (max-width: 494px) {
    width: ${(props) => props.isCenter ? '15em' : '8em'};
    height: ${(props) => props.isCenter ? '224px' : '152px'}; 
    margin-left: ${(props) => props.isFirst && '-100px'};
    margin-right: ${(props) => props.isLast && '-100px'};
  };

  @media (max-width: 406px) {
    width: ${(props) => props.isCenter ? '13em' : '6em'};
    height: ${(props) => props.isCenter ? '194px' : '112px'}; 
    margin-left: ${(props) => props.isFirst && '-100px'};
    margin-right: ${(props) => props.isLast && '-100px'};
  };
`;

export const Image = styled.img`
  height: 68%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

export const TextContainer = styled.div`
  display: grid;
  grid-template-rows: 70% 30%;
  padding: 5px 15px;
  height: 31%;
  
`;

export const InfosContainer = styled.div`
  display: grid;
  width: 100%;
  grid-row: 2/-1;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 820px) {
    display: ${(props) => props.isFirst &&  'none'};
    display: ${(props) => props.isLast &&  'none'};
  };

`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 3px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  justify-self: center;

  p {
    margin-top: 2px;
  }

  @media (max-width: 820px) {
    font-size: 16px;
  };

  @media (max-width: 600px) {
    font-size: 14px;
  };

  @media (max-width: 494px) {
    font-size: 10px;
  };

  @media (max-width: 406px) {
    font-size: 8px;
  };
`;

export const Title = styled.h5`
  height: 100%;
  width: 87%;
  font-size: 1.25em;
  text-align: left;
  font-weight: 700;
  grid-row: 1/2;
  padding: 5px;
  padding-top: 12px;

  font-size: ${(props) => props.isFirst &&  '1.1em'};
  font-size: ${(props) => props.isLast &&  '1.1em'};
  width: ${(props) => props.isFirst &&  '92%'};
  width: ${(props) => props.isLast &&  '92%'};

  @media (max-width: 820px) {
    font-size: 1.1em;
    width: 94%;
    font-size: ${(props) => props.isFirst &&  '0.9em'};
    font-size: ${(props) => props.isLast &&  '0.9em'};
  };

  @media (max-width: 600px) {
    font-size: 0.9em;
    width: 100%;
    padding-bottom: 0;
    display: ${(props) => props.isFirst &&  'none'};
    display: ${(props) => props.isLast &&  'none'};
  };

  @media (max-width: 494px) {
    font-size: 0.7em;
    padding: 1px 1px 1px 0;
  };

  @media (max-width: 406px) {
    font-size: 0.6em;
  };
`;

export const ChevronRightIcon = styledMui(MuiChevronRightIcon)(({ theme }) => ({
  fontSize: '44px',
  padding: '12px',
  color: '#000000A6',
  border: '1px solid #00000030',
  borderRadius: "50%",
  cursor: 'pointer',
  boxShadow: '0px 2px 3px 0px #0000002B',
  marginLeft: '24px',
  gridColumn: '4/5',
  gridRow: '1/-1',

  '@media (max-width: 820px)': {
    fontSize: '32px',
    padding: '7px',
    marginLeft: "0px",
  },

  '@media (max-width: 494px)': {
    fontSize: '24px',
    padding: '4px',
    marginLeft: "-16px",
  },
}));

export const ChevronLeftIcon = styledMui(MuiChevronLeftIcon)(({ theme }) => ({
  fontSize: '44px',
  padding: '12px',
  color: '#000000A6',
  border: '1px solid #00000030',
  borderRadius: "50%",
  cursor: 'pointer',
  boxShadow: '0px 2px 3px 0px #0000002B',
  marginRight: '24px',
  gridColumn: '2/3',
  justifySelf: 'flex-end',
  gridRow: '1/-1',

  '@media (max-width: 820px)': {
    fontSize: '32px',
    padding: '7px',
    marginRight: "0px",
  },

  '@media (max-width: 494px)': {
    fontSize: '24px',
    padding: '4px',
    marginRight: '-16px',
  },
}));

export const SignalCellularAltIcon = styledMui(MuiSignalCellularAltIcon)(({ theme }) => ({
  width: '0.6em',
  height: '0.9em',

  '@media (max-width: 820px)': {
    height: '0.7em',
  },

  '@media (max-width: 494px)': {
    height: '0.6em',
  },

  '@media (max-width: 406px)': {
    height: '0.5em',
  },
}));

export const AccessTimeIcon = styledMui(MuiAccessTimeIcon)(({ theme }) => ({
  width: '0.6em',
  height: '0.9em',

  '@media (max-width: 820px)': {
    height: '0.7em',
  },

  '@media (max-width: 494px)': {
    height: '0.6em',
  },

  '@media (max-width: 406px)': {
    height: '0.5em',
  },
}));

export const ArticleIcon = styledMui(MuiArticleIcon)(({ theme }) => ({
  width: '0.6em',
  height: '0.9em',

  '@media (max-width: 820px)': {
    height: '0.7em',
  },

  '@media (max-width: 494px)': {
    height: '0.6em',
  },

  '@media (max-width: 406px)': {
    height: '0.5em',
  },
}));
