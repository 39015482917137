import React from "react";
import Card from "./card/Card";
import CardImage from "./card/CardImage";
import CardDivTitle from "./card/CardDivTitle";
import CardInfos from "./card/CardInfos";
import CardTitle from "./card/CardTitle";
import ProgressBar from "./card/ProgressBar";
import CardInfosSecondary from "./card/CardInfosSecondary";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArticleIcon from "@mui/icons-material/Article";
import { useNavigate } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";
import { ACADEMY } from "navigation/ROUTES";
import { useLocation } from "react-router-dom";

function Course({ item }) {
  const course = item;
  const location = useLocation();
  const isOwnedCourse = location.pathname === ACADEMY.COURSE.OWNED;
  
  let navigate = useNavigate();

  return (
    <Card>
      <CardImage
        image={course.attributes.image}
        onClick={() =>
          navigate(
            pathWithParams(ACADEMY.COURSE.SHOW, { id: course.attributes.slug })
          )
        }
      />
      <CardInfos className={isOwnedCourse ? "" : "noProgress"}>
        <CardDivTitle>
          <p className="area">{course.attributes.area}</p>
          <CardTitle
            onClick={() =>
              navigate(
                pathWithParams(ACADEMY.COURSE.SHOW, {
                  id: course.attributes.slug,
                })
              )
            }
          >
            {course.attributes.title}
          </CardTitle>
        </CardDivTitle>
        {isOwnedCourse && <ProgressBar percentage={course.attributes.progress || 0} isVisible={isOwnedCourse} />}
        <CardInfosSecondary>
          <AccessTimeIcon style={{ width: "0.6em", height: "0.6em" }} />
          <p>{course.attributes.duration}</p>
          <ArticleIcon style={{ width: "0.6em", height: "0.6em" }} />
          <p>{course.attributes.total_section_items} aulas</p>
          <SignalCellularAltIcon style={{ width: "0.6em", height: "0.6em" }} />
          <p>{course.attributes.difficulty}</p>
        </CardInfosSecondary>
        <p
          className="see_course"
          onClick={() =>
            navigate(
              pathWithParams(ACADEMY.COURSE.SHOW, {
                id: course.attributes.slug,
              })
            )
          }
        >
          VER CURSO
        </p>
      </CardInfos>
    </Card>
  );
}

export default React.memo(Course);
