import React, { useEffect, useState } from "react";
import _ from "lodash";
import Terms from "./Terms";
import { useConvention, userInfoAttributes } from "./context";

function Fields(props) {
  const { convention } = useConvention();

  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (_.isEmpty(fields)){
      let list = convention.user_info_required_attributes.map((field) => React.cloneElement(userInfoAttributes[field], {...props, required: field != ("disabilities", "cellphone")}, null));
      
      setFields(list);
    }
  }, [convention])

  const renderUserInfoFields = () => {
    return fields.map((field, index) => (
      <div key={index} className="col-xs-12 col-sm-12 col-md-12">
        {field}
      </div>
    ))
  }

  return (
    <>
      <div className="row">
        {renderUserInfoFields()}
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <Terms {...props} required />
        </div>
      </div>
    </>
  );
}

export default Fields;
