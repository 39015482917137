import React from "react";

export default (props) => {
  const { job, candidature } = props;

  return(
    <>
      <p>
        <b>Processo:</b>
        <br /> {candidature.ats_type}
      </p>
      <p>
        <b>Status:</b>
        <br /> {candidature.status}
      </p>
      {job.external ? (
        <ExternalInfo job={job} />
      ) : (
        <p>
          <b>Etapa atual:</b>
          <br /> {candidature.current_phase}
        </p>
      )}
    </>
  )
}

function ExternalInfo({ job }) {
  let contact = job.contact;
  let info = { label: "Site do processo:", text: "Clique aqui" };
  
  if (contact.match(/@/g)) {
    contact = `mailto:${contact}`;
    info = { label: "Contato:", text: job.contact };
  } else if (!contact.match(/(http|https):\/\//g)) {
    contact = `https://${contact}`;
  }

  return (
    <p>
      <b>{info?.label}</b>
      <br />
      <a href={contact} target="_blank" className="external-link">
        {info?.text}
      </a>
    </p>
  );
}
