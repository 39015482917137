import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import Benefit from "./Benefit";
import styled from "styled-components";
import { USERS } from "navigation/ROUTES";

const Paragraph = styled.p`
  & {
    text-align: center;
  }
`;

function List() {
  const [benefits, setBenefits] = useState([]);
  let navigate = useNavigate();

  const getBenefits = () => {
    api
      .get("/v1/profile/users/benefits")
      .then((resp) => {
        let list = resp.data;
        if (!_.isEmpty(list)) setBenefits(list);
      })
      .catch((error) => {
        swal("Ops!", error.response.data?.message, "warning");
        navigate(USERS.WIZARD);
      });
  };

  const renderBenefits = () => {
    return benefits.map((benefit) => (
      <Benefit key={benefit.id} attributes={benefit} />
    ));
  };

  useEffect(() => {
    if (_.isEmpty(benefits)) getBenefits();
  }, [benefits]);

  return (
    <section>
      <h2>Clube de Benefícios do WallJobs</h2>
      <p>
        O WallJobs preparou um clube para você aproveitar todos os benefícios em
        nossa plataforma!
      </p>
      <p>
        Através dessa página você terá acesso a códigos especiais para adquirir
        desconto em diversas lojas e plataformas de cursos!
      </p>
      <br />
      <br />
      {_.isEmpty(benefits) ? (
        <Paragraph>
          <b>
            Infelizmente, não temos benefícios válidos para você no momento...
          </b>{" "}
          &#128531;
        </Paragraph>
      ) : (
        <>
          <h3>Confira nossos parceiros:</h3>
          <div className="benefits-list">{renderBenefits()}</div>
        </>
      )}
    </section>
  );
}

export default List;
