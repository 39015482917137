import React from "react";

function WhyWork({why_work}) {

  if(!why_work){
    return;
  }

  return (
    <>
      <h3>Porque trabalhar conosco</h3>
      <p dangerouslySetInnerHTML={{ __html: why_work }}></p>
    </>
  );
}

export default WhyWork;
