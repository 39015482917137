import React from "react";
import { Container } from "./styles";

function Box(props){
  
  return (
    <Container display={props.display}>
      {props.children}
    </Container>
  )
}

export default Box;