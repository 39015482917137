import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";
import Name from "./SupervisorInfos/Name";
import Email from "./SupervisorInfos/Email";
import JobTitle from "./SupervisorInfos/JobTitle";

export default (props) => {
  const [value, setValue] = React.useState();
  const [supervisor, setSupervisor] = React.useState({});

  const { t } = useTranslation();

  const handleSupervisorChange = (manager) => {
    setSupervisor(manager || {});
  };

  const renderSupervisorInfos = () => {
    if (!value) return;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          margin: "15px 0px",
        }}
      >
        <h4>
          {t("update_form_answer.questions.company_manager.supervisor_infos")}
        </h4>
        <Name {...props} handleSupervisorChange={handleSupervisorChange} />
        <Email {...props} defaultValue={supervisor.email} />
        <JobTitle {...props} defaultValue={supervisor.job_title} />
      </div>
    );
  };

  return (
    <div className="form-group">
      <FormControl>
        <FormLabel color="secondary">
          {t("update_form_answer.questions.company_manager.question")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="changed_supervisor"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          <FormControlLabel
            slotProps={{ typography: { variant: "body2" } }}
            value={true}
            control={<Radio color="secondary" />}
            label={t("update_form_answer.questions.company_manager.radio.yes")}
          />
          <FormControlLabel
            slotProps={{ typography: { variant: "body2" } }}
            value={false}
            control={<Radio color="secondary" />}
            label={t("update_form_answer.questions.company_manager.radio.no")}
          />
        </RadioGroup>
      </FormControl>
      {renderSupervisorInfos()}
    </div>
  );
};
