import styled from "styled-components";

export const StudyTrack = styled.div`
  font-family: "Open Sans", sans-serif !important;
`

export const Container = styled.section`
  margin: 45px 45px;

  @media(min-width: 1280px){
    max-width: 1280px;
    margin-left: calc(50vw - 600px);
    margin-right: calc(50vw - 600px);
  }
`

export const HeaderStudyTrack = styled.div`
  width: 100%;
  min-height: 520px;
  background: linear-gradient(270.39deg, rgba(49, 33, 133, 0.98) 23.86%, rgba(55, 40, 130, 0.95) 56.44%, rgba(58, 44, 128, 0.93) 79.2%, rgba(60, 47, 126, 0.92) 99.22%);
  display: flex;
  flex-direction: column;
  margin: 0 !important;

  @media(min-width: 1280px){
    min-height: 0;
  }
`

export const AboutTrack = styled.section`
  display: flex;
  flex-direction: column;
  color: white;
  padding: 55px 0 75px;

  @media(min-width: 1280px){
    position: relative;
  }
`

export const AboutTrackLogo = styled.img`
  width: 83px;
  height: 83px;
  margin-bottom: 10px;
  
  @media(min-width: 1280px){
    position: absolute;
    width: 150px;
    height: 150px;
    right: 0;
    top: 13%;
  }
`

export const AboutTrackTitle = styled.h1`
  font-size: 28px;
  font-weight: 800;

  @media(min-width: 1280px){
    font-size: 42px;
    margin: 0 !important;
  }
`

export const AboutTrackDescription = styled.h2`
  max-width: 400px;
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
  line-height: 1.48;

  @media(min-width: 1280px){
    font-size: 23px;
    margin: 0 !important;
  }
`

export const Progress = styled.section`
  display: flex;
  flex-direction: column;
  color: white;
  padding: 45px 0 75px;

  @media(min-width: 1280px){
    flex-direction: row;
  }
`

export const ProgressFinished = styled.div`
  padding: 1.9em 1.25em;
  border: 1px solid hsla(0,0%,100%,.3);
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const ProgressBarDiv = styled.div`
  max-width: 70px;
`

export const ProgressInfosDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-family: "Open Sans" !important;
`

export const InfoQuantityFinished = styled.span`
  font-size: 16px !important;
  font-family: "Open Sans" !important;
`

export const InfoQuantityNumber = styled.span`
  font-weight: 700;
  font-family: "Open Sans" !important;
`

export const AddButtonDiv = styled.div`
  padding: 1.9em 1.25em;
  border: 1px solid hsla(0,0%,100%,.3);
`

export const AddButton = styled.button`
  padding: 0.625em;
  border-radius: 27px;
  border: 1px solid #051933;
  background-color: #051933;
  color: white;
  width: 100%;
  height: 3em;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
`

export const InfosContainer = styled.section`
  background-color: white;
  padding: 30px 0;

  @media(min-width: 1280px){
    padding-left: calc(50vw - 600px);
    padding-right: calc(50vw - 600px);
  }
`

export const Infos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 40px;
  max-width: 800px;
  justify-content: space-between;
  align-items: center;

  @media(min-width: 1280px){
    justify-content: flex-start;
    margin: 0;
  }
`

export const InfosItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;

  @media(min-width: 1280px){
    min-width: 80px;
    max-width: 150px;
    border-right: 2px solid #eff1f9;
    margin-right: 32px;
  }
`

export const InfosItemLogo = styled.div`
  width: 24px;
  margin-right: 9px;
  display: flex;
  align-items: center;
`

export const InfosItemValueAndLabel = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfosItemValue = styled.span`
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  font-family: "Open Sans" !important;
`

export const InfosItemLabel = styled.p`
  font-size: 11px;
  margin: 0;
  padding: 0;
  font-family: "Open Sans" !important;
`

export const ContentTitle = styled.h4`
  font-size: 27px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(49, 33, 133, 0.98);
  font-family: "Open Sans" !important;
`

export const ContentText = styled.div`
  font-size: 14px;
  color: #516a84;
  font-family: "Open Sans" !important;
`

export const ContentImage = styled.img`
  width: 100%;
  margin: 10px 0;
`

export const ContentVideo = styled.div`
  width: 100%;
  margin: 10px 0;
  height: 300px;

  @media(min-width: 1280px){
    height: 820px;
  }
`

export const Sections = styled.section`
  background-color: white;
  box-shadow: 0 2px 9px 0 rgba(101,103,122,.1);
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  padding: 30px 0 20px;
  margin: 0 15px;

`

export const SectionInfos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SectionNumber = styled.div`
  width: 35px;
  height: 35px;
  color: rgba(49, 33, 133, 0.98);
  box-shadow: 0 2px 9px 0 rgba(101,103,122,.1);
  flex-shrink: 0;
  border-radius: 50%;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-right: 15px;
  line-height: 35px;
`

export const SectionTitle = styled.h4`
  font-size: 16px;
  color: rgba(49, 33, 133, 0.98);
  margin: 0;
`

export const SectionDescription = styled.span`
  font-size: 14px;
  color: #516a84;
  margin: 16px 0;
  font-family: "Open Sans" !important;
`

export const CourseCard = styled.div`
  width: 100%;
  max-height: 66px;
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 6px 15px 11px 11px;
  border-left: 4px solid rgba(49, 33, 133, 0.98);
  margin: 10px 0;
  align-items: center;
  box-shadow: inset 0 0 0 1px #e8eef4, 0 1px 4px 0 rgba(101,103,122,.06);

  @media(min-width: 856px){
    padding: 15px 0 15px 14px;
  }

  :hover{
    background-color: #e8eef4;
    cursor: pointer;
    transition: background-color .5s ease;
  }

`

export const CourseImage = styled.img`
  width: 35px;
  height: 35px;

  @media(min-width: 856px){
    width: 40px;
    height: 40px;
  }
`

export const CourseDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 11px;
  align-items: flex-start;
  overflow: hidden;
  white-space: nowrap;

  @media(min-width: 856px){
    position: relative;
    margin-top: 0;

    span {
      margin: 0 !important;
    }
  }
`

export const Course = styled.span`
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
  color: #65677a;
  font-family: "Open Sans" !important;
  margin: 0;
`

export const CourseTitle = styled.span`
  color: #516a84;
  margin: 2px 0 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Open Sans" !important;
`

export const EndSection = styled.hr`
  width: 100%;
`