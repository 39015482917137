import React, { useEffect, useState } from "react";
import { TextField, MenuItem } from "@mui/material";
import { useFilters } from "utils/hooks";
import { Filter } from "components/ui";
import { FilterContext } from "components/filters/FilterController";
import { getCollection } from "utils/getCollection";

function Filters() {
  const { infos, filterData } = React.useContext(FilterContext);

  const { filters, handleChange, createFilterLabel, renderFilters, handleSelect, } =
    useFilters({ title: "", course_area: "", difficulty: "" }, filterData);

  const [areas, setAreas] = useState([]);
  const [difficulties, setDifficulties] = useState([]);


  useEffect(() => {
    if (areas.length === 0) {
      getAreas();
    }else if(difficulties.length === 0) {
      getDifficulties();
    }
  });

  function getAreas(){
    getCollection('/selects/academy_course_areas.json', setAreas)
  }

  function getDifficulties(){
    getCollection('/selects/academy_course_difficulties.json', setDifficulties)
  }

  function _renderAreas() {
    const list = areas || [];
    
    return list.map((area) => (
      <MenuItem key={area.value} value={area.value}>
        {area.label}
      </MenuItem>
    ));
  }

  function _renderDifficulties() {
    const list = difficulties || [];
    
    return list.map((difficulty) => (
      <MenuItem key={difficulty.value} value={difficulty.value}>
        {difficulty.label}
      </MenuItem>
    ));
  }

  return (
    <Filter
      length={infos.length}
      totalCount={infos.totalCount}
      renderFilters={renderFilters}
    >
      <TextField
        filter
        variant="standard"
        id="title"
        name="title"
        label="Curso"
        placeholder="Ex: Javascript Intermediário..."
        value={filters.course_name}
        onChange={handleChange}
        onBlur={createFilterLabel}
      />

      <TextField
        select
        filter
        variant="standard"
        id="course_area"
        name="course_area"
        label="Área do curso"
        value={filters.area}
        onChange={(event) => handleSelect(event, areas)}
      >
        {_renderAreas()}
      </TextField>

      <TextField
        select
        filter
        variant="standard"
        id="difficulty"
        name="difficulty"
        label="Nível"
        value={filters.course_name}
        onChange={(event) => handleSelect(event, difficulties)}
        onBlur={createFilterLabel}
      >
        {_renderDifficulties()}
      </TextField>
    </Filter>
  );
}

export default Filters;
