import React from "react";
import Company from "./Company";
import Display from "components/filters/Display";

function Companies() {
  const request = "/v1/profile/companies";
  return <Display request={request} component={<Company />} />;
}

export default Companies;
