import React from "react";

function InternshipLaw(){    
  return (    
    <p style={{color:'red', fontWeight: "bold"}}>
      De acordo com a Lei do Estágio, é imprescindível que não exista um contrato de estágio ativo com outra empresa para iniciar uma nova experiência de estágio. Portanto, é fundamental que você envie o documento de rescisão do seu estágio atual para a Instituição de Ensino em que você estuda o mais breve possível. Caso tenha quaisquer dúvidas, não hesite em entrar em contato com o suporte do WallJobs (<a href="https://www.walljobs.com.br/usuarios/entrar">suporte@walljobs.com.br</a>) para obter assistência.
    </p>        
  )
}

export default InternshipLaw;
