import React from "react";
import {
  Main,
  Title,
  Description,
  TutorialContainer,
  Warning,
  WarningTitle,
  WarningAmberIcon,
  Tutorial,
  TutorialTitle,
  TutorialDescription,
  Button
} from "./styles";

export default (props) => {
  const { test, startTest } = props;

  return (
    <Main>
      <Title>{test.title}</Title>
      <Description dangerouslySetInnerHTML={{ __html: test.description }} />
      <TutorialContainer>
        <Warning>
          <WarningTitle>Atenção</WarningTitle>
          <WarningAmberIcon />
        </Warning>
        <Tutorial>
          <TutorialTitle>1. Questão de resposta única</TutorialTitle>
          <TutorialDescription>
            Você encontrará perguntas de múltipla escolha, mas deve escolher
            apenas uma resposta correta.
          </TutorialDescription>
          <TutorialDescription>
            <strong>Instruções:</strong> Selecione a opção que melhor responde à
            pergunta e clique em "Enviar resposta”.
          </TutorialDescription>
        </Tutorial>
        <Tutorial>
          <TutorialTitle>2. Questão de resposta múltipla</TutorialTitle>
          <TutorialDescription>
            Você encontrará perguntas de múltipla escolha, você pode escolher
            múltiplas respostas corretas.{" "}
          </TutorialDescription>
          <TutorialDescription>
            <strong>Instruções:</strong> Marque todas as opções que considerar
            corretas e clique em “Enviar resposta”.
          </TutorialDescription>
        </Tutorial>
        <Tutorial>
          <TutorialTitle>3. Cronômetro</TutorialTitle>
          <TutorialDescription>
            Nosso questionário inclui um cronômetro para monitorar o tempo de
            conclusão.
          </TutorialDescription>
          <TutorialDescription>
            <strong>Instruções:</strong> Ao iniciar o teste, o cronômetro
            começará a contar automaticamente. Esteja ciente do tempo disponível
            para completar o questionário.
          </TutorialDescription>
        </Tutorial>
        <Tutorial>
          <TutorialTitle>4. Restrições do teste</TutorialTitle>
          <TutorialDescription>
            Durante o teste, é fundamental que você não feche o navegador ou a
            janela do teste.
          </TutorialDescription>
          <TutorialDescription>
            <strong>Instruções:</strong> Evite fechar acidentalmente a janela do
            navegador, pois isso pode resultar na perda do progresso e na
            finalização do teste.
          </TutorialDescription>
        </Tutorial>
      </TutorialContainer>
      <Button onClick={startTest}>Começar teste</Button>
    </Main>
  );
};
