import React from "react";
import _ from "lodash";
import { useUserInfo } from "../../context";
import { EditIcon } from "components/users/EditMode";
import { NotInformed } from "components/ui";

function Educations() {
  const { getIncluded } = useUserInfo();
  const educations = getIncluded("education");
  const hasEducations = !_.isEmpty(educations);

  const _renderEducations = () => {
    const list = educations || [];
    return list.map(({ id, attributes }) => (
      <div key={id} className="education">
        <h3>{attributes.institution_name}</h3>
        <h4>{attributes.course_name}</h4>
        <p>
          <b>Tipo: </b> {attributes.type}
        </p>
        <p>
          <b>Duração: </b> {attributes.duration}
        </p>
      </div>
    ));
  };

  return (
    <section className="educations">
      <hr />
      <EditIcon type="education" />

      <h3 className="section-title">Outras Formações</h3>
      {hasEducations ? _renderEducations() : <NotInformed />}
    </section>
  );
}

export default Educations;
