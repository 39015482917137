import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import JobLabel from "components/ats/JobLabel";
import BackBreadcrumb from "./BackBreadcrumb";
import IesExclusivity from "./IesJob/IesExclusivity";
import { useJob } from "./context";
import candidatureProcess from "./candidatureProcess";
import VerifiedIcon from '@mui/icons-material/Verified';
import { pathWithParams } from "utils/pathWithParams";
import { COMPANIES, INTERNSHIP_PROGRAMS } from "navigation/ROUTES";
import { ContentBoxHeaderButtons } from "components/ui";

function Header(props) {
  const { job, company, university } = useJob();
  const { isLogged, updatedProfileAt } = props;

  const CandidatureProcess = candidatureProcess({ job, updatedProfileAt });

  const redirectOrCandidate = () => {
    if (!isLogged) {
      CandidatureProcess.unauthenticated();
    } else {
      CandidatureProcess.applyCandidature();
    }
  };

  const candidatureButton = (job) => {
    return (
      <button
        className={`button button-${job.state}`}
        onClick={redirectOrCandidate}
        disabled={job.state == "closed"}
      >
        {candidatureText(job)}
      </button>
    );
  };

  const candidatureText = (job) => {
    switch (job.state) {
      case "closed":
        return "Inscrições Encerradas";
      case "started":
        return "Quero me candidatar";
    }
  };

  return (
    <>
      <header className="content-box-header">
        <div className="content-box-header-infos">
          <div className="images">
            <img
              className="logo logo-company"
              src={job.avatar}
              alt={`Logo de ${company.name}`}
            />

            {job.ies_exclusive && <img
              className="logo logo-university"
              src={university.avatar}
              alt={`Logo de ${university.name}`}
            />}
          </div>
          <div>
            <h3>
              {job.author_name}{" "}
              {job.ies_exclusive && (
                <>
                  <small style={{ color: "#959aad" }}>&</small>{" "}
                  {university.root_name}
                </>
              )}
            </h3>
            <h1>{job.title}</h1>
            <h4>{company.sector_and_location}</h4>
            {job.program_exclusive && <p><VerifiedIcon fontSize='small' sx={{marginRight: '5px'}} /> Faz parte de um Programa de estágio</p>}
            {job.ies_exclusive && <IesExclusivity />}
            <p>{job.workplace_type}</p>
            {job.ies_exclusive && <p className="job-salary">
              <i className="material-icons">attach_money</i> {job.salary}
            </p>}
            <p className="company-employees">
              <b>{company.employees_number}</b>
            </p>
            <p className="job-address">
              <i className="material-icons">place</i> {job.address}
            </p>
          </div>
        </div>

        <div className="content-box-header-label">
          <JobLabel job={job} />
        </div>

        <ContentBoxHeaderButtons>
          {candidatureButton(job)}
          {job.program_exclusive && (
            <Link
            className="button button-program"
            target="_blank"
            to={pathWithParams(INTERNSHIP_PROGRAMS.SHOW, { id: job.internship_program_id })}
            >
            Página do Programa
            </Link>
          )}
          {!job.confidential && (
            <Link
              className="button button-secondary"
              to={pathWithParams(COMPANIES.SHOW, { id: company.slug })}
            >
              Página da empresa
            </Link>
          )}
        </ContentBoxHeaderButtons>
      </header>
      <BackBreadcrumb />
    </>
  );
}

function mapStateToProps(state) {
  const { isLogged } = state.session;
  const updatedProfileAt = state.session?.attributes?.updated_profile_at;

  return { isLogged, updatedProfileAt };
}

export default connect(mapStateToProps)(Header);
