import React from "react";
import styled from "styled-components";
import Menu from "./Menu";
import { MainBoxStyles } from "components/ui/MainBox";
import Content from "./Content";
import W from "assets/images/W.png";
import login from "assets/images/login.png"

const Box = styled(MainBoxStyles)`
  min-height: calc(100vh - 110px); //altura do header
  display: flex;
  background-image: url(${({theme}) => theme.mode === "light" ? W : login});
  background-repeat: no-repeat;
  background-size: auto cover;
  background-position: right center;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 20px 20px 30px 20px;
    background-position: center center;
  }
`;

function Main() {
  return (
    <Box>
      <Content />
      <Menu />
    </Box>
  );
}

export default Main;
