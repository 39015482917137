import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useFormAnswerInfos() {
  const { uuid, user_info_id } = useParams();
  const { data, isLoading } = useFetch(`/v1/internship/update/form_answer_infos/${user_info_id}`);

  return {
    required_attributes: data?.required_attributes,
    user_info: data?.user_info,
    category_documents: data?.category_documents,
    uuid: uuid,
    company_id: data?.company_id,
    questions: data?.questions,
    isLoading
  };
}