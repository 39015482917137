import React, { useState, useEffect } from "react";
import _ from "lodash";
import { fields } from "./context";
import { useForm } from "react-hook-form";
import { formPost } from "services/api";
import { Link, useNavigate } from "react-router-dom";
import { useSteps } from "utils/hooks";
import { HiddenField } from "components/form";
import { updateAttributesInStore } from "@root/redux/features/sessions/actions";
import { USERS } from "navigation/ROUTES";
import StepMarker from "./components/StepMarker";
import Buttons from "./components/Buttons";

function Form(props) {
  let navigate = useNavigate();
  const [data, setData] = useState({});
  const [components, setComponents] = useState(fields);

  const { defaultValues } = props;

  const {
    register,
    unregister,
    control,
    reset,
    resetField,
    setError,
    setValue,
    getValues,
    formState,
    handleSubmit,
    watch
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  const Steps = useSteps({
    totalLength: components.length,
  });

  const getCollectedData = (collectedData) => {
    let attributes = _.merge({...data}, collectedData);

    if(collectedData.disabilities_attributes){
      setTimeout(() => setData(attributes)); //sem o timeout, não é possível captar o cid
    } else {
      setData(attributes);
    }
  };

  function submit() {
    let attributes = {...data};

    if(defaultValues.address_attributes?.id){
      attributes.address_attributes.id = defaultValues.address_attributes.id;
    }

    const user = { user_info_attributes: attributes };
    formPost(`/v1/profile/users`, { user })
      .then(() => successfulRequisition())
      .catch((error) => unsuccessfulRequisition(error.response.data.errors));
  }

  function successfulRequisition() {
    navigate(USERS.HOME);
    swal(
      "Tudo certo!",
      "As informações básicas do seu perfil foram atualizadas com sucesso!",
      "success"
    );
    updateAttributesInStore();
  }

  function unsuccessfulRequisition(errors) {
    for (let field in errors) {
      errors[field].forEach((message) => {
        setError(field, { type: "manual", message });
      });
    }

    findIndexToSetStep(errors);

    swal(
      "Ops!",
      "Não foi possível atualizar as informações básicas. Por favor, corrija os itens solicitados e tente novamente!",
      "error"
    );
  }

  function findIndexToSetStep(errors) {
    let keys = Object.keys(errors);
    let step = fields.findIndex((field) => field.name == keys[0]);
    if (step >= 0) Steps.setStep(step);
  }

  const renderField = () => {    
    const { component, required, autoFocus } = components[Steps.step];

    return React.cloneElement(
      component,
      {
        register,
        unregister,
        control,
        resetField,
        setValue,
        getValues,
        watch,
        errors: formState.errors,
        required,
        autoFocus,
      },
      null
    );
  };

  function filterFields(){
    let array = fields;

    if (data?.not_in_university === "true"){
      array = array.filter((field) => field.type !== "academic");
    }

    if (data?.disability !== "true") {
      array = array.filter((field) => field.type !== "disability")
    }

    setComponents(array);
  }

  function callFormAction() {
    if (Steps.hasNext()) {
      Steps.next();
    } else {
      submit();
    }
  }

  useEffect(() => {
    reset(_.merge({...defaultValues}, data));
    
    filterFields();

    if (!_.isEmpty(data)) callFormAction();
  }, [data]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(_.merge({...defaultValues}, data));
    }
  }, [formState]);

  return (
    <>
      <Link to={USERS.HOME} className="cancel">
        <i className="material-icons">close</i> cancelar
      </Link>
      <StepMarker min={Steps.step + 1} max={components.length} />
      <form onSubmit={handleSubmit(getCollectedData)}>
        {renderField()}
        <Id
          register={register}
          setValue={setValue}
          control={control}
          defaultValues={defaultValues}
        />
        <Buttons Steps={Steps} />
      </form>
    </>
  );
}

function Id(props) {
  const { id } = props.defaultValues;
  return <HiddenField {...props} name="id" value={id} />;
}

export default Form;
