import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import api from "services/api";
import { Loader } from "components/ui";
import { FormAnswerProvider } from "./context";
import Main from "./Main";
import "./style.scss";
import { INTERNSHIP } from "navigation/ROUTES";

function Show() {
  const [formAnswer, setFormAnswer] = useState(null);
  const { uuid } = useParams();
  let navigate = useNavigate();

  const getFormAnswer = () => {
    const request = `/v1/internship/company_evaluation/form_answers/${uuid}`;
    api
      .get(request)
      .then((resp) => setFormAnswer(resp.data.data.attributes))
      .catch(() => notFound());
  };

  function notFound() {
    navigate(INTERNSHIP.COMPANY_EVALUATION.INDEX)
    swal("Ops!", "Essa avaliação não está disponível!", "warning");
  }

  useEffect(() => {
    if (_.isEmpty(formAnswer)) getFormAnswer();
  }, [formAnswer]);

  return formAnswer ? (
    <FormAnswerProvider value={{formAnswer}}>
      <div className="company-evaluation-show">
        <Main />
      </div>
    </FormAnswerProvider>
  ) : (
    <Loader />
  );
}

export default Show;
