import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { StatusCodes } from "http-status-codes";

import api from "services/api";
import consumer from "services/api/cable";
import { usePayload, useQuestion } from "./context";
import { USERS, TESTS } from "navigation/ROUTES";

import {
  Main,
  Title,
  ButtonContainer,
  SmallButton,
  FinishedTestContainer,
} from "./styles";
import { Loader } from "components/ui";
import Watermark from "./components/Watermark";
import Timer from "./components/Timer";
import Question from "./components/Question";

function Taking(props) {
  const { user, isLogged } = props;

  let navigate = useNavigate();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [channel, setChannel] = useState(null);
  const [connected, setConnected] = useState(false);
  const [hasActiveSession, setHasActiveSession] = useState(false);
  const time = useState(undefined);

  const { test, payload, answered, payloadIsLoading } = usePayload();

  const { question, questionIsLoading } = useQuestion(
    payload?.questions[currentQuestion]?.id
  );

  useEffect(() => {
    if (payloadIsLoading) return;
    setCurrentQuestion(payload.current_question);

    if (!channel) {
      const newChannel = consumer.subscriptions.create(
        {
          channel: "ScreenTimerControlChannel",
          owner_type: "Test::Answer",
          time: payload.time,
          owner_id: payload.answer_id,
        },
        {
          connected() {
            this.perform("confirm_subscribe");
            setConnected(true);
          },
          disconnected: () => {
            setConnected(false);
          },
          received: (data) => {
            time[1](data.time_remaining);
          },
        }
      );
      setChannel(newChannel);
      setHasActiveSession(payload.has_active_session);
    }

    return () => {
      if (channel) {
        channel.unsubscribe();
        setConnected(false);
      }
    };
  }, [payload]);

  if (!isLogged) {
    swal(
      "Ops!",
      "Você precisa estar logado para realizar o teste!",
      "warning"
    ).then(() => navigate(USERS.LOGIN));
  }

  if (
    payloadIsLoading ||
    questionIsLoading ||
    !channel ||
    time[0] === undefined
  ) {
    return <Loader />;
  }

  if (hasActiveSession) {
    swal(
      "Ops!",
      "Você já está fazendo o teste em outra aba.",
      "warning"
    ).then(() => navigate(USERS.HOME));
  }

  if (answered) {
    return (
      <FinishedTestContainer>
        <h2>Você já respondeu o teste {test.title}.</h2>
        <p style={{ marginTop: "20px" }}>
          Caso esse teste revele sua nota, você pode conferir a mesma nas
          candidaturas que utilizam o teste ou na página de{" "}
          <Link to={PATHS.TESTS.INDEX}>Testes de habilidades</Link>.
        </p>
      </FinishedTestContainer>
    );
  }

  const isFirstQuestion = currentQuestion === 0;
  const isLastQuestion = currentQuestion === payload.questions.length - 1;

  const sendTest = () => {
    api
      .post(`/v1/test/answers/finish_test`, { answer_id: payload.answer_id })
      .then(({data, status}) => {
        if (status === StatusCodes.OK){
          swal("Sucesso!", data.message, "success");
        } else {
          swal("Atenção!", data.message, "info");
        }

        navigate(TESTS.FINISHED);
      })
      .catch((error) => {
        const { message } = error.response.data;
        swal("Ops!", message, "error");
      });
  };

  const previousQuestion = () => {
    time[1](time[0]);
    if (!isFirstQuestion) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const nextQuestion = () => {
    time[1](time[0]);
    if (!isLastQuestion) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      sendTest();
    }
  };

  const checkExpired = (newTime) => {
    if (newTime <= 0) time[1](0);
  };

  const setupTime = (newTime) => {
    time[0] = newTime;
  };

  return (
    <Main>
      <Timer
        time_remaining={time[0]}
        formula={-1}
        checkExpired={checkExpired}
        setTimeTaking={setupTime}
        endConnection={true}
        channel={channel}
      />
      <Watermark content={user.email}>
        <Title>{test.title}</Title>
        <Question
          question={question}
          currentQuestion={currentQuestion + 1}
          answer={payload.answer_id}
          isLastQuestion={isLastQuestion}
          nextQuestion={nextQuestion}
          time={time[0]}
          sendTest={sendTest}
        />
        <ButtonContainer>
          {test.skip_question && (
            <>
              <SmallButton
                onClick={previousQuestion}
                disabled={isFirstQuestion}
              >
                Anterior
              </SmallButton>
              <SmallButton onClick={nextQuestion} disabled={isLastQuestion}>
                Próximo
              </SmallButton>
            </>
          )}
        </ButtonContainer>
      </Watermark>
    </Main>
  );
}

function mapStateToProps(state) {
  const user = state.session?.attributes;
  const isLogged = state.session?.isLogged;

  return { user, isLogged };
}

export default connect(mapStateToProps)(Taking);
