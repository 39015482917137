import React from 'react';
import Section from './Section';
import Motivations from "components/users/fields/personal/Motivations";
import InterestingFact from "components/users/fields/personal/InterestingFact";
import Hobbies from "components/users/fields/personal/Hobbies";

function AboutSection(props){
  return(
    <Section title="Sobre você">
      <div className="col-md-12">
        <Motivations {...props} />
      </div>
      <div className="col-md-12">
        <InterestingFact {...props} />
      </div>
      <div className="col-md-12">
        <Hobbies {...props} />
      </div>
    </Section>
  )
}

export default AboutSection;