import React from "react";
import Form from "./components/Form";
import Header from "./components/Header";
import { Footer, Loader } from "components/ui";
import { useCandidature } from "./context";

export default () => {
  const { isLoading } = useCandidature();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <Form />
      <Footer />
    </>
  );
};
