import React from "react";
import styled from "styled-components";
import man from "assets/images/man.png";

const Title = styled.h1`
  font-size: 3rem;
  font-weight: bolder;
  margin-bottom: 30px;
  color: ${({theme}) => theme.info[700]};
`;

const Button = styled.button`
  margin-top: 30px;
  background-color: ${({theme}) => theme.info[700]};
`

function Begin(props) {
  return (
    <div className="begin">
      <div>
        <Title>Complete seu perfil para desbloquear as vagas!</Title>
        <p>
          Essas informações vão ajudar a gente a te conhecer melhor e, assim, te mostrar vagas que têm tudo a ver com você. É rapidinho e necessário para seguir com o processo seletivo!
        </p>
        <Button className="button" onClick={props.changeComponent}>
          COMEÇAR
        </Button>
      </div>

      <img src={man} alt="Imagem homem com celular" />
    </div>
  );
}

export default Begin;
