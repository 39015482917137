import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import store from "@root/redux/store";

import AppContent from "pages/AppContent";
import "styles/App.scss";

import ThemeWrapper from "components/ui/wrappers/ThemeWrapper";
import Flipper from "services/api/flipper";

export default () => (
  <Provider store={store}>
    <Flipper>
      <ThemeWrapper>
        <Router>
          <Routes>
            <Route path="/*" element={<AppContent />} />
          </Routes>
        </Router>
      </ThemeWrapper>
    </Flipper>
  </Provider>
);
