import React from "react";
import { Logo } from "components/ui";
import Form from "./Form";
import ProviderLogin from "./ProviderLogin";
import "./style.scss";

function User() {
  return (
    <div className="user-login">
      <Logo />
      <Form />
      <ProviderLogin />
      <Links />
    </div>
  );
}

function Links() {
  return (
    <>
      <a className="confirmation-link" href="/usuarios/confirmacao/criar">
        Não recebi email de confirmação
      </a>
      <a className="registration-link" href="/usuarios/cadastro/criar">
        Ainda não é um usuário? Cadastre-se no Walljobs!
      </a>
    </>
  );
}



export default User;
