import React from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "services/api";

function DeleteAction(props) {
  const { id } = props;

  const deleteDocument = () => {
    api
      .delete(`/v1/profile/users/personal_documents/${id}`)
      .then(() => {
        swal("Tudo certo!", "Documento excluído com sucesso", "success");
        props.getDocuments();
      })
      .catch(() => {
        swal(
          "Ops",
          "Não foi possível excluir esse documento. Por favor, tente novamente mais tarde.",
          "error"
        );
      });
  };

  const deleteDialog = () => {
    swal(
      "Tem certeza?",
      "Você está prestes a excluir esse documento. Deseja prosseguir com essa ação?",
      "warning",
      {
        dangerMode: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Excluir",
            value: true,
            closeModal: true,
          },
        },
      }
    ).then((value) => {
      if (value) deleteDocument();
    });
  };

  return (
    <IconButton onClick={deleteDialog} aria-label="Deletar">
      <DeleteIcon />
    </IconButton>
  );
}

export default DeleteAction;
