import React from "react";
import styled from "styled-components";

const CardInfos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  .see_course {
    width: 100%;
    margin-top: 32px;
    text-align: right;
    cursor: pointer;
    transition: 0.8s;

    &:hover {
      color: #000000;
    }

    &:active {
      color: #000000;
    }
  }

  &.noProgress > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default ({ children, className }) => (
  <CardInfos className={className}>{children}</CardInfos>
);
