import React from "react";
import { connect } from "react-redux";

function Tabs(props) {
  const { user } = props;
  return (
    <nav>
      <ul className="nav">
        <li>
          <a className="active" data-toggle="tab" href="#summary">
            Resumo
          </a>
        </li>
        <li>
          <a data-toggle="tab" href="#experiences">
            Experiência Profissional
          </a>
        </li>
        <li>
          <a data-toggle="tab" href="#softwares">
            Softwares
          </a>
        </li>
        <li>
          <a data-toggle="tab" href="#academic">
            Acadêmico
          </a>
        </li>
        <li>
          <a data-toggle="tab" href="#certifications">
            Certificações
          </a>
        </li>
        {(user?.intern || user?.studying) && (
          <li>
            <a data-toggle="tab" href="#internship">
              Estágio
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
}

function mapStateToProps(state) {
  const user = state.session?.attributes;

  return { user };
}

export default connect(mapStateToProps)(Tabs);
