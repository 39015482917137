import React from "react";
import styled from "styled-components";

const BarDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #65677a;

  p {
    font-size: 10px;
    font-weight: 600;
    font-family: "Open Sans" !important;
    margin: 0 !important;
  }

  @media(min-width: 856px){
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    justify-content: flex-end;

    p {
      margin-left: 10px !important;
      min-width: 26px;
      text-align: center;
    }
  }
`

const BarWrapper = styled.div`
  width: 75%;
  background-color: #ccc;
  border-radius: 0px;
  height: 5px;
  margin: 2px;
  
  @media(min-width: 856px) {
    max-width: 123px;
    border-radius: 0px;
  }
`;

const BarProgress = styled.div`
  width: ${({ percentage }) => percentage}%;
  height: 5px;
  background-color: #007bff;
  transition: width 0.3s ease-in-out;
  border-radius: 10px;

  background: ${({ percentage }) => {
    if (percentage < 25) {
      return "#EB3723";
    }

    if (percentage >= 25 && percentage < 100) {
      return "#0C6DBC";
    }

    return "#0CBC87";
  }};

  @media(min-width: 856px) {
    border-radius: 0px;
  }
`;

export default (props) => (
  <BarDiv>
    <BarWrapper>
      <BarProgress percentage={props.percentage}/>
    </BarWrapper>
    <p>{props.percentage}%</p>
  </BarDiv>
);
