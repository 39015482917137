import React from "react";
import { ACADEMY } from "navigation/ROUTES";
import { pathWithParams } from "utils/pathWithParams";
import { useNavigate } from "react-router-dom";
import { Container, Image, TextContainer, InfosContainer, Info, Title, SignalCellularAltIcon, AccessTimeIcon, ArticleIcon } from './styles';

function Card(props) {
  const { image, title, duration, difficulty, total_section_items, slug } = props.course.attributes;
  const isCenter = props.index === props.center;
  const isLast = props.index === props.last;
  const isFirst = props.index === props.first;

  const navigate = useNavigate();

  return (
    <Container isCenter={isCenter} isLast={isLast} isFirst={isFirst} onClick={() => navigate(pathWithParams(ACADEMY.COURSE.SHOW, { id: slug }))} >
      <Image src={image} alt={`Capa do curso de ${title}`} />
      <TextContainer >
        <Title isLast={isLast} isFirst={isFirst}>{title}</Title>
        <InfosContainer isLast={isLast} isFirst={isFirst}>
          <Info>
            <ArticleIcon />
            <p>{total_section_items} aulas</p>
          </Info>
          <Info>
            <SignalCellularAltIcon />
            <p>{difficulty}</p>
          </Info>
          <Info>
            <AccessTimeIcon />
            <p>{duration}</p>
          </Info>
        </InfosContainer>
      </TextContainer>
    </Container>
  );
}

export default Card;
