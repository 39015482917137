import React from "react";
import { useFormAnswerInfos } from "./context";
import Form from "./Form";
import { Loader } from "components/ui";
import Header from "./Header";
import "./style.scss";

function FormAnswer() {
  const { isLoading } = useFormAnswerInfos();
  
  if(isLoading){
    return <Loader />
  }

  return (
    <div className="internship-update-form">
      <Header />
      <Form />
    </div>
  );
}

export default FormAnswer;
