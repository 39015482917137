import React from "react";
import { styled, Stepper, Step, StepLabel } from "@mui/material";

const CustomStepper = styled(Stepper)(({theme}) => ({
  paddingTop: theme.spacing(11),
  paddingBottom: theme.spacing(6),

  [`& .MuiStepLabel-root`]: {
    cursor: "pointer !important"
  }
}))

function Steps(props) {
  const { step, setStep, forms } = props;

  const renderSteps = () => {
    return forms.map(({stepLabel}, index) => {
      return <Step key={index} onClick={() => setStep(index)}>
        <StepLabel>{stepLabel}</StepLabel>
      </Step>
    })
  }

  return (
    <CustomStepper activeStep={step} alternativeLabel>
      {renderSteps()}
    </CustomStepper>
  );
}


export default Steps;
