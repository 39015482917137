import React from "react";
import Display from "components/filters/Display";
import { Link } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";
import { ATTENDEES } from "navigation/ROUTES";
import {
  CardBox,
  CardBoxFooter,
  CardBoxImage,
  CardBoxInfo,
  CardBoxLabel,
} from "components/ui";

function Attendees() {
  const request = "/v1/attendee/attendees";
  return <Display request={request} component={<Attendee />} />;
}

const Attendee = React.memo(function Attendee({ item, included }) {
  const attendee = item.attributes;
  const meeting = included?.meeting?.attributes;
  const confirmation = attendee.last_confirmation?.attributes;

  if (!attendee || !meeting) {
    return;
  }

  return (
    <CardBox>
      <Link
        to={pathWithParams(ATTENDEES.ABOUT, { id: item.id })}
        className="card-box-body"
      >
        <CardBoxImage image={meeting.company_avatar} />

        <CardBoxInfo title={meeting.title} subtitle={meeting.company_name}>
          <CardBoxLabel>
            <ConfirmationLabel confirmation={confirmation} />
            <small>
              <i className="material-icons">bookmark</i> {attendee.status}
            </small>
          </CardBoxLabel>

          <CardBoxFooter>
            <i className="material-icons">place</i> {meeting.address}
          </CardBoxFooter>
        </CardBoxInfo>
      </Link>
    </CardBox>
  );
});

function ConfirmationLabel({ confirmation }) {
  return confirmation ? (
    confirmation.confirmed ? (
      <p>
        <i className="material-icons">schedule</i> {confirmation.time}
      </p>
    ) : (
      <p>
        <i className="material-icons">highlight_off</i> convite recusado
      </p>
    )
  ) : (
    <p>
      <i className="material-icons">help_outline</i> aguardando
    </p>
  );
}

export default Attendees;
