import React from "react";
import { WithoutCourses, DivWithoutCourses } from "../styles";
import { ACADEMY } from "navigation/ROUTES";
import Course from "../../components/courses/Course";

function Unfinished({unfinished, hasUnfinished}){

  return(
    <>
      {hasUnfinished ? (
        <DivWithoutCourses>
          <WithoutCourses>Você não possui cursos em andamento. <a href={ACADEMY.COURSE.INDEX} style={{color: "#0645AD", textDecoration: "none"}}>Clique aqui</a> para acessar a lista de cursos.</WithoutCourses> 
        </DivWithoutCourses>
      ) : 
        unfinished.map((item) => (
          <Course item={item} />
        ))
      }
    </>
  )
}

export default Unfinished;