import React from "react";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 250px;
  border-radius: 8px;
  background-color: #FFFFFF;
  margin: 35px 0 0 0;

  @media (min-width: 770px) {
    flex-direction: row;
    min-height: 100px;
  }

  @media (min-width: 1234px) {
    flex-direction: row;
    max-width: 677px;
    min-width: 400px;
    min-height: 200px;
  }
`;

export default (props) => <Card>{props.children}</Card>;
