import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Loader, MainBox } from "components/ui";
import "./style.scss";
import api from "services/api";
import formList from "./forms";
import Form from "./Form";
import Steps from "./Steps";
import { useSteps } from "utils/hooks";

function Wizard() {
  const forms = formList();

  const [defaultValues, setDefaultValues] = useState(null);
  const { step, setStep, previous, next, hasNext } = useSteps({
    totalLength: forms.length,
  });

  function getDefaultValues() {
    const form = forms[step].component;
    const step_name = form.type.name?.toLowerCase?.();
    api
      .get(`/v1/profile/user_infos/attributes`, { params: { step: step_name } })
      .then((resp) => setDefaultValues(resp.data?.data?.attributes))
      .catch(() => setDefaultValues({}));
  }

  useEffect(() => {
    if (_.isEmpty(defaultValues)) getDefaultValues();
  }, [defaultValues]);

  useEffect(() => {
    setDefaultValues(null);
  }, [step]);

  return (
    <div className="user-wizard">
      <MainBox>
        <Steps step={step} setStep={setStep} forms={forms} />
        {defaultValues ? (
          <Form
            forms={forms}
            step={step}
            previous={previous}
            defaultValues={defaultValues}
            next={next}
            hasNext={hasNext}
          />
        ) : (
          <Loader />
        )}
      </MainBox>
    </div>
  );
}

export default Wizard;
