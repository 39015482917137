import React from "react";
import Jobs from "./Jobs";
import Filters from "./Filters";
import FilterController from "components/filters/FilterController";

function Main() {
  return (
    <FilterController>
      <Filters />
      <Jobs />
    </FilterController>
  );
}

export default Main;
