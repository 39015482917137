import React from "react";
import _ from "lodash";
import { useUserInfo } from "../../context";
import { CircularProgressWithLabel, NotInformed } from "components/ui";
import { EditIcon } from "components/users/EditMode";

function Softwares() {
  const { getIncluded } = useUserInfo();
  const softwares = getIncluded("software");
  const hasSoftwares = !_.isEmpty(softwares);

  const _renderSoftwares = () => {
    const list = softwares || [];
    return list.map((item) => (
      <Software key={item.id} attributes={item.attributes} />
    ));
  };

  return (
    <div
      className="tab-pane fade"
      id="softwares"
      role="tabpanel"
      aria-labelledby="softwares-tab"
    >
      <EditIcon type="softwares" />
      {hasSoftwares ? (
        <div className="grid">{_renderSoftwares()}</div>
      ) : (
        <NotInformed />
      )}
    </div>
  );
}

function Software({ attributes }) {
  const normalise = (value) => ((value + 1) * 100) / 3;

  return (
    <div className="software">
      <p>{attributes.name}</p>
      <CircularProgressWithLabel
        size={120}
        value={normalise(attributes.level_value)}
        text={attributes.level_text}
      />
    </div>
  );
}

export default Softwares;
