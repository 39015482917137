import React, { useState } from "react";
import api from "services/api";
import { TextField } from "@mui/material";
import BackDescription from "./BackDescription";
import ConfirmForm from "./ConfirmForm";
import { useAttendee } from "../context";

function Reschedule(props) {
  const { attendee } = useAttendee();
  const { id: attendee_id } = attendee;

  const [observation, setObservation] = useState("");

  const handleChange = (event) => {
    setObservation(event.target.value);
  };

  const handleSubmit = ({ time }) => {
    api
      .post(`/v1/attendee/attendees/reschedule`, {
        time,
        observation,
        attendee_id,
      })
      .then(() =>
        props.successfulRequisition(
          "Sua presença nesse evento foi reagendada com sucesso!"
        )
      )
      .catch((error) =>
        swal("Algo deu errado", `${error.response.data.error[0]}`, "error")
      );
  };

  return (
    <div className="reschedule-form">
      <BackDescription />
      <h2>Reagendar</h2>
      <p>
        Escolha um horário para remarcar sua presença no evento.
        <br />
        Se quiser, você pode justificar o motivo do reagendamento.
      </p>
      <ConfirmForm handleSubmit={handleSubmit}>
        <TextField
          variant="standard"
          id="observation"
          label="Observações"
          placeholder="Justificar reagendamento?"
          value={observation}
          onChange={handleChange}
          multiline
          rows={4}
        />
      </ConfirmForm>
    </div>
  );
}

export default Reschedule;
