import React from "react";
import { Breadcrumb, MainBox } from "components/ui";
import Main from "./Main";

export default () => {
  return (
    <MainBox>
      <Breadcrumb>
        <a>Testes de habilidade</a>
      </Breadcrumb>
      <Main />
    </MainBox>
  )
}