import React from "react";
import { Link } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";
import PATHS from "navigation/ROUTES";

export default ({user}) => {
  return (
    <div className="links">
      <Link to={pathWithParams(PATHS.USERS.SHOW, {id: user?.slug})}>
        Meu perfil <i className="material-icons">keyboard_arrow_right</i>
      </Link>
      <Link to={PATHS.ATS.JOBS.INDEX}>
        Mural de vagas <i className="material-icons">keyboard_arrow_right</i>
      </Link>
      <Link to={PATHS.USERS.CANDIDATURES.INDEX}>
        Minhas candidaturas
        <i className="material-icons">keyboard_arrow_right</i>
      </Link>
      <Link to={PATHS.BENEFITS.INDEX}>
        Clube de Benefícios <i className="material-icons">keyboard_arrow_right</i>
      </Link>
      <Link to={PATHS.COMPANIES.INDEX}>
        Empresas <i className="material-icons">keyboard_arrow_right</i>
      </Link>
      <Link to={PATHS.ACADEMY.COURSE.INDEX}>
        Academy
      </Link>
    </div>
  );
}