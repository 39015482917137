import React, { useState } from "react";
import TabPanel from "./components/TabPanel";
import Tabs from "./components/Tabs";
import Box from "@mui/material/Box";

import { Loader } from "components/ui";

import { useCertificates } from "./context";

const TABS = [
  { type: "internship", label: "Estágio" },
  { type: "academy_matriculation", label: "Cursos do WJ Academy" },
  { type: "convention", label: "Eventos/Convenções" },
];

export default () => {
  const [value, setValue] = useState(0);

  const { certificates, isLoading } = useCertificates();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs items={TABS} value={value} handleChange={handleChange} />

      {TABS.map((tab, index) => (
        <TabPanel
          content={certificates[tab.type]}
          value={value}
          index={index}
          key={tab.type}
        />
      ))}
    </Box>
  );
};
