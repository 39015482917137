import React from "react";
import EditMode from "components/users/EditMode";
import  Main  from "./Main"

export default () => {

  return (
    <>
      <EditMode>
        <Main />
      </EditMode>
    </>
  )
};
