import React, { useEffect } from "react";
import { FormControl, FormLabel } from "@mui/material";
import styled from "styled-components";
import { Checkbox, HiddenField } from "components/form";
import { useConvention } from "../Participate/context";

const StyledFormLabel = styled(FormLabel)`
  & {
    color: ${({ theme }) => theme.text.primary}!important;
    font-size: 1.5rem!important;
    font-weight: 600!important;
    margin-bottom: 10px;
  }

  &.Mui-focused {
    color: ${({ theme }) => theme.secondary.main} !important;
  }
`

export default (props) => {
  const { convention } = useConvention();
  const { term } = convention;

  const prefix = props.prefix || {
    name: "term_acceptances_attributes[0]",
    id: "term_acceptances_attributes_0",
  };

  const handleTermId = (value) => {
    props.setValue?.(`${prefix.name}[term_id]`, value);
  };

  return (
    <FormControl component="fieldset">
      <StyledFormLabel className="checkbox-label" component="legend">
        {term.title}
      </StyledFormLabel>
      <HiddenField 
        {...props}
        id={`${prefix.id}_term_id`}
        name={`${prefix.name}[term_id]`}
        handleChange={handleTermId}
        value={term.id}
      />
      <Checkbox
        {...props}
        name={`${prefix.name}[accepted]`}
        id={`${prefix.id}_accepted`}
        label={
          <p dangerouslySetInnerHTML={{ __html: term.conditions }}></p>
        }
      />
    </FormControl>
  );
};
