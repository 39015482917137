import React, { useState } from "react";
import { TextField } from "@mui/material";
import api from "services/api";
import BackDescription from "./BackDescription";
import  { useAttendee } from "../context";

function Decline(props) {
  const { attendee } = useAttendee();
  const { id: attendee_id } = attendee;

  const [observation, setObservation] = useState("");

  const handleChange = (event) => {
    setObservation(event.target.value);
  };

  const _handleSubmit = (event) => {
    event.preventDefault();
    api
      .post(`/v1/attendee/attendees/decline`, {
        observation,
        attendee_id,
      })
      .then(() =>
        props.successfulRequisition(
          "Confirmamos a sua recusa ao convite desse evento!"
        )
      )
      .catch((error) => {
        swal("Algo deu errado", `${error.response.data.error[0]}`, "error");
      });
  };

  return (
    <div className="decline-form">
      <BackDescription />
      <h2>Recusar</h2>
      <form onSubmit={_handleSubmit}>
        <TextField
          variant="standard"
          id="observation"
          label="Observações"
          placeholder="Justificar recusa?"
          value={observation}
          onChange={handleChange}
          multiline
          rows={4}
        />
        <button className="button" type="submit">
          Recusar convite
        </button>
      </form>
    </div>
  );
}

export default Decline;
