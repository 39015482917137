import React from "react";
import Course from "../components/courses/Course";
import Display from "components/filters/Display";
import { connect } from "react-redux";
import swal from "sweetalert";
import { USERS } from "navigation/ROUTES";

function Courses(props){
  const request = "/v1/academy/courses";
  const {user, isLogged} = props;

  if(!isLogged){
    swal(
      "Ops!",
      "Você precisa estar logado para visualizar essa página!",
      "warning"
    ).then(() => window.location.href = USERS.LOGIN);
  }

  return isLogged && <Display request={request} component={<Course />} grid={true} user_info_id={props.user.slug} style={"none"} />;
}

function mapStateToProps(state) {
  const user = state.session?.attributes;
  const isLogged = state.session?.isLogged;

  return {user, isLogged};
}

export default connect(mapStateToProps)(Courses);