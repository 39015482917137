import React from "react";
import { connect } from "react-redux";
import man from "assets/images/man.png";

const Intern = React.lazy(() => import("./components/menu/Intern"));
const Candidate = React.lazy(() => import("./components/menu/Candidate"));
const PosIntern = React.lazy(() => import("./components/menu/PosIntern"));

function Menu(props) {
  const { user } = props;

  const renderMenu = () => {
    if(user?.intern){
      return <Intern {...props} />
    } else if(user?.former_intern){
      return <PosIntern {...props} />
    } else {
      return <Candidate {...props} />
    }
  }

  return (
    <div className="menu">
      <img src={man} alt="Imagem homem com celular" />
      {renderMenu()}
    </div>
  );
}


function mapStateToProps(state) {
  const user = state.session?.attributes;

  return { user };
}

export default connect(mapStateToProps)(Menu);
