import React from "react";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import '@root/i18n';
import App from "@root/App";

const tagManagerArgs = {
  gtmId: process.env.GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
