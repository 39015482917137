import React from "react";
import Main from "./Main";
import { useUserInfo } from "./context";
import "./style.scss";
import { Loader } from "components/ui";
import EditMode from "components/users/EditMode";

function Show() {
  const { isLoading } = useUserInfo();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="user-show">
      <EditMode>
        <Main />
      </EditMode>
    </div>
  );
}

export default Show;
