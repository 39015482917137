import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Category from "./fields/Category";
import DocumentType from "./fields/DocumentType";
import DocumentFiles from "./fields/DocumentFiles";
import { formPost } from "services/api";

function AddDocument(props) {
  const { open, setOpen } = props;
  const {
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen(false);
  };

  const successfulRequisition = () => {
    props.getDocuments();
    swal("Tudo certo!", "Documento adicionado com sucesso!", "success");
  };

  const unsuccessfulRequisition = () => {
    swal(
      "Ops!",
      "Não foi possível adicionar esse documento. Por favor, tente novamente mais tarde.",
      "error"
    );
  };

  const submitData = (data) => {
    const personal_document = data;
    formPost("/v1/profile/users/personal_documents", { personal_document })
      .then(() => successfulRequisition())
      .catch(() => unsuccessfulRequisition());
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <form onSubmit={handleSubmit(submitData)}>
        <DialogTitle>Adicionar documento</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ my: 2 }}>
            <p>
              Preencha algumas informações para fazer o upload do documento.
            </p>
            <p>
              <b>Atenção:</b> Se você for fazer o upload do comprovante/atestado de matrícula, por favor selecione a categoria <b>Comprovante de Matrícula</b>!
            </p>
            <p>
              <b>Dica:</b> Subindo um documento de cada vez, você facilita para
              a empresa que te contratou. Faça um upload de documento por vez!
            </p>
          </DialogContentText>
          <Fields
            register={register}
            control={control}
            getValues={getValues}
            errors={errors}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "0 24px 24px 24px" }}>
          <button
            className="button button-secondary modal-button"
            type="button"
            onClick={handleClose}
          >
            Cancelar
          </button>
          <button className="button button-primary modal-button" type="submit">
            Adicionar
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function Fields(props) {
  return (
    <>
      <Category {...props} />
      <DocumentType {...props} />
      <DocumentFiles {...props} />
    </>
  );
}

export default AddDocument;
