import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { MenuItem, TextField } from "@mui/material";
import api from "services/api";
import { useFilters } from "utils/hooks";
import { FilterContext } from "components/filters/FilterController";
import { Filter } from "components/ui";
import IesJobsFilter from "components/ats/IesJobsFilter";

function Filters(props) {
  const { infos, filterData } = useContext(FilterContext);
  const { user } = props;

  const params = { company: "", job: "", status: "" };

  const [statuses, setStatuses] = useState([]);

  const {
    filters,
    handleChange,
    createFilterLabel,
    renderFilters,
    handleSelect,
  } = useFilters(params, filterData);

  useEffect(() => {
    if (_.isEmpty(statuses)) getStatuses();
  });

  function _renderOptions() {
    const list = statuses || [];
    return list.map((status) => (
      <MenuItem key={status.value} value={status.value}>
        {status.label}
      </MenuItem>
    ));
  }

  function getStatuses() {
    api
      .get("/selects/job_status.json")
      .then((resp) => setStatuses(resp.data))
      .catch(() => setStatuses([]));
  }

  return (
    <>
      {user?.talent_hub_ies_student && (
        <IesJobsFilter
          enabled={!!filters.ies_jobs_only}
          handleChange={handleChange}
          createFilterLabel={createFilterLabel}
        />
      )}
      <Filter
        length={infos.length}
        totalCount={infos.totalCount}
        renderFilters={renderFilters}
      >
        <TextField
          filter
          variant="standard"
          id="company"
          name="company"
          label="Empresa"
          placeholder="Ex: WallJobs, 99, Goldman Sachs..."
          value={filters.company}
          onChange={handleChange}
          onBlur={createFilterLabel}
        />
        <TextField
          filter
          variant="standard"
          id="job"
          name="job"
          label="Informações da Vaga"
          placeholder="Ex: Marketing, RH..."
          value={filters.job}
          onChange={handleChange}
          onBlur={createFilterLabel}
        />
        <TextField
          filter
          select
          variant="standard"
          id="status"
          name="status"
          label="Status da Vaga"
          placeholder="Selecione..."
          value={filters.status}
          onChange={(event) => handleSelect(event, statuses)}
        >
          {_renderOptions()}
        </TextField>
      </Filter>
    </>
  );
}

function mapStateToProps(state) {
  const user = state.session?.attributes;

  return { user };
}

export default connect(mapStateToProps)(Filters);
