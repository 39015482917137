import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function usePayload() {
  const {user_info_test_id} = useParams();
  const {data, isLoading} = useFetch(`/v1/test/answers/payload/${user_info_test_id}`);

  return {
    test: data?.test,
    payload: data?.collection,
    answered: data?.collection?.answered,
    isLoading,
  }
}