import React from "react";
import JobLabel from "components/ats/JobLabel";
import AtsInfos from "../../shared/AtsInfos";
import { useCandidature } from "../context";

export default () => {
  const { candidature, job } = useCandidature();

  return (
    <div className="sidebar">
      <AutoEnrollStatus candidature={candidature} />
      <JobLabel job={job} />
      <AtsInfos job={job} candidature={candidature} />
    </div>
  );
}

const AutoEnrollStatus = ({ candidature }) => {
  const { auto_enroll_status: status, auto_enroll } = candidature;

  const canShow = auto_enroll && status.value != "not_defined";

  if (!canShow) {
    return;
  }

  return (
    <p className={`auto-enroll-status auto-enroll-${status.value}`}>
      {status.text}
    </p>
  );
}
