import React from "react";
import { CardCourseArea, StyledImage, Title } from "./styles";
import { useCourses } from "../../Index/context";
import { Loader } from "components/ui";
import { useNavigate } from "react-router-dom";
import { ACADEMY } from "navigation/ROUTES";

function CourseArea() {

  const {courses, isLoading} = useCourses();
  let navigate = useNavigate();

  if(isLoading){
    return <Loader />
  }

  function redirectCourses() {
    navigate(ACADEMY.COURSE.INDEX)
  }
  
  return (
    <CardCourseArea>
      <div className="course_area">
              {
                courses.map((course) => (
                  <div className="areas_card" onClick={() => redirectCourses()}>
                    <StyledImage src={course.attributes.image} alt="Imagem do curso" />
                    <Title>{course.attributes.title}</Title>
                  </div>
                ))
              }
            
          <div className="text">
            {/*
              <h1>Loren Ipsum</h1>
              <p>Neque porro quisquam est qui dolorem ipsum quia dolor <br />sit amet, consectetur, adipisci velit...</p>
            */}
          </div>
          <div className="rectangle01"></div>
          <div className="rectangle02"></div>
          <div className="dotted_circle01"></div>
        </div>
    </CardCourseArea>
  )
}


export default CourseArea;