import React from 'react';
import { connect } from 'react-redux';
import Academic from './academic/Academic';
import Certifications from './certifications/Certifications';
import Experiences from './experiences/Experiences';
import Internship from './internship/Internship';
import Softwares from './softwares/Softwares';
import Summary from './summary/Summary';

function TabContent(props){
  const {user} = props
  return(
    <div className="tab-content">
      <Summary />
      <Experiences />
      <Softwares />
      <Academic />
      <Certifications />
      {(user?.intern || user?.studying) && <Internship />}
    </div>
  )
}

function mapStateToProps(state){
  const user = state.session?.attributes;

  return { user }
}

export default connect(mapStateToProps)(TabContent);