import React from "react";
import { useAttendee } from "../context";
import { useForm } from "react-hook-form";
import { RadioButtons } from "components/form";

function ConfirmForm(props) {
  const { meeting } = useAttendee();

  const { control, getValues, handleSubmit, errors } = useForm();

  const options = () => {
    const list = meeting.days_and_times || [];

    let options = list.map(({ day, times }) =>
      times.map(({ id, time, full }) => ({
        value: id,
        label: `${day} - ${time}`,
        disabled: full,
      }))
    );

    return options.flat();
  };

  function onSubmit(data) {
    props.handleSubmit(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RadioButtons
        control={control}
        errors={errors}
        getValues={getValues}
        id="time"
        name="time"
        options={options()}
        required={false}
        label="Escolha um horário"
      />
      {props.children}
      <button className="button" type="submit">
        Confirmar horário
      </button>
    </form>
  );
}

export default ConfirmForm;
