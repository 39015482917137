import React from "react";
import { UncontrolledInput as Input } from "components/form";

function DocumentType(props) {
  const prefix = props.prefix || {
    id: "document_type",
    name: "document_type",
  };

  return (
    <div className="form-group">
      <Input
        {...props}
        required
        placeholder="RG, CPF, Comprovante de Matrícula, etc..."
        type="text"
        name={prefix.name}
        id={prefix.id}
        label="Tipo de Documento"
      />
    </div>
  );
}

export default DocumentType;
