import { useFetch } from "utils/hooks";

export function useAvailable() {
  const { data, isLoading } = useFetch(`/v1/ats/test/tests`);

  return {
    tests: data?.data,
    isLoading,
  };
}

export function useTestResults() {
  const { data, isLoading } = useFetch(`/v1/ats/test/tests/owned`);

  return {
    tests: data?.data,
    isLoading
  }
}
