import React from 'react';
import {UncontrolledInput as Input, RadioButtons } from "components/form";

function Answer(props) {
  return (
    <div className="answer">
      <IdField {...props} />
      <Score {...props} />
      <Note {...props} />
    </div>
  )
}

function Score(props){
  const { answer, index } = props;
  const { title, options, score } = answer;

  const prefix = {
    id: `answers_${index}_score`,
    name: `answers[${index}][score]`,
  };

  return (
    <RadioButtons
      {...props}
      id={prefix.id}
      name={prefix.name}
      options={options}
      label={title}
      defaultValue={score}
    />
  );
}

function Note(props){
  const { answer, index } = props;

  const prefix = {
    id: `answers_${index}_note`,
    name: `answers[${index}][note]`,
  };

  return (
    <Input
      {...props}
      placeholder="Digite sua resposta..."
      type="text"
      name={prefix.name}
      id={prefix.id}
      defaultValue={answer.note}
      multiline
      rows={6}
      label="Tendo a questão avaliativa como parâmetro, avalie estágio/empresa nesse contexto ou faça uma observação:"
    />
  );
}

function IdField(props) {
  const { answer, index } = props;
  const { id } = answer;

  const prefix = {
    id: `answers_${index}_id`,
    name: `answers[${index}][id]`,
  };

  const inputRef = props.register?.(prefix.name)

  return (
    <input
      type="hidden"
      value={id}
      name={prefix.name}
      id={prefix.id}
      ref={inputRef?.ref}
    />
  );
}

export default Answer
