import React from "react";
import styled from "styled-components";

const Fieldset = styled.fieldset`
  border: 0;
  border-top: 1px solid
    ${({ theme }) => (theme.mode === "light" ? "#333b4d8f" : "#f0f0f0e7")};
  padding: 5px 0px;
  display: block;
  text-align: center;
`;

const Legend = styled.legend`
  padding: 5px 10px;
  display: block;
  width: 12%;
  max-width: 12%;
  margin-bottom: 0;
  line-height: 32px;
  color: ${({ theme }) => (theme.mode === "light" ? "#333b4d" : "#f0f0f0")};
  white-space: normal;
  font-size: 16px;
`;

export default () => (
  <Fieldset>
    <Legend>ou</Legend>
  </Fieldset>
);
