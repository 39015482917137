import React from "react";
import styled from "styled-components";

const DivTitle = styled.div`
  display: block;

  .area {
    color: #000000;
    font-size: 13px;
  }

  @media (min-width: 770px) {
    display: inline-block;
  }
`;

export default (props) => <DivTitle>{props.children}</DivTitle>;
