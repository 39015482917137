import React from "react";
import {
  Radio,
  Badge,
  Slider,
  LinearProgress,
  CircularProgress,
  Tooltip,
  Switch,
  Avatar,
  AvatarGroup,
  Breadcrumbs,
  Alert,
  BarChart,
  LineChart,
  PieChart,
  ScatterChart,
  Pagination,
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  Snackbar,
  Grid,
  Paper,
  TransferList,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  TableFooter,
  Collapse,
  Box,
  Autocomplete,
} from "components/ui";

import {
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  ListItemAvatar,
  Link,
  Button,
  Backdrop,
  Rating,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Typography,
  MobileStepper,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Switch as SwitchBase,
  FormLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

const MailIcon = () => {
  return <span className="material-symbols-rounded">mail</span>;
};

const PersonIcon = () => {
  return <span className="material-symbols-rounded">person</span>;
};

const StarIcon = () => {
  return <span className="material-symbols-rounded">star</span>;
};

const ArrowRight = () => {
  return <span className="material-symbols-rounded">arrow_right_alt</span>;
};

const ExpandMoreIcon = () => {
  return <span className="material-symbols-rounded">keyboard_arrow_down</span>;
};

const CloseIcon = () => {
  return <span className="material-symbols-rounded">close</span>;
}

const KeyboardArrowUpIcon = () => {
  return <span className="material-symbols-rounded">keyboard_arrow_up</span>;
}

const KeyboardArrowDownIcon = ExpandMoreIcon;

const sliderDivStyle = {
  display: "flex",
  gap: "40px",
  marginBottom: "30px",
  padding: "15px",
  height: "100px",
  alignItems: "center",
};

const sliderMarks = [
  { value: 0, label: "0" },
  { value: 12, label: "12" },
];

export default () => {
  const options = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
    { label: 'The Lord of the Rings: The Return of the King', year: 2003 },
    { label: 'The Good, the Bad and the Ugly', year: 1966 },
    { label: 'Fight Club', year: 1999 },
    { label: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
    { label: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 }];

  return (
    <Box sx={{ m: 6 }}>
      <Autocomplete
        disablePortal
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Movie" />}
      />
      <Autocomplete
        disablePortal
        options={options}
        sx={{ my: 2, width: 300 }}
        multiple
        renderInput={(params) => <TextField {...params} label="Movie" variant="standard" />}
      />
    </Box>
  )
}

const Tables = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

  const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
      id: 'population',
      label: 'Population',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'size',
      label: 'Size\u00a0(km\u00b2)',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'density',
      label: 'Density',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toFixed(2),
    },
  ];

  const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
  ];
  
  return (
    <>
      <Paper sx={{ overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Paper sx={{ my: 2 }}>
        <TableContainer>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} sx={{ my: 2 }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <CollapsedRow key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const CollapsedRow = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const history = [
    {
      date: '2020-01-05',
      customerId: '11091700',
      amount: 3,
    },
    {
      date: '2020-01-02',
      customerId: 'Anonymous',
      amount: 1,
    },
  ];

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.code}</TableCell>
        <TableCell align="right">{row.population}</TableCell>
        <TableCell align="right">{row.size}</TableCell>
        <TableCell align="right">{row.density}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * Math.random(10) * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Dialogs = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>Disagree</Button>
          <Button color="secondary" onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

const TransferLists = () => {
  const left = ["Kina", "Renan", "Fernando", "Stephani"];
  const right = ["Mathos", "Felipe", "Marcelo", "Murilo"];

  return (
    <>
      <TransferList left={left} right={right} />
      <TransferList left={left} right={right} enhanced />
    </>
  )
}

const Snackbars = () => {
  return (
    <>
      <SnackbarDefault />
      <SnackbarSuccess />
      <SnackbarError />
    </>
  )
}

const SnackbarDefault = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button size="small" onClick={handleClose}>
        Action
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Button onClick={handleClick}>Open Snackbar</Button>
      <Snackbar
        {...props}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        action={action}
      />
    </div>
  );
}

const SnackbarError = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClick}>Open Snackbar</Button>
      <Snackbar
        {...props}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity="error"
          title="Error!"
        />
      </Snackbar>
    </div>
  );
}

const SnackbarSuccess = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  return (
    <div>
      <Button onClick={handleClick}>Open Snackbar</Button>
      <Snackbar
        {...props}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity="success"
          title="Success!"
        />
      </Snackbar>
    </div>
  );
}

const Timelines = () => {
  const items = [
    {content: "Comer", opposite: "9h"},
    {content: "Malhar", opposite: "16h"},
    {content: "Dormir", opposite: "23h"},
  ];

  const renderItems = (variant = "filled") => {
    return items.map((item, index) => (
      <TimelineItem key={index}>
        {item.opposite && (
          <TimelineOppositeContent>
            {item.opposite}
          </TimelineOppositeContent>
        )}
        <TimelineSeparator>
          <TimelineDot variant={variant} />
          {index + 1 != items.length && (<TimelineConnector />)}
        </TimelineSeparator>
        <TimelineContent>
          {item.content}
        </TimelineContent>
      </TimelineItem>
    ));
  }

  return (
    <>
      <Timeline>{renderItems()}</Timeline>
      <Timeline position="alternate">{renderItems()}</Timeline>
      <Timeline position="left">{renderItems()}</Timeline>
      <Timeline position="alternate-reverse">{renderItems()}</Timeline>
      <Timeline>{renderItems("outlined")}</Timeline>
      <Timeline position="alternate">{renderItems("outlined")}</Timeline>
      <Timeline position="left">{renderItems("outlined")}</Timeline>
      <Timeline position="alternate-reverse">{renderItems("outlined")}</Timeline>
    </>
  )
}

const Groups = () => {
  return (
    <div style={{ padding: "40px"}}>
      <FormControl>
        <FormLabel>Label</FormLabel>
        <RadioGroup>
          <FormControlLabel value="1" control={<Radio />} label="Label" />
          <FormControlLabel value="2" control={<Radio />} label="Label" />
          <FormControlLabel value="3" control={<Radio />} label="Label" />
        </RadioGroup>
        <FormHelperText>Helper text</FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel>Label</FormLabel>
        <FormGroup>
          <FormControlLabel value="1" control={<Checkbox />} label="Label" />
          <FormControlLabel value="2" control={<Checkbox />} label="Label" />
          <FormControlLabel value="3" control={<Checkbox />} label="Label" />
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Label</FormLabel>
        <FormGroup>
          <FormControlLabel value="1" control={<SwitchBase />} label="Label" />
          <FormControlLabel value="2" control={<SwitchBase />} label="Label" />
          <FormControlLabel value="3" control={<SwitchBase />} label="Label" />
        </FormGroup>
      </FormControl>
    </div>
  )
}

const Selects = () => {
  const currencies = [
    {
      value: 'USD',
      label: 'Dólar',
    },
    {
      value: 'EUR',
      label: 'Euro',
    },
    {
      value: 'BTC',
      label: 'Bitcoin',
    },
    {
      value: 'JPY',
      label: 'Yen',
    },
  ];

  return (
    <>
      <div style={{margin: "40px", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "15px"}}>
        <TextField
          select
          label="Select"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Select"
          variant="filled"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Select"
          variant="standard"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div style={{margin: "40px", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "15px"}}>
        <TextField
          select
          label="Select"
          defaultValue="EUR"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Select"
          defaultValue="EUR"
          variant="filled"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Select"
          defaultValue="EUR"
          variant="standard"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div style={{margin: "40px", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "15px"}}>
        <TextField
          select
          label="Select"
          error
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Select"
          variant="filled"
          error
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Select"
          variant="standard"
          error
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div style={{margin: "40px", display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "15px"}}>
        <TextField
          select
          label="Select"
          defaultValue="EUR"
          error
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Select"
          defaultValue="EUR"
          variant="filled"
          error
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Select"
          defaultValue="EUR"
          variant="standard"
          error
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </>
  )
}

const TextFields = () => {
  return (
    <>
      <div style={{margin: "40px", display: "flex", gap: "15px"}}>
        <TextField label="Label Testando" />
        <TextField label="Label" variant="filled" />
        <TextField label="Label" variant="standard" />
      </div>
      <div style={{margin: "40px", display: "flex", gap: "15px"}}>
        <TextField helperText="Helper text" label="Label Testando" />
        <TextField helperText="Helper text" label="Label" variant="filled" />
        <TextField helperText="Helper text" label="Label" variant="standard" />
      </div>
      <div style={{margin: "40px", display: "flex", gap: "15px"}}>
        <TextField disabled label="Label Testando" />
        <TextField disabled label="Label" variant="filled" />
        <TextField disabled label="Label" variant="standard" />
      </div>
      <div style={{margin: "40px", display: "flex", gap: "15px"}}>
        <TextField disabled helperText="Helper text" label="Label Testando" />
        <TextField disabled helperText="Helper text" label="Label" variant="filled" />
        <TextField disabled helperText="Helper text" label="Label" variant="standard" />
      </div>
      <div style={{margin: "40px", display: "flex", gap: "15px"}}>
        <TextField error label="Label Testando" />
        <TextField error label="Label" variant="filled" />
        <TextField error label="Label" variant="standard" />
      </div>
      <div style={{margin: "40px", display: "flex", gap: "15px"}}>
        <TextField error helperText="Helper text" label="Label Testando" />
        <TextField error helperText="Helper text" label="Label" variant="filled" />
        <TextField error helperText="Helper text" label="Label" variant="standard" />
      </div>
    </>
  )
}

const Steppers = () => {
  const steps = ["Step title", "Step title", "Step title"];

  return (
    <>
      <Box sx={{ margin: "45px" }}>
        <Stepper activeStep={1}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box sx={{ margin: "45px" }}>
        <Stepper activeStep={1}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                optional={<Typography variant="body2">Optional</Typography>}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box sx={{ margin: "45px" }}>
        <Stepper activeStep={1} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box sx={{ margin: "45px" }}>
        <Stepper activeStep={1} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                optional={<Typography variant="body2">Optional</Typography>}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box sx={{ margin: "45px", display: "flex", gap: "60px" }}>
        <Stepper activeStep={1} orientation="vertical">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Stepper activeStep={1} orientation="vertical">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                optional={<Typography variant="body2">Optional</Typography>}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box sx={{ margin: "45px", display: "flex", gap: "60px" }}>
        <Stepper activeStep={1} alternativeLabel orientation="vertical">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Stepper activeStep={1} alternativeLabel orientation="vertical">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                optional={<Typography variant="body2">Optional</Typography>}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box sx={{ margin: "45px" }}>
        <MobileStepper variant="text" steps={steps.length} activeStep={1} position="static" />
        <MobileStepper variant="dots" steps={steps.length} activeStep={1} position="static" />
        <MobileStepper variant="progress" steps={steps.length} activeStep={1} position="static" />
      </Box>
    </>
  );
};

const Menus = () => {
  return (
    <Menu
      id="basic-menu"
      open={true}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={() => {}} selected>
        Profile
      </MenuItem>
      <MenuItem onClick={() => {}}>My account</MenuItem>
      <MenuItem divider onClick={() => {}}>Divider</MenuItem>
      <MenuItem onClick={() => {}} disabled>
        Logout
      </MenuItem>
    </Menu>
  );
};

const Paginations = () => {
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        margin: "40px",
        flexDirection: "column",
      }}
    >
      <Pagination count={10} color="standard" size="large" />
      <Pagination count={10} color="standard" size="medium" />
      <Pagination count={10} color="standard" size="small" />
      <Pagination count={10} color="standard" disabled />
      <Pagination count={10} color="standard" variant="outlined" />
      <Pagination count={10} color="standard" variant="outlined" disabled />
      <Pagination count={10} color="standard" shape="rounded" />
      <Pagination count={10} color="standard" shape="rounded" disabled />
      <Pagination
        count={10}
        color="standard"
        variant="outlined"
        shape="rounded"
      />
      <Pagination
        count={10}
        color="standard"
        variant="outlined"
        shape="rounded"
        disabled
      />
      <Pagination count={10} color="primary" />
      <Pagination count={10} color="primary" disabled />
      <Pagination count={10} color="primary" variant="outlined" />
      <Pagination count={10} color="primary" variant="outlined" disabled />
      <Pagination count={10} color="primary" shape="rounded" />
      <Pagination count={10} color="primary" shape="rounded" disabled />
      <Pagination
        count={10}
        color="primary"
        variant="outlined"
        shape="rounded"
      />
      <Pagination
        count={10}
        color="primary"
        variant="outlined"
        shape="rounded"
        disabled
      />
      <Pagination count={10} color="secondary" />
      <Pagination count={10} color="secondary" disabled />
      <Pagination count={10} color="secondary" variant="outlined" />
      <Pagination count={10} color="secondary" variant="outlined" disabled />
      <Pagination count={10} color="secondary" shape="rounded" />
      <Pagination count={10} color="secondary" shape="rounded" disabled />
      <Pagination
        count={10}
        color="secondary"
        variant="outlined"
        shape="rounded"
      />
      <Pagination
        count={10}
        color="secondary"
        variant="outlined"
        shape="rounded"
        disabled
      />
    </div>
  );
};

const ScatterCharts = () => {
  const data = [
    {
      id: "data-0",
      x1: 329.39,
      x2: 391.29,
      y1: 443.28,
      y2: 153.9,
    },
    {
      id: "data-1",
      x1: 96.94,
      x2: 139.6,
      y1: 110.5,
      y2: 217.8,
    },
    {
      id: "data-2",
      x1: 336.35,
      x2: 282.34,
      y1: 175.23,
      y2: 286.32,
    },
    {
      id: "data-3",
      x1: 159.44,
      x2: 384.85,
      y1: 195.97,
      y2: 325.12,
    },
    {
      id: "data-4",
      x1: 188.86,
      x2: 182.27,
      y1: 351.77,
      y2: 144.58,
    },
    {
      id: "data-5",
      x1: 143.86,
      x2: 360.22,
      y1: 43.253,
      y2: 146.51,
    },
    {
      id: "data-6",
      x1: 202.02,
      x2: 209.5,
      y1: 376.34,
      y2: 309.69,
    },
    {
      id: "data-7",
      x1: 384.41,
      x2: 258.93,
      y1: 31.514,
      y2: 236.38,
    },
    {
      id: "data-8",
      x1: 256.76,
      x2: 70.571,
      y1: 231.31,
      y2: 440.72,
    },
    {
      id: "data-9",
      x1: 143.79,
      x2: 419.02,
      y1: 108.04,
      y2: 20.29,
    },
    {
      id: "data-10",
      x1: 103.48,
      x2: 15.886,
      y1: 321.77,
      y2: 484.17,
    },
    {
      id: "data-11",
      x1: 272.39,
      x2: 189.03,
      y1: 120.18,
      y2: 54.962,
    },
    {
      id: "data-12",
      x1: 23.57,
      x2: 456.4,
      y1: 366.2,
      y2: 418.5,
    },
    {
      id: "data-13",
      x1: 219.73,
      x2: 235.96,
      y1: 451.45,
      y2: 181.32,
    },
    {
      id: "data-14",
      x1: 54.99,
      x2: 434.5,
      y1: 294.8,
      y2: 440.9,
    },
    {
      id: "data-15",
      x1: 134.13,
      x2: 383.8,
      y1: 121.83,
      y2: 273.52,
    },
    {
      id: "data-16",
      x1: 12.7,
      x2: 270.8,
      y1: 287.7,
      y2: 346.7,
    },
    {
      id: "data-17",
      x1: 176.51,
      x2: 119.17,
      y1: 134.06,
      y2: 74.528,
    },
    {
      id: "data-18",
      x1: 65.05,
      x2: 78.93,
      y1: 104.5,
      y2: 150.9,
    },
    {
      id: "data-19",
      x1: 162.25,
      x2: 63.707,
      y1: 413.07,
      y2: 26.483,
    },
    {
      id: "data-20",
      x1: 68.88,
      x2: 150.8,
      y1: 74.68,
      y2: 333.2,
    },
    {
      id: "data-21",
      x1: 95.29,
      x2: 329.1,
      y1: 360.6,
      y2: 422.0,
    },
    {
      id: "data-22",
      x1: 390.62,
      x2: 10.01,
      y1: 330.72,
      y2: 488.06,
    },
  ];

  return (
    <>
      <div style={{ display: "flex" }}>
        <ScatterChart
          width={600}
          height={300}
          series={[
            {
              label: "Series A",
              data: data.map((v) => ({ x: v.x1, y: v.y1, id: v.id })),
            },
          ]}
        />
        <ScatterChart
          width={600}
          height={300}
          series={[
            {
              label: "Series A",
              data: data.map((v) => ({ x: v.x1, y: v.y1, id: v.id })),
            },
            {
              label: "Series B",
              data: data.map((v) => ({ x: v.x1, y: v.y2, id: v.id })),
            },
          ]}
        />
      </div>
      <div style={{ display: "flex" }}>
        <ScatterChart
          width={600}
          height={300}
          series={[
            {
              label: "Series A",
              data: data.map((v) => ({ x: v.x1, y: v.y1, id: v.id })),
            },
            {
              label: "Series B",
              data: data.map((v) => ({ x: v.x1, y: v.y2, id: v.id })),
            },
            {
              label: "Series C",
              data: data.map((v) => ({
                x: v.x1 * 1.5,
                y: v.y1 * 1.25,
                id: v.id,
              })),
            },
          ]}
        />
        <ScatterChart
          width={600}
          height={300}
          series={[
            {
              label: "Series A",
              data: data.map((v) => ({ x: v.x1, y: v.y1, id: v.id })),
            },
            {
              label: "Series B",
              data: data.map((v) => ({ x: v.x1, y: v.y2, id: v.id })),
            },
          ]}
          rightAxis={{}}
        />
      </div>
    </>
  );
};

const PieCharts = () => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: 10, label: "series A" },
                { id: 1, value: 15, label: "series B" },
                { id: 2, value: 20, label: "series C" },
              ],
            },
          ]}
          width={400}
          height={200}
        />
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: 10, label: "series A" },
                { id: 1, value: 15, label: "series B" },
                { id: 2, value: 20, label: "series C" },
              ],
              innerRadius: 75,
            },
          ]}
          width={400}
          height={200}
        />
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: 10, label: "series A" },
                { id: 1, value: 15, label: "series B" },
                { id: 2, value: 20, label: "series C" },
              ],
              innerRadius: 50,
            },
          ]}
          width={400}
          height={200}
        />
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: 10, label: "series A" },
                { id: 1, value: 15, label: "series B" },
                { id: 2, value: 20, label: "series C" },
              ],
              innerRadius: 25,
            },
          ]}
          width={400}
          height={200}
        />
      </div>
      <div style={{ display: "flex" }}>
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: 10, label: "series A" },
                { id: 1, value: 15, label: "series B" },
                { id: 2, value: 20, label: "series C" },
              ],
              startAngle: -90,
              endAngle: 90,
            },
          ]}
          width={400}
          height={200}
        />
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: 10, label: "series A" },
                { id: 1, value: 15, label: "series B" },
                { id: 2, value: 20, label: "series C" },
              ],
              innerRadius: 75,
              startAngle: -90,
              endAngle: 90,
            },
          ]}
          width={400}
          height={200}
        />
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: 10, label: "series A" },
                { id: 1, value: 15, label: "series B" },
                { id: 2, value: 20, label: "series C" },
              ],
              innerRadius: 50,
              startAngle: -90,
              endAngle: 90,
            },
          ]}
          width={400}
          height={200}
        />
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: 10, label: "series A" },
                { id: 1, value: 15, label: "series B" },
                { id: 2, value: 20, label: "series C" },
              ],
              innerRadius: 25,
              startAngle: -90,
              endAngle: 90,
            },
          ]}
          width={400}
          height={200}
        />
      </div>
      <div style={{ display: "flex" }}>
        <PieChart
          series={[
            {
              innerRadius: 0,
              outerRadius: 80,
              id: "platform-series",
              data: [
                { id: 0, value: 10, label: "series A" },
                { id: 1, value: 15, label: "series B" },
                { id: 2, value: 20, label: "series C" },
              ],
            },
            {
              innerRadius: 100,
              outerRadius: 120,
              id: "OS-series",
              data: [
                { id: 0, value: 4, label: "series D" },
                { id: 1, value: 36, label: "series E" },
                { id: 2, value: 12, label: "series F" },
              ],
            },
          ]}
          width={500}
          height={300}
        />
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: 10, label: "series A" },
                { id: 1, value: 15, label: "series B" },
                { id: 2, value: 20, label: "series C" },
              ],
              innerRadius: 75,
              paddingAngle: 5,
              startAngle: -90,
              endAngle: 90,
            },
          ]}
          width={400}
          height={200}
        />
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: 10, label: "series A" },
                { id: 1, value: 15, label: "series B" },
                { id: 2, value: 20, label: "series C" },
              ],
              arcLabel: (item) => `${item.value}%`,
              arcLabelMinAngle: 35,
            },
          ]}
          width={400}
          height={200}
        />
      </div>
    </>
  );
};

const LineCharts = () => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <LineChart
          xAxis={[{ data: [0, 22, 44, 63, 74, 100] }]}
          series={[{ data: [7, 19, 29, 45, 58, 89], label: "Legenda" }]}
          width={500}
          height={300}
        />

        <LineChart
          xAxis={[
            { data: [0, 22, 44, 63, 74, 100] },
            { data: [0, 14, 35, 50, 69, 85] },
          ]}
          series={[
            { data: [10, 20, 39, 57, 76, 95] },
            { data: [7, 19, 29, 45, 58, 89] },
          ]}
          width={500}
          height={300}
        />

        <LineChart
          xAxis={[
            { data: [0, 22, 44, 63, 74, 100] },
            { data: [0, 14, 35, 50, 69, 85] },
          ]}
          series={[
            { data: [10, 20, 39, 57, 76, 95] },
            { data: [7, 19, 29, 45, 58, 89] },
          ]}
          width={500}
          height={300}
          bottomAxis={null}
        />
      </div>
    </>
  );
};

const BarCharts = () => {
  const dataset = [
    { value: 100, value2: 18, label: "Um" },
    { value: 30, value2: 74, label: "Dois" },
    { value: 52, value2: 12, label: "Três" },
    { value: 79, value2: 96, label: "Quatro" },
    { value: 39, value2: 43, label: "Cinco" },
    { value: 20, value2: 63, label: "Seis" },
    { value: 7, value2: 55, label: "Sete" },
  ];

  return (
    <>
      <div style={{ display: "flex" }}>
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: [
                "Group A",
                "Group B",
                "Group C",
                "Group D",
                "Group E",
                "Group F",
                "Group G",
              ],
            },
          ]}
          dataset={dataset}
          series={[{ dataKey: "value", scaleType: "band", label: "Legenda" }]}
          width={500}
          height={300}
        />

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: [
                "Group A",
                "Group B",
                "Group C",
                "Group D",
                "Group E",
                "Group F",
                "Group G",
              ],
            },
          ]}
          dataset={dataset}
          series={[{ dataKey: "value", scaleType: "band", label: "Legenda" }]}
          width={500}
          height={300}
          leftAxis={null}
        />

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: [
                "Group A",
                "Group B",
                "Group C",
                "Group D",
                "Group E",
                "Group F",
                "Group G",
              ],
            },
          ]}
          dataset={dataset}
          series={[{ dataKey: "value", scaleType: "band", label: "Legenda" }]}
          width={500}
          height={300}
          rightAxis={{}}
        />
      </div>
      <div style={{ display: "flex" }}>
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: [
                "Group A",
                "Group B",
                "Group C",
                "Group D",
                "Group E",
                "Group F",
                "Group G",
              ],
            },
          ]}
          dataset={dataset}
          series={[{ dataKey: "value", scaleType: "band", label: "Legenda" }]}
          width={500}
          height={300}
          bottomAxis={null}
        />

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: [
                "Group A",
                "Group B",
                "Group C",
                "Group D",
                "Group E",
                "Group F",
                "Group G",
              ],
            },
          ]}
          dataset={dataset}
          series={[{ dataKey: "value", scaleType: "band", label: "Legenda" }]}
          width={500}
          height={300}
          bottomAxis={null}
          leftAxis={null}
        />

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: [
                "Group A",
                "Group B",
                "Group C",
                "Group D",
                "Group E",
                "Group F",
                "Group G",
              ],
            },
          ]}
          dataset={dataset}
          series={[{ dataKey: "value", scaleType: "band" }]}
          width={500}
          height={300}
        />
      </div>
      <div style={{ display: "flex" }}>
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: [
                "Group A",
                "Group B",
                "Group C",
                "Group D",
                "Group E",
                "Group F",
                "Group G",
              ],
            },
          ]}
          dataset={dataset}
          series={[
            { dataKey: "value", label: "Legenda 1" },
            { dataKey: "value2", label: "Legenda 2" },
          ]}
          width={500}
          height={300}
        />

        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: [
                "Group A",
                "Group B",
                "Group C",
                "Group D",
                "Group E",
                "Group F",
                "Group G",
              ],
            },
          ]}
          series={[
            {
              data: [39, 43, 15, 55, 67, 23, 91],
              label: "Legenda 1",
              stack: "total",
            },
            {
              data: [42, 34, 71, 55, 18, 81, 64],
              label: "Legenda 2",
              stack: "total",
            },
          ]}
          width={500}
          height={300}
        />
      </div>
    </>
  );
};

const Accordions = () => {
  return (
    <div style={{ margin: "40px" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Accordion 1
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>
      <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Accordion 2
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Accordion 3
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Accordion 4
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const Tabz = () => {
  const [value, setValue] = React.useState("two");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div style={{ margin: "40px", display: "flex" }}>
        <Tabs value="two" onChange={handleChange}>
          <Tab value="one" label="Tab" />
          <Tab value="two" label="Tab" />
          <Tab disabled value="three" label="Tab" />
          <Tab value="four" label="Tab" />
          <Tab value="five" label="Tab" />
          <Tab value="six" label="Tab" />
          <Tab value="seven" label="Tab" />
        </Tabs>
      </div>
      <div style={{ margin: "40px", display: "flex", width: "300px" }}>
        <Tabs
          value="two"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          onChange={handleChange}
        >
          <Tab value="one" label="Tab" />
          <Tab value="two" label="Tab" />
          <Tab disabled value="three" label="Tab" />
          <Tab value="four" label="Tab" />
          <Tab value="five" label="Tab" />
          <Tab value="six" label="Tab" />
          <Tab value="seven" label="Tab" />
        </Tabs>
      </div>
      <div style={{ margin: "40px", display: "flex" }}>
        <Tabs value="two" orientation="vertical" onChange={handleChange}>
          <Tab value="one" label="Tab" />
          <Tab value="two" label="Tab" />
          <Tab disabled value="three" label="Tab" />
          <Tab value="four" label="Tab" />
          <Tab value="five" label="Tab" />
          <Tab value="six" label="Tab" />
          <Tab value="seven" label="Tab" />
        </Tabs>
      </div>
      <div style={{ margin: "40px", display: "flex", height: "300px" }}>
        <Tabs
          value="two"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          orientation="vertical"
          onChange={handleChange}
        >
          <Tab value="one" label="Tab" />
          <Tab value="two" label="Tab" />
          <Tab disabled value="three" label="Tab" />
          <Tab value="four" label="Tab" />
          <Tab value="five" label="Tab" />
          <Tab value="six" label="Tab" />
          <Tab value="seven" label="Tab" />
        </Tabs>
      </div>
    </>
  );
};

const Chips = () => {
  return (
    <>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip clickable label="Chip" />
        <Chip clickable label="Chip" variant="outlined" />
        <Chip clickable label="Chip" color="primary" />
        <Chip clickable label="Chip" variant="outlined" color="primary" />
        <Chip clickable label="Chip" color="secondary" />
        <Chip clickable label="Chip" variant="outlined" color="secondary" />
        <Chip clickable label="Chip" color="error" />
        <Chip clickable label="Chip" variant="outlined" color="error" />
        <Chip clickable label="Chip" color="info" />
        <Chip clickable label="Chip" variant="outlined" color="info" />
        <Chip clickable label="Chip" color="success" />
        <Chip clickable label="Chip" variant="outlined" color="success" />
        <Chip clickable label="Chip" color="warning" />
        <Chip clickable label="Chip" variant="outlined" color="warning" />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip clickable onDelete={() => {}} label="Chip" />
        <Chip clickable onDelete={() => {}} label="Chip" variant="outlined" />
        <Chip clickable onDelete={() => {}} label="Chip" color="primary" />
        <Chip
          clickable
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip clickable onDelete={() => {}} label="Chip" color="secondary" />
        <Chip
          clickable
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip clickable onDelete={() => {}} label="Chip" color="error" />
        <Chip
          clickable
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip clickable onDelete={() => {}} label="Chip" color="info" />
        <Chip
          clickable
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip clickable onDelete={() => {}} label="Chip" color="success" />
        <Chip
          clickable
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip clickable onDelete={() => {}} label="Chip" color="warning" />
        <Chip
          clickable
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip disabled label="Chip" />
        <Chip disabled label="Chip" variant="outlined" />
        <Chip disabled label="Chip" color="primary" />
        <Chip disabled label="Chip" variant="outlined" color="primary" />
        <Chip disabled label="Chip" color="secondary" />
        <Chip disabled label="Chip" variant="outlined" color="secondary" />
        <Chip disabled label="Chip" color="error" />
        <Chip disabled label="Chip" variant="outlined" color="error" />
        <Chip disabled label="Chip" color="info" />
        <Chip disabled label="Chip" variant="outlined" color="info" />
        <Chip disabled label="Chip" color="success" />
        <Chip disabled label="Chip" variant="outlined" color="success" />
        <Chip disabled label="Chip" color="warning" />
        <Chip disabled label="Chip" variant="outlined" color="warning" />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip disabled onDelete={() => {}} label="Chip" />
        <Chip disabled onDelete={() => {}} label="Chip" variant="outlined" />
        <Chip disabled onDelete={() => {}} label="Chip" color="primary" />
        <Chip
          disabled
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip disabled onDelete={() => {}} label="Chip" color="secondary" />
        <Chip
          disabled
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip disabled onDelete={() => {}} label="Chip" color="error" />
        <Chip
          disabled
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip disabled onDelete={() => {}} label="Chip" color="info" />
        <Chip
          disabled
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip disabled onDelete={() => {}} label="Chip" color="success" />
        <Chip
          disabled
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip disabled onDelete={() => {}} label="Chip" color="warning" />
        <Chip
          disabled
          onDelete={() => {}}
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip clickable avatar={<Avatar>OP</Avatar>} label="Chip" />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="primary"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="secondary"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="error"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="info"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="success"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="warning"
        />
        <Chip
          clickable
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="primary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="secondary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="error"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="info"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="success"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="warning"
        />
        <Chip
          clickable
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip disabled avatar={<Avatar>OP</Avatar>} label="Chip" />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
        />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="primary"
        />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="secondary"
        />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="error"
        />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip disabled avatar={<Avatar>OP</Avatar>} label="Chip" color="info" />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="success"
        />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="warning"
        />
        <Chip
          disabled
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="primary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="secondary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="error"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="info"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="success"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="warning"
        />
        <Chip
          disabled
          onDelete={() => {}}
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip clickable size="small" label="Chip" />
        <Chip clickable size="small" label="Chip" variant="outlined" />
        <Chip clickable size="small" label="Chip" color="primary" />
        <Chip
          clickable
          size="small"
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip clickable size="small" label="Chip" color="secondary" />
        <Chip
          clickable
          size="small"
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip clickable size="small" label="Chip" color="error" />
        <Chip
          clickable
          size="small"
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip clickable size="small" label="Chip" color="info" />
        <Chip
          clickable
          size="small"
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip clickable size="small" label="Chip" color="success" />
        <Chip
          clickable
          size="small"
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip clickable size="small" label="Chip" color="warning" />
        <Chip
          clickable
          size="small"
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip clickable onDelete={() => {}} size="small" label="Chip" />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="primary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="secondary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="error"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="info"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="success"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="warning"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip disabled size="small" label="Chip" />
        <Chip disabled size="small" label="Chip" variant="outlined" />
        <Chip disabled size="small" label="Chip" color="primary" />
        <Chip
          disabled
          size="small"
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip disabled size="small" label="Chip" color="secondary" />
        <Chip
          disabled
          size="small"
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip disabled size="small" label="Chip" color="error" />
        <Chip
          disabled
          size="small"
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip disabled size="small" label="Chip" color="info" />
        <Chip
          disabled
          size="small"
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip disabled size="small" label="Chip" color="success" />
        <Chip
          disabled
          size="small"
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip disabled size="small" label="Chip" color="warning" />
        <Chip
          disabled
          size="small"
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip disabled onDelete={() => {}} size="small" label="Chip" />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="primary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="secondary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="error"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="info"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="success"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          color="warning"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="primary"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="secondary"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="error"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="info"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="success"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="warning"
        />
        <Chip
          clickable
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="primary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="secondary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="error"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="info"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="success"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="warning"
        />
        <Chip
          clickable
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip disabled size="small" avatar={<Avatar>OP</Avatar>} label="Chip" />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="primary"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="secondary"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="error"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="info"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="success"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="warning"
        />
        <Chip
          disabled
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
      <div style={{ margin: "40px", display: "flex", gap: "10px" }}>
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="primary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="primary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="secondary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="secondary"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="error"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="error"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="info"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="info"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="success"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="success"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          color="warning"
        />
        <Chip
          disabled
          onDelete={() => {}}
          size="small"
          avatar={<Avatar>OP</Avatar>}
          label="Chip"
          variant="outlined"
          color="warning"
        />
      </div>
    </>
  );
};

const Ratings = () => {
  return (
    <div
      style={{
        margin: "40px",
        display: "flex",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      <Rating size="small" />
      <Rating />
      <Rating size="large" />
      <Rating precision={0.5} size="small" />
      <Rating precision={0.5} />
      <Rating precision={0.25} size="large" />
    </div>
  );
};

const Alerts = () => {
  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <Alert
        title="Alert"
        description="Description"
        variant="filled"
        severity="error"
      />
      <Alert
        title="Alert"
        description="Description"
        variant="filled"
        severity="info"
      />
      <Alert
        title="Alert"
        description="Description"
        variant="filled"
        severity="success"
      />
      <Alert
        title="Alert"
        description="Description"
        variant="filled"
        severity="warning"
      />
      <Alert
        title="Alert"
        description="Description"
        variant="outlined"
        severity="error"
      />
      <Alert
        title="Alert"
        description="Description"
        variant="outlined"
        severity="info"
      />
      <Alert
        title="Alert"
        description="Description"
        variant="outlined"
        severity="success"
      />
      <Alert
        title="Alert"
        description="Description"
        variant="outlined"
        severity="warning"
      />
      <Alert
        title="Alert"
        description="Description"
        variant="standard"
        severity="error"
      />
      <Alert
        title="Alert"
        description="Description"
        variant="standard"
        severity="info"
      />
      <Alert
        title="Alert"
        description="Description"
        variant="standard"
        severity="success"
      />
      <Alert
        title="Alert"
        description="Description"
        variant="standard"
        severity="warning"
      />
      <Alert description="Description" variant="filled" severity="error" />
      <Alert description="Description" variant="filled" severity="info" />
      <Alert description="Description" variant="filled" severity="success" />
      <Alert description="Description" variant="filled" severity="warning" />
      <Alert description="Description" variant="outlined" severity="error" />
      <Alert description="Description" variant="outlined" severity="info" />
      <Alert description="Description" variant="outlined" severity="success" />
      <Alert description="Description" variant="outlined" severity="warning" />
      <Alert description="Description" variant="standard" severity="error" />
      <Alert description="Description" variant="standard" severity="info" />
      <Alert description="Description" variant="standard" severity="success" />
      <Alert description="Description" variant="standard" severity="warning" />
      <Alert title="Alert" variant="filled" severity="error" />
      <Alert title="Alert" variant="filled" severity="info" />
      <Alert title="Alert" variant="filled" severity="success" />
      <Alert title="Alert" variant="filled" severity="warning" />
      <Alert title="Alert" variant="outlined" severity="error" />
      <Alert title="Alert" variant="outlined" severity="info" />
      <Alert title="Alert" variant="outlined" severity="success" />
      <Alert title="Alert" variant="outlined" severity="warning" />
      <Alert title="Alert" variant="standard" severity="error" />
      <Alert title="Alert" variant="standard" severity="info" />
      <Alert title="Alert" variant="standard" severity="success" />
      <Alert title="Alert" variant="standard" severity="warning" />

      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="filled"
        severity="error"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="filled"
        severity="info"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="filled"
        severity="success"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="filled"
        severity="warning"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="outlined"
        severity="error"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="outlined"
        severity="info"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="outlined"
        severity="success"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="outlined"
        severity="warning"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="standard"
        severity="error"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="standard"
        severity="info"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="standard"
        severity="success"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        description="Description"
        variant="standard"
        severity="warning"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="filled"
        severity="error"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="filled"
        severity="info"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="filled"
        severity="success"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="filled"
        severity="warning"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="outlined"
        severity="error"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="outlined"
        severity="info"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="outlined"
        severity="success"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="outlined"
        severity="warning"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="standard"
        severity="error"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="standard"
        severity="info"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="standard"
        severity="success"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        description="Description"
        variant="standard"
        severity="warning"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="filled"
        severity="error"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="filled"
        severity="info"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="filled"
        severity="success"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="filled"
        severity="warning"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="outlined"
        severity="error"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="outlined"
        severity="info"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="outlined"
        severity="success"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="outlined"
        severity="warning"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="standard"
        severity="error"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="standard"
        severity="info"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="standard"
        severity="success"
      />
      <Alert
        action={
          <Button color="inherit" size="small">
            Label
          </Button>
        }
        title="Alert"
        variant="standard"
        severity="warning"
      />

      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="filled"
        severity="error"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="filled"
        severity="info"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="filled"
        severity="success"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="filled"
        severity="warning"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="outlined"
        severity="error"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="outlined"
        severity="info"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="outlined"
        severity="success"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="outlined"
        severity="warning"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="standard"
        severity="error"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="standard"
        severity="info"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="standard"
        severity="success"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        description="Description"
        variant="standard"
        severity="warning"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="filled"
        severity="error"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="filled"
        severity="info"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="filled"
        severity="success"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="filled"
        severity="warning"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="outlined"
        severity="error"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="outlined"
        severity="info"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="outlined"
        severity="success"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="outlined"
        severity="warning"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="standard"
        severity="error"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="standard"
        severity="info"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="standard"
        severity="success"
      />
      <Alert
        onClose={() => {}}
        description="Description"
        variant="standard"
        severity="warning"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="filled"
        severity="error"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="filled"
        severity="info"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="filled"
        severity="success"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="filled"
        severity="warning"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="outlined"
        severity="error"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="outlined"
        severity="info"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="outlined"
        severity="success"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="outlined"
        severity="warning"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="standard"
        severity="error"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="standard"
        severity="info"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="standard"
        severity="success"
      />
      <Alert
        onClose={() => {}}
        title="Alert"
        variant="standard"
        severity="warning"
      />
    </div>
  );
};

const Backdrops = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button onClick={handleOpen}>Show backdrop</Button>
      <Backdrop open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const Breadcrumb = () => {
  return (
    <>
      <div style={{ marginTop: "40px", marginLeft: "40px" }}>
        <Breadcrumbs>
          <Link to="#">
            <StarIcon /> Link 1
          </Link>
          <Link to="/usuarios/home">
            <StarIcon /> Link 2
          </Link>
          <Link href="/vagas">
            <StarIcon /> Link 3
          </Link>
        </Breadcrumbs>
        <Breadcrumbs arrowSeparator>
          <Link to="#">
            <StarIcon /> Link 1
          </Link>
          <Link to="/usuarios/home">
            <StarIcon /> Link 2
          </Link>
          <Link href="/vagas">
            <StarIcon /> Link 3
          </Link>
        </Breadcrumbs>
        <Breadcrumbs maxItems={2}>
          <Link to="#">
            <StarIcon /> Link 1
          </Link>
          <Link to="/usuarios/home">
            <StarIcon /> Link 2
          </Link>
          <Link href="/vagas">
            <StarIcon /> Link 3
          </Link>
        </Breadcrumbs>
        <Breadcrumbs maxItems={2} separator={<ArrowRight />}>
          <Link to="#">
            <StarIcon /> Link 1
          </Link>
          <Link to="/usuarios/home">
            <StarIcon /> Link 2
          </Link>
          <Link href="/vagas">
            <StarIcon /> Link 3
          </Link>
        </Breadcrumbs>
        <Breadcrumbs>
          <Link to="#">Link 1</Link>
          <Link to="/usuarios/home">Link 2</Link>
          <Link href="/vagas">Link 3</Link>
        </Breadcrumbs>
        <Breadcrumbs separator={<ArrowRight />}>
          <Link to="#">Link 1</Link>
          <Link to="/usuarios/home">Link 2</Link>
          <Link href="/vagas">Link 3</Link>
        </Breadcrumbs>
        <Breadcrumbs maxItems={2}>
          <Link to="#">Link 1</Link>
          <Link to="/usuarios/home">Link 2</Link>
          <Link href="/vagas">Link 3</Link>
        </Breadcrumbs>
        <Breadcrumbs maxItems={2} separator={<ArrowRight />}>
          <Link to="#">Link 1</Link>
          <Link to="/usuarios/home">Link 2</Link>
          <Link href="/vagas">Link 3</Link>
        </Breadcrumbs>
      </div>
    </>
  );
};

const Avatars = () => {
  return (
    <>
      <div style={sliderDivStyle}>
        <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        <Avatar
          variant="rounded"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          variant="square"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          size="medium"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          size="medium"
          variant="rounded"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          size="medium"
          variant="square"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar size="small" src="https://mui.com/static/images/avatar/1.jpg" />
        <Avatar
          size="small"
          variant="rounded"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          size="small"
          variant="square"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar size="xs" src="https://mui.com/static/images/avatar/1.jpg" />
        <Avatar
          size="xs"
          variant="rounded"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          size="xs"
          variant="square"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
      </div>
      <div style={sliderDivStyle}>
        <Avatar badge src="https://mui.com/static/images/avatar/1.jpg" />
        <Avatar
          badge
          variant="rounded"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          badge
          variant="square"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          badge
          size="medium"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          badge
          size="medium"
          variant="rounded"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          badge
          size="medium"
          variant="square"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          badge
          size="small"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          badge
          size="small"
          variant="rounded"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          badge
          size="small"
          variant="square"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          badge
          size="xs"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          badge
          size="xs"
          variant="rounded"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
        <Avatar
          badge
          size="xs"
          variant="square"
          src="https://mui.com/static/images/avatar/1.jpg"
        />
      </div>
      <div style={sliderDivStyle}>
        <Avatar variant="circular">OP</Avatar>
        <Avatar variant="rounded">OP</Avatar>
        <Avatar variant="square">OP</Avatar>
        <Avatar size="medium" variant="circular">
          OP
        </Avatar>
        <Avatar size="medium" variant="rounded">
          OP
        </Avatar>
        <Avatar size="medium" variant="square">
          OP
        </Avatar>
        <Avatar size="small" variant="circular">
          OP
        </Avatar>
        <Avatar size="small" variant="rounded">
          OP
        </Avatar>
        <Avatar size="small" variant="square">
          OP
        </Avatar>
        <Avatar size="xs" variant="circular">
          OP
        </Avatar>
        <Avatar size="xs" variant="rounded">
          OP
        </Avatar>
        <Avatar size="xs" variant="square">
          OP
        </Avatar>
      </div>
      <div style={sliderDivStyle}>
        <Avatar badge variant="circular">
          OP
        </Avatar>
        <Avatar badge variant="rounded">
          OP
        </Avatar>
        <Avatar badge variant="square">
          OP
        </Avatar>
        <Avatar badge size="medium" variant="circular">
          OP
        </Avatar>
        <Avatar badge size="medium" variant="rounded">
          OP
        </Avatar>
        <Avatar badge size="medium" variant="square">
          OP
        </Avatar>
        <Avatar badge size="small" variant="circular">
          OP
        </Avatar>
        <Avatar badge size="small" variant="rounded">
          OP
        </Avatar>
        <Avatar badge size="small" variant="square">
          OP
        </Avatar>
        <Avatar badge size="xs" variant="circular">
          OP
        </Avatar>
        <Avatar badge size="xs" variant="rounded">
          OP
        </Avatar>
        <Avatar badge size="xs" variant="square">
          OP
        </Avatar>
      </div>
      <div style={sliderDivStyle}>
        <Avatar variant="circular">
          <PersonIcon />
        </Avatar>
        <Avatar variant="rounded">
          <PersonIcon />
        </Avatar>
        <Avatar variant="square">
          <PersonIcon />
        </Avatar>
        <Avatar size="medium" variant="circular">
          <PersonIcon />
        </Avatar>
        <Avatar size="medium" variant="rounded">
          <PersonIcon />
        </Avatar>
        <Avatar size="medium" variant="square">
          <PersonIcon />
        </Avatar>
        <Avatar size="small" variant="circular">
          <PersonIcon />
        </Avatar>
        <Avatar size="small" variant="rounded">
          <PersonIcon />
        </Avatar>
        <Avatar size="small" variant="square">
          <PersonIcon />
        </Avatar>
        <Avatar size="xs" variant="circular">
          <PersonIcon />
        </Avatar>
        <Avatar size="xs" variant="rounded">
          <PersonIcon />
        </Avatar>
        <Avatar size="xs" variant="square">
          <PersonIcon />
        </Avatar>
      </div>
      <div style={sliderDivStyle}>
        <Avatar badge variant="circular">
          <PersonIcon />
        </Avatar>
        <Avatar badge variant="rounded">
          <PersonIcon />
        </Avatar>
        <Avatar badge variant="square">
          <PersonIcon />
        </Avatar>
        <Avatar badge size="medium" variant="circular">
          <PersonIcon />
        </Avatar>
        <Avatar badge size="medium" variant="rounded">
          <PersonIcon />
        </Avatar>
        <Avatar badge size="medium" variant="square">
          <PersonIcon />
        </Avatar>
        <Avatar badge size="small" variant="circular">
          <PersonIcon />
        </Avatar>
        <Avatar badge size="small" variant="rounded">
          <PersonIcon />
        </Avatar>
        <Avatar badge size="small" variant="square">
          <PersonIcon />
        </Avatar>
        <Avatar badge size="xs" variant="circular">
          <PersonIcon />
        </Avatar>
        <Avatar badge size="xs" variant="rounded">
          <PersonIcon />
        </Avatar>
        <Avatar badge size="xs" variant="square">
          <PersonIcon />
        </Avatar>
      </div>
      <div style={sliderDivStyle}>
        <Avatar color="default">OP</Avatar>
        <Avatar color="primary">OP</Avatar>
        <Avatar color="secondary">OP</Avatar>
        <Avatar color="error">OP</Avatar>
        <Avatar color="warning">OP</Avatar>
        <Avatar color="info">OP</Avatar>
        <Avatar color="success">OP</Avatar>
      </div>
      <div style={sliderDivStyle}>
        <AvatarGroup spacing="medium" max={2}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="medium" max={3}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="medium" max={4}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="medium" max={5}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
      </div>
      <div style={sliderDivStyle}>
        <AvatarGroup spacing="medium" max={2} size="medium">
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="medium" max={3} size="medium">
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="medium" max={4} size="medium">
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="medium" max={5} size="medium">
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
      </div>
      <div style={sliderDivStyle}>
        <AvatarGroup spacing="medium" max={2} size="small">
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="medium" max={3} size="small">
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="medium" max={4} size="small">
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="medium" max={5} size="small">
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
      </div>

      <div style={sliderDivStyle}>
        <AvatarGroup spacing="small" max={2}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="small" max={3}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="small" max={4}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing="small" max={5}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
      </div>
      <div style={sliderDivStyle}>
        <AvatarGroup spacing={24} max={2}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing={24} max={3}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing={24} max={4}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
        <AvatarGroup spacing={24} max={5}>
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
          <Avatar src="https://mui.com/static/images/avatar/1.jpg" />
        </AvatarGroup>
      </div>
    </>
  );
};

const Others = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginBottom: "30px",
          padding: "15px",
          width: "fit-content",
        }}
      >
        <Radio color="primary" size="small" />
        <Radio color="primary" size="medium" />
        <Radio color="primary" size="large" />
        <Radio color="secondary" size="medium" />
        <Radio color="default" size="medium" />
        <Radio color="error" size="medium" />
        <Radio color="warning" size="medium" />
        <Radio color="info" size="medium" />
        <Radio color="success" size="medium" />
        <Radio disabled size="medium" />
        <Radio disabled checked size="medium" />
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          marginBottom: "30px",
          padding: "15px",
          width: "fit-content",
        }}
      >
        <Badge badgeContent={1} variant="dot">
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} variant="dot" color="primary">
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} variant="dot" color="secondary">
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} variant="dot" color="error">
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} variant="dot" color="warning">
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} variant="dot" color="info">
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} variant="dot" color="success">
          <MailIcon />
        </Badge>

        <Badge badgeContent={1}>
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} color="primary">
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} color="secondary">
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} color="error">
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} color="warning">
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} color="info">
          <MailIcon />
        </Badge>
        <Badge badgeContent={1} color="success">
          <MailIcon />
        </Badge>
      </div>
      <div style={sliderDivStyle}>
        <Slider
          marks={sliderMarks}
          min={0}
          max={12}
          color="primary"
          size="small"
        />
        <Slider
          marks={sliderMarks}
          min={0}
          max={12}
          color="primary"
          size="medium"
        />
        <Slider
          marks={sliderMarks}
          disabled
          min={0}
          max={12}
          color="primary"
          size="small"
        />
        <Slider
          marks={sliderMarks}
          disabled
          min={0}
          max={12}
          color="primary"
          size="medium"
        />
        <Slider marks min={0} max={12} color="primary" size="small" />
        <Slider marks min={0} max={12} color="primary" size="medium" />
        <Slider
          marks
          min={0}
          max={12}
          valueLabelDisplay="auto"
          color="primary"
          size="medium"
        />
        <Slider marks min={0} max={12} disabled color="primary" size="medium" />
      </div>
      <div style={sliderDivStyle}>
        <Slider
          orientation="vertical"
          marks={sliderMarks}
          min={0}
          max={12}
          color="primary"
          size="small"
        />
        <Slider
          orientation="vertical"
          marks={sliderMarks}
          min={0}
          max={12}
          color="primary"
          size="medium"
        />
        <Slider
          orientation="vertical"
          marks={sliderMarks}
          disabled
          min={0}
          max={12}
          color="primary"
          size="small"
        />
        <Slider
          orientation="vertical"
          marks={sliderMarks}
          disabled
          min={0}
          max={12}
          color="primary"
          size="medium"
        />
      </div>
      <div style={sliderDivStyle}>
        <Slider
          marks={sliderMarks}
          min={0}
          max={12}
          color="secondary"
          size="small"
        />
        <Slider
          marks={sliderMarks}
          disabled
          min={0}
          max={12}
          defaultValue={[5, 13]}
          valueLabelDisplay="auto"
          color="secondary"
          size="medium"
        />
        <Slider
          marks={sliderMarks}
          disabled
          min={0}
          max={12}
          color="secondary"
          size="small"
        />
        <Slider
          marks={sliderMarks}
          disabled
          min={0}
          max={12}
          color="secondary"
          size="medium"
        />
      </div>
      <div style={{ padding: "20px" }}>
        <LinearProgress color="inherit" />
        <br />
        <LinearProgress color="primary" label value={47} />
        <br />
        <LinearProgress color="secondary" />
        <br />
        <LinearProgress variant="determinate" color="inherit" value={75} />
        <br />
        <LinearProgress variant="determinate" color="primary" value={75} />
        <br />
        <LinearProgress variant="determinate" color="secondary" value={75} />
        <br />
        <LinearProgress
          variant="buffer"
          color="inherit"
          value={45}
          valueBuffer={68}
        />
        <br />
        <LinearProgress
          variant="buffer"
          color="primary"
          value={45}
          valueBuffer={68}
        />
        <br />
        <LinearProgress
          variant="buffer"
          color="secondary"
          value={45}
          valueBuffer={68}
        />
        <br />
        <LinearProgress
          variant="buffer"
          color="inherit"
          value={45}
          valueBuffer={68}
          label
        />
        <br />
        <LinearProgress
          variant="buffer"
          color="primary"
          value={45}
          valueBuffer={68}
          label
        />
        <br />
        <LinearProgress
          variant="buffer"
          color="secondary"
          value={45}
          valueBuffer={68}
          label
        />
        <br />
      </div>
      <div style={{ padding: "20px" }}>
        <CircularProgress label color="primary" size="32px" value={75} />
        <CircularProgress label color="secondary" size="32px" value={75} />
        <CircularProgress label color="inherit" size="32px" value={75} />
      </div>
      <div style={{ padding: "20px" }}>
        <CircularProgress color="primary" size="16px" value={75} />
        <CircularProgress color="secondary" size="16px" value={75} />
        <CircularProgress color="inherit" size="16px" value={75} />
      </div>
      <div style={{ padding: "20px" }}>
        <CircularProgress color="primary" size="32px" value={75} />
        <CircularProgress color="secondary" size="32px" value={75} />
        <CircularProgress color="inherit" size="32px" value={75} />
      </div>
      <div style={sliderDivStyle}>
        <Tooltip arrow title="Tooltip" placement="bottom">
          Tooltip
        </Tooltip>
        <Tooltip arrow title="Tooltip" placement="top">
          Tooltip
        </Tooltip>
        <Tooltip arrow title="Tooltip" placement="left">
          Tooltip
        </Tooltip>
        <Tooltip arrow title="Tooltip" placement="right">
          Tooltip
        </Tooltip>
        <Tooltip title="Tooltip" placement="top">
          Tooltip
        </Tooltip>
      </div>
      <div style={sliderDivStyle}>
        <Switch color="default" size="small" />
        <Switch color="default" size="medium" />
        <Switch color="default" size="medium" disabled />
        <Switch color="default" size="medium" disabled defaultChecked />
        <Switch color="primary" size="medium" />
        <Switch color="secondary" size="medium" />
        <Switch color="error" size="medium" />
        <Switch color="warning" size="medium" />
        <Switch color="info" size="medium" />
        <Switch color="success" size="medium" />
      </div>
      <div style={sliderDivStyle}>
        <Switch
          color="default"
          size="medium"
          labelPlacement="end"
          label="Label"
        />
        <Switch
          color="secondary"
          size="medium"
          labelPlacement="bottom"
          label="Label"
        />
        <Switch
          color="error"
          size="medium"
          labelPlacement="top"
          label="Label"
        />
        <Switch
          color="warning"
          size="medium"
          labelPlacement="start"
          label="Label"
        />
        <Switch
          color="warning"
          size="medium"
          labelPlacement="start"
          label="Label"
          disabled
        />
      </div>
      <div style={{ padding: "20px" }}>
        <List>
          <ListItem>
            <ListItemButton selected>
              <ListItemText primary="Inbox" />
            </ListItemButton>
          </ListItem>
          <ListItem divider>
            <ListItemButton disabled>
              <ListItemText primary="Drafts" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="Drafts" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton disableGutters>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="Drafts" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton selected>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="Drafts" secondary="Secondary" />
              <MailIcon />
            </ListItemButton>
          </ListItem>
          <ListItem dense>
            <ListItemButton selected>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="Drafts" secondary="Secondary" />
              <MailIcon />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton selected>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="Drafts" />
              <MailIcon />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton selected>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="Drafts" />
              <MailIcon />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton disabled>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="Drafts" />
              <MailIcon />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton disabled selected>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="Drafts" />
              <MailIcon />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar
                  alt="Avatar"
                  src="https://mui.com/static/images/avatar/1.jpg"
                />
              </ListItemAvatar>
              <ListItemText primary="Henrique" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemText primary="Drafts" secondary="Secondary text" />
            </ListItemButton>
          </ListItem>
          <ListItem>Testando</ListItem>
        </List>
      </div>
    </>
  );
};
