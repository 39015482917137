import React from "react";
import {CertificateContainer, CertificateHeader, CertificateHeaderRight, CourseTitle, WJTitle, WJLogo, CourseDuration,
  CertificateTitle,
  CertificateTitleDiv,
  CertificateContent,
  CertificateMainContent,
  TextCertification
} from "./styles.js";
import logo from "assets/images/logo-w.png";
import { Loader } from "components/ui";
import { useCertificateData } from "./context.js";

function Certificate() {

  const {course, full_name, formatted_duration, certificateIsLoading } = useCertificateData();

  if(certificateIsLoading){
    return <Loader />
  }

  return (
    <CertificateContainer>
      <CertificateTitleDiv>
        <CertificateTitle>Certificado</CertificateTitle>
      </CertificateTitleDiv>
      <CertificateMainContent>
        <CertificateHeader>
          <WJLogo src={logo} alt="Logo" />
          <CertificateHeaderRight>
            <WJTitle>Walljobs</WJTitle>
            <CourseTitle>{course}</CourseTitle>
            <CourseDuration>{course.formatted_duration}</CourseDuration>
          </CertificateHeaderRight>
        </CertificateHeader>
        <CertificateContent>
          <TextCertification>
            Certificamos que o aluno {full_name} concluiu o curso {course} com a carga horária de {formatted_duration}.
          </TextCertification>
        </CertificateContent>
      </CertificateMainContent>
    </CertificateContainer>
  )
}

export default Certificate;
