import React from "react";
import { connect } from "react-redux";
import { Loader } from "components/ui";
import { useStudyTrack } from "./context";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ProgressBar from "./ProgressBar";
import { useNavigate } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";
import { ACADEMY, USERS } from "navigation/ROUTES";
import api from "services/api";
import CardMedia from "@mui/material/CardMedia";
import {StudyTrack, HeaderStudyTrack, AboutTrack, AboutTrackTitle, 
  Container,
  AboutTrackDescription, 
  AboutTrackLogo, 
  Progress,
    ProgressFinished,
    AddButtonDiv,
    AddButton, 
    ProgressBarDiv,
    ProgressInfosDiv, 
    InfoQuantityFinished,
    InfoQuantityNumber, 
    InfosContainer,
    Infos,
    InfosItem,
    InfosItemLogo,
    InfosItemValueAndLabel,
    InfosItemValue,
    InfosItemLabel,
    ContentTitle,
    ContentText,
    ContentImage,
    ContentVideo,
    Sections,
    Section,
    SectionInfos,
    SectionNumber,
    SectionTitle,
    SectionDescription,
    EndSection,
    CourseCard,
    CourseImage,
    CourseDetails,
    Course,
    CourseTitle, } from "./styles";

function Show(props){
  const {studyTrack, isLoading} = useStudyTrack();

  let navigate = useNavigate();

  if(isLoading){
    return <Loader />
  }

  function redirectToCourse(course_id){
    if(props.isLogged){
      navigate(pathWithParams(ACADEMY.COURSE.SHOW, {id: course_id}))
    }else{
      swal(
        "Ops!",
        "Você precisa estar logado para visualizar essa página!",
        "warning"
      ).then(() => navigate(USERS.LOGIN));
    }
  }

  function successfulRequisition(message, type) {
    let path = "";

    if(type == "remove"){
      path = pathWithParams(ACADEMY.STUDY_TRACK.SHOW, {id: studyTrack.slug});
    }else{
      path = ACADEMY.COURSE.OWNED;
    }

    swal(
      "Trilha de estudos salva!",
      message,
      "success"
    ).then(() => {
      navigate(path);

      if(type == "remove"){
        window.location.reload();
      }
    })
    
  }

  function unsuccessfulRequisition(message){
    swal(
      "Ops!",
      message,
      "error"
    );
  }

  function saveStudyTrack(){
    if(!props.isLogged){
      return swal(
        "Ops!",
        "Você precisa estar logado para realizar essa ação!",
        "warning"
      ).then(() => navigate(USERS.LOGIN));
    }

    api.post(`/v1/academy/study_track/study_tracks/${studyTrack.id}/save_study_track`)
      .then((response) => successfulRequisition(response.data.message, "add"))
      .catch((error) => unsuccessfulRequisition(error.response.data.message));
  }

  function removeStudyTrack(){
    if(!props.isLogged){
      return swal(
        "Ops!",
        "Você precisa estar logado para realizar essa ação!",
        "warning"
      ).then(() => navigate(USERS.LOGIN));
    }

    api.post(`/v1/academy/study_track/study_tracks/${studyTrack.id}/remove_study_track`)
      .then((response) => successfulRequisition(response.data.message, "remove"))
      .catch((error) => unsuccessfulRequisition(error.response.data.message));
  }

  function renderAddButton(){
    if(studyTrack.user_saved) {
      return (
        <AddButton onClick={() => removeStudyTrack()}>
          Remover essa trilha do perfil
        </AddButton>
      )
    }else{
      return (
        <AddButton onClick={() => saveStudyTrack()}>
          Salvar essa Trilha
        </AddButton>
      )
    }
  }

  function getVideoDescriptionSrc(content) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const iframeElement = doc.querySelector("iframe");
    const src = iframeElement.getAttribute("src");
    return src;
  }

  function renderContentImage(){
    if(studyTrack.content_image){
      return (
        <ContentImage src={studyTrack.content_image} />
      )
    }
  }

  function renderVideoDescription(){
    if(studyTrack.video_description){
      return (
        <ContentVideo>
          <CardMedia
            component="iframe"
            controls
            width="100%"
            height="100%"
            src={getVideoDescriptionSrc(studyTrack.video_description)}
            allowFullScreen
          />
        </ContentVideo>
      )
    }
  }

  return (
    <StudyTrack>
      <HeaderStudyTrack>
        <Container>
          <AboutTrack>
            <AboutTrackLogo src={studyTrack.image} />
            <AboutTrackTitle>
              {studyTrack.title}
            </AboutTrackTitle>
            <AboutTrackDescription>
              {studyTrack.description}
            </AboutTrackDescription>
          </AboutTrack>
          <Progress>
            <ProgressFinished>
              <ProgressBarDiv>
                {/* Progress Bar AQUI */}
              </ProgressBarDiv>
              <ProgressInfosDiv>
                <InfoQuantityFinished>
                  <InfoQuantityNumber>{studyTrack.total_finished}</InfoQuantityNumber>/{studyTrack.course_quantity}
                </InfoQuantityFinished>
                Cursos finalizados
              </ProgressInfosDiv>
            </ProgressFinished>
            <AddButtonDiv>
              {renderAddButton()}
            </AddButtonDiv>
          </Progress>
        </Container>
      </HeaderStudyTrack>
      <InfosContainer>
        <Infos>
          <InfosItem>
            <InfosItemLogo>
              <AccessTimeIcon />
            </InfosItemLogo>
            <InfosItemValueAndLabel>
              <InfosItemValue>
                {studyTrack.study_track_duration}
              </InfosItemValue>
              <InfosItemLabel>
                Carga horária total
              </InfosItemLabel>
            </InfosItemValueAndLabel>
          </InfosItem>
          <InfosItem>
            <InfosItemLogo>
              <AccessTimeIcon />
            </InfosItemLogo>
            <InfosItemValueAndLabel>
              <InfosItemValue>
                {studyTrack.course_quantity}
              </InfosItemValue>
              <InfosItemLabel>
                Cursos
              </InfosItemLabel>
            </InfosItemValueAndLabel>
          </InfosItem>
        </Infos>
      </InfosContainer>
      <Container>
        <ContentTitle>
          {studyTrack.content_title}
        </ContentTitle>
        <ContentText>
          {studyTrack.content}
        </ContentText>
        {renderContentImage()}
        {renderVideoDescription()}
      </Container>

      <Container>
        <Sections>
          {
            studyTrack.sections.map((section, index) => {
              return(
                <Section>
                  <SectionInfos>
                    <SectionNumber>
                      {index + 1}
                    </SectionNumber>
                    <SectionTitle>
                      {section.title}
                    </SectionTitle>
                  </SectionInfos>
                  <SectionDescription>
                    {section.description}
                  </SectionDescription>

                  {section.courses.map((course) => {
                    return(
                      <CourseCard onClick={() => redirectToCourse(course.slug)}>
                        <CourseImage src={course.image} />
                        <CourseDetails>
                          <ProgressBar percentage={course.progress || 0} />
                          <Course>Curso</Course>
                          <CourseTitle>{course.title}</CourseTitle>
                        </CourseDetails>
                      </CourseCard>
                    )
                  })}
                  <EndSection />
                </Section>
              )
              
            })
          }
          
        </Sections>
      </Container>
    </StudyTrack>
  )
}

function mapStateToProps(state) {
  const user = state.session?.attributes;
  const isLogged = state.session?.isLogged;

  return {user, isLogged};
}


export default connect(mapStateToProps)(Show);