import styled from "styled-components";

export const Main = styled.section`
  padding: 0px;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  max-width: 860px;
  text-align: left;

  @media (min-width: 767px) {
    font-size: 38px;
  }
`;

export const ButtonContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 60px;
  width: 100%;
  max-width: 440px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 320px) {
    flex-direction: column;
  }
`;

export const SmallButton = styled.button`
  margin: 25px auto;
  background-color: #009bf6;
  color: #ececee;
  display: flex;
  width: 150px;
  height: 40px;
  max-width: 90vw;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 8px;
  font-family: "Source Sans Pro";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  z-index: 10;

  &:disabled {
    background-color: #d3d3d3;
    color: #999999;
  }
`;

export const FinishedTestContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px;
  font-size: 16px;
  text-align: center;
  @media(min-width: 720px){
    width: 60vw;
    margin: 0 auto;
    font-size: 24px;
  }
`