import React, { useState, useEffect } from "react";
import _, { get } from "lodash";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { CPF, Email } from "components/users/fields/essential";
import { INTERNSHIP } from "navigation/ROUTES";
import { pathWithParams } from 'utils/pathWithParams';

function Form() {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const { uuid } = useParams();

  const [data, setData] = useState({});

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const getData = (collectedData) => {
    $(".button:submit").attr("disabled", true);
    setData({ ...data, ...collectedData });
  };


  const successfulLogin = (user_info_id = user_info_id) => {   
    swal("Login bem-sucedido", "Pedimos que você preencha esse formulário com atenção e cuidado!", "success");
    navigate(pathWithParams(INTERNSHIP.UPDATE.FORM_ANSWER, { uuid, user_info_id }));
  }

  const unsuccessfulLogin = (message) => {
    $(".button:submit").removeAttr("disabled")
    swal("Ops!", message, "error");
    setError("email", { type: "manual" })
    setError("cpf", { type: "manual" })
  }

  const submit = () => {
    axios
      .post(pathname, { user_info: data })
      .then((user_info_id) => successfulLogin(user_info_id.data.user_info_id))
      .catch((error) => unsuccessfulLogin(error.response.data?.message));
  };

  useEffect(() => {
    if (!_.isEmpty(data.email) && !_.isEmpty(data.cpf)) submit();
  }, [data]);

  return (
    <form onSubmit={handleSubmit(getData)}>
      <h3>Faça seu login</h3>
      <CPF register={register} errors={errors} required />
      <Email register={register} errors={errors} label="Email" required />
      <button className="button button-primary" type="submit">Entrar</button>
    </form>
  );
}

export default Form;
