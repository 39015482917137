import React, { useState, useEffect } from "react";
import _ from "lodash";
import { formPost } from "services/api";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorMessages } from "components/ui";
import { HiddenField } from "components/form";
import { updateAttributesInStore } from "@root/redux/features/sessions/actions";
import { USERS } from "navigation/ROUTES";

function Form(props) {
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  let navigate = useNavigate();
  const { forms, step, defaultValues } = props;

  const {
    register,
    unregister,
    control,
    resetField,
    reset,
    setValue,
    handleSubmit,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  function renderForm() {
    return React.cloneElement(
      forms[step].component,
      {
        resetField,
        setValue,
        register,
        unregister,
        control,
        getValues,
        errors,
        watch
      },
      null
    );
  }

  function getData(collectedData) {
    setData(collectedData);
    setErrorMessages([]);
  }

  function submit() {
    setIsFetching(true);
    swal("Aguarde", "Estamos atualizando suas informações", "info");
    const user = { user_info_attributes: data };
    formPost(`/v1/profile/users`, { user })
      .then(() => successfulRequisition())
      .catch((error) => unsuccessfulRequisition(error.response.data))
      .finally(() => setIsFetching(false));
  }

  function successfulRequisition() {
    props.hasNext() ? props.next() : navigate(USERS.HOME);
    swal(
      "Tudo certo!",
      "As informações dessa seção foram atualizadas com sucesso! Obrigado por manter seu perfil atualizado!",
      "success"
    );
    updateAttributesInStore();
  }

  function unsuccessfulRequisition(data) {
    const { errors, messages } = data;
    for (let field in errors) {
      errors[field].forEach((message) => {
        let pattern = /\.(.*)(\[\d*\])+\.(.*)/i
        let match = field.match(pattern);
        let f = field;
        if(match?.length > 0) {
          f = match[1] + "_attributes" + match[2] + '[' + match[3] + ']';
        }
        setError(f, { type: "manual", message });
      });
    }

    setErrorMessages(messages);

    swal(
      "Ops!",
      "Não foi possível atualizar as informações dessa seção. Por favor, corrija os itens solicitados e tente novamente!",
      "error"
    );
  }

  useEffect(() => {
    if (!_.isEmpty(data)) submit();
  }, [data]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <form onSubmit={handleSubmit(getData)}>
      <ErrorMessages messages={errorMessages} />
      {renderForm()}
      <Id
        register={register}
        setValue={setValue}
        control={control}
        defaultValues={defaultValues}
      />
      <Buttons {...props} isFetching={isFetching} />
    </form>
  );
}

function Buttons(props) {
  const { step, previous, isFetching } = props;
  const disabled = step == 0;

  return (
    <div className="buttons">
      <button
        onClick={previous}
        className="button button-secondary"
        type="button"
        disabled={disabled}
      >
        Voltar
      </button>
      <button className="button button-primary" type="submit" disabled={isFetching}>
        Atualizar
      </button>
    </div>
  );
}

function Id(props) {
  const { id } = props.defaultValues;
  return <HiddenField {...props} name="id" value={id} />;
}

export default Form;
