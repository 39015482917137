import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useCourse() {
  const { id } = useParams();
  const { data, isLoading } = useFetch(`/v1/academy/courses/${id}`);
  
  return {
    course: data?.data?.attributes,
    courseIsLoading: isLoading,
  };
}

export function useUserInfo(slug) {
  const { data, isLoading, mutate } = useFetch(`/v1/profile/user_infos/${slug}`);

  const getIncluded = (type) => {
    return data?.included.filter(element => element?.type === type) || []
  }

  return {
    userInfo: data?.data?.attributes,
    included: data?.included,
    mutate,
    getIncluded,
    isLoading,
  };
}

export function useProgressItem(matriculation_id = null, item_id = null) {

  if(!matriculation_id || !item_id){
    return {
      progressItem: {
        watched: false
      }
    }
  }

  const {data, isLoading } = useFetch(`/v1/academy/progress_items/progress_item/${matriculation_id}/${item_id}`)

  return {
    progressItem: data?.data?.attributes,
    isLoading
  }
}

export function useTotalWatched(section_id){
  const {data, isLoading } = useFetch(`/v1/academy/progress_items/total_watched/${section_id}`)

  return {
    total: data?.total,
    isLoading
  }

}

export function useWatchedAll(section_id) {
  const {data, isLoading} = useFetch(`/v1/academy/progress_items/check_watched_all/${section_id}`)

  return {
    watched_all: data?.watched_all,
    isLoading
  }
}

export function useNextItem(){
  const params = useParams();
  const course_id = params.id;
  const {data, isLoading } = useFetch(`/v1/academy/progress_items/current_item/${course_id}`)

  return {
    nextItem: data?.section_item_id,
    nextItemIsLoading: isLoading
  }
}