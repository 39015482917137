import React from 'react';
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import { useUserInfo } from '../../context';

function AcademicEntities(){
  const { userInfo } = useUserInfo();
  const { academic_entities } = userInfo;

  const { t } = useTranslation();

  const _renderEntities = () => {
    const list = academic_entities || [];
    return list.map((item, index) => (
      <li key={index}>{item}</li>
    ))
  }

  if(_.isEmpty(academic_entities)){
    return;
  }

  return (
    <section className="academic-entities">
      <hr />
      <h3 className="section-title">{t("user_info.attributes.academic_entities")}</h3>
      <ul>
        {_renderEntities()}
      </ul>
    </section>
  );
}

export default AcademicEntities;