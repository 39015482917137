import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";

export function useStudyTrack() {
  const { id } = useParams();
  const { data, isLoading } = useFetch(`/v1/academy/study_track/study_tracks/${id}`);

  return {
    studyTrack: data?.data.attributes,
    isLoading: isLoading,
  };
}