import React from "react";
import { useCompany } from "../context";
import Sidebar from './Sidebar';
import AditionalContent from './AditionalContent';

function About() {
  const { company } = useCompany();
  
  return (
    <div
      className="tab-pane fade show active"
      id="about"
      role="tabpanel"
      aria-labelledby="about-tab"
    >
      <div className="about-content">
        <section>
          <h3>Sobre nós</h3>
          <p dangerouslySetInnerHTML={{ __html: company.description }}></p>
        </section>
        <Sidebar />
      </div>
      <AditionalContent />
    </div>
  );
}

export default About;
