import React, { useState, useEffect } from "react";
import _ from "lodash";
import { formPost } from "services/api";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import Fields from "./Fields";

function Form() {
  const [data, setData] = useState({});
  const { pathname } = useLocation();
  const {
    register,
    control,
    reset,
    resetField,
    setValue,
    handleSubmit,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur", shouldUnregister: true });

  const getData = (collectedData) => {
    setData(collectedData);
  };

  const successfulRequisition = (message) => {
    swal("Tudo certo!", message, "success").then(() => location.reload());
  };

  const unsuccessfulRequisition = (data) => {
    let message = data?.message || "Não foi possível efetuar o cadastro! Por favor, tente novamente.";

    swal(
      "Ops!",
      message,
      "error"
    );

    if (!data?.errors) {
      return;
    }

    for (let field in data.errors) {
      data.errors[field].forEach((message) => {
        setError(field, { type: "manual", message });
      });
    }
  };

  const submit = () => {
    const user_info = data;
    formPost(pathname, { user_info })
      .then((resp) => successfulRequisition(resp?.data?.message))
      .catch((error) => unsuccessfulRequisition(error?.response?.data));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!_.isEmpty(data)) submit();
  }, [data]);

  return (
    <form onSubmit={handleSubmit(getData)}>
      <Fields
        register={register}
        control={control}
        errors={errors}
        resetField={resetField}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
      />
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <button className="button" type="submit">
            Inscreva-se
          </button>
        </div>
      </div>
    </form>
  );
}

export default Form;
