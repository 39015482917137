import React from "react";
import Course from "./Course";

function Main(){

  return (
    <Course />
  )
}

export default Main;