import { useParams } from "react-router-dom";
import { useFetch } from "utils/hooks";


export function useCertificateData() {
  const { id } = useParams();
  const { data, isLoading } = useFetch(`/v1/academy/certificates/${id}`);

  return {
    course: data?.course,
    full_name: data?.full_name,
    formatted_duration: data?.formatted_duration,
    certificateIsLoading: isLoading,
  }
}
