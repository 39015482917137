import React from "react";
import styled from "styled-components";

const Title = styled.h2`
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  margin: 0 20px 5px 0;
  transition: 0.8s;
  cursor: pointer;
  display: inline-block;

  &: hover {
    color: #3C2F7E;
  }

  &: active {
    color: #3C2F7E;
  }

  @media(min-width: 770px){
    font-size: 18px;    
  }

  @media (min-width: 1234px) {
    font-size: 18px;
  }
`

export default (props) => (
  <Title onClick={props.onClick}>
    {props.children}
  </Title>
)