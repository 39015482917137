import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatTime } from "../utils";
import TimerIcon from '@mui/icons-material/Timer';

const TimerContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: fit-content;
  padding: 0 8px;
  gap: 6px;
  border-radius: 7px;
  z-index: 3;
  top: 45px;
  right: 35px;
  font-size: 28px;
  font-weight: 700;
  background-color: #ffffff;
  box-shadow: 4px 4px 18px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 767px) {
    font-size: 24px;
    right: 15px;
  }
`;

function Timer({ formula, setTimeTaking, checkExpired, time_remaining, channel}) {
  const [time, setTime] = useState(time_remaining);
  
  useEffect(() => {
    if(channel){
      const intervalId = setInterval(() => {
        if (time > 0) {
          const newTime = time + formula;
          setTime(newTime);
          setTimeTaking(newTime)

          channel.send({time: newTime});
          
          checkExpired(newTime);
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
  
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [time, channel]);

  return (
    <TimerContainer>
      <TimerIcon /> {formatTime(time)}
    </TimerContainer>
  );
}

export default Timer;
