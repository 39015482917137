import React, { useState } from "react";
import { Breadcrumb, ContentBox, MainBox } from "components/ui";
import Menu from "./Menu";
import Content from "./Content";

function Main() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <MainBox>
      <Breadcrumb>
        <a>Configurações</a>
      </Breadcrumb>
      <ContentBox>
        <Menu
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        <Content selectedIndex={selectedIndex} />
      </ContentBox>
    </MainBox>
  );
}

export default Main;
