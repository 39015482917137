import React from 'react';
import styled from 'styled-components';

const Fieldset = styled.fieldset`
  border-top: 1px solid ${({theme}) => theme.mode === "light" ? "#333b4d8f" : "#f0f0f0e7"};
  padding: 5px 0px;
  border-bottom: none;
  border-left: none;
  border-right: none;
  display: block;
  text-align: center;
`

const Legend = styled.legend`
  padding: 5px 10px !important;
  display: block !important; 
  width: 12% !important; 
  max-width: 12% !important; 
  margin-bottom: none !important; 
  line-height: none !important; 
  color: ${({theme}) => theme.mode === "light" ? "#333b4d" : "#f0f0f0"} !important; 
  white-space: none !important;
`

export default () =>{
  return(
    <Fieldset className='fieldset'>
      <Legend>ou</Legend>
    </Fieldset>
  )
}