import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import University from "components/users/fields/academic/University";
import { useTranslation } from "react-i18next";

function UniversityField(props) {
  const [value, setValue] = useState();

  const { t } = useTranslation();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  function renderUniversityField() {
    if (value === "1") {
      return <University {...props} required />;
    }
  }

  return (
    <div className="form-group">
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label" color="secondary">
          {t("update_form_answer.questions.university.question")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            slotProps={{ typography: { variant: "body2" } }}
            value="1"
            control={<Radio color="secondary" />}
            label={t("update_form_answer.questions.university.radio.yes")}
          />
          <FormControlLabel
            slotProps={{ typography: { variant: "body2" } }}
            value="0"
            control={<Radio color="secondary" />}
            label={t("update_form_answer.questions.university.radio.no")}
          />
        </RadioGroup>
      </FormControl>
      {renderUniversityField()}
    </div>
  );
}

export default UniversityField;
