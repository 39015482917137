import {React, useState} from "react";
import _ from "lodash";
import Benefit from "components/ats/Benefit";
import { useJob } from "./context";
import Section from "./Section";
import benefits from 'components/ats/benefits';
import Faq from "./faq/Faq";

function MainSection() {
  const { job, company } = useJob();

  function _renderBenefits() {
    const list = job.job_benefits || [];
    return list.map((item) => (
      <Benefit key={item.icon} path={benefits[item.icon]} name={item.name} />
    ));
  }

  return (
    <>
      <div className="content">
        {!job.confidential && !_.isEmpty(company.description) && (
          <Section name="company" title={`Sobre ${job.author_name}`}>
            <div dangerouslySetInnerHTML={{ __html: company.description }}></div>
          </Section>
        )}
        {!_.isEmpty(job.description) && (
          <Section name="description" title="Descrição da vaga">
            <div dangerouslySetInnerHTML={{ __html: job.description }}></div>
          </Section>
        )}
        {!_.isEmpty(job.skills) && (
          <Section name="skills" title="Habilidades esperadas">
            <div dangerouslySetInnerHTML={{ __html: job.skills }}></div>
          </Section>
        )}
        {!_.isEmpty(job.job_benefits) && (
          <Section name="benefits" title="Benefícios">
            {_renderBenefits()}
          </Section>
        )}
      </div>
      {!job.confidential && !_.isEmpty(job.faq) && (
        <div style={{gridArea: "faq"}}>
          {<Faq />}
        </div>
      )}
    </>
  );
}

export default MainSection;
