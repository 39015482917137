import React from "react";
import HomepageImage from "assets/images/HomepageImage.png";
import { Container, HomepageContainer, Content, Image, TextContent, MainTitle, CourseContainer, BlackTitle, BlueTitle, Text, MyCourses, MiddleBelt, MiddleBeltContainer, MiddleBeltTitle, MiddleBeltMessage, FindCourses } from "./styles";
import { useNavigate } from "react-router-dom";
import { ACADEMY, USERS } from "navigation/ROUTES"
import Carousel from './components/Carousel';

function Home({user}){
  const navigate = useNavigate();

  return (
    <>
    <Container>
      <HomepageContainer>
        <Content>
          <TextContent>
            <MainTitle>
              <BlackTitle>Se </BlackTitle>
              <BlueTitle>desenvolva </BlueTitle>
              <BlackTitle>no Academy</BlackTitle>
            </MainTitle>

            <Text>
              O <b>Walljobs Academy</b> é uma plataforma de estudos <b>gratuita</b> onde você <b>aprende e desenvolve habilidades,</b> tem benefícios e ainda ganha <b>horas complementares</b> para sua faculdade.
            </Text>

            <CourseContainer>
              {
                user && (
                  <MyCourses onClick={()=> navigate(ACADEMY.COURSE.OWNED)}>
                    Ver meus cursos
                  </MyCourses>
                )
              }
              <FindCourses onClick={() => user ? navigate(ACADEMY.COURSE.INDEX) : navigate(USERS.LOGIN)}>
                Quer encontrar novos cursos? Clique aqui
              </FindCourses>
            </CourseContainer>
          </TextContent>
        <Image src={HomepageImage} alt="Logo" />
        </Content>
      </HomepageContainer>
    </Container>
      <MiddleBelt>
        <MiddleBeltContainer >
          <MiddleBeltTitle>
            Acelere sua Carreira com os Cursos e Benefícios do WallJobs Academy
          </MiddleBeltTitle>
          <MiddleBeltMessage>
            No WallJobs Academy, você descobrirá o ambiente ideal para se dedicar aos estudos, ampliar seus conhecimentos e aperfeiçoar suas habilidades. Com uma vasta oferta de cursos gratuitos, materiais de apoio e benefícios exclusivos
          </MiddleBeltMessage>
        </MiddleBeltContainer>
      </MiddleBelt>
      <Carousel />
    </>
  )
}

export default Home;