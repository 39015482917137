import React from "react";
import styled from "styled-components";
import image from "assets/images/pessoas_comemorando.png"

const Section = styled.section`
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  background-color: white;
  padding: 50px 15px;

  img {
    display: flex;
    margin: 0 auto;
  }

  h1, h2, p {
    text-align: center;
  }

  h1 {
    @media (min-width: 769px) {
      font-size: 50px;
    }
  }

  p {
    @media (min-width: 769px) {
      font-size: 20px;
    }
  }
`;

function Finished() {
  return (
    <Section>
      <h1>Parabéns!</h1>
      <h2>Você concluiu este teste! 😎🎉</h2>
      <img src={image} alt="Pessoas comemorando" />
      <p>O resultado chegará no e-mail cadastrado ou quando a empresa fizer a apuração do teste.</p>
      <p style={{marginTop: "20px"}}>Você pode fechar esta aba agora.</p>
    </Section>
  )
}

export default Finished;