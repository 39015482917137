import React from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Breadcrumb(props) {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="white" href="/">
      Inicio
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="white"
      href="/academy"
    >
      Academy
    </Link>,
    <Typography key="3" color="textSecondary">
      {props.text}
    </Typography>,
  ];

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      color="white"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
}

export default Breadcrumb;