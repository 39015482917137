import React from "react";
import {LectureTitle, LectureContainer, LectureDuration, LeftItems, LectureInfos, TimeInfos, RightItems} from "./styles";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import QuizIcon from '@mui/icons-material/Quiz';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { ACADEMY } from "navigation/ROUTES";
import { Link } from "react-router-dom";
import { pathWithParams } from "utils/pathWithParams";
import { useProgressItem } from "./context";
import { Loader } from "components/ui";

function Lecture(props){
  const { item, applied, course_id, matriculation_id } = props;
  const { progressItem, isLoading } = useProgressItem(matriculation_id, item.id)

  let style = {fontSize: "22px", color: "#747579"}

  if(isLoading){
    return <Loader />; 
  }

  return (
    <>
      <LectureContainer>
        <LeftItems>
          {!item.is_quiz ? (<PlayCircleIcon style={style} />) : (<QuizIcon style={style} />)}
          <LectureInfos>
            <LectureTitle> {applied ? <Link to={pathWithParams(ACADEMY.COURSE.WATCH, {id: course_id, item_id: item.id, matriculation_id: matriculation_id})} >{item.title}</Link> : item.title}</LectureTitle>
            <TimeInfos>
              <QueryBuilderIcon style={{fontSize: "13px", color: "6c737a"}} />
              <LectureDuration>{item.duration} min.</LectureDuration>
            </TimeInfos>
          </LectureInfos>
        </LeftItems>

        <RightItems>
          {progressItem.watched ? <CheckCircleIcon style={{color: "green"}} /> : <RadioButtonUncheckedIcon />}
        </RightItems>
      </LectureContainer>
      <hr />
    </>
  )

}

export default Lecture;