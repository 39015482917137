import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useWatchCourse } from "./context";
import { Loader } from "components/ui";
import Quiz from "./quiz/Quiz";
import Drawer from "./drawer/Drawer";
import api from "services/api";
import { HeaderComplement, WindowElement, MainContent, HeaderLeft, LectureTitle, HeaderRight, Content, ConfirmDiv, ConfirmButton, LectureVideo, AttachmentContainer, AttachmentItem } from "./styles.js";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CardMedia from "@mui/material/CardMedia";
import { USERS, ACADEMY } from "navigation/ROUTES";
import { pathWithParams } from "utils/pathWithParams";

function Course(props) {
  const { user, isLogged } = props;
  const { watch, watchCourseIsLoading } = useWatchCourse();

  let navigate = useNavigate();

  if (watchCourseIsLoading) {
    return <Loader />;
  }

  const item = watch.item.data.attributes;
  const course = watch.course.data.attributes;
  const matriculation = watch.matriculation.data.attributes;
  const unviewedNextItem = watch.unviewed_next_item.data.attributes;
  const nextItem = watch.next_item.data.attributes;
  const progressItem = watch.progress_item.data.attributes;

  if (!watch.success) {
    navigate(ACADEMY.COURSE.INDEX);
    return;
  }

  function getContentSrc(content) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const iframeElement = doc.querySelector("iframe");
    const src = iframeElement.getAttribute("src");
    return src;
  }

  function redirectToNextItem(confirm) {
    let section_item_id = nextItem.id;

    if(confirm){
      section_item_id = unviewedNextItem.id;
    }

    if (progressItem.last_item && confirm) {
      window.location.reload();
    }

    navigate(
      pathWithParams(ACADEMY.COURSE.WATCH, {
        id: course.slug,
        item_id: section_item_id,
        matriculation_id: matriculation.id,
      })
    );
  }

  const successfulRequisition = (confirm) => {
    swal(
      "Sucesso!",
      `Você será redirecionado para a próxima aula.`,
      "success"
    ).then(() => {
      redirectToNextItem(confirm);
    });
  };

  const successfulFinalize = () => {
    swal("Sucesso!", `Parabéns, você concluiu o curso!`, "success").then(() => {
      navigate(
        pathWithParams(ACADEMY.COURSE.FINISHED, {
          id: course.slug,
          matriculation_id: matriculation.id,
        })
      );
    });
  };

  const unsuccessfulRequisition = (message) => {
    swal("Ops!", message, "warning");
  };

  const finalizeCourse = () => {
    if (isLogged) {
      const current_date = new Date().toLocaleDateString() + "";
      const data = { finished_at: current_date };
      api
        .put(`/v1/academy/matriculations/${matriculation.id}`, data)
        .then(() => successfulFinalize())
        .catch((error) => unsuccessfulRequisition());
    }
  };

  const renderConfirmButton = () => {
    if (!progressItem.watched) {
      return (
        <ConfirmDiv>
          {!item.is_quiz && "Você confirma que assistiu essa aula?"}
          <ConfirmButton onClick={() => updateProgressItem(confirm)}>
            Confirmar
          </ConfirmButton>
        </ConfirmDiv>
      );
    }
  };

  const updateProgressItem = (confirm) => {
    if (isLogged) {
      let data = null;

      data = { watched: true };

      if (!progressItem.watched) {
        api
          .put(`/v1/academy/progress_items/${progressItem.id}`, data)
          .then(() => successfulRequisition(confirm))
          .catch((error) => unsuccessfulRequisition());
      } else {
        successfulRequisition();
      }
    } else {
      navigate(USERS.LOGIN);
      swal("Ops!", "Você precisa estar logado realizar essa ação!", "warning");
    }
  };

  function renderAttachments(){
    if(item.attachments.length > 0){
      return (
        <>
          <h3 style={{textAlign: 'center', margin: "20px 0"}}>
            Arquivos disponíveis
          </h3>
          <AttachmentContainer>
            {item.attachments.map((attachment) => (
              <AttachmentItem>
                <AttachFileIcon fontSize={"large"} />
                <div>
                  <p>{attachment.name}</p>
                  <a href={attachment.url}>Baixar Arquivo</a>
                </div>
              </AttachmentItem>
            ))}
          </AttachmentContainer>
        </>
      )
    }
  }

  return (
    <WindowElement>
      <Drawer course={course} item={item} />
      <MainContent>
        <HeaderComplement>
          <HeaderLeft>
            <SlideshowIcon
              style={{ alignSelf: "center", marginRight: "8px" }}
            />
            <LectureTitle>{item.title}</LectureTitle>
          </HeaderLeft>
          <HeaderRight onClick={() => redirectToNextItem()}>
            Próxima Aula
            <ArrowForwardIcon />
          </HeaderRight>
        </HeaderComplement>
        <Content>
          {item.is_quiz ? (
            <>
              {!progressItem.watched && <Quiz quiz={item.quiz} />}
              {renderAttachments()}
            </>
          ) : (
            <>
              <LectureVideo>
                <CardMedia
                  component="iframe"
                  controls
                  width="100%"
                  height="600px"
                  src={getContentSrc(item.content)}
                  allowFullScreen
                />
              </LectureVideo>
              {renderAttachments()}
            </>
          )}
          {progressItem.watched && item.last_item ? (
            <ConfirmDiv>
              <ConfirmButton onClick={() => finalizeCourse()}>
                Finalizar curso
              </ConfirmButton>
            </ConfirmDiv>
          ) : (
            renderConfirmButton()
          )}
        </Content>
      </MainContent>
    </WindowElement>
  );
}

function mapStateToProps(state) {
  const user = state.session?.attributes;
  const isLogged = state.session?.isLogged;

  return { user, isLogged };
}

export default connect(mapStateToProps)(Course);
