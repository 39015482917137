import React from "react";
import Main from "./Main";
import "./style.scss";

function Index() {
  return (
    <div className="candidature-index">
      <Main />
    </div>
  );
}

export default Index;
