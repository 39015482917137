import React from "react";
import HeaderCourses from "../components/header/Header";
import Main from "./Main";

function Index(){
  

  return (
    <>
      <HeaderCourses title={"Todos os Cursos"} breadcrumb={"Todos os Cursos"} />
      <Main />
    </>
  )
}

export default Index;