import React from 'react';
import Section from './Section';
import Experiences from 'components/users/fields/experience/Experiences';

function ExperiencesSection(props){
  return(
    <Section title="Experiências">
      <Experiences {...props} />
    </Section>
  )
}

export default ExperiencesSection;